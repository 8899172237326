<template>
  <div class="z-500 w-full mx-auto fixed bottom-0 bg-white/80 backdrop-blur border-t-2 border-t-brand-800 opacity-100">
    <slot></slot>
    <div class="z-500 w-full flex justify-center items-center min-h-12 md:h-16">
      <div v-if="cartIsEmpty">
        <button
          class="p-4 font-bold transition-colors duration-150 flex-grow md:flex-none transition-all duration-150 cursor-not-allowed"
        >
          <div class="font-semibold text-sm text-center tracking-tighter">
            <p v-html="cartMessage"></p>
          </div>
        </button>
      </div>
      <div v-else class="w-full flex justify-center md:max-w-xl">
        <div class="w-1/3 p-1 flex justify-end">
          <button
            class="transition-all duration-300 ease-in-out relative p-3 h-full flex justify-end items-center rounded-md"
            :class="{
              'bg-gradient-to-br from-brand-900 to-brand-800 md:hover:bg-brand-600 text-white px-5':
                !isActivelySubmittingCart && inCart,
              'border-gray-brand-400 md:hover:bg-brand-100 md:hover:text-brand-600 text-brand-900 px-3 hover:px-4':
                isActivelySubmittingCart || !inCart
            }"
            @click="clickedCart($event.target)"
          >
            <BasicSpinner v-if="isActivelySubmittingCart" class="ml-3" />

            <div v-if="!isActivelySubmittingCart" class="h-5 text-sm flex items-center justify-between space-x-1.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 md:w-6 md:h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
              <div>
                <div v-if="showPrice">
                  <div class="font-bold text-left">{{ currentCart?.attributes?.total_price.format }}</div>
                  <div class="font-normal text-xs text-left -mt-0.5">
                    {{ $store.getters.cartSize }} item{{ $store.getters.cartSize === 1 ? '' : 's' }}
                  </div>
                </div>
                <div v-if="!showPrice">
                  <div class="font-bold text-left">
                    {{ $store.getters.cartSize }} item{{ $store.getters.cartSize === 1 ? '' : 's' }}
                  </div>
                </div>
              </div>
            </div>
          </button>
        </div>
        <div class="w-2/3 p-1" :class="{ 'border-l-2 border-l-brand-800': !cartIsClear }">
          <button
            v-if="!cartIsClear"
            class="relative p-3 font-bold w-full h-full flex-grow md:flex-none cursor-not-allowed"
          >
            <div class="font-semibold text-sm text-left leading-4 tracking-tighter">
              <p v-html="cartMessage"></p>
            </div>
          </button>
          <PrimaryButton
            v-if="cartIsClear"
            @click="clickedProceed"
            class="h-full w-full"
            :size="'lg'"
            :streaking="true"
          >
            <span v-if="inUpsell && !hasUpsellItems">{{ upsellButton }}</span>
            <span v-else-if="willAutocharge">Confirm order</span>
            <span v-else>Proceed to checkout</span>
            <template #right-icon><ArrowRightIcon class="h-4" /></template>
          </PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let strftime = require('strftime')
import BasicButton from '@/components/Molecules/BasicButton'
import BasicSpinner from '@/components/Molecules/BasicSpinner'
import { ArrowRightIcon } from '@heroicons/vue/solid'
import { mapGetters } from 'vuex'
import PrimaryButton from '@/components/Molecules/PrimaryButton'
import mapRelationship from '@/utils/mapRelationship'

export default {
  components: { BasicButton, ArrowRightIcon, BasicSpinner, PrimaryButton },
  props: {
    inCart: {
      type: Boolean
    },
    inUpsell: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters(['info']),
    link() {
      if (this.$store.getters.ownsBottle(this.$store.getters.currentBottle)) {
        return 'Checkout'
      } else if (this.$store.getters.authorizedForBottle(this.$store.getters.currentBottle)) {
        return 'Login'
      } else {
        return 'Home'
      }
    },
    isActivelySubmittingCart() {
      return this.$store.state.activelySubmittingCart
    },
    currentCart() {
      return this.$store.getters.currentCart
    },
    currentBottle() {
      return this.$store.getters.currentBottle
    },
    // CLEARING: COME BACK AND REFORM THESE
    cartIsClear() {
      return !this.currentBottle?.attributes?.validations?.has_cart_errors
    },
    cartMessage() {
      return this.currentBottle?.attributes?.validations?.cart_errors?.[0] || 'The cart is empty'
    },
    showSeparator() {
      return false && !this.cartIsClear && !this.inCart
    },
    cartIsEmpty() {
      return this.$store.getters.cartSize == 0
    },
    showPrice() {
      return this.currentBottle?.attributes.show_prices
    },
    facebookPixelContainerID() {
      return this.info?.attributes?.facebook_pixel_container_id
    },
    bottleCycleDetails() {
      return this.currentBottle?.attributes?.bottle_cycle_details
    },
    willAutocharge() {
      return this.bottleCycleDetails?.autocharge
    },
    hasUpsellItems() {
      return this.$store.getters.cartItems.some((x) => x.attributes.frontend_key.endsWith('-upsell'))
    },
    upsellButton() {
      const upsellCategory = mapRelationship(this.$store.state, this.upsells[0]?.relationships.category.data)
      return upsellCategory?.attributes.upsell_button_text || 'No thanks'
    }
  },
  async mounted() {
    this.upsells = (await this.$store.dispatch('getUpsells')).data
  },
  watch: {
    async currentCart() {
      this.upsells = (await this.$store.dispatch('getUpsells')).data
    }
  },
  data() {
    return { upsells: [] }
  },
  methods: {
    clickedCart() {
      this.$emit('showCart', !this.inCart)
    },
    clickedProceed() {
      if (this.upsells.length && !this.inUpsell && !this.hasUpsellItems) {
        this.$emit('showUpsell', true)
      } else {
        this.handleTracking()
        this.$router.push({ name: this.link })
        this.$store.dispatch('confirmCart')
      }
    },
    orderForDate(forBottle) {
      if (!forBottle) return null
      if (forBottle.attributes.is_bottle_cycle) {
        if (this.bottleCycleDetailsForBottle(forBottle)?.fulfillment_slot_day) {
          return new Date(this.bottleCycleDetailsForBottle(forBottle)?.fulfillment_slot_day + 'T12:00:00.000Z')
        } else if (this.bottleCycleDetailsForBottle(forBottle)?.possible_fulfillment_days.length > 0) {
          return new Date(this.bottleCycleDetailsForBottle(forBottle)?.possible_fulfillment_days[0] + 'T12:00:00.000Z')
        }
      } else {
        if (this.fulfillmentSlotForBottle(forBottle)) {
          return new Date(this.fulfillmentSlotForBottle(forBottle).attributes.fulfillment_date + 'T12:00:00.000Z')
        }
      }

      return null
    },
    bottleCycleDetailsForBottle(forBottle) {
      return forBottle?.attributes?.bottle_cycle_details
    },
    fulfillmentSlotForBottle(bottle) {
      return this.$store.getters.fulfillmentSlotForBottle(bottle)
    },
    handleTracking() {
      window.bottle.hooks.v1.emit('begin_checkout', {
        currency: this.$store.getters.checkout?.attributes.bottle_total.currency_iso,
        value: this.$store.getters.checkout?.attributes.bottle_total.cents / 100,
        items: this.$store.getters.cartItems.map((x) => ({
          item_id: x.attributes.id,
          item_name: mapRelationship(this.$store.state, x.relationships.product.data).attributes.product_name,
          price: x.attributes.total_price.cents / 100,
          quantity: x.attributes.quantity
        }))
      })
      if (this.facebookPixelContainerID) {
        window.fbq('track', 'InitiateCheckout')
      }
    }
  }
}
</script>
