<template>
  <div class="flex flex-col">
    <span>{{ address }}</span>
    <span>{{ props.deliveryAddress?.attributes.notes }}</span>
  </div>
</template>
<script setup>
import { computed } from 'vue'
const props = defineProps(['deliveryAddress'])
const address = computed(() => {
  const attrs = props.deliveryAddress?.attributes
  return [attrs?.address1, attrs?.address2, attrs?.city, attrs?.state, attrs?.zip].filter(Boolean).join(', ')
})
</script>
