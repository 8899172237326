<template>
  <section v-if="variant" v-show="showVariant">
    <div
      class="transition-all text-sm border-t px-3 py-3 justify-between cursor-pointer"
      @click="$emit('open', variant.attributes.id)"
    >
      <button class="transition-all uppercase tracking-wider text-left inline-flex items-center flex-shrink">
        {{ variant.attributes.name }}
        <div class="inline-block ml-1 w-4 h-4 flex-shrink-0">
          <svg class="transition-all" v-show="!openTray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
          <svg class="transition-all" v-show="openTray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z" />
          </svg>
        </div>
      </button>
      <div
        v-if="selectedVariantOptions.length > 0"
        v-show="!openTray"
        class="transition-all flex flex-shrink-0 flex-wrap min-w-[5em] items-center justify-beginning gap-2 mt-2"
      >
        <div
          v-for="(variantOption, i) in selectedVariantOptions"
          :key="variantOption.id"
          class="transition-all flex-inline bg-gray-brand-200 text-gray-brand-700 rounded-lg text-xs font-manrope font-semibold tracking-tight px-2 py-1 flex-none"
        >
          {{ variantOption.attributes.name }}
          <span v-if="(quantityAddedForVariantOption(variantOption.id) || 0) > 1">
            x {{ quantityAddedForVariantOption(variantOption.id) }}
          </span>
        </div>
      </div>
    </div>
    <div v-show="openTray" class="transition-all px-3 pb-1">
      <ProductVariantOptions
        :variant="variant"
        :submittable-cart-item="submittableCartItem"
        :show-price="showPrice"
        :product="product"
        :category-product-store-pair="categoryProductStorePair"
      />
    </div>
    <div
      v-if="showMinimumToAddNotice || showTooManyNotice"
      class="transition-all text-sm px-3 pb-3 justify-between cursor-pointer"
      @click="$emit('open', variant.attributes.id)"
    >
      <div
        v-if="showMinimumToAddNotice"
        class="transition-all w-full mt-2 py-1 px-2 rounded-md bg-red-50 text-red-900 border border-red-900 text-center"
      >
        Select {{ needToAddCount }} more
      </div>
      <div
        v-else-if="showTooManyNotice"
        class="transition-all w-full mt-2 py-1 px-2 rounded-md bg-red-50 text-red-900 border border-red-900 text-center"
      >
        Remove {{ needToRemoveCount }} options
      </div>
    </div>
  </section>
</template>

<script>
import { ProductVariantOptions } from '@/components'

export default {
  name: 'ProductVariant',

  components: {
    ProductVariantOptions
  },

  props: {
    variant: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    submittableCartItem: {
      type: Object,
      required: false
    },
    openTray: {
      type: Boolean,
      required: true
    },
    categoryProductStorePair: {
      required: true
    }
  },

  data() {
    return {}
  },

  computed: {
    selectedVariantOptions() {
      return this.$store.getters.variantOptionsWithPointer(this.selectedVariantOptionIDs)
    },
    selectedVariantOptionIDs() {
      return this.submittableCartItem?.cart_item_variant_options_attributes
        .filter((option) => option.variant_id == this.variant.attributes?.id)
        .map((option) => ({
          id: option.variant_option_id,
          type: 'variant_option'
        }))
    },
    showPrice() {
      return this.categoryProductStorePair.attributes.show_prices
    },
    showVariant() {
      return this.variant.attributes.is_visible
    },
    totalQuantityAddedForVariant() {
      if (!this.submittableCartItem || !this.submittableCartItem.cart_item_variant_options_attributes) return 0
      return this.submittableCartItem.cart_item_variant_options_attributes
        .filter((x) => x.variant_id == this.variant.id)
        .reduce((sum, x) => sum + (x.quantity ? x.quantity : 0), 0)
    },
    minimumToAdd() {
      return this.variant?.attributes?.minimum || 0
    },
    maximumAllowed() {
      return this.variant?.attributes.maximum || 100
    },
    showTooManyNotice() {
      return (
        this.totalQuantityAddedForVariant > this.maximumAllowed &&
        this.customQuantities &&
        this.needToRemoveCount > 0 &&
        this.cartItemQuantity > 0
      )
    },
    showMinimumToAddNotice() {
      return (
        this.totalQuantityAddedForVariant < this.minimumToAdd &&
        this.customQuantities &&
        this.needToAddCount > 0 &&
        this.cartItemQuantity > 0 &&
        !this.isFirstSubmission
      )
    },
    needToAddCount() {
      return this.minimumToAdd - this.totalQuantityAddedForVariant
    },
    needToRemoveCount() {
      return this.totalQuantityAddedForVariant - this.maximumAllowed
    },
    customQuantities() {
      return this.variant.attributes.quantity_option == 'custom_quantities'
    },
    cartItemQuantity() {
      return this.submittableCartItem?.quantity || 0
    },
    isActivelySubmittingCart() {
      return this.$store.state.activelySubmittingCart
    },
    isFirstSubmission() {
      return this.isActivelySubmittingCart && this.totalQuantityAddedForVariant == 0
    }
  },

  methods: {
    quantityAddedForVariantOption(variantOptionID) {
      if (!this.submittableCartItem || !this.submittableCartItem.cart_item_variant_options_attributes) return 0
      return this.submittableCartItem.cart_item_variant_options_attributes
        .filter((x) => x.variant_option_id == variantOptionID)
        .reduce((sum, x) => sum + (x.quantity ? x.quantity : 0), 0)
    }
  }
}
</script>
