<template>
  <div class="w-full">
    <TemplateMembership
      v-if="showMembershiptier"
      :show-tier="true"
      :show-list="true"
      :show-store="false"
      :show-autocharge="false"
    />
    <div v-if="isFulfillmentSlot && showFulfillmentslot" id="fulfillmentModule" class="w-full mt-3">
      <!-- selection module -->
      <FulfillmentSelectionModule
        v-if="showSelectionFulfillmentModule"
        :fulfillment-options-section="fulfillmentSelectionModuleTab"
        :is-loading="isLoading"
        @clicked-new-method="clickedNewMethod"
        @show-loading="showLoading"
      />
      <!-- selected module -->
      <FulfillmentSelectedModule
        v-if="showSelectedFulfillmentModule"
        layout="checkout"
        :is-loading="isLoading"
        @select-new-fulfillment-option="selectNewFulfillmentOption"
        @show-loading="showLoading"
      />
    </div>
    <div v-if="isPackage && showPackage">
      <PackageSelection
        :bottle="bottle"
        :store="store"
        :always-show="true"
        :show-button="false"
        @pending-selection="setIsLoadingButtons"
      />
    </div>
    <div
      v-if="showCollapsedRow && !isMembershiptier && !isFulfillmentSlot"
      class="flex w-full border-b"
      :class="{ 'py-2': addedPaddingNeeded }"
    >
      <div class="flex justify-between pt-4 pb-4 pl-6 pr-2 w-full">
        <div class="flex items-center">
          <fieldset class="">
            <div class="leading-5">
              <div class="flex items-center">
                <label for="1" class="">
                  <span class="text-md font-semibold text-brand-gray-800">
                    {{ mainText }}
                  </span>
                  <div v-if="hasSecondaryText" class="text-sm text-gray-brand-600">
                    {{ secondaryText }}
                  </div>
                </label>
              </div>
            </div>
          </fieldset>
        </div>
        <div v-if="canChange" class="flex items-center">
          <button
            class="items-center px-2.5 py-2 text-xs font-medium italic rounded-md bg-gray-brand-100 text-gray-brand-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
            @click="changeClicked"
          >
            Change
          </button>
        </div>
      </div>
    </div>
    <Box
      v-else-if="!showCollapsedRow || showMembershiptier || showFulfillmentslot || showPackage"
      :is-loading="isLoadingButtons"
      :class="{
        'py-2': addedPaddingNeeded,
        'mt-4 md:mt-6 bg-white rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600 w-full':
          !hasAnswer,
        'border-b': hasAnswer
      }"
    >
      <InfoRowOptions
        v-if="showChooseDropdown"
        :options="options"
        :selected-option="selectedOption"
        :options-type="optionsType"
        :class="{ 'pl-6': !hasIcon }"
        @clicked-option="clickedOption"
      />
      <InfoRowEditField
        v-if="showSingleEditField"
        :class="{ 'w-full': !hasIcon, 'w-4/5': hasIcon }"
        :placeholder="placeholder"
        :text-field-value="answer?.attributes?.value"
        :name="fieldName"
        :action-text="hasAnswer ? 'Save' : 'Next'"
        action-type="branded"
        :optional="optional"
        :mode="hasAnswer ? 'inline' : 'dedicated'"
        @saved-option="savedOption"
        @skip-question="skipQuestion"
        @cancel-answer="cancelAnswer"
      />
      <InfoRowEditDualFields
        v-if="showDualEditField"
        :class="{ 'w-full': !hasIcon, 'w-4/5': hasIcon }"
        placeholder1="First name"
        placeholder2="Last name"
        :text-field-value1="
          answer?.attributes?.value?.split(' ').length > 0 ? answer?.attributes?.value?.split(' ')?.[0] : ''
        "
        :text-field-value2="
          answer?.attributes?.value?.split(' ').length > 1 ? answer?.attributes?.value?.split(' ')?.[1] : ''
        "
        name1="first-name"
        name2="last-name"
        :action-text="hasAnswer ? 'Save' : 'Next'"
        action-type="branded"
        :optional="optional"
        :mode="hasAnswer ? 'inline' : 'dedicated'"
        @saved-option="savedOption"
        @skip-question="skipQuestion"
        @cancel-answer="cancelAnswer"
      />
      <InfoRowEditTextarea
        v-if="showEditTextarea"
        :class="{ 'w-full': !hasIcon, 'w-4/5': hasIcon }"
        :placeholder="placeholder"
        :text-field-value="answer?.attributes?.value"
        :name="fieldName"
        :action-text="hasAnswer ? 'Save' : 'Next'"
        action-type="branded"
        :optional="optional"
        :mode="hasAnswer ? 'inline' : 'dedicated'"
        @saved-option="savedOption"
        @skip-question="skipQuestion"
        @cancel-answer="cancelAnswer"
      />
      <InfoRowMultiselect
        v-if="showMultiselect"
        :class="{ 'w-full': !hasIcon, 'w-4/5': hasIcon }"
        :options-type="optionsType"
        :options="options"
        :selected-options="selectedOptions"
        :action-text="hasAnswer ? 'Save' : 'Next'"
        action-type="branded"
        :optional="optional"
        :mode="hasAnswer ? 'inline' : 'dedicated'"
        @saved-options="savedOptions"
        @skip-question="skipQuestion"
        @cancel-answer="cancelAnswer"
      />
      <InfoRowSingleselect
        v-if="showSingleselect"
        :class="{ 'w-full': !hasIcon, 'w-4/5': hasIcon }"
        :options-type="optionsType"
        :options="options"
        :selected-options="selectedOptions"
        :action-text="hasAnswer ? 'Save' : 'Next'"
        action-type="branded"
        :optional="optional"
        :mode="hasAnswer ? 'inline' : 'dedicated'"
        @saved-options="savedOptions"
        @skip-question="skipQuestion"
        @cancel-answer="cancelAnswer"
      />
      <InfoRowActionButtons
        v-if="showMembershiptier || showFulfillmentslot || showPackage"
        class="w-full pt-3"
        :action-text="hasAnswer ? 'Save' : 'Next'"
        action-type="branded"
        :optional="optional"
        :mode="hasAnswer ? 'inline' : 'dedicated'"
        :can-proceed="canProceed"
        @saved-options="skipQuestion"
        @skip-question="skipQuestion"
        @cancel-answer="cancelAnswer"
      />
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Molecules/Box'
import InfoRowOptions from '@/components/Checkout/InfoRowOptions'
import InfoRowEditField from '@/components/Checkout/InfoRowEditField'
import InfoRowEditDualFields from '@/components/Checkout/InfoRowEditDualFields'
import InfoRowEditTextarea from '@/components/Checkout/InfoRowEditTextarea'
import InfoRowMultiselect from '@/components/Checkout/InfoRowMultiselect'
import InfoRowSingleselect from '@/components/Checkout/InfoRowSingleselect'
import InfoRowActionButtons from '@/components/Checkout/InfoRowActionButtons'

import FulfillmentSelectionModule from '@/components/Checkout/FulfillmentSelectionModule'
import FulfillmentSelectedModule from '@/components/Checkout/FulfillmentSelectedModule'

import PackageSelection from '@/components/Checkout/PackageSelection'

import TemplateMembership from '@/components/Checkout/TemplateMembership'
import mapRelationship from '@/utils/mapRelationship'

export default {
  name: 'QuestionRow',
  components: {
    Box,
    InfoRowOptions,
    InfoRowEditField,
    InfoRowEditDualFields,
    InfoRowEditTextarea,
    InfoRowMultiselect,
    InfoRowSingleselect,
    InfoRowActionButtons,
    TemplateMembership,
    FulfillmentSelectionModule,
    FulfillmentSelectedModule,
    InfoRowEditDualFields,
    PackageSelection
  },
  props: ['question', 'answer', 'editable'],
  data: () => ({
    choose: false,
    layout: 'checkout',
    selectNewFulfillmentMethod: false,
    fulfillmentSelectionModuleTab: 'pickup',
    isLoading: false,
    isLoadingButtons: false
  }),
  computed: {
    currentRouteName() {
      return this.$route.name
    },
    bottle() {
      return this.$store.getters.currentBottle
    },
    store() {
      return mapRelationship(this.$store.state, this.bottle?.relationships?.store?.data)
    },
    canChange() {
      return this.hasAnswer && this.editable
    },
    hasAnswer() {
      return this.answer && true
    },
    canProceed() {
      if (this.showFulfillmentslot) {
        return this.fulfillmentSlotID
      } else if (this.showPackage) {
        return this.packageID
      } else {
        return true
      }
    },
    mainText() {
      return this.question?.attributes?.question
    },
    secondaryText() {
      if (this.answer?.attributes.value_description) return this.answer?.attributes.value_description
      else return this.answer?.attributes.values_description
    },
    hasSecondaryText() {
      return this.secondaryText?.length > 0
    },
    options() {
      return this.question?.attributes?.possible_answers
    },
    selectedOption() {
      return null
    },
    selectedOptions() {
      if (this.answer?.attributes?.values) return this.answer?.attributes?.values
      return []
    },
    optionsType() {
      return this.question?.attributes?.answer_format
    },
    placeholder() {
      return this.question?.attributes?.placeholder
    },
    fieldName() {
      return this.question?.attributes?.field_name
    },
    hasOptions() {
      return this.options && this.options.length > 1
    },
    optional() {
      return !this.question?.attributes?.required
    },
    isEditField() {
      return this.optionsType == 'textfield'
    },
    isTextArea() {
      return this.optionsType == 'textarea'
    },
    isMultiselect() {
      return this.optionsType == 'multiselect'
    },
    isSingleselect() {
      return this.optionsType == 'singleselect'
    },
    isMembershiptier() {
      return this.optionsType == 'membershiptier'
    },
    isFulfillmentSlot() {
      return this.optionsType == 'fulfillmentslot'
    },
    isPackage() {
      return this.optionsType == 'package'
    },
    isDropdown() {
      return this.optionsType == 'dropdown'
    },
    layoutReceipt() {
      return this.layout == 'receipt'
    },
    layoutCheckout() {
      return this.layout == 'checkout'
    },
    showChooseDropdown() {
      return this.isDropdown && (!this.hasAnswer || this.choose)
    },
    showEditField() {
      return this.isEditField && (!this.hasAnswer || this.choose)
    },
    showSingleEditField() {
      return this.showEditField && !this.showDualEditField
    },
    showDualEditField() {
      return this.showEditField && this.fieldName == 'first-and-last-name'
    },
    showEditTextarea() {
      return this.isTextArea && (!this.hasAnswer || this.choose)
    },
    showMultiselect() {
      return this.isMultiselect && (!this.hasAnswer || this.choose)
    },
    showSingleselect() {
      return this.isSingleselect && (!this.hasAnswer || this.choose)
    },
    showMembershiptier() {
      return this.isMembershiptier && !this.hasAnswer
    },
    showFulfillmentslot() {
      return this.isFulfillmentSlot && !this.hasAnswer
    },
    showPackage() {
      return this.isPackage && !this.hasAnswer
    },
    showCollapsedRow() {
      return this.hasAnswer && !this.choose
    },
    showLoadingButtons() {
      return this.isLoadingButtons
    },
    // fulfillment slot stuff
    showSelectionFulfillmentModule() {
      return this.selectNewFulfillmentMethod || this.fulfillmentSlotID == null
    },
    showSelectedFulfillmentModule() {
      return this.fulfillmentSlotID
    },
    fulfillmentSlotID() {
      return this.$store.getters.fulfillmentSlot?.id
    },
    packageID() {
      return mapRelationship(this.$store.state, this.bottle?.relationships?.package?.data)?.id
    },
    showDeliveryFulfillmentOptionsFirst() {
      return mapRelationship(this.$store.state, this.bottle?.relationships?.store?.data)?.attributes
        ?.show_delivery_fulfillment_options_first
    }
  },
  watch: {
    fulfillmentSlotID: function (newID, oldID) {
      if (newID !== null) {
        this.selectNewFulfillmentMethod = false
      }
    }
  },
  async mounted() {
    window.scrollTo({ top: 0 })
    if (this.isFulfillmentSlot && this.currentRouteName == 'Selection') {
      await this.$store.dispatch('getBottle', {
        includes: [
          'fulfillment_slot',
          'possible_fulfillment_methods',
          'possible_fulfillment_slot_days',
          'possible_fulfillment_slot_times',
          'possible_delivery_addresses',
          'possible_fulfillment_methods.delivery_address',
          'possible_fulfillment_methods.fulfillment_slots_detail',
          'delivery_address',
          'checkout_questions',
          'selection_questions'
        ]
      })
    } else if (this.isPackage && this.currentRouteName == 'Selection') {
      await this.$store.dispatch('getBottle', {
        includes: ['store', 'package', 'store.possible_packages']
      })
    }
    if (this.showDeliveryFulfillmentOptionsFirst) {
      this.fulfillmentSelectionModuleTab = 'delivery'
    } else {
      this.fulfillmentSelectionModuleTab = 'pickup'
    }
  },
  methods: {
    savedOptions(values) {
      if (values) {
        this.isLoadingButtons = true
        this.$store
          .dispatch('answerQuestion', {
            questionID: this.question?.id,
            payload: {
              answer: {
                values: values
              }
            }
          })
          .then(
            (response) => {
              // properly updated
              this.isLoadingButtons = false
              window.scrollTo({ top: 0 })
            },
            (error) => {
              // failed to update
              this.isLoadingButtons = false
            }
          )
        this.choose = false
      }
    },
    savedOption(value) {
      // submit the answer to Rails
      if (value) {
        this.isLoadingButtons = true
        this.$store
          .dispatch('answerQuestion', {
            questionID: this.question?.id,
            payload: {
              answer: {
                value: value
              }
            }
          })
          .then(
            (response) => {
              // properly updated
              this.isLoadingButtons = false
              window.scrollTo({ top: 0 })
            },
            (error) => {
              // failed to update
              this.isLoadingButtons = false
            }
          )
        this.choose = false
      }
    },
    skipQuestion() {
      this.isLoadingButtons = true
      this.$store
        .dispatch('answerQuestion', {
          questionID: this.question?.id,
          payload: {
            answer: {
              skipped: true
            }
          }
        })
        .then(
          (response) => {
            // properly updated
            this.isLoadingButtons = false
            window.scrollTo({ top: 0 })
          },
          (error) => {
            // failed to update
            this.isLoadingButtons = false
          }
        )
    },
    setIsLoadingButtons(value) {
      this.isLoadingButtons = value
    },
    cancelAnswer() {
      this.choose = false
    },
    changeClicked() {
      this.choose = true
    },
    selectNewFulfillmentOption(option) {
      this.selectNewFulfillmentMethod = true
      if (option == 'pickup') {
        this.fulfillmentSelectionModuleTab = 'pickup'
      } else if (option == 'address') {
        this.fulfillmentSelectionModuleTab = 'delivery'
      }
    },
    clickedNewMethod() {
      this.selectNewFulfillmentMethod = false
    },
    showLoading(value) {
      this.isLoading = value
    }
  }
}
</script>
