<template>
  <div>
    <div class="flex justify-right pt-4 pb-4 pl-4 pr-2 w-full">
      <input
        id="infoRowTextfield1"
        v-model="textFieldValue1"
        type="text"
        autocomplete="on"
        :name="fieldName1"
        :aria-label="placeholder1"
        class="form-input rounded-lg bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5 flex-grow mr-2 w-1/2"
        :placeholder="placeholder1"
        @keyup.enter="savedOption"
      />
      <input
        id="infoRowTextfield2"
        v-model="textFieldValue2"
        type="text"
        autocomplete="on"
        :name="fieldName2"
        :aria-label="placeholder2"
        class="form-input rounded-lg bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5 flex-grow mr-2 w-1/2"
        :placeholder="placeholder2"
        @keyup.enter="savedOption"
      />
      <button
        v-if="!brandedActionType"
        class="px-4 py-2 text-gray-brand-600 border border-transparent font-semibold text-sm rounded-full hover:border-gray-brand-600 flex-grow-0"
        @click="savedOption"
      >
        {{ actionText }}
      </button>
    </div>
    <div v-if="brandedActionType" class="p-4 pt-0 mt-1">
      <PrimaryButton
        @click="savedOption"
        class="w-full"
        :class="{
          'cursor-pointer opacity-100': canProceed,
          'cursor-not-allowed opacity-50': !canProceed
        }"
        :size="'xl'"
        :streaking="canProceed"
        :justify="'justify-center'"
      >
        {{ actionText }}
      </PrimaryButton>
    </div>
    <div v-if="showOptionalButton" class="p-4 pt-0 -mt-1">
      <SecondaryButton v-if="brandedActionType" @click="skipQuestion" class="w-full" :size="'xl'">
        Skip Question
      </SecondaryButton>
    </div>
    <div v-if="showCancelButton" class="p-4 pt-0 -mt-1">
      <SecondaryButton v-if="brandedActionType" @click="cancelAnswer" class="w-full" :size="'xl'">
        Cancel
      </SecondaryButton>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '../Molecules/PrimaryButton.vue'
import SecondaryButton from '@/components/Molecules/SecondaryButton'
export default {
  name: 'InfoRowEditDualFields',
  components: { PrimaryButton, SecondaryButton },
  props: [
    'textFieldValue1',
    'textFieldValue2',
    'placeholder1',
    'placeholder2',
    'actionText',
    'actionType',
    'name1',
    'name2',
    'optional',
    'mode'
  ],
  data: () => ({}),
  computed: {
    brandedActionType() {
      return this.actionType == 'branded'
    },
    fieldName1() {
      if (this.name1 && this.name1.length > 0) return this.name1
      return 'infoRowTextfield1'
    },
    fieldName2() {
      if (this.name2 && this.name2.length > 0) return this.name2
      return 'infoRowTextfield2'
    },
    canProceed() {
      return (
        this.textFieldValue1 &&
        this.textFieldValue1?.length > 0 &&
        this.textFieldValue2 &&
        this.textFieldValue2?.length > 0
      )
    },
    combinedTextFieldValues() {
      return this.textFieldValue1 + ' ' + this.textFieldValue2
    },
    showOptionalButton() {
      return this.optional && !this.inlineMode
    },
    showCancelButton() {
      return this.inlineMode
    },
    inlineMode() {
      return this.mode == 'inline'
    }
  },
  mounted() {},
  methods: {
    savedOption() {
      if (!this.canProceed) return
      this.$emit('savedOption', this.combinedTextFieldValues)
    },
    skipQuestion() {
      this.$emit('skipQuestion')
    },
    cancelAnswer() {
      this.$emit('cancelAnswer')
    }
  }
}
</script>
