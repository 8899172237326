import { provide, inject } from 'vue'

const StoreSymbol = Symbol()

export function provideCollapseStore(store) {
  provide(StoreSymbol, store)
}

export function useCollapseStore() {
  const store = inject(StoreSymbol)
  if (!store) {
    // throw error, no store provided
  }
  return store
}
