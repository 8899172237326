<template>
  <div class="bg-gray-brand-100 pt-4 pb-10">
    <!-- Navbar -->
    <div class="fixed w-full top-0 z-10">
      <div class="w-full">
        <MerchantNav :disable-hide-on-scroll="true" :empty-middle="true">
          <ProgressPills :pills="progressPills" />
        </MerchantNav>
      </div>
    </div>

    <!-- Main -->
    <div class="w-full max-w-5xl mx-auto pt-10 pb-20 h-full">
      <!-- Thank you -->
      <div class="px-2 md:px-0 md:w-1/2 mx-auto mt-10 text-center mb-8 sm:mb-12">
        <span
          class="flex-shrink-0 inline-block px-2 py-0.5 text-white text-xs font-medium bg-brand-600 rounded-full mb-3"
        >
          Order #{{ bottle?.attributes.display_id }} Paid
        </span>
        <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">{{ thankYouText }}</h3>
        <!-- <p class="text-md sm:mt-4 sm:text-lg text-gray-500">We appreciate it.</p> -->
      </div>

      <!-- Top level info -->
      <div class="mb-8 bg-gray-brand-200 rounded-md py-4 px-6 md:w-1/2 mx-auto">
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-9">
            <div class="text-sm font-bold mb-1">{{ displayPaymentTimestamp }}</div>
            <div class="text-xs mb-1">Time paid</div>
          </div>
          <div class="col-span-3">
            <div v-if="!isMultibottleFunctionality()" class="text-sm font-bold mb-1">
              {{ checkout?.attributes.bottle_total.format }}
            </div>
            <div v-if="isMultibottleFunctionality()" class="text-sm font-bold mb-1">
              {{ checkout?.attributes.multibottle_bottle_total.format }}
            </div>
            <div class="text-xs mb-1">Order total</div>
          </div>
        </div>
      </div>

      <!-- questions -->
      <div
        v-for="question in receipt_questions"
        :key="question.id"
        class="px-4 md:px-0 md:w-1/2 mx-auto mb-8"
        :class="{ 'mt-4': question?.attributes?.question || question?.attributes?.secondary_info }"
      >
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter">{{ question?.attributes?.question }}</div>
        </div>
        <div v-if="question?.attributes?.secondary_info" class="flex justify-start items-center">
          <h3 class="text-md text-gray-brand-700 w-full">
            {{ question?.attributes?.secondary_info }}
          </h3>
        </div>
        <QuestionRow :question="question" :editable="true" />
      </div>

      <!-- Fulfillment -->
      <div
        v-if="requiresFulfillmentSlot && !isMultibottleFunctionality()"
        id="fulfillmentModule"
        class="px-2 md:px-0 md:w-1/2 mx-auto mt-14 md:mt-12 mb-8 pointer-events-none"
      >
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter ml-3 capitalize">
            {{ fulfillmentDisplayTitle }}
          </div>
        </div>

        <!-- selected module -->
        <FulfillmentSelectedModule v-if="fulfillmentSlotForBottle(bottle)" layout="receipt" class="mt-4" />
      </div>
      <div v-if="isMultibottleFunctionality() && bottlesList">
        <div
          v-for="bottle in bottlesList"
          id="fulfillmentModule"
          :key="bottle.id"
          class="px-2 md:px-0 md:w-1/2 mx-auto mt-14 md:mt-12 mb-8 pointer-events-none"
        >
          <div class="flex justify-start items-center">
            <div class="text-xl font-bold text-gray-brand-900 tracking-tighter ml-3 capitalize">
              {{ fulfillmentDisplayTitleForBottle(bottle) }}
            </div>
          </div>
          <FulfillmentSelectedModule
            v-if="fulfillmentSlotForBottle(bottle)"
            :passed-bottle="bottle"
            layout="receipt"
            class="mt-4"
          />
        </div>
      </div>

      <!-- /Fulfillment -->

      <!-- Note -->
      <div v-if="hasNote" class="px-2 md:px-0 md:w-1/2 mx-auto mt-14 mb-8">
        <div class="flex justify-between items-center mb-4">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter ml-3">Note</div>
        </div>
        <NoteSection :bottle="bottle" :note="storeNote" />
      </div>

      <!-- Summary -->
      <div class="px-2 md:px-0 md:w-1/2 mx-auto mt-14 mb-8">
        <div class="flex justify-start items-center mb-4">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter ml-3">Order Summary</div>
        </div>
        <OrderSummarySection
          :list="$store.getters.cartItems"
          :checkout-attributes="checkout?.attributes"
          use-neutral-theme
          has-discount-code-list
        />
      </div>
      <!-- /Summary -->

      <!-- Payment -->
      <div class="px-2 md:px-0 md:w-1/2 mx-auto mt-14 mb-8">
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter ml-3">Payment</div>
        </div>

        <!-- Read-only receipt layout for payment method: -->
        <!-- TODO: replace placeholder content -->
        <div
          class="mt-4 md:mt-6 bg-white rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600"
        >
          <div v-if="hasAccountCreditAmount" class="leading-5 ml-3 pt-4 pb-5 px-6 border-b">
            <span class="text-md font-semibold text-brand-gray-800 mb-1 block">
              {{ customer?.attributes?.user_name }}
            </span>
            <!-- <div class="text-sm text-gray-brand-600"></div> -->
            <div class="flex justify-between mt-3 items-center">
              <div class="flex items-center flex-grow">
                <div class="text-sm font-mono text-gray-brand-900">
                  Used {{ bottlePayment?.attributes?.account_credit_amount?.format }} of account credit.
                </div>
              </div>
            </div>
          </div>
          <div v-if="stripePaymentIntentSucceeded" class="leading-5 ml-3 pt-4 pb-5 px-6">
            <span class="text-md font-semibold text-brand-gray-800 mb-1 block">
              {{ stripePaymentMethod?.attributes?.name }}
            </span>
            <!-- <div class="text-sm text-gray-brand-600"></div> -->
            <div class="flex justify-between mt-3 items-center">
              <div class="flex items-center flex-grow">
                <span class="w-8 mr-3 text-gray-500 block">
                  <svg focusable="false" viewBox="0 0 32 21">
                    <g fill="currentColor" fill-rule="evenodd">
                      <path
                        d="M26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38v14.25A2.4 2.4 0 0 1 26.58 19zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
                        opacity=".2"
                      ></path>
                      <path
                        d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
                        opacity=".3"
                      ></path>
                    </g>
                  </svg>
                </span>
                <div class="text-sm font-mono text-gray-brand-900">
                  *{{ stripePaymentMethod?.attributes?.last4 }} {{ stripePaymentMethodBrand }}
                  {{ stripePaymentMethod?.attributes?.exp_month }}/{{ stripePaymentMethod?.attributes?.exp_year }}
                </div>
              </div>

              <div class="ml-3 text-sm font-mono text-gray-brand-900">
                {{ stripePaymentIntent?.attributes?.amount.format }}
              </div>
            </div>
          </div>
          <div v-if="hasRefundedAmount" class="leading-5 ml-3 pt-4 pb-5 px-6 border-b">
            <span class="text-md font-semibold text-brand-gray-800 mb-1 block">
              {{ stripePaymentMethod?.attributes?.name }}
            </span>
            <!-- <div class="text-sm text-gray-brand-600"></div> -->
            <div class="flex justify-between mt-3 items-center">
              <div class="flex items-center flex-grow">
                <span class="w-8 mr-3 text-gray-500 block">
                  <svg focusable="false" viewBox="0 0 32 21">
                    <g fill="currentColor" fill-rule="evenodd">
                      <path
                        d="M26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38v14.25A2.4 2.4 0 0 1 26.58 19zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
                        opacity=".2"
                      ></path>
                      <path
                        d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
                        opacity=".3"
                      ></path>
                    </g>
                  </svg>
                </span>
                <div class="text-sm font-mono text-gray-brand-900">
                  Refund to *{{ stripePaymentMethod?.attributes?.last4 }} {{ stripePaymentMethodBrand }}
                  {{ stripePaymentMethod?.attributes?.exp_month }}/{{ stripePaymentMethod?.attributes?.exp_year }}
                </div>
              </div>

              <div class="ml-3 text-sm font-mono text-gray-brand-900">
                ({{ checkout?.attributes?.refunded_amount.format }})
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Payment -->

      <!-- store and membership tier -->
      <div class="px-2 md:px-0 md:w-1/2 mx-auto mt-14 mb-8">
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter ml-3">Tier &amp; Store</div>
        </div>
        <div class="bg-white rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600 mt-4">
          <div class="flex w-full border-b h-16">
            <div class="w-1/5 flex items-center">
              <div
                class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 mx-auto mb-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                  Tier
                </div>
              </div>
            </div>
            <div class="flex justify-between w-4/5 pt-4 pb-4 pl-6 pr-2 items-center">
              <div>
                <fieldset class="">
                  <div class="leading-5">
                    <div class="flex items-center">
                      <label for="1" class="">
                        <span class="text-md font-semibold text-brand-gray-800">
                          {{ membershipTierName }}
                        </span>
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div>
            <div class="flex w-full border-b h-16">
              <div class="w-1/5 flex items-center">
                <div
                  class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 mx-auto mb-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                      />
                    </svg>
                    Store
                  </div>
                </div>
              </div>
              <div class="flex justify-between w-4/5 pt-4 pb-4 pl-6 pr-2">
                <div class="flex content-center items-center h-full">
                  <span class="">{{ storeName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Referral discount program -->
      <div
        v-for="discountCode in referralDiscountCodes"
        :key="discountCode?.id"
        class="px-2 md:px-0 md:w-1/2 mx-auto mt-14 mb-8"
      >
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter ml-3">Refer friends. Get rewarded.</div>
        </div>
        <div class="bg-white rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600 mt-4">
          <div class="flex w-full border-b py-2">
            <div class="w-1/5 flex items-center">
              <div
                class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
              >
                <div class="cursor-pointer" @click="copy(discountCode?.attributes?.code)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mx-auto mb-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 3.75v16.5M2.25 12h19.5M6.375 17.25a4.875 4.875 0 004.875-4.875V12m6.375 5.25a4.875 4.875 0 01-4.875-4.875V12m-9 8.25h16.5a1.5 1.5 0 001.5-1.5V5.25a1.5 1.5 0 00-1.5-1.5H3.75a1.5 1.5 0 00-1.5 1.5v13.5a1.5 1.5 0 001.5 1.5zm12.621-9.44c-1.409 1.41-4.242 1.061-4.242 1.061s-.349-2.833 1.06-4.242a2.25 2.25 0 013.182 3.182zM10.773 7.63c1.409 1.409 1.06 4.242 1.06 4.242S9 12.22 7.592 10.811a2.25 2.25 0 113.182-3.182z"
                    />
                  </svg>
                  {{ discountCode?.attributes?.code }}
                </div>
              </div>
            </div>
            <div class="flex justify-between w-4/5 pt-4 pb-4 pl-6 pr-2 items-center">
              <div>
                <fieldset class="">
                  <div class="leading-5">
                    <div class="flex items-center">
                      <label for="1" class="">
                        <span class="text-md text-brand-gray-800">
                          {{ referralExplanationFor(discountCode) }}
                        </span>
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasAnswers" class="px-2 md:px-0 md:w-1/2 mx-auto mt-14 mb-8">
        <div class="flex justify-between items-center mb-4">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter ml-3">Additional Info</div>
        </div>
        <AnswersSection :bottle="bottle" :answers="answers" :editable="false" />
      </div>
    </div>
  </div>
</template>

<script>
import OrderSummarySection from '@/components/Checkout/OrderSummarySection'
import AnswersSection from '@/components/Checkout/AnswersSection'
import NoteSection from '@/components/Checkout/NoteSection'
import QuestionRow from '@/components/Checkout/QuestionRow'

import FulfillmentSelectedModule from '@/components/Checkout/FulfillmentSelectedModule'

import { MerchantNav } from '@/components'
import ProgressPills from '@/components/Merchant/ProgressPills'

import mapRelationships from '@/utils/mapRelationships'
import mapRelationship from '@/utils/mapRelationship'

export default {
  components: {
    OrderSummarySection,
    FulfillmentSelectedModule,
    MerchantNav,
    AnswersSection,
    NoteSection,
    QuestionRow,
    ProgressPills
  },
  data: () => ({
    refreshBottleAttempts: 0,
    progressPills: [
      { status: 'completed', to: '/' },
      { status: 'completed', to: '' },
      { status: 'completed', to: '' }
    ]
  }),
  computed: {
    bottle() {
      return this.$store.getters.currentBottle
    },
    bottlesList() {
      if (this.$store.getters.currentBottles) {
        return this.$store.getters.currentBottles.sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1))
      }
    },
    checkout() {
      return this.$store.getters.checkout
    },
    customer() {
      return this.$store.getters.getCustomer(this.bottle?.relationships?.customer?.data)
    },
    storeCustomer() {
      return this.$store.getters.customer
    },
    customerName() {
      return this.customer?.attributes.user_name
    },
    requiresFulfillmentSlot() {
      return this.bottle?.attributes?.requires_fulfillment_slot
    },
    requiresMembershipTierSelection() {
      return this.bottle?.attributes?.requires_membership_tier_selection
    },
    bottlePayment() {
      return this.$store.getters.bottlePayment
    },
    store() {
      return this.$store.getters.store(this.bottle?.relationships?.store?.data)
    },
    storeName() {
      return this.store === null ? '' : this.store?.attributes?.store_name
    },
    membershipTier() {
      return this.$store.getters.membershipTierFromRelationship(this.bottle?.relationships?.membership_tier?.data)
    },
    membershipTierName() {
      return this.membershipTier === null ? '' : this.membershipTier.attributes.tier_name
    },
    distributionList() {
      return this.$store.getters.distributionListFromRelationship(this.bottle?.relationships?.distribution_list?.data)
    },
    distributionListName() {
      return this.distributionList === null ? '' : this.distributionList.attributes.name
    },
    stripePaymentIntent() {
      return this.$store.getters.stripePaymentIntent
    },
    stripePaymentIntentSucceeded() {
      return this.stripePaymentIntent && this.stripePaymentIntent.attributes.status == 'succeeded'
    },
    hasAccountCreditAmount() {
      return this.bottlePayment?.attributes?.account_credit_amount?.cents > 0
    },
    hasRefundedAmount() {
      return this.checkout?.attributes?.refunded_amount?.cents > 0
    },
    displayPaymentTimestamp() {
      if (!this.bottlePayment?.attributes.created_at) return ''
      let time = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hourCycle: 'h12'
      }).format(new Date(this.bottlePayment?.attributes.created_at))
      let day = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).format(new Date(this.bottlePayment?.attributes.created_at))
      return day + ' at ' + time
    },
    fulfillmentMethod() {
      return this.$store.getters.fulfillmentMethod
    },
    stripePaymentMethod() {
      return this.$store.getters.stripePaymentMethod(
        this.stripePaymentIntent?.relationships?.stripe_payment_method?.data?.id
      )
    },
    stripePaymentMethodBrand() {
      if (this.stripePaymentMethod?.attributes?.brand) {
        return (
          this.stripePaymentMethod?.attributes?.brand.charAt(0).toUpperCase() +
          this.stripePaymentMethod?.attributes?.brand.slice(1)
        )
      } else {
        return ''
      }
    },
    isPickup() {
      return this.fulfillmentMethod?.attributes?.type === 'PickupFulfillmentMethod'
    },
    isDelivery() {
      return this.fulfillmentMethod?.attributes?.type === 'DeliveryFulfillmentMethod'
    },
    isShipping() {
      return this.fulfillmentMethod?.attributes?.type === 'ShippingFulfillmentMethod'
    },
    fulfillmentDisplayTitle() {
      if (this.isPickup) {
        return 'Pickup'
      } else if (this.isDelivery) {
        return 'Delivery'
      } else {
        return 'Fulfillment'
      }
    },
    answers() {
      return this.$store.getters.answers(this.bottle?.relationships?.answers?.data)
    },
    hasAnswers() {
      return this.answers?.length > 0
    },
    hasNote() {
      return !this.store ? false : this.store?.attributes.receipt_page_display_text
    },
    storeNote() {
      return !this.store ? '' : this.store?.attributes.receipt_page_display_text
    },
    receipt_questions() {
      return this.$store.getters.questions(this.bottle?.relationships?.receipt_questions?.data)?.slice(0, 1)
    },
    thankYouText() {
      if (this.customerName) {
        if (this.customerName.endsWith('.')) {
          return `Thank you, ${this.customerName}`
        } else {
          return `Thank you, ${this.customerName}.`
        }
      } else {
        return `Thank you.`
      }
    },
    referralDiscountCodes() {
      return mapRelationships(this.$store.state, this.storeCustomer?.relationships?.referral_discount_codes?.data)
    }
  },
  async mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('getInfo')
    this.refreshBottle()
  },
  methods: {
    fulfillmentDisplayTitleForBottle(forBottle) {
      if (this.isPickupForBottle(forBottle)) {
        return 'Pickup'
      } else if (this.isDeliveryForBottle(forBottle)) {
        return 'Delivery'
      } else {
        return 'Fulfillment'
      }
    },
    isPickupForBottle(forBottle) {
      return this.fulfillmentMethodForBottle(forBottle)?.attributes?.type === 'PickupFulfillmentMethod'
    },
    isDeliveryForBottle(forBottle) {
      return this.fulfillmentMethodForBottle(forBottle)?.attributes?.type === 'DeliveryFulfillmentMethod'
    },
    isShippingForBottle(forBottle) {
      return this.fulfillmentMethodForBottle(forBottle)?.attributes?.type === 'ShippingFulfillmentMethod'
    },
    fulfillmentMethodForBottle(forBottle) {
      return this.$store.getters.fulfillmentMethodForBottle(forBottle)
    },
    fulfillmentSlotForBottle(bottle) {
      if (bottle) {
        return this.$store.getters.fulfillmentSlotForBottle(bottle)
      }
    },
    isMultibottleFunctionality() {
      const currentBottle = this.$store.getters.currentBottle
      if (currentBottle) {
        return currentBottle.attributes.allow_multiple_days
      } else {
        return false
      }
    },
    handleAuthorization() {
      if (!this.$store.getters.authorizedForBottle(this.$store.getters.currentBottle)) {
        this.$router.push({ name: 'Login', params: { bottleid: this.bottle?.id } })
      }
    },
    async refreshBottle() {
      await this.$store.dispatch('getBottle', {
        includes: [
          'distribution_list',
          'membership_tier',
          'store',
          'fulfillment_slot',
          'checkout',
          'stripe_payment_intent',
          'stripe_payment_intent.stripe_payment_method',
          'checkout.bottle_payment',
          'fulfillment_slot.fulfillment_method',
          'delivery_address',
          'fulfillment_slot.fulfillment_method.delivery_address',
          'cart',
          'cart.cart_items',
          'cart.cart_items.product',
          'cart.cart_items.product.category_product_store_pairs',
          'answers',
          'answers.question',
          'receipt_questions',
          'customer',
          'children_bottles',
          'children_bottles.fulfillment_slot.fulfillment_method',
          'children_bottles.cart',
          'children_bottles.cart.cart_items',
          'children_bottles.delivery_address',
          'children_bottles.fulfillment_slot.fulfillment_method.delivery_address'
        ]
      })
      this.$store.dispatch('getCustomerInfo', [
        'referral_discount_codes',
        'referral_discount_codes.referral_discount_program'
      ])
      this.handleAuthorization()
      if (!this.bottlePayment && this.refreshBottleAttempts < 10) {
        this.refreshBottleAttempts = this.refreshBottleAttempts + 1
        setTimeout(() => {
          this.refreshBottle()
        }, 1000)
      }
    },
    copy(text) {
      const input = document.createElement('input')
      input.setAttribute('value', text)
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      window.alert(`Copied your referral code: ${text}`)
    },
    referralExplanationFor(discountCode) {
      let referralDiscountProgram = mapRelationship(
        this.$store.state,
        discountCode.relationships?.referral_discount_program?.data
      )
      return (
        referralDiscountProgram?.attributes?.explanation ||
        `Share your code to earn ${referralDiscountProgram?.attributes?.referrer_reward?.format} for everybody new who places an order.`
      )
    }
  }
}
</script>
