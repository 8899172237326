<template>
  <article ref="articleRef" class="w-screen max-w-xl px-2 sm:w-1/2 sm:px-2 mb-4 md:mb-8">
    <section
      class="relative border rounded-md bg-white w-full overflow-hidden rounded-lg shadow-solid-xs"
      :class="inCart ? 'shadow-gray-brand-900 border-gray-brand-900' : 'shadow-gray-brand-500 border-gray-brand-500'"
    >
      <slot></slot>
    </section>
  </article>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue'
export default {
  name: 'ProductContainer',
  props: {
    inCart: { type: Boolean, required: true }
  },
  setup() {
    const articleRef = ref(null)

    return {
      articleRef
    }
  }
}
</script>
