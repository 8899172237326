<template>
  <div class="min-h-full antialiased font-inter text-gray-brand-900 bg-gray-brand-100">
    <div class="w-full">
      <main class="max-w-5xl mx-auto pt-24 flex justify-center">
        <p>Merchant not found.</p>
        <img :src="svg" alt="Page not found" />
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  setup() {
    const svg = require('@/assets/404.svg')
    return { svg }
  }
}
</script>
