import { createStore } from 'vuex'
import merchantFetch from '@/utils/merchantFetch'
import mapIncluded from '@/utils/mapIncluded'
import mapRelationships from '@/utils/mapRelationships'
import mapRelationship from '@/utils/mapRelationship'
import router from '@/router'
import { ulid } from 'ulid'
import VueJwtDecode from 'vue-jwt-decode'

const store = createStore({
  state: {
    pendingItems: [],
    currentTokenMerchant: null,
    currentTokenCustomer: null,
    anonymousTokenSecret: null,
    decodedJWT: null,
    dismissedLoggedInAsFor: null,
    cartController: new AbortController(),
    activelySubmittingCart: false,
    objects: {
      applied_discount_code: {},
      attachment: {},
      bottle: {},
      customer: {},
      delivery_address: {},
      fulfillment_slot: {},
      fulfillment_method: {},
      submittable_cart: {},
      cart: {},
      cart_item: {},
      checkout: {},
      customer: {},
      membership_tier: {},
      membership_tier_selection: {},
      cart_subscription: {},
      cart_subscription_setting: {},
      merchant: {},
      store: {},
      user: {},
      stripe_payment_method: {},
      stripe_payment_intent: {},
      category: {},
      category_product_store_pair: {},
      pricing_tier: {},
      question: {},
      answer: {},
      issued_gift_card: {},
      tip_amount: {},
      discount_code: {},
      referral_discount_program: {},
      package: {},
      page: {},
      page_module: {},
      form: {}
    },
    splitDay: null,
    promises: new Map()
  },
  getters: {
    isLoggedIn: (state, getters) => {
      return state.currentTokenCustomer?.id
    },

    customer: (state, getters) => {
      return state.objects.customer[state.currentTokenCustomer?.id]
    },

    ownsBottle: (state, getters) => (bottle) => {
      return getters.isLoggedIn && bottle?.relationships?.customer?.data?.id == state.currentTokenCustomer?.id
    },

    authorizedForBottle: (state, getters) => (bottle) => {
      if (bottle?.relationships?.customer?.data?.id) {
        return getters.ownsBottle(bottle)
      } else {
        return state.anonymousTokenSecret == bottle?.attributes?.anonymous_token
      }
    },

    bottleId: () => {
      return router.currentRoute.value.params.bottleid
    },

    currentBottle: (state, getters) => {
      const bottle = state.objects.bottle?.[getters.bottleId]
      if (bottle) {
        const store = mapRelationship(state, bottle.relationships.store.data)
        if (store && !store.attributes.is_active) {
          router.push({ name: 'Home' })
        }
      }
      return bottle
    },

    bottleWithID: (state, getters) => (bottleId) => {
      if (state.objects.bottle) return state.objects.bottle[parseInt(bottleId)]
    },

    currentBottles: (state, getters) => {
      if (state.objects.bottle) {
        const parentBottle = state.objects.bottle[getters.bottleId]
        if (parentBottle) {
          const bottles = parentBottle.relationships.children_bottles.data.map((obj) => {
            return state.objects.bottle[parseInt(obj.id)]
          })
          return bottles
        }
      }
    },

    getCustomer: (state, getters) => (relationshipHash) => {
      if (relationshipHash == null || state.objects.customer == null) {
        return null
      } else {
        return mapRelationship(state, relationshipHash)
      }
    },

    bottles: (state, getters) => (relationshipArray) => {
      if (relationshipArray == null || state.objects.bottle == null) {
        return null
      } else {
        return mapRelationships(state, relationshipArray)
      }
    },

    issuedGiftCards: (state, getters) => (relationshipArray) => {
      if (relationshipArray == null || state.objects.issued_gift_card == null) {
        return null
      } else {
        return mapRelationships(state, relationshipArray)
      }
    },

    currentCart: (state, getters) => {
      return mapRelationship(state, getters.currentBottle?.relationships?.cart?.data)
    },

    cart: (state, getters) => (relationshipHash) => {
      if (relationshipHash == null || state.objects.cart == null) {
        return null
      } else {
        return mapRelationship(state, relationshipHash)
      }
    },

    cartSize: (state, getters) => getters.submittableCart.reduce((acc, x) => acc + x.quantity, 0),

    appliedDiscountCodes: (state, getters) => (relationshipArray) => {
      if (relationshipArray == null || state.objects.applied_discount_code == null) {
        return null
      } else {
        return mapRelationships(state, relationshipArray)
      }
    },

    currentPricingTier: (state, getters) => {
      return mapRelationship(state, getters.currentCart?.relationships.pricing_tier?.data)
    },

    pricingTierWithID: (state, getters) => (pricingTierID) => {
      return state.objects.pricing_tier[pricingTierID]
    },

    storeId: (state, getters) => getters.currentBottle?.relationships.store.data?.id,

    store: (state, getters) => (relationshipHash) => {
      if (relationshipHash == null || state.objects.store == null) {
        return null
      } else {
        return mapRelationship(state, relationshipHash)
      }
    },

    stores: (state, getters) => {
      if (!state.objects.store) return []
      return Object.values(state.objects.store)
    },

    rootPage: (state, getters) => {
      if (!state.objects.page) return null
      return Object.values(state.objects.page).find((x) => x.attributes.type === 'RootPage')
    },

    page: (state, getters) => (route) => {
      if (!state.objects.page) return null
      return Object.values(state.objects.page).find((x) => x.attributes.route === route)
    },

    questions: (state, getters) => (relationshipArray) => {
      if (relationshipArray == null || state.objects.question == null) {
        return null
      } else {
        return mapRelationships(state, relationshipArray)
      }
    },

    question: (state, getters) => (relationshipHash) => {
      if (relationshipHash == null || state.objects.question == null) {
        return null
      } else {
        return mapRelationship(state, relationshipHash)
      }
    },

    answers: (state, getters) => (relationshipArray) => {
      if (relationshipArray == null || state.objects.answer == null) {
        return null
      } else {
        return mapRelationships(state, relationshipArray)
      }
    },

    attachmentsFromRelationships: (state, getters) => (relationshipsArray) => {
      if (relationshipsArray == null || state.objects.attachment == null) {
        return []
      } else {
        return mapRelationships(state, relationshipsArray)
      }
    },

    handle: (state, getters) => state.currentTokenMerchant?.handle,

    merchantID: (state, getters) => state.currentTokenMerchant?.id,

    info: (state, getters) => {
      if (!state.objects.user[getters.merchantID]) {
        const response = localStorage.getItem(['merchant-info-', getters.merchantID].join(''))
        state.objects.user[getters.merchantID] = typeof response == 'string' ? JSON.parse(response) : null
      }
      return state.objects.user[getters.merchantID]
    },

    merchant: (state, getters) => {
      if (!state.currentTokenMerchant) return null
      return state.objects.user[state.currentTokenMerchant.id]
    },

    submittableCartItems: (state, getters) => (categoryProductStorePairID) => {
      return getters.submittableCart?.filter((x) => x.category_product_store_pair_id == categoryProductStorePairID)
    },

    submittableCart: (state, getters) => {
      if (getters.newSubmittableCart) {
        return getters.newSubmittableCart
      } else {
        return getters.dynamicSubmittableCart
      }
    },

    newSubmittableCart: (state, getters) => {
      return state.objects.submittable_cart[getters.currentCart?.id]
    },

    dynamicSubmittableCart: (state, getters) => {
      // let cartID = getters.currentCart?.id
      let cartItems = mapRelationships(state, getters.currentCart?.relationships?.cart_items?.data)

      // id, quantity, frontend_key, category_product_store_pair_id, cart_item_variant_options_attributes: [:variant_option_id]
      let submittableCartItems = cartItems?.map((item) => ({
        type: item.attributes?.type,
        quantity: item.attributes?.quantity,
        frontend_key: item.attributes?.frontend_key,
        default_price: item.attributes?.default_price,
        membership_price: item.attributes?.membership_price,
        variants_price: item.attributes?.variants_price,
        default_price_plus_variants_price: item.attributes?.default_price_plus_variants_price,
        membership_price_plus_variants_price: item.attributes?.membership_price_plus_variants_price,
        net_price_plus_net_variants_price: item.attributes?.net_price_plus_net_variants_price,
        category_product_store_pair_id: item.attributes?.category_product_store_pair_id,
        product_id: item.attributes?.product_id,
        fulfillment_group_date: item.attributes?.fulfillment_group_date,
        cart_item_variant_options_attributes: mapRelationships(
          state,
          item.relationships?.cart_item_variant_options?.data
        ).map((pair) => ({
          variant_option_id: pair.attributes?.variant_option_id?.toString(),
          variant_id: pair.attributes?.variant_id.toString(),
          quantity: pair.attributes?.quantity
        }))
      }))

      return submittableCartItems
    },

    categories: (state, getters) =>
      mapRelationships(
        state,
        getters.store(getters.currentBottle?.relationships?.store?.data)?.relationships?.categories?.data
      ).filter((x) => x.attributes.type !== 'UpsellCategory'),

    categoryProductStorePairs: (state, getters) => (relationshipsArray) => {
      if (relationshipsArray == null || state.objects.category_product_store_pair == null) {
        return []
      } else {
        return mapRelationships(state, relationshipsArray)
      }
    },

    category: (state, getters) => (relationshipHash) => {
      if (relationshipHash == null || state.objects.product == null) {
        return null
      } else {
        return mapRelationship(state, relationshipHash)
      }
    },

    products: (state, getters) => (relationshipsArray) => {
      if (relationshipsArray == null || state.objects.product == null) {
        return []
      } else {
        return mapRelationships(state, relationshipsArray)
      }
    },

    product: (state, getters) => (relationshipHash) => {
      if (relationshipHash == null || state.objects.product == null) {
        return null
      } else {
        return mapRelationship(state, relationshipHash)
      }
    },

    cartItems: (state, getters) => mapIncluded(state, getters.currentCart, 'cart_item'),

    attachments: (state) => (productId) => mapIncluded(state, state.objects.product[productId], 'attachment'),

    labels: (state) => (productId) => mapIncluded(state, state.objects.product[productId], 'label'),

    labelsFromRelationships: (state) => (relationshipsArray) => mapRelationships(state, relationshipsArray),

    pricingTiers: (state, getters) => {
      return Object.values(state.objects.pricing_tier)
    },

    // FIXME(alicja): make sure we're only getting membership tiers from the current store
    membershipTiers: (state, getters) =>
      state.objects.membership_tier &&
      Object.values(state.objects.membership_tier)?.sort(
        // sort NoMembershipTiers last, keep order equal for the rest
        // (take advantage of stable sorting)
        (x, y) => (x.attributes.type === 'NoMembershipTier') - (y.attributes.type === 'NoMembershipTier') || 0
      ),

    distributionLists: (state, getters) => (relationshipsArray) => {
      if (relationshipsArray == null || state.objects.distribution_list == null) {
        return []
      } else {
        return mapRelationships(state, relationshipsArray)
      }
    },

    variants: (state, getters) => (productId) => mapIncluded(state, state.objects.product[productId], 'variant'),

    variantOptions: (state, getters) => (variantId) =>
      mapIncluded(state, state.objects.variant[variantId], 'variant_option'),

    variantOptionsWithPointer: (state, getters) => (relationshipArray) => {
      return mapRelationships(state, relationshipArray)
    },

    // pair: (state) => (productId, categoryId) => {
    //   const productPairs = state.objects.product[productId].relationships.category_product_store_pairs.data.map((x) =>
    //     Number(x.id)
    //   )

    //   const categoryPairs = state.objects.category[categoryId].relationships.category_product_store_pairs.data.map(
    //     (x) => Number(x.id)
    //   )

    //   const categoryPairsSet = new Set(categoryPairs)
    //   return productPairs.find((x) => categoryPairsSet.has(x))
    // },

    fulfillmentSlot: (state, getters) => {
      if (!getters.currentBottle?.relationships?.fulfillment_slot.data) return null
      return state.objects.fulfillment_slot[getters.currentBottle.relationships.fulfillment_slot.data.id]
    },

    fulfillmentSlotForBottle: (state, getters) => (bottle) => {
      if (!bottle || !getters.bottleWithID(bottle.id).relationships?.fulfillment_slot.data) return null
      return state.objects.fulfillment_slot[bottle.relationships.fulfillment_slot.data.id]
    },

    fulfillmentMethodForBottle: (state, getters) => (bottle) => {
      if (!getters.bottleWithID(bottle.id)?.relationships?.fulfillment_method.data) return null

      const fulfillmentMethodId = getters.bottleWithID(bottle.id).relationships.fulfillment_method.data.id
      return state.objects.fulfillment_method[fulfillmentMethodId]
    },
    fulfillmentSlotForFulfillmentMethod: (state, getters) => (fulfillmentMethod) => {
      const fulfillmentSlotId = fulfillmentMethod
      return state.objects.fulfillment_slot[fulfillmentSlotId]
    },

    fulfillmentSlots: (state, getters) => (relationshipsArray) => {
      if (relationshipsArray == null || state.objects.fulfillment_method == null) {
        return []
      } else {
        return mapRelationships(state, relationshipsArray)
      }
    },

    fulfillmentMethod: (state, getters) => {
      if (getters.fulfillmentSlot) {
        return state.objects.fulfillment_method[getters.fulfillmentSlot.relationships.fulfillment_method.data.id]
      } else {
        return null
      }
    },

    fulfillmentMethods: (state, getters) => (relationshipsArray) => {
      if (relationshipsArray == null || state.objects.fulfillment_method == null) {
        return []
      } else {
        return mapRelationships(state, relationshipsArray)
      }
    },

    deliveryAddresses: (state, getters) => (relationshipsArray) => {
      if (relationshipsArray == null || state.objects.delivery_address == null) {
        return []
      } else {
        return mapRelationships(state, relationshipsArray)
      }
    },

    deliveryAddress: (state, getters) => (deliveryAddressID) => {
      if (state.objects.delivery_address == null) {
        return null
      }
      return state.objects.delivery_address[deliveryAddressID]
    },

    stripePaymentMethods: (state, getters) => (relationshipsArray) => {
      if (relationshipsArray == null || state.objects.stripe_payment_method == null) {
        return []
      } else {
        return mapRelationships(state, relationshipsArray)
      }
    },

    stripePaymentMethod: (state, getters) => (stripePaymentMethodID) => {
      if (state.objects.stripe_payment_method == null) {
        return null
      }
      return state.objects.stripe_payment_method[stripePaymentMethodID]
    },

    pickupFulfillmentMethods: (state, getters) => (bottleId) => {
      return []
    },

    membershipTier: (state, getters) => {
      if (!getters.currentBottle?.relationships.membership_tier?.data) return null
      return state.objects.membership_tier?.[getters.currentBottle.relationships.membership_tier.data.id]
    },

    membershipTierFromRelationship: (state, getters) => (relationshipHash) => {
      if (relationshipHash == null || state.objects.membership_tier == null) {
        return null
      } else {
        return mapRelationship(state, relationshipHash)
      }
    },

    distributionList: (state, getters) => {
      // TODO(alicja): change to state.currentBottle.relationships.distribution_list.data.id
      // after https://github.com/bottle-labs/bottle/issues/455 is closed
      if (!getters.currentBottle?.attributes.distribution_list_id) return null
      return state.objects.distribution_list?.[getters.currentBottle.attributes.distribution_list_id]
    },

    distributionListFromRelationship: (state, getters) => (relationshipHash) => {
      if (relationshipHash == null || state.objects.distribution_list == null) {
        return null
      } else {
        return mapRelationship(state, relationshipHash)
      }
    },

    checkout: (state, getters) => {
      if (!getters.currentBottle?.relationships.checkout?.data) return null
      return state.objects.checkout[getters.currentBottle.relationships.checkout.data.id]
    },

    checkoutFromRelationship: (state, getters) => (relationshipHash) => {
      if (relationshipHash == null || state.objects.checkout == null) {
        return null
      } else {
        return mapRelationship(state, relationshipHash)
      }
    },

    bottlePayment: (state, getters) => {
      if (!getters.checkout?.relationships?.bottle_payment?.data) return null
      return state.objects.bottle_payment?.[getters.checkout?.relationships?.bottle_payment.data.id]
    },

    bottlePaymentFromRelationship: (state, getters) => (relationshipHash) => {
      if (relationshipHash == null || state.objects.bottle_payment == null) {
        return null
      } else {
        return mapRelationship(state, relationshipHash)
      }
    },

    stripePaymentIntent: (state, getters) => {
      if (!getters.currentBottle?.relationships.stripe_payment_intent?.data) return null
      return state.objects.stripe_payment_intent[getters.currentBottle.relationships.stripe_payment_intent.data.id]
    },

    stripePaymentIntentFromRelationship: (state, getters) => (relationshipHash) => {
      if (relationshipHash == null || state.objects.stripe_payment_intent == null) {
        return null
      } else {
        return mapRelationship(state, relationshipHash)
      }
    }
  },
  mutations: {
    ADD_PENDING_ITEM(state, item) {
      state.pendingItems.push(item)
    },

    UPDATE_PENDING_ITEM(state, { key, cartAttributes }) {
      const item = state.pendingItems.find((x) => x.cartAttributes.key === key)
      item.cartAttributes = { ...item.cartAttributes, ...cartAttributes }
    },

    RECEIVE_OBJECT(state, object) {
      function set(obj) {
        const type = obj.type
        if (!state.objects[type]) state.objects[type] = {}
        state.objects[type][obj.id] = obj
      }
      if (!object) return
      if (Array.isArray(object.data)) {
        object.data.forEach((x) => set(x))
      } else {
        set(object.data)
      }
      if (object.included) {
        object.included.forEach((x) => set(x))
      }
    },

    SET_PROMISE(state, { name, promise }) {
      state.promises.set(name, promise)
    },

    CLEAR_PROMISES(state) {
      state.promises.clear()
    },

    NEW_CART_CONTROLLER(state) {
      state.cartController.abort()
      state.cartController = new AbortController()
    },

    SET_SUBMITTABLE_CART(state, { key, submittableCart }) {
      state.objects.submittable_cart[key] = submittableCart
    },

    SET_CURRENT_TOKENS(state, jwtString) {
      state.currentTokenMerchant = VueJwtDecode.decode(jwtString)?.merchant
      state.currentTokenCustomer = VueJwtDecode.decode(jwtString)?.customer
      state.anonymousTokenSecret = VueJwtDecode.decode(jwtString)?.anonymous_token
      state.decodedJWT = VueJwtDecode.decode(jwtString)
    },

    SET_DISMISSED_LOGGED_IN_FOR(state, loggedInFor) {
      state.dismissedLoggedInAsFor = loggedInFor
    },

    SET_SPLIT_DAY(state, splitDay) {
      state.splitDay = splitDay
    }
  },
  actions: {
    // getters

    initializeCurrentTokens({ state, commit, getters }) {
      if (!state.currentTokenMerchant && localStorage.getItem('token')) {
        commit('SET_CURRENT_TOKENS', localStorage.getItem('token'))
      }
    },

    async getToken({ state, commit }, { handle, subdomain }) {
      const name = 'token-' + (localStorage.getItem('token') ? localStorage.getItem('token').toString() : '')
      if (state.promises.has(name)) return state.promises.get(name)

      if (localStorage.getItem('token') && VueJwtDecode.decode(localStorage.getItem('token'))?.merchant) {
        commit('SET_CURRENT_TOKENS', localStorage.getItem('token'))
        commit('SET_PROMISE', {
          name: name,
          promise: Promise.resolve(localStorage.getItem('token'))
        })
        return state.promises.get(name)
      }

      commit('SET_PROMISE', {
        name: name,
        promise: fetch(process.env.VUE_APP_API_BASE_URL + '/merchant/tokens', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({
            handle,
            subdomain
          })
        })
          .then((res) => res.json())
          .then((data) => {
            localStorage.setItem('token', data.token)
            commit('SET_CURRENT_TOKENS', localStorage.getItem('token'))
            return data.token
          })
      })

      return state.promises.get(name)
    },

    async getDrops({ state, commit, getters }, storeId) {
      const name = 'drops-' + storeId.toString() + Math.random().toString(36).slice(2)
      if (state.promises.has(name)) return state.promises.get(name)
      commit('SET_PROMISE', {
        name: name,
        promise: merchantFetch('stores/' + storeId + '/drops', {
          method: 'GET',
          includes: ['cart']
        }).then((res) => {
          commit('RECEIVE_OBJECT', res)
          return res.data
        })
      })

      return state.promises.get(name)
    },

    async getStoreBottles({ state, commit, getters }, storeId) {
      const name = 'bottleId-' + storeId.toString() + Math.random().toString(36).slice(2)
      if (state.promises.has(name)) return state.promises.get(name)

      commit('SET_PROMISE', {
        name: name,
        promise: merchantFetch('stores/' + storeId + '/bottles', {
          method: 'GET',
          includes: [
            'cart',
            'cart.cart_items',
            'cart.cart_items.product',
            'cart.cart_items.product.attachments',
            'fulfillment_slot',
            'fulfillment_slot.fulfillment_method'
          ]
        }).then((res) => {
          commit('RECEIVE_OBJECT', res)
          return res.data
        })
      })

      return state.promises.get(name)
    },

    async getNewBottleId({ state, commit, getters }, storeId) {
      const name = 'newBottleId-' + storeId.toString() + Math.random().toString(36).slice(2)
      if (state.promises.has(name)) return state.promises.get(name)

      commit('SET_PROMISE', {
        name: name,
        promise: merchantFetch('stores/' + storeId + '/bottles', { params: { generate_new_bottle: true } }).then(
          (res) => {
            commit('RECEIVE_OBJECT', res)
            return res.data
          }
        )
      })

      return state.promises.get(name)
    },

    async getBottle({ state, commit, getters }, { includes, temporaryActualUltramarineEnable = false } = {}) {
      const promiseKey = 'bottle-' + getters.bottleId + JSON.stringify(includes)
      // if (state.promises.has(promiseKey)) return state.promises.get(promiseKey)
      commit('SET_PROMISE', {
        name: promiseKey,
        promise: merchantFetch('bottles/' + getters.bottleId, {
          includes,
          context: { bottle: getters.bottleId },
          temporaryActualUltramarineEnable
        }).then((res) => {
          commit('RECEIVE_OBJECT', res)
          return res.data.attributes.id
        })
      })

      return state.promises.get(promiseKey)
    },

    async getBottleCheckout({ state, commit, getters }) {
      if (state.promises.has('bottleCheckout')) return state.promises.get('bottleCheckout')
      commit('SET_PROMISE', {
        name: 'bottleCheckout',
        promise: merchantFetch('bottles/' + getters.bottleId, { params: { generate_checkout: true } }).then((res) => {
          commit('RECEIVE_OBJECT', res)
          return res.data.attributes.id
        })
      })

      return state.promises.get('bottleCheckout')
    },

    async getStores({ state, commit, dispatch, getters }) {
      const name = 'stores'
      if (state.promises.has(name)) return state.promises.get(name)

      commit('SET_PROMISE', {
        name,
        promise: merchantFetch('stores', { includes: ['attachments'] }).then((res) => {
          commit('RECEIVE_OBJECT', res)
        })
      })

      return state.promises.get(name)
    },

    async showStore({ state, commit, getters }, storeId) {
      const name = 'store-' + storeId.toString() + Math.random().toString(36).slice(2)
      if (state.promises.has(name)) return state.promises.get(name)

      commit('SET_PROMISE', {
        name: name,
        promise: merchantFetch('stores/' + storeId, { params: { included: 'none' } }).then((res) => {
          commit('RECEIVE_OBJECT', res)
          return res.data
        })
      })

      return state.promises.get(name)
    },

    async getPages({ state, commit, dispatch, getters }) {
      const name = 'pages'
      if (state.promises.has(name)) return state.promises.get(name)

      commit('SET_PROMISE', {
        name,
        promise: merchantFetch('pages', {
          method: 'GET',
          includes: [
            'page_modules',
            'page_modules.content_elements',
            'page_modules.content_elements.attachments',
            'page_modules.form'
          ]
        }).then((res) => {
          commit('RECEIVE_OBJECT', res)
        })
      })

      return state.promises.get(name)
    },

    async getStore({ state, commit, dispatch, getters }) {
      const name = 'bottles/' + getters.bottleId + '/stores'
      if (state.promises.has(name)) return state.promises.get(name)

      commit('SET_PROMISE', {
        name,
        promise: merchantFetch('bottles/' + getters.bottleId + '/stores', {
          includes: [
            'categories',
            'pricing_tiers',
            'membership_tiers',
            'membership_tiers.cart_subscription_settings',
            'attachments',
            'possible_packages',
            'questions'
          ],
          context: { bottle: getters.bottleId },
          temporaryActualUltramarineEnable: true
        }).then((res) => {
          commit('RECEIVE_OBJECT', res)
        })
      })

      return state.promises.get(name)
    },

    async getStoreDetailsForBottle({ state, commit, dispatch, getters }) {
      const name = 'bottles/' + getters.bottleId + '/store'

      if (state.promises.has(name)) return state.promises.get(name)

      commit('SET_PROMISE', {
        name,
        promise: merchantFetch('bottles/' + getters.bottleId + '/store', {
          includes: [
            'product',
            'product.attachments',
            'product.labels',
            'product.variants',
            'product.variants.variant_options',
            'product.nutritional_information'
          ],
          context: { bottle: getters.bottleId },
          temporaryActualUltramarineEnable: true
        }).then((res) => {
          commit('RECEIVE_OBJECT', res)
        })
      })
      return state.promises.get(name)
    },

    async getInfo({ state, commit }) {
      if (state.promises.has('info')) return state.promises.get('info')

      commit('SET_PROMISE', {
        name: 'info',
        promise: merchantFetch('info').then((res) => {
          localStorage.setItem(['merchant-info-', res.data.id].join(''), JSON.stringify(res.data))
          commit('RECEIVE_OBJECT', res)
        })
      })

      return state.promises.get('info')
    },

    async getCustomerInfo({ state, commit, dispatch, getters }, includes) {
      merchantFetch('customer', { includes }).then((res) => {
        commit('RECEIVE_OBJECT', res)
      })
    },

    async getIssuedGiftCard({ state, commit, dispatch, getters }, code) {
      const res = await merchantFetch('issued_gift_cards/' + code, {
        method: 'GET'
      })
      if (!res?.errors) commit('RECEIVE_OBJECT', res)
      return res
    },

    async addDiscountCode({ state, commit, dispatch, getters }, discountCodeName) {
      const res = await merchantFetch('bottles/' + getters.bottleId + '/discount_codes', {
        method: 'POST',
        params: { code: discountCodeName }
      })
      if (!res?.errors) commit('RECEIVE_OBJECT', res)
      return res
    },

    async removeAppliedDiscountCode({ state, commit, dispatch, getters }, appliedDiscountCodeID) {
      const res = await merchantFetch(
        'bottles/' + getters.bottleId + '/applied_discount_codes/' + appliedDiscountCodeID,
        {
          method: 'DELETE'
        }
      )
      commit('RECEIVE_OBJECT', res)
    },

    async markBottleAsPaid({ state, commit, dispatch, getters }) {
      const res = await merchantFetch('bottles/' + getters.bottleId + '/pay', {
        method: 'POST'
      })
      if (!res?.errors) commit('RECEIVE_OBJECT', res)
      return res
    },

    async bottleValidation({ state, commit, getters }, bottleID) {
      const name = 'bottleValidation-' + bottleID.toString() + Math.random().toString(36).slice(2)
      if (state.promises.has(name)) return state.promises.get(name)

      commit('SET_PROMISE', {
        name: name,
        promise: merchantFetch('bottles/' + bottleID + '/validation', {
          method: 'GET'
        }).then((res) => {
          commit('RECEIVE_OBJECT', res.objects)
          return res
        })
      })

      return state.promises.get(name)
    },

    async answerQuestion({ state, commit, dispatch, getters }, { questionID, payload }) {
      const res = await merchantFetch('bottles/' + getters.bottleId + '/questions/' + questionID + '/answers', {
        method: 'POST',
        body: JSON.stringify(payload),
        includes: [
          'question',
          'bottle',
          'bottle.questions',
          'bottle.checkout_questions',
          'bottle.selection_questions',
          'bottle.cart',
          'bottle.cart.cart_items',
          'bottle.cart.cart_items.cart_item_variant_options',
          'bottle.children_bottles',
          'bottle.fulfillment_slot',
          'bottle.children_bottles.fulfillment_slot'
        ]
      })
      if (!res?.errors) commit('RECEIVE_OBJECT', res)
      return res
    },

    async updateCustomerInfo({ state, commit }, payload) {
      const name = 'update-info-' + localStorage.getItem('token').toString() + Math.random().toString(36).slice(2)
      // if (state.promises.has(name)) return state.promises.get(name)
      commit('SET_PROMISE', {
        name: name,
        promise: merchantFetch('customer/profile', {
          method: 'POST',
          body: JSON.stringify(payload),
          includes: [
            'current_membership_tier_selection',
            'current_membership_tier_selection.cart_subscriptions',
            'current_membership_tier_selection.membership_tier',
            'current_membership_tier_selection.membership_tier.cart_subscription_settings'
          ]
        }).then((res) => {
          commit('RECEIVE_OBJECT', res)
        })
      })

      return state.promises.get(name)
    },

    async getMembershipTiers({ state, commit, getters }) {
      const name = 'tiers/' + getters.bottleId
      if (state.promises.has(name)) return state.promises.get(name)

      commit('SET_PROMISE', {
        name,
        promise: merchantFetch('bottles/' + getters.bottleId + '/membership_tiers', {
          includes: ['distribution_lists', 'cart_subscription_settings']
        }).then((res) => {
          commit('RECEIVE_OBJECT', res)
        })
      })

      return state.promises.get(name)
    },

    async getUpsells({ state, commit, getters }) {
      const name = 'upsells/' + getters.bottleId + '/' + getters.currentCart?.id
      if (state.promises.has(name)) return state.promises.get(name)

      // TODO(alicja): terrible but it'll do for now
      const timer = (ms) => new Promise((res) => setTimeout(res, ms))
      while (!getters.currentCart?.id) await timer(50)

      commit('SET_PROMISE', {
        name,
        promise: merchantFetch('bottles/' + getters.bottleId + '/carts/' + getters.currentCart?.id + '/upsells', {
          method: 'GET',
          includes: ['category']
        })
      })

      return state.promises.get(name)
    },

    // async getDistributionLists({ state, commit, getters }) {
    //   const name = 'lists/' + getters.bottleId
    //   if (state.promises.has(name)) return state.promises.get(name)

    //   commit('SET_PROMISE', {
    //     name,
    //     promise: merchantFetch('bottles/' + getters.bottleId + '/distribution_lists').then((res) => {
    //       commit('RECEIVE_OBJECT', res)
    //     })
    //   })

    //   return state.promises.get(name)
    // },

    // setters

    async confirmCart({ state, commit, getters, dispatch }) {
      const res = await merchantFetch(
        'bottles/' + getters.bottleId + '/carts/' + getters.currentCart?.id + '/confirmations',
        {
          method: 'POST'
        }
      )
      commit('RECEIVE_OBJECT', res)
    },

    async skipBottle({ state, commit, getters, dispatch }, bottleID) {
      // Use the provided bottleID or fall back to the currentBottle's id.
      const id = bottleID || getters.bottleId
      const res = await merchantFetch('bottles/' + id + '/skips', {
        method: 'POST',
        body: JSON.stringify({})
      })
      commit('RECEIVE_OBJECT', res)
    },

    async unskipBottle({ state, commit, getters, dispatch }, bottleID) {
      const id = bottleID || getters.bottleId
      const res = await merchantFetch('bottles/' + id + '/unskips', {
        method: 'POST',
        body: JSON.stringify({})
      })
      commit('RECEIVE_OBJECT', res)
    },

    async setNewDeliveryAddress(
      { state, commit, getters, dispatch },
      { address1, address2, city, addressState, zip, notes, bottle, replacingAddressId }
    ) {
      let bottleId = getters.currentBottle?.id
      if (bottle) {
        bottleId = bottle.id
      }
      const res = await merchantFetch('bottles/' + bottleId + '/delivery_addresses', {
        method: 'POST',
        body: JSON.stringify({
          delivery_address: {
            address1: address1,
            address2: address2,
            city: city,
            state: addressState,
            zip: zip,
            notes: notes,
            replacing_address_id: replacingAddressId
          }
        }),
        includes: [
          'checkout',
          'fulfillment_slot',
          'fulfillment_method',
          'delivery_address.eligible_fulfillment_methods',
          'fulfillment_slot.fulfillment_method',
          'possible_delivery_addresses',
          'possible_fulfillment_slot_days',
          'possible_fulfillment_slot_times',
          'possible_fulfillment_methods',
          'possible_fulfillment_methods.delivery_address',
          'possible_fulfillment_methods.fulfillment_slots_detail'
        ]
      })
      commit('RECEIVE_OBJECT', res)
    },

    async setDeliveryAddressID({ state, commit, getters, dispatch }, data) {
      const res = await merchantFetch('bottles/' + data['bottle'].id, {
        method: 'PUT',
        body: JSON.stringify({
          bottle: {
            delivery_address_id: data['deliveryAddressID']
          }
        }),
        includes: [
          'possible_stripe_payment_methods',
          'possible_fulfillment_methods',
          'possible_fulfillment_slot_days',
          'possible_fulfillment_slot_times',
          'possible_delivery_addresses',
          'possible_fulfillment_methods.delivery_address',
          'possible_fulfillment_methods.fulfillment_slots_detail',
          'checkout',
          'fulfillment_slot',
          'fulfillment_method',
          'cart',
          'cart.cart_items',
          'parent_bottle.children_bottles',
          'parent_bottle.children_bottles.checkout'
        ]
      })
      commit('RECEIVE_OBJECT', res)
    },

    async removeDeliveryAddress({ state, commit, getters, dispatch }, data) {
      const res = await merchantFetch(`bottles/${data.bottleId}/delivery_addresses/${data.deliveryAddressID}`, {
        method: 'DELETE',
        includes: [
          'bottle',
          'bottle.checkout',
          'bottle.parent_bottle.children_bottles',
          'bottle.parent_bottle.children_bottles.checkout'
        ]
      })

      commit('RECEIVE_OBJECT', res)
    },

    async selectFulfillmentMethod({ commit, getters }, data) {
      const res = await merchantFetch('bottles/' + data['bottle'].id + '/fulfillment_methods', {
        method: 'POST',
        body: JSON.stringify({
          fulfillment_method: { id: data['methodId'] }
        }),
        includes: [
          'possible_stripe_payment_methods',
          'possible_fulfillment_methods',
          'possible_fulfillment_slot_days',
          'possible_fulfillment_slot_times',
          'possible_delivery_addresses',
          'possible_fulfillment_methods.delivery_address',
          'possible_fulfillment_methods.fulfillment_slots_detail',
          'checkout',
          'fulfillment_slot',
          'fulfillment_method',
          'cart',
          'cart.cart_items',
          'parent_bottle.children_bottles',
          'parent_bottle.children_bottles.checkout'
        ]
      })
      commit('RECEIVE_OBJECT', res)
    },

    async selectFulfillmentSlot({ commit, getters }, slotAndBottleId) {
      const res = await merchantFetch('bottles/' + slotAndBottleId['bottleId'], {
        method: 'PUT',
        body: JSON.stringify({
          bottle: { fulfillment_slot_id: slotAndBottleId['newSlotId'] }
        }),
        includes: [
          'possible_stripe_payment_methods',
          'possible_fulfillment_methods',
          'possible_fulfillment_slot_days',
          'possible_fulfillment_slot_times',
          'possible_delivery_addresses',
          'possible_fulfillment_methods.delivery_address',
          'possible_fulfillment_methods.fulfillment_slots_detail',
          'checkout',
          'fulfillment_slot',
          'fulfillment_method',
          'cart',
          'cart.cart_items',
          'parent_bottle.children_bottles',
          'parent_bottle.children_bottles.checkout'
        ]
      })
      commit('RECEIVE_OBJECT', res)
    },

    async setPackageID({ commit, getters }, packageID) {
      const res = await merchantFetch('bottles/' + getters.bottleId, {
        method: 'PUT',
        body: JSON.stringify({
          bottle: { package_id: packageID }
        }),
        includes: [
          'cart',
          'cart.cart_items',
          'package',
          'cart.cart_items.cart_item_variant_options',
          'cart.cart_items.variants',
          'cart.cart_items.variants.variant_options'
        ]
      })
      commit('RECEIVE_OBJECT', res)
    },

    async setMembershipTier({ commit, getters }, membershipTierId) {
      const res = await merchantFetch('bottles/' + getters.bottleId, {
        method: 'PUT',
        body: JSON.stringify({
          bottle: { membership_tier_id: membershipTierId }
        }),
        includes: [
          'possible_stripe_payment_methods',
          'possible_fulfillment_methods',
          'possible_fulfillment_slot_days',
          'possible_fulfillment_slot_times',
          'possible_delivery_addresses',
          'possible_fulfillment_methods.delivery_address',
          'possible_fulfillment_methods.fulfillment_slots_detail',
          'checkout',
          'fulfillment_slot',
          'fulfillment_method',
          'cart',
          'cart.cart_items',
          'cart.cart_items.product',
          'children_bottles.cart.cart_items'
        ]
      })
      commit('RECEIVE_OBJECT', res)
    },

    async setDistributionList({ commit, getters }, distributionListId) {
      const res = await merchantFetch('bottles/' + getters.bottleId, {
        method: 'PUT',
        body: JSON.stringify({
          bottle: { distribution_list_id: distributionListId }
        }),
        includes: [
          'possible_stripe_payment_methods',
          'possible_fulfillment_methods',
          'possible_fulfillment_slot_days',
          'possible_fulfillment_slot_times',
          'possible_delivery_addresses',
          'possible_fulfillment_methods.delivery_address',
          'possible_fulfillment_methods.fulfillment_slots_detail',
          'checkout',
          'fulfillment_slot',
          'fulfillment_method',
          'cart',
          'cart.cart_items',
          'cart.cart_items.product'
        ]
      })
      commit('RECEIVE_OBJECT', res)
    },

    async setIntervalCartSubscriptionSetting({ commit, getters }, intervalSettingId) {
      const res = await merchantFetch('bottles/' + getters.bottleId, {
        method: 'PUT',
        body: JSON.stringify({
          bottle: { interval_cart_subscription_setting_id: intervalSettingId }
        }),
        includes: [
          'possible_stripe_payment_methods',
          'possible_fulfillment_methods',
          'possible_fulfillment_slot_days',
          'possible_fulfillment_slot_times',
          'possible_delivery_addresses',
          'possible_fulfillment_methods.delivery_address',
          'possible_fulfillment_methods.fulfillment_slots_detail',
          'checkout',
          'fulfillment_slot',
          'fulfillment_method',
          'cart',
          'cart.cart_items',
          'cart.cart_items.product'
        ]
      })
      commit('RECEIVE_OBJECT', res)
    },

    async setAutochargeCartSubscriptionSetting({ commit, getters }, autochargeSettingId) {
      const res = await merchantFetch('bottles/' + getters.bottleId, {
        method: 'PUT',
        body: JSON.stringify({
          bottle: { autocharge_cart_subscription_setting_id: autochargeSettingId }
        }),
        includes: [
          'possible_stripe_payment_methods',
          'possible_fulfillment_methods',
          'possible_fulfillment_slot_days',
          'possible_fulfillment_slot_times',
          'possible_delivery_addresses',
          'possible_fulfillment_methods.delivery_address',
          'possible_fulfillment_methods.fulfillment_slots_detail',
          'checkout',
          'fulfillment_slot',
          'fulfillment_method',
          'cart',
          'cart.cart_items',
          'cart.cart_items.product'
        ]
      })
      commit('RECEIVE_OBJECT', res)
    },

    async setTipAmount({ commit, getters }, tipAmountId) {
      const res = await merchantFetch('bottles/' + getters.bottleId, {
        method: 'PUT',
        body: JSON.stringify({
          bottle: { tip_amount_id: tipAmountId }
        }),
        includes: ['checkout']
      })
      commit('RECEIVE_OBJECT', res)
    },

    async setCustomTipAmount({ commit, getters }, tipAmountCents) {
      const res = await merchantFetch('bottles/' + getters.bottleId, {
        method: 'PUT',
        body: JSON.stringify({
          bottle: { tip_amount_attributes: { type: 'CustomTipAmount', tip_amount_cents: tipAmountCents } }
        }),
        includes: ['checkout']
      })
      commit('RECEIVE_OBJECT', res)
    },

    // NEW CART SETTING / UPDATING

    async updateSubmittableCart({ state, commit, getters, dispatch }, { submittableCartItem }) {
      let submittableCart = getters.submittableCart
      if (submittableCartItem.quantity == 0) {
        // if quantity is 0, remove the hash from cart item
        submittableCart = submittableCart.filter((item) => !(item.frontend_key == submittableCartItem.frontend_key))
      } else if (submittableCart.filter((item) => item.frontend_key == submittableCartItem.frontend_key).length > 0) {
        // if frontend_key is present in the array, replace
        submittableCart.forEach(function (item, i) {
          if (item.frontend_key == submittableCartItem.frontend_key) submittableCart[i] = submittableCartItem
        })
      } else {
        // if frontend_key isnt present in the array, add
        submittableCart.push(submittableCartItem)
      }
      commit('SET_SUBMITTABLE_CART', {
        key: getters.currentCart?.id,
        submittableCart: submittableCart
      })
      dispatch('submitNewCart')
    },

    async submitNewCart({ state, commit, getters }) {
      commit('NEW_CART_CONTROLLER')
      try {
        state.activelySubmittingCart = true
        const res = await merchantFetch('bottles/' + getters.bottleId + '/carts', {
          signal: state.cartController.signal,
          method: 'POST',
          temporaryActualUltramarineEnable: true,
          includes: [
            'cart.bottle.fulfillment_slot',
            'cart.membership_tier',
            'cart.pricing_tier',
            'cart.next_pricing_tier',
            'cart.cart_items',
            'cart.cart_items.cart_item_variant_options',
            'category_product_store_pairs'
          ],
          context: { bottle: getters.bottleId },
          body: JSON.stringify({
            cart: {
              cart_items_attributes: getters.submittableCart
            }
          })
        })
        state.activelySubmittingCart = false
        commit('RECEIVE_OBJECT', res)
      } catch (e) {
        if (e.name === 'AbortError') {
          // aborted by a newer cart
        } else {
          throw e
        }
      }
    },

    async submitOldCartToNewBottle({ state, commit, getters }, newBottleID) {
      commit('NEW_CART_CONTROLLER')
      try {
        state.activelySubmittingCart = true
        const res = await merchantFetch('bottles/' + newBottleID + '/carts', {
          signal: state.cartController.signal,
          method: 'POST',
          temporaryActualUltramarineEnable: true,
          includes: [
            'cart.bottle.fulfillment_slot',
            'cart.membership_tier',
            'cart.pricing_tier',
            'cart.next_pricing_tier',
            'cart.cart_items',
            'cart.cart_items.cart_item_variant_options',
            'category_product_store_pairs'
          ],
          context: { bottle: getters.bottleId },
          body: JSON.stringify({
            cart: {
              cart_items_attributes: getters.submittableCart
            }
          })
        })
        state.activelySubmittingCart = false
        commit('RECEIVE_OBJECT', res)
      } catch (e) {
        if (e.name === 'AbortError') {
          // aborted by a newer cart
        } else {
          throw e
        }
      }
    }
  }
})

export default store
