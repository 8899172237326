<template>
  <div class="relative flex justify-between items-center px-3 py-3 border-t">
    <div class="my-auto text-sm text-gray-brand-600 rounded-full">
      <span v-if="showStrikethroughPrice && showPrice">
        <s>{{ defaultPrice?.format }}</s>
        &nbsp;
      </span>
      <span v-if="showFreePrice" class="font-bold">Free</span>
      <span v-else-if="showPrice" class="font-bold">{{ price?.format }}</span>
    </div>
    <ProductQuantities
      v-if="openForOrders"
      :product="product"
      :submittable-cart-item="submittableCartItem"
      :category-product-store-pair="categoryProductStorePair"
    />
  </div>
</template>

<script>
import { ProductQuantities } from '@/components'
import mapRelationship from '../../utils/mapRelationship'
import mapRelationships from '../../utils/mapRelationships'

export default {
  components: { ProductQuantities },

  props: {
    product: {
      required: true
    },
    submittableCartItem: {
      required: false
    },
    categoryProductStorePair: {
      required: false
    }
  },

  computed: {
    currentCart() {
      return this.$store.getters.currentCart
    },
    currentBottle() {
      return this.$store.getters.currentBottle
    },
    statusForPlacingOrders() {
      return this.currentBottle?.attributes?.status_for_placing_orders?.status
    },
    openForOrders() {
      return this.statusForPlacingOrders === 'open'
    },
    membershipTierID() {
      return this.$store.getters.currentCart?.relationships.membership_tier?.data?.id
    },
    pricingTierID() {
      return this.$store.getters.currentCart?.relationships.pricing_tier?.data?.id
    },
    isUpsell() {
      return (
        mapRelationship(this.$store.state, this.categoryProductStorePair?.relationships.category.data)?.attributes
          ?.type === 'UpsellCategory'
      )
    },
    price() {
      if (this.isUpsell) {
        const upsellCents = this.categoryProductStorePair?.attributes?.default_upsell_price_cents
        if (upsellCents) return { cents: upsellCents, format: '$' + (upsellCents / 100).toFixed(2) }
      }
      if (this.productPriceListPair) {
        return this.productPriceListPair.attributes.price
      } else if (this.submittableCartItem?.net_price_plus_net_variants_price?.cents > 0) {
        return this.submittableCartItem.net_price_plus_net_variants_price
      } else {
        return this.categoryProductStorePair?.attributes?.product_price_tiers?.find(
          (item) => item.membership_tier_id == this.membershipTierID && item.pricing_tier_id == this.pricingTierID
        )
      }
    },
    productPriceListPair() {
      return mapRelationships(
        this.$store.state,
        this.currentBottle?.relationships?.product_price_list_pairs?.data
      )?.find((item) => item.relationships.product?.data?.id?.toString() == this.product?.id?.toString())
    },
    defaultMembershipTierID() {
      return this.$store.getters.membershipTiers?.find((item) => item.attributes.is_default)?.attributes.id
    },
    defaultPricingTierID() {
      return this.$store.getters.pricingTiers?.find((item) => item.attributes.is_default)?.attributes.id
    },
    defaultPrice() {
      if (
        this.submittableCartItem?.default_price_plus_variants_price?.cents &&
        this.submittableCartItem?.membership_price_plus_variants_price?.cents &&
        this.submittableCartItem?.default_price_plus_variants_price?.cents >
          this.submittableCartItem?.membership_price_plus_variants_price?.cents
      )
        return this.submittableCartItem.default_price_plus_variants_price
      if (
        this.submittableCartItem?.membership_price_plus_variants_price?.cents &&
        this.submittableCartItem?.membership_price_plus_variants_price?.cents > 0
      )
        return this.submittableCartItem.membership_price_plus_variants_price
      let defaultPriceObject = this.categoryProductStorePair?.attributes?.product_price_tiers?.find(
        (item) =>
          item.membership_tier_id == this.defaultMembershipTierID && item.pricing_tier_id == this.defaultPricingTierID
      )
      if (
        this.product.attributes?.default_price?.cents &&
        this.product.attributes?.default_price?.cents > defaultPriceObject?.cents
      )
        return this.product.attributes.default_price
      return defaultPriceObject
    },
    showStrikethroughPrice() {
      return this.defaultPrice && this.price && this.defaultPrice.cents > this.price.cents
    },
    showFreePrice() {
      if (!this.categoryProductStorePair.attributes.show_prices) return false
      return this.price?.cents == 0 && this.product?.relationships?.variants?.data?.length == 0
    },
    showPrice() {
      if (!this.categoryProductStorePair.attributes.show_prices) return false
      return this.product?.relationships?.variants?.data?.length == 0 || this.price?.cents > 0
    }
  }
}
</script>
