<template>
  <div>
    <div class="flex justify-right pt-4 pb-4 pl-4 pr-4">
      <div class="flex-grow">
        <button
          v-for="option in options"
          :key="option?.id"
          class="w-full text-left px-4 py-2 border font-semibold text-sm hover:border-gray-brand-600 mr-2 mb-2 rounded-md"
          :class="{
            'text-gray-brand-100 bg-gray-brand-600': selected(option),
            'text-gray-brand-600': !selected(option)
          }"
          @click="flipSelection(option)"
        >
          {{ displayFor(option) }}
          <div v-if="descriptionFor(option)" class="font-normal">{{ descriptionFor(option) }}</div>
        </button>
      </div>
      <button
        v-if="!brandedActionType"
        class="h-10 px-4 py-2 text-gray-brand-600 border border-transparent font-semibold text-sm rounded-full hover:border-gray-brand-600 flex-grow-0"
        @click="savedOptions"
      >
        Save
      </button>
    </div>
    <div v-if="brandedActionType" class="p-4 pt-0 mt-1">
      <PrimaryButton
        @click="savedOptions"
        class="w-full"
        :class="{
          'cursor-pointer opacity-100': canProceed,
          'cursor-not-allowed opacity-50': !canProceed
        }"
        :size="'xl'"
        :streaking="canProceed"
        :justify="'justify-center'"
      >
        {{ actionText }}
      </PrimaryButton>
    </div>
    <div v-if="showOptionalButton" class="p-4 pt-0 -mt-1">
      <SecondaryButton v-if="brandedActionType" @click="skipQuestion" class="w-full" :size="'xl'">
        Skip Question
      </SecondaryButton>
    </div>
    <div v-if="showCancelButton" class="p-4 pt-0 -mt-1">
      <SecondaryButton v-if="brandedActionType" @click="cancelAnswer" class="w-full" :size="'xl'">
        Cancel
      </SecondaryButton>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '@/components/Molecules/PrimaryButton'
import SecondaryButton from '@/components/Molecules/SecondaryButton'
export default {
  name: 'InfoRowMultiselect',
  components: { PrimaryButton, SecondaryButton },
  props: ['options', 'selectedOptions', 'actionText', 'actionType', 'optional', 'mode'],
  data: () => ({
    optionsArray: []
  }),
  computed: {
    showOptionalButton() {
      return this.optional && !this.inlineMode
    },
    showCancelButton() {
      return this.inlineMode
    },
    inlineMode() {
      return this.mode == 'inline'
    },
    brandedActionType() {
      return this.actionType == 'branded'
    },
    canProceed() {
      return true
    }
  },
  mounted() {
    this.optionsArray = this.selectedOptions
  },
  methods: {
    savedOptions() {
      this.$emit('savedOptions', this.optionsArray)
    },
    displayFor(option) {
      if (option?.type == 'label') return option.attributes?.label_filter_text
      if (option?.type == 'question') return option.title
      return ''
    },
    descriptionFor(option) {
      if (option?.type == 'question') return option.description
      return null
    },
    selected(option) {
      return this.optionsArray?.filter((x) => x?.id == option.id).length > 0
    },
    flipSelection(option) {
      if (this.selected(option)) {
        this.optionsArray = this.optionsArray?.filter((x) => x?.id != option.id)
      } else {
        this.optionsArray.push({ type: option.type, id: option.id, title: option.title })
      }
    },
    skipQuestion() {
      this.$emit('skipQuestion')
    },
    cancelAnswer() {
      this.$emit('cancelAnswer')
    }
  }
}
</script>
