<template>
  <div>
    <div v-if="showTier" class="flex w-full border-b">
      <div class="w-1/5 flex items-center">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5 mx-auto mb-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
            Tier
          </div>
        </div>
      </div>
      <div v-if="!showMembershipOptions" class="flex justify-between w-4/5 pt-4 pb-4 pl-2 items-center">
        <div>
          <fieldset class="">
            <div class="leading-5">
              <div class="flex items-center">
                <label for="1" class="">
                  <span class="text-md font-semibold text-brand-gray-800 tracking-tight">
                    {{ membershipTier?.attributes.tier_name }}
                  </span>
                  <div class="text-xs tracking-wide text-brand-900">
                    <span v-if="membershipTier?.attributes.price?.cents > 0">
                      {{ membershipTier.attributes.price.format }}/{{ membershipTier.attributes.billing_frequency }}
                    </span>
                    <span v-else>Free</span>
                  </div>
                </label>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="flex items-center">
          <button
            v-if="!blockCancel"
            class="items-center px-2.5 py-2 text-xs font-medium italic rounded-md bg-gray-brand-100 text-gray-brand-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 ml-2 mr-6"
            @click="openMembershipOptions"
          >
            Change
          </button>
        </div>
      </div>
      <div v-if="showMembershipOptions" class="w-full">
        <div class="leading-5 py-1 px-6">
          <fieldset>
            <div v-for="tier in membershipTiers" :key="tier.id" class="my-3 flex">
              <input
                :id="'tier' + tier.id"
                :value="tier.id"
                name="membershipOption"
                type="radio"
                class="flex-shrink-0 form-radio h-4 w-4 text-brand-600 transition duration-150 ease-in-out cursor-pointer mt-0.5"
                :checked="tier.id == membershipTier?.id"
                @click="(e) => clickMembershipTier(e, tier.id)"
              />
              <label :for="'tier' + tier.id" class="ml-3 cursor-pointer">
                <span class="text-md font-semibold text-brand-gray-800 tracking-tight">
                  {{ tier.attributes.tier_name }}
                </span>
                <span v-if="tier.attributes.price.cents > 0" class="ml-1 text-xs tracking-wide text-brand-900">
                  {{ tier.attributes.price.format }}/{{ tier.attributes.billing_frequency }}
                </span>
                <div
                  :ref="`tier-${tier.id}`"
                  v-linkified:options="{ defaultProtocol: 'https' }"
                  class="mt-1 text-sm tracking-wide text-brand-900 whitespace-pre-line mb-2"
                  :class="expandedTier !== tier.id && 'max-h-[112px] overflow-hidden'"
                >
                  {{ tier.attributes.description }}
                </div>
                <div
                  v-if="expandedTier !== tier.id && overflowing[tier.id]"
                  class="text-xs p-1 border rounded border-gray-brand-400 text-center"
                >
                  Show more...
                </div>
              </label>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <div v-if="showList">
      <div v-if="showDistributionListRow" class="flex w-full border-b">
        <div class="w-1/5 flex items-center">
          <div
            class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
          >
            <div>
              <!-- <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5 mx-auto mb-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                />
              </svg> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mx-auto mb-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                />
              </svg>
              List
            </div>
          </div>
        </div>
        <div v-if="showDistributionListSelection" class="flex justify-between w-4/5 pt-4 pb-4 pl-2">
          <div class="flex content-center items-center h-full">
            <span class="tracking-wide text-md">{{ distributionList?.attributes.name }}</span>
          </div>
          <div class="flex items-center">
            <button
              class="items-center px-2.5 py-2 text-xs font-medium italic rounded-md bg-gray-brand-100 text-gray-brand-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 ml-2 mr-6"
              @click="openDistributionOptions"
            >
              Change
            </button>
          </div>
        </div>
        <div v-if="showDistributionListOptions" class="w-4/5 border-b">
          <div class="leading-5 pt-4 pb-4 pr-6 pl-6">
            <fieldset>
              <div
                v-for="list in distributionLists"
                :key="list.id"
                class="mb-3 flex items-center"
                @click="setDistributionList(list.id)"
              >
                <input
                  :id="'list' + list.id"
                  :value="list.id"
                  name="distributionListOption"
                  type="radio"
                  class="form-radio h-4 w-4 text-brand-600 transition duration-150 ease-in-out cursor-pointer flex-shrink-0"
                  :checked="list.id == distributionList?.id"
                />
                <label :for="'list' + list.id" class="ml-3 cursor-pointer text-brand-gray-800 inline">
                  <span class="text-md font-semibold text-brand-gray-800 tracking-tight">
                    {{ list.attributes.name }}
                  </span>
                  <span class="block text-sm text-brand-gray-600 tracking-wide">
                    {{ list.attributes.description }}
                  </span>
                </label>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showInterval">
      <div v-if="showIntervalRow" class="flex w-full border-b">
        <div class="w-1/5 flex items-center">
          <div
            class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mx-auto mb-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                />
              </svg>
              Interval
            </div>
          </div>
        </div>
        <div v-if="showIntervalListSelection" class="flex justify-between w-4/5 pt-4 pb-4 pl-2">
          <div class="flex content-center items-center h-full">
            <span class="tracking-wide text-md">{{ intervalCartSetting?.attributes.display_description }}</span>
          </div>
          <div class="flex items-center">
            <button
              class="items-center px-2.5 py-2 text-xs font-medium italic rounded-md bg-gray-brand-100 text-gray-brand-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 ml-2 mr-6"
              @click="openIntervalOptions"
            >
              Change
            </button>
          </div>
        </div>
        <div v-if="showIntervalListOptions" class="w-4/5 border-b">
          <div class="leading-5 pt-4 pb-4 pr-6 pl-6">
            <fieldset>
              <div
                v-for="interval in intervalCartSettings"
                :key="interval.id"
                class="mb-3 flex items-center"
                @click="setCartInterval(interval.id)"
              >
                <input
                  :id="'interval' + interval.id"
                  :value="interval.id"
                  name="intervalCartSettingOption"
                  type="radio"
                  class="form-radio h-4 w-4 text-brand-600 transition duration-150 ease-in-out cursor-pointer flex-shrink-0"
                  :checked="interval.id == intervalCartSetting?.id"
                />
                <label :for="'interval' + interval.id" class="ml-3 cursor-pointer text-brand-gray-800 inline">
                  <span class="text-md font-semibold text-brand-gray-800 tracking-tight">
                    {{ interval.attributes.display_description }}
                  </span>
                  <span class="hidden text-sm text-brand-gray-600 tracking-wide"></span>
                </label>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showPackage">
      <div v-if="showPackageRow" class="flex w-full border-b">
        <div class="w-1/5 flex items-center">
          <div
            class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                data-slot="icon"
                class="h-5 w-5 mx-auto mb-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                />
              </svg>
              Package
            </div>
          </div>
        </div>
        <div v-if="showPackageListSelection" class="flex justify-between w-4/5 pt-4 pb-4 pl-2">
          <div class="flex content-center items-center h-full">
            <span class="tracking-wide text-md">{{ currentPackage?.attributes?.package_name }}</span>
          </div>
          <div class="flex items-center">
            <button
              class="items-center px-2.5 py-2 text-xs font-medium italic rounded-md bg-gray-brand-100 text-gray-brand-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 ml-2 mr-6"
              @click="openPackageOptions"
            >
              Change
            </button>
          </div>
        </div>
        <div v-if="showPackageListOptions" class="w-4/5 border-b">
          <div class="leading-5 pt-4 pb-4 pr-6 pl-6">
            <fieldset>
              <div
                v-for="packageOption in possiblePackages"
                :key="packageOption.id"
                class="mb-3 flex items-center"
                @click="setCartPackage(packageOption.id)"
              >
                <input
                  :id="'package' + packageOption.id"
                  :value="packageOption.id"
                  name="packageCartSettingOption"
                  type="radio"
                  class="form-radio h-4 w-4 text-brand-600 transition duration-150 ease-in-out cursor-pointer flex-shrink-0"
                  :checked="packageOption?.id == currentPackage?.id"
                />
                <label :for="'package' + packageOption.id" class="ml-3 cursor-pointer text-brand-gray-800 inline">
                  <span class="text-md font-semibold text-brand-gray-800 tracking-tight">
                    {{ packageOption?.attributes.package_name }}
                  </span>
                  <span class="hidden text-sm text-brand-gray-600 tracking-wide"></span>
                </label>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <div v-if="false && showStore">
      <div v-if="showStoreRow" class="flex w-full border-b">
        <div class="w-1/5 flex items-center">
          <div
            class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-5 w-5 mx-auto mb-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                />
              </svg>
              Store
            </div>
          </div>
        </div>
        <div v-if="showStoreListSelection" class="flex justify-between w-4/5 pt-4 pb-4 pl-2">
          <div class="flex content-center items-center h-full">
            <span class="tracking-wide text-md">{{ store?.attributes?.store_name }}</span>
          </div>
          <div class="flex items-center">
            <button
              class="items-center px-2.5 py-2 text-xs font-medium italic rounded-md bg-gray-brand-100 text-gray-brand-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 ml-2 mr-6"
              @click="openStoreOptions"
            >
              Change
            </button>
          </div>
        </div>
        <div v-if="showStoreListOptions" class="w-4/5 border-b">
          <div class="leading-5 pt-4 pb-4 pr-6 pl-6">
            <fieldset>
              <div
                v-for="storeOption in stores"
                :key="storeOption.id"
                class="mb-3 flex items-center"
                @click="setCartStore(storeOption.id)"
              >
                <input
                  :id="'store' + storeOption.id"
                  :value="storeOption.id"
                  name="storeCartSettingOption"
                  type="radio"
                  class="form-radio h-4 w-4 text-brand-600 transition duration-150 ease-in-out cursor-pointer flex-shrink-0"
                  :checked="store?.id == storeOption?.id"
                />
                <label :for="'store' + storeOption.id" class="ml-3 cursor-pointer text-brand-gray-800 inline">
                  <span class="text-md font-semibold text-brand-gray-800 tracking-tight">
                    {{ storeOption?.attributes.store_name }}
                  </span>
                  <span class="hidden text-sm text-brand-gray-600 tracking-wide"></span>
                </label>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <div v-if="false && showAutocharge">
      <div v-if="showIntervalRow" class="flex w-full border-b">
        <div class="w-1/5 flex items-center">
          <div
            class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mx-auto mb-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                />
              </svg>
              Interval
            </div>
          </div>
        </div>
        <div v-if="showIntervalListSelection" class="flex justify-between w-4/5 pt-4 pb-4 pl-2">
          <div class="flex content-center items-center h-full">
            <span class="tracking-wide text-md">{{ intervalCartSetting?.attributes.display_description }}</span>
          </div>
          <div class="flex items-center">
            <button
              class="items-center px-2.5 py-2 text-xs font-medium italic rounded-md bg-gray-brand-100 text-gray-brand-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 ml-2 mr-6"
              @click="openIntervalOptions"
            >
              Change
            </button>
          </div>
        </div>
        <div v-if="showIntervalListOptions" class="w-4/5 border-b">
          <div class="leading-5 pt-4 pb-4 pr-6 pl-6">
            <fieldset>
              <div
                v-for="interval in intervalCartSettings"
                :key="interval.id"
                class="mb-3 flex items-center"
                @click="setCartInterval(interval.id)"
              >
                <input
                  :id="'interval' + interval.id"
                  :value="interval.id"
                  name="intervalCartSettingOption"
                  type="radio"
                  class="form-radio h-4 w-4 text-brand-600 transition duration-150 ease-in-out cursor-pointer flex-shrink-0"
                  :checked="interval.id == intervalCartSetting?.id"
                />
                <label :for="'interval' + interval.id" class="ml-3 cursor-pointer text-brand-gray-800 inline">
                  <span class="text-md font-semibold text-brand-gray-800 tracking-tight">
                    {{ interval.attributes.display_description }}
                  </span>
                  <span class="hidden text-sm text-brand-gray-600 tracking-wide"></span>
                </label>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="w-full border-b bg-brand-100 text-brand-800" @click="expandNotice = true">
        <div
          v-show="notice"
          ref="notice"
          v-linkified:options="{ defaultProtocol: 'https' }"
          class="leading-5 pt-4 pb-4 px-6 scrolling-touch text-sm text-center whitespace-pre-line"
          :class="!expandNotice && 'max-h-[88px] overflow-hidden'"
        >
          {{ notice }}
        </div>
        <div
          v-if="noticeOverflowing && !expandNotice"
          class="absolute flex justify-center content-center text-xs bg-brand-100 border-2 rounded-full border-gray-brand-700 text-brand-800 cursor-pointer w-8 h-8 pt-0.5 left-1/2 -ml-4 -mt-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'process'
import mapRelationships from '../../utils/mapRelationships'
import mapRelationship from '../../utils/mapRelationship'

export default {
  name: 'MembershipOptionsBlock',
  components: {},
  props: [
    'membershipTier',
    'membershipTiers',
    'distributionList',
    'distributionLists',
    'showTier',
    'showList',
    'showStore',
    'showPackage',
    'showAutocharge',
    'cartSubscription',
    'intervalCartSetting',
    'storeCartSetting',
    'autochargeSetting'
  ],
  data: () => ({
    changeMembership: false,
    changeList: false,
    changeInterval: false,
    changeStore: false,
    changePackage: false,
    changeAutocharge: false,
    expandNotice: false,
    noticeOverflowing: false,
    expandedTier: null,
    overflowing: {}
  }),
  computed: {
    customer() {
      return this.$store.getters.customer
    },
    customerMembershipTier() {
      return this.customer?.relationships?.membership_tier?.data
    },
    noMembershipTier() {
      return this.membershipTier?.attributes.type == 'NoMembershipTier'
    },
    showMembershipOptions() {
      return this.changeMembership
    },
    showDistributionListRow() {
      return !this.noMembershipTier && this.distributionLists?.length > 0
    },
    showDistributionListSelection() {
      return this.showDistributionListRow && !this.changeList && this.distributionList
    },
    showDistributionListOptions() {
      return this.showDistributionListRow && (this.changeList || !this.distributionList)
    },
    notice() {
      nextTick(() => {
        this.noticeOverflowing = this.$refs.notice && this.$refs.notice.clientHeight !== this.$refs.notice.scrollHeight
      })
      return [this.membershipTier?.attributes.description, this.distributionList?.attributes.description]
        .filter(Boolean)
        .join(' ')
    },
    // CART SUBSCRIPTION - global
    cartSettings() {
      return mapRelationships(this.$store.state, this.membershipTier?.relationships?.cart_subscription_settings?.data)
    },
    // CART SUBSCRIPTION - Interval
    showInterval() {
      return this.membershipTier?.attributes?.has_cart_subscription_attached && this.intervalCartSettings?.length > 0
    },
    showIntervalRow() {
      return this.showInterval
    },
    showIntervalListSelection() {
      return this.showIntervalRow && !this.changeInterval && this.intervalCartSetting
    },
    showIntervalListOptions() {
      return this.showIntervalRow && (this.changeInterval || !this.intervalCartSetting)
    },
    intervalCartSettings() {
      return this.cartSettings?.filter((setting) => setting.attributes.setting_name == 'interval')
    },
    // CART SUBSCRIPTION - Store
    blockCancel() {
      return this.cartSubscription?.attributes?.block_cancel
    },
    showStoreRow() {
      return this.showStore && this.storeCartSettings.length > 1 && this.cartSubscription
    },
    showStoreListSelection() {
      return this.showStoreRow && !this.changeStore && this.store
    },
    showStoreListOptions() {
      return this.showStoreRow && (this.changeStore || !this.store)
    },
    storeCartSettings() {
      return this.cartSettings?.filter((setting) => setting.attributes.setting_name == 'store')
    },
    stores() {
      return mapRelationships(
        this.$store.state,
        this.membershipTier?.relationships?.cart_subscription_store_options?.data
      )
    },
    store() {
      return mapRelationship(this.$store.state, this.cartSubscription?.relationships?.store?.data)
    },
    // CART SUBSCRIPTION - Package
    showPackageRow() {
      return this.showPackage && this.possiblePackages && this.possiblePackages?.length > 1 && this.cartSubscription
    },
    showPackageListSelection() {
      return this.showPackageRow && !this.changePackage && this.currentPackage
    },
    showPackageListOptions() {
      return this.showPackageRow && (this.changePackage || !this.currentPackage)
    },
    possiblePackages() {
      return mapRelationships(this.$store.state, this.cartSubscription?.relationships?.possible_packages?.data) // this.cartSettings?.filter((setting) => setting.attributes.setting_name == 'store')
    },
    currentPackage() {
      return mapRelationship(this.$store.state, this.cartSubscription?.relationships?.package?.data)
    }
    // CART SUBSCRIPTION - Autocharge
    // showInterval() {
    //   return this.intervalCartSettings?.length > 0
    // },
    // showIntervalRow() {
    //   return this.showInterval
    // },
    // showIntervalListSelection() {
    //   return this.showIntervalRow && !this.changeInterval && this.intervalCartSetting
    // },
    // showIntervalListOptions() {
    //   return this.showIntervalRow && (this.changeInterval || !this.intervalCartSetting)
    // },
    // intervalCartSettings() {
    //   return this.cartSettings?.filter((setting) => setting.attributes.setting_name == 'interval')
    // }
  },
  mounted() {
    this.determineMembershipBoxOpenedOrNot()
  },
  methods: {
    openMembershipOptions() {
      this.changeMembership = true
      this.changeList = false
      this.changeInterval = false
      this.changeAutocharge = false
      this.changeStore = false
      this.changePackage = false
      nextTick(() => {
        for (const tier of this.membershipTiers) {
          const ref = this.$refs[`tier-${tier.id}`]
          this.overflowing[tier.id] = ref.clientHeight !== ref.scrollHeight
        }
      })
    },
    openDistributionOptions() {
      this.changeMembership = false
      this.changeList = true
      this.changeInterval = false
      this.changeAutocharge = false
      this.changeStore = false
      this.changePackage = false
    },
    openIntervalOptions() {
      this.changeMembership = false
      this.changeList = false
      this.changeInterval = true
      this.changeAutocharge = false
      this.changeStore = false
      this.changePackage = false
    },
    openAutochargeOptions() {
      this.changeMembership = false
      this.changeList = false
      this.changeInterval = false
      this.changeAutocharge = true
      this.changeStore = false
      this.changePackage = false
    },
    openStoreOptions() {
      this.changeMembership = false
      this.changeList = false
      this.changeInterval = false
      this.changeAutocharge = false
      this.changeStore = true
      this.changePackage = false
    },
    openPackageOptions() {
      this.changeMembership = false
      this.changeList = false
      this.changeInterval = false
      this.changeAutocharge = false
      this.changeStore = false
      this.changePackage = true
    },
    clickMembershipTier(e, tierId) {
      if (this.overflowing[tierId] && this.expandedTier !== tierId) {
        this.expandedTier = tierId
        e.preventDefault()
      } else {
        this.setMembershipTier(tierId)
        this.expandedTier = null
      }
    },
    setMembershipTier(tierID) {
      if (!this.blockCancel) {
        this.$emit('setMembershipTier', tierID)
        this.changeMembership = false
      }
    },
    setDistributionList(listID) {
      this.$emit('setDistributionList', listID)
      this.changeList = false
    },
    setCartInterval(intervalID) {
      this.$emit('setCartInterval', intervalID)
      this.changeInterval = false
    },
    setCartStore(storeID) {
      this.$emit('setCartStore', storeID)
      this.changeStore = false
    },
    setCartPackage(packageID) {
      this.$emit('setCartPackage', packageID)
      this.changePackage = false
    },
    setCartAutocharge(autochargeID) {
      this.$emit('setCartAutocharge', autochargeID)
      this.changeAutocharge = false
    },
    determineMembershipBoxOpenedOrNot() {
      if (this.membershipTier?.id != this.customerMembershipTier?.id) {
        this.openMembershipOptions()
      }
    }
  }
}
</script>
