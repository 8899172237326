<template>
  <span
    v-for="label in labels"
    :key="label.id"
    class="inline-block text-xxs uppercase bg-brand-100 text-brand-900 px-1 py-1 mr-1 rounded-sm shadow-sm font-manrope tracking-widest"
  >
    {{ label.attributes.label_product_text }}
  </span>
</template>

<script>
export default {
  name: 'ProductLabel',

  props: {
    labels: {
      type: Array,
      required: true
    }
  }
}
</script>
