<template>
  <div>
    <div class="flex justify-right pt-4 pb-4 pl-4 pr-2">
      <input
        id="infoRowTextfield"
        v-model="textFieldValue"
        type="text"
        autocomplete="on"
        :name="fieldName"
        :aria-label="placeholder"
        class="form-input rounded-lg bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5 flex-grow mr-2"
        :placeholder="placeholder"
        @keyup.enter="savedOption"
      />
      <button
        v-if="!brandedActionType"
        class="px-4 py-2 text-gray-brand-600 border border-transparent font-semibold text-sm rounded-full hover:border-gray-brand-600 flex-grow-0"
        @click="savedOption"
      >
        {{ actionText }}
      </button>
    </div>
    <div v-if="brandedActionType" class="p-4 pt-0 mt-1">
      <PrimaryButton
        @click="savedOption"
        class="w-full"
        :class="{
          'cursor-pointer opacity-100': canProceed,
          'cursor-not-allowed opacity-50': !canProceed
        }"
        :size="'xl'"
        :streaking="canProceed"
        :justify="'justify-center'"
      >
        {{ actionText }}
      </PrimaryButton>
    </div>
    <div v-if="showOptionalButton" class="p-4 pt-0 -mt-1">
      <SecondaryButton v-if="brandedActionType" @click="skipQuestion" class="w-full" :size="'xl'">
        Skip Question
      </SecondaryButton>
    </div>
    <div v-if="showCancelButton" class="p-4 pt-0 -mt-1">
      <SecondaryButton v-if="brandedActionType" @click="cancelAnswer" class="w-full" :size="'xl'">
        Cancel
      </SecondaryButton>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '@/components/Molecules/PrimaryButton'
import SecondaryButton from '@/components/Molecules/SecondaryButton'
export default {
  name: 'InfoRowEditField',
  components: { PrimaryButton, SecondaryButton },
  props: ['textFieldValue', 'placeholder', 'actionText', 'actionType', 'name', 'optional', 'mode'],
  data: () => ({}),
  computed: {
    brandedActionType() {
      return this.actionType == 'branded'
    },
    fieldName() {
      if (this.name && this.name.length > 0) return this.name
      return 'infoRowTextfield'
    },
    isEmailQuestion() {
      return this.fieldName == 'email'
    },
    answerIsValidEmail() {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      return emailRegex.test(this.textFieldValue)
    },
    canProceed() {
      if (this.isEmailQuestion) {
        return this.textFieldValue && this.textFieldValue?.length > 0 && this.answerIsValidEmail
      } else {
        return this.textFieldValue && this.textFieldValue?.length > 0
      }
    },
    showOptionalButton() {
      return this.optional && !this.inlineMode
    },
    showCancelButton() {
      return this.inlineMode
    },
    inlineMode() {
      return this.mode == 'inline'
    }
  },
  mounted() {},
  methods: {
    savedOption() {
      this.$emit('savedOption', this.textFieldValue)
    },
    skipQuestion() {
      this.$emit('skipQuestion')
    },
    cancelAnswer() {
      this.$emit('cancelAnswer')
    }
  }
}
</script>
