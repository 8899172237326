<template>
  <div>
    <div class="w-full">
      <Loading v-if="loading" class="mt-10" />
      <div class="z-20 w-full fixed top-0">
        <MerchantNav
          v-fade-in
          :disable-hide-on-scroll="showCartItemsOnly || shouldDisplayQuestions"
          :empty-middle="true"
        >
          <ProgressPills :pills="progressPills" />
        </MerchantNav>
        <MerchantCategoriesBar v-if="!showCartItemsOnly && !shouldDisplayQuestions" v-fade-in />
        <TheBanner
          v-for="(message, i) in messages"
          :key="message.uid"
          :position="message?.position"
          :status="message?.status"
          :use-emoji="message?.useEmoji"
          :title="message?.title"
          :actions="message?.actions"
          :stack-index="i"
          :unique-id="message.uid"
          :dismiss-timeout="message?.dismissTimeout"
          @on-dismiss="handleDismissedBanner(bannerIndex)"
        >
          <template #message>{{ message.message }}</template>
        </TheBanner>
      </div>
      <div v-show="!loading">
        <!-- <MerchantFiltersBar /> -->
        <MerchantMain
          v-if="!shouldDisplayQuestions"
          :in-cart="showCartItemsOnly"
          :in-upsell="showUpsell"
          @show-full-view="showFullView"
        />

        <MerchantBasket
          v-if="!shouldDisplayQuestions && openForOrders"
          :in-cart="showCartItemsOnly"
          @show-cart="toggleShowCartItemsOnly"
          :in-upsell="showUpsell"
          @show-upsell="toggleUpsell"
        >
          <PricingTierProgress v-show="!loading" v-fade-in :override-show="showCartItemsOnly" />
        </MerchantBasket>

        <!-- questions -->
        <div v-if="shouldDisplayQuestions" v-fade-in class="w-full max-w-5xl mx-auto py-20 h-full">
          <div
            v-for="question in selection_questions"
            :key="question.id"
            class="px-4 md:px-0 md:w-1/2 mx-auto mb-8"
            :class="{ 'mt-4': question?.attributes?.question || question?.attributes?.secondary_info }"
          >
            <div class="flex justify-start items-center">
              <div class="text-xl font-bold text-gray-brand-900 tracking-tighter">
                {{ question?.attributes?.question }}
              </div>
            </div>
            <div v-if="question?.attributes?.secondary_info" class="flex justify-start items-center">
              <h3 class="text-md text-gray-brand-700 w-full">
                {{ question?.attributes?.secondary_info }}
              </h3>
            </div>
            <QuestionRow :question="question" :editable="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import QuestionRow from '@/components/Checkout/QuestionRow'
import PricingTierProgress from '@/components/Merchant/PricingTierProgress'
import ProgressPills from '@/components/Merchant/ProgressPills'

import {
  Loading,
  MerchantBasket,
  MerchantCategoriesBar,
  // MerchantFiltersBar,
  MerchantMain,
  MerchantNav
} from '@/components'
import TheBanner from '@/components/Molecules/TheBanner'

export default {
  components: {
    MerchantMain,
    MerchantNav,
    MerchantBasket,
    MerchantCategoriesBar,
    // MerchantFiltersBar,
    Loading,
    TheBanner,
    QuestionRow,
    PricingTierProgress,
    ProgressPills
  },
  setup() {},
  data() {
    return {
      loading: true,
      messages: [],
      showCartItemsOnly: false,
      showUpsell: false
    }
  },
  computed: {
    bottle() {
      return this.$store.getters.currentBottle
    },
    cartIsClear() {
      return !this.bottle?.attributes?.validations?.has_cart_errors
    },
    statusForPlacingOrders() {
      return this.bottle?.attributes?.status_for_placing_orders?.status
    },
    openForOrders() {
      return this.statusForPlacingOrders === 'open'
    },
    pricingTierID() {
      return this.$store.getters.currentCart?.relationships?.pricing_tier?.data?.id
    },
    hasQuestions() {
      return this.selection_questions?.length > 0
    },
    shouldDisplayQuestions() {
      return this.hasQuestions && !this.bottleCycleIsExpired
    },
    bottleCycleIsExpired() {
      return this.bottleCycleDetailsForBottle?.expired
    },
    bottleCycleDetailsForBottle() {
      return this.bottle?.attributes?.bottle_cycle_details
    },
    selection_questions() {
      return this.$store.getters.questions(this.bottle?.relationships?.selection_questions?.data)?.slice(0, 1)
    },
    progressPills() {
      return [
        { status: 'current', to: { name: 'Selection' }, label: 'Selection' },
        !this.$store.getters.isLoggedIn && {
          status: 'upcoming',
          to: this.cartIsClear ? { name: 'Login' } : '',
          label: 'Information'
        },
        { status: 'upcoming', to: this.cartIsClear ? { name: 'Checkout' } : '', label: 'Checkout' }
      ].filter(Boolean)
    }
  },

  watch: {
    pricingTierID: function (newPricingTierID, oldPricingTierID) {
      let newPricingTier = this.pricingTierWithID(newPricingTierID)
      let oldPricingTier = this.pricingTierWithID(oldPricingTierID)
      if (
        oldPricingTier &&
        newPricingTier &&
        (newPricingTier.attributes?.rate_off > oldPricingTier.attributes?.rate_off ||
          (newPricingTier.attributes?.rate_off == oldPricingTier.attributes?.rate_off &&
            newPricingTier.attributes?.free_shipping))
      ) {
        this.notifyAboutPricingTier(newPricingTier)
      }
    }
  },
  async mounted() {
    window.scrollTo(0, 0)
    if (!this.$store.getters.bottleId || this.$store.getters.bottleId == 'undefined') {
      this.$router.push({ name: 'Home' })
      return
    } else {
      await Promise.all([
        this.$store
          .dispatch('getBottle', {
            includes: [
              'category_product_store_pairs',
              'product_price_list_pairs',
              'cart',
              'cart.cart_items',
              'cart.cart_items.cart_item_variant_options',
              'cart.cart_items.product',
              'cart.membership_tier',
              'cart.pricing_tier',
              'cart.next_pricing_tier',
              'fulfillment_slot',
              'fulfillment_slot.fulfillment_method',
              'merchant',
              'customer',
              'membership_tier',
              'distribution_list',
              'selection_questions',
              'removed_category_product_store_pairs_still_in_cart',
              'removed_category_product_store_pairs_still_in_cart.product'
            ],
            temporaryActualUltramarineEnable: true
          })
          .then(() => {
            this.handleAuthorization()
            this.handleStatus()
          }),
        this.$store.dispatch('getStoreDetailsForBottle'),
        this.$store.dispatch('getStore')
      ])
      this.loading = false
      if (this.$route.query.showCart && this.$route.query.showCart === 'y') {
        this.toggleShowCartItemsOnly(true)
      }
    }
  },

  methods: {
    handleAuthorization() {
      if (!this.$store.getters.authorizedForBottle(this.bottle)) {
        this.$router.push({ name: 'Home' })
      }
    },
    handleStatus() {
      const status = this.bottle?.attributes?.status
      if (status == 'paid') {
        this.$router.push({ name: 'Receipt' })
      } else if (status == 'skipped') {
        this.$router.push({ name: 'Notice' })
      } else if (this.bottle && !this.bottle.attributes.supports_selection_screen) {
        this.$router.replace({ name: 'Checkout' })
      }
    },
    pricingTierWithID(pricingTierID) {
      return this.$store.getters.pricingTierWithID(pricingTierID)
    },
    handleDismissedBanner(bannerIndex) {
      this.messages.splice(bannerIndex, 1)
      if (this.messages.length > 0) this.messages[0].uid = Math.random()
    },
    notifyAboutPricingTier(pricingTier) {
      this.resetBanner()
      this.messages.push({
        status: 'brand',
        position: 'fixed',
        title: 'Unlocked ' + pricingTier.attributes?.title,
        useEmoji: '💸',
        message: pricingTier.attributes?.subtitle,
        dismissTimeout: 6000,
        uid: Math.random()
      })
    },
    resetBanner() {
      this.messages = []
    },
    toggleShowCartItemsOnly(setTo) {
      this.showCartItemsOnly = setTo
      if (this.showCartItemsOnly) {
        window.scrollTo(0, 0)
        this.showUpsell = false
      }
    },
    toggleUpsell(visible) {
      this.showUpsell = visible
      if (this.showUpsell) {
        window.scrollTo(0, 0)
        this.showCartItemsOnly = false
      }
    },
    showFullView() {
      this.showCartItemsOnly = false
      this.showUpsell = false
    }
  }
}
</script>
