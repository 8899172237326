<template>
  <div
    class="w-full flex items-center justify-between font-semibold text-sm text-left text-gray-brand-600 bg-gray-brand-100 py-1 px-2 rounded-lg"
    :class="[count > 0 ? 'bg-gray-brand-200' : '', !canAddMore && count == 0 ? 'opacity-80 ' : '']"
  >
    <div class="">
      {{ variantOption.attributes.name }}
      <span v-if="hasPrice && showPrice && showPlusSign">(+{{ variantOption.attributes.price?.format }})</span>
      <span v-else-if="hasPrice && showPrice">({{ variantOption.attributes.price?.format }})</span>
      <div class="font-normal">{{ variantOption.attributes.description }}</div>
    </div>
    <div class="flex flex-shrink-0">
      <button
        class="inline-block w-10 h-8 p-2 px-3 rounded-l-lg items-center text-gray-brand-500 hover:text-gray-brand-700 focus:outline-none"
        :class="[
          count > 0
            ? 'bg-transparent text-gray-brand-700 border-gray-brand-500 opacity-100'
            : 'bg-transparent text-gray-brand-500 border-none opacity-50 cursor-not-allowed '
        ]"
        :disabled="count == 0"
        @click="decreaseCount()"
      >
        <svg
          class="w-full fill-current"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M20 12H4"></path>
        </svg>
      </button>
      <div className="flex rounded-md group">
        <input
          type="text"
          inputmode="numeric"
          placeholder="0"
          class="peer w-8 h-8 text-center font-inter font-semibold focus:outline-none"
          :value="countForValue"
          :class="[
            count > 0
              ? 'bg-transparent text-gray-brand-700'
              : 'bg-transparent text-gray-brand-500 border-none rounded-l-lg'
          ]"
          @change="set($event.target.value)"
        />
      </div>
      <button
        class="inline-block w-10 h-8 p-2 px-3 rounded-r-lg items-center text-gray-brand-500 hover:text-gray-brand-700 focus:outline-none"
        :class="[
          count > 0
            ? 'bg-transparent text-gray-brand-700 border-gray-brand-500'
            : 'bg-transparent text-gray-brand-500 border-none',
          !canAddMore ? 'opacity-50 cursor-not-allowed ' : 'opacity-100'
        ]"
        :disabled="!canAddMore"
        @click="increaseCount()"
      >
        <svg
          class="w-full fill-current"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from 'vue'

export default {
  name: 'ProductVariantOptionCustomQuantity',

  props: {
    key: {
      type: [String, Number]
    },
    submittableCartItem: {
      type: Object,
      required: false
    },
    variantOption: {
      type: Object,
      required: true
    },
    variant: {
      type: Object,
      required: true
    },
    showPrice: {
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    categoryProductStorePair: {
      type: Object,
      required: false
    }
  },

  computed: {
    selected() {
      if (!this.submittableCartItem || !this.submittableCartItem.cart_item_variant_options_attributes) return false
      return this.submittableCartItem.cart_item_variant_options_attributes
        .map((x) => x.variant_option_id)
        .includes(this.variantOption.id)
    },
    cartItemVariantOptionAttributes() {
      if (!this.submittableCartItem || !this.submittableCartItem.cart_item_variant_options_attributes) return false
      return this.submittableCartItem.cart_item_variant_options_attributes.filter(
        (x) => x.variant_option_id == this.variantOption.id
      )[0]
    },
    totalQuantityAddedForVariant() {
      if (!this.submittableCartItem || !this.submittableCartItem.cart_item_variant_options_attributes) return 0
      return this.submittableCartItem.cart_item_variant_options_attributes
        .filter((x) => x.variant_id == this.variant.id)
        .reduce((sum, x) => sum + (x.quantity ? x.quantity : 0), 0)
    },
    minimumToAdd() {
      return this.variant?.attributes?.minimum || 0
    },
    maximumAllowed() {
      return this.variant?.attributes.maximum || 100
    },
    canAddMore() {
      return this.totalQuantityAddedForVariant < this.maximumAllowed
    },
    countForValue() {
      return this.count == 0 ? null : this.count
    },
    count() {
      return this.cartItemVariantOptionAttributes?.quantity || 0
    },
    hasPrice() {
      return this.variantOption.attributes.price?.cents > 0
    },
    showPlusSign() {
      return this.priceToEvaluate?.cents > 0
    },
    priceToEvaluate() {
      if (this.submittableCartItem?.membership_price?.cents && this.submittableCartItem?.membership_price?.cents > 0)
        return this.submittableCartItem?.membership_price
      return this.categoryProductStorePair?.attributes?.product_price_tiers?.find(
        (item) => item.membership_tier_id == this.membershipTierID && item.pricing_tier_id == this.pricingTierID
      )
    },
    membershipTierID() {
      return this.$store.getters.currentCart?.relationships.membership_tier?.data?.id
    },
    pricingTierID() {
      return this.$store.getters.currentCart?.relationships.pricing_tier?.data?.id
    }
  },

  methods: {
    decreaseCount() {
      if (this.count == 0) return
      this.set(this.count - 1)
    },
    increaseCount() {
      this.set(this.count + 1)
    },
    set(quantity) {
      if (quantity > 0) {
        // this.handleTracking(quantity)
        this.$emit('quantitySet', this.variantOption, quantity)
      } else {
        this.$emit('unselected', this.variantOption)
      }
    }
  }
}
</script>
