<template>
  <div>
    <div class="pt-4 pb-4 px-6">
      <div v-show="!showingAdd">
        <SecondaryButton @click="showAdd" class="w-full" :size="'lg'">Add New Address</SecondaryButton>
      </div>
      <fieldset v-show="showingAdd">
        <div>
          <div class="flex justify-between">
            <legend class="block text-md font-semibold tracking-tighter leading-5 text-gray-brand-900">
              {{ title }}
            </legend>
            <button
              v-if="passedDeliveryAddress"
              class="ml-2 inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 group-hover:opacity-100 text-gray-500 hover:text-blue-600"
              @click="updateCloseClicked()"
            >
              <XIcon class="h-4 w-4" aria-hidden="true" />
            </button>
          </div>

          <legend class="block mt-1 mb-3 text-sm tracking-wide leading-5 text-gray-brand-800">
            Enter your address to see if we can deliver to you.
          </legend>
        </div>

        <form class="mt-2 bg-white rounded-md shadow-sm">
          <div class="-mt-px flex">
            <div class="w-3/5 flex-1 min-w-0">
              <input
                id="address-line1"
                ref="addr1"
                v-model="address1"
                type="text"
                name="address-line1"
                autocomplete="address-line1"
                autocapitalize="words"
                aria-label="Street address"
                class="form-input relative block w-full rounded-none rounded-tl-lg bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                placeholder="Street address"
              />
            </div>
            <div class="w-2/5 -ml-px min-w-0">
              <input
                id="address-line2"
                ref="addr2"
                v-model="address2"
                type="text"
                autocomplete="address-line2"
                name="address-line2"
                aria-label="Unit"
                class="form-input relative block w-full rounded-none rounded-tr-lg bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                placeholder="Unit"
              />
            </div>
          </div>
          <div class="-mt-px flex">
            <div class="w-3/10 sm:w-2/5 flex-1 min-w-0">
              <input
                id="address-level2"
                v-model="city"
                type="text"
                autocomplete="address-level2"
                autocapitalize="words"
                name="address-level2"
                aria-label="City"
                class="form-input relative block w-full rounded-none bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                placeholder="City"
              />
            </div>
            <div class="w-3/10 sm:w-1/5 -ml-px min-w-0">
              <select
                id="state"
                v-model="state"
                autocomplete="address-level1"
                name="state"
                class="form-select relative block w-full rounded-none bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                placeholder="State"
              >
                <option value="" disabled selected>State</option>
                <template v-if="merchantCountry === 'USA'">
                  <option value="AK">AK</option>
                  <option value="AZ">AZ</option>
                  <option value="AL">AL</option>
                  <option value="AR">AR</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DC">DC</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="IA">IA</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="ME">ME</option>
                  <option value="MD">MD</option>
                  <option value="MA">MA</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MS">MS</option>
                  <option value="MO">MO</option>
                  <option value="MT">MT</option>
                  <option value="NE">NE</option>
                  <option value="NV">NV</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NY">NY</option>
                  <option value="NC">NC</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WV">WV</option>
                  <option value="WI">WI</option>
                  <option value="WY">WY</option>
                </template>
                <template v-if="merchantCountry === 'AUS'">
                  <option value="ACT">ACT</option>
                  <option value="NSW">NSW</option>
                  <option value="NT">NT</option>
                  <option value="QLD">QLD</option>
                  <option value="SA">SA</option>
                  <option value="TAS">TAS</option>
                  <option value="VIC">VIC</option>
                  <option value="WA">WA</option>
                </template>
              </select>
            </div>
            <div class="w-2/5 -ml-px min-w-0">
              <input
                id="postal-code"
                v-model="zip"
                pattern="[0-9]*"
                autocomplete="postal-code"
                name="postal-code"
                aria-label="Postal code"
                class="form-input relative block w-full rounded-none bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                placeholder="Postal code"
              />
            </div>
          </div>
          <div class="-mt-px flex">
            <div class="w-full flex-1 min-w-0">
              <input
                id="notes"
                v-model="notes"
                type="text"
                autocomplete="off"
                name="notes"
                aria-label="Notes"
                class="form-input relative block w-full rounded-none rounded-bl-md rounded-br-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                placeholder="Gate code or instructions (optional)"
              />
            </div>
          </div>
        </form>
        <div
          v-show="hasErrorMessage"
          role="alert"
          class="mt-2 text-red-600 font-semibold text-sm rounded-md px-3 pt-1 pb-2 text-center"
        >
          {{ errorMessage }}
        </div>
        <div class="mt-2">
          <PrimaryButton
            @click="addAddress"
            class="w-full mt-2"
            :size="'xl'"
            :justify="'justify-center'"
            :active="validAddress == true"
            :streaking="validAddress == true"
            :is-loading="isAddingAddress"
          >
            {{ selectButtonTitle }}
          </PrimaryButton>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import BasicButton from '@/components/Molecules/BasicButton'
import SecondaryButton from '@/components/Molecules/SecondaryButton'
import PrimaryButton from '../Molecules/PrimaryButton.vue'
import mapRelationship from '@/utils/mapRelationship'
import { XIcon } from '@heroicons/vue/solid'
import { nextTick } from 'vue'
export default {
  name: 'AddDeliveryAddress',
  components: { BasicButton, XIcon, SecondaryButton, PrimaryButton },
  props: {
    passedBottle: {
      type: Object,
      required: true
    },
    passedDeliveryAddress: {
      type: Object,
      required: false
    },
    forceOpen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    notes: '',
    isAddingAddress: false,
    errorMessage: '',
    replacingAddressId: undefined,
    showingAdd: false
  }),
  computed: {
    selectButtonTitle() {
      if (this.passedDeliveryAddress) {
        return 'Update & Select'
      } else {
        return 'Add & Select'
      }
    },
    title() {
      if (this.passedDeliveryAddress) {
        return 'Update an address'
      } else {
        return 'Add an address'
      }
    },
    fulfillmentSlot() {
      return this.$store.getters.fulfillmentSlot
    },
    validAddress() {
      return this.address1 && this.city && this.state && this.zip.length >= { USA: 5, AUS: 4 }[this.merchantCountry]
    },
    hasErrorMessage() {
      return this.errorMessage.length > 0
    },
    merchantCountry() {
      return mapRelationship(this.$store.state, this.passedBottle.relationships.merchant.data)?.attributes.country
    }
  },
  watch: {
    passedDeliveryAddress(newValue) {
      if (newValue) {
        this.address1 = newValue.attributes.address1
        this.address2 = newValue.attributes.address2
        this.city = newValue.attributes.city
        this.state = newValue.attributes.state
        this.zip = newValue.attributes.zip
        this.notes = newValue.attributes.notes
        this.replacingAddressId = newValue.id
      }
    }
  },
  mounted() {
    this.showingAdd = this.forceOpen
    google.maps.importLibrary('places').then(({ Autocomplete }) => {
      const autocomplete = new Autocomplete(this.$refs.addr1, {
        componentRestrictions: { country: [{ USA: 'us', AUS: 'au' }[this.merchantCountry]] },
        fields: ['address_components']
      })
      autocomplete.addListener('place_changed', () => {
        for (const comp of autocomplete.getPlace().address_components) {
          switch (comp.types[0]) {
            case 'street_number':
              this.address1 = comp.long_name
              break
            case 'route':
              this.address1 += ' ' + comp.short_name
              break
            case 'subpremise':
              this.address2 = comp.long_name
              break
            case 'locality':
            case 'sublocality_level_1': // for New York
              this.city = comp.long_name
              break
            case 'administrative_area_level_1':
              this.state = comp.short_name
              break
            case 'postal_code':
              this.zip = comp.long_name
              break
          }
        }
        nextTick(() => this.$refs.addr2.focus())
      })
    })
  },
  methods: {
    showAdd() {
      this.showingAdd = true
      nextTick(() => this.$refs.addr1.focus())
    },
    addAddress() {
      this.isAddingAddress = true
      this.$store
        .dispatch('setNewDeliveryAddress', {
          address1: this.address1,
          address2: this.address2,
          city: this.city,
          addressState: this.state,
          zip: this.zip,
          notes: this.notes,
          bottle: this.passedBottle,
          replacingAddressId: this.replacingAddressId
        })
        .then(
          (response) => {
            // properly updated
            this.isAddingAddress = false
            this.alertUserIfNoFulfillmentSlot()
            this.clearFields()
            this.$emit('deliveryAddressEdited')
          },
          (error) => {
            // failed to update
            this.isAddingAddress = false
          }
        )
    },
    clearFields() {
      this.address1 = ''
      this.address2 = ''
      this.city = ''
      this.state = ''
      this.zip = ''
      this.notes = ''
      this.replacingAddressId = undefined
    },
    updateCloseClicked() {
      this.clearFields()
      this.$emit('deliveryAddressEditCanceled')
    },
    alertUserIfNoFulfillmentSlot() {
      if (this.fulfillmentSlot === null || !this.fulfillmentSlot) {
        this.errorMessage = 'Sorry, no delivery option is currently available for that address.'
      } else {
        this.errorMessage = ''
      }
    }
  }
}
</script>
<style>
.pac-container {
  @apply min-w-max;
}
</style>
