<template>
  <div>
    <div v-if="customQuantities" class="flex flex-wrap items-center gap-2 pb-2">
      <ProductVariantOptionCustomQuantity
        v-for="variantOption in variantOptions"
        :key="variantOption.id"
        class="w-full"
        :variant-option="variantOption"
        :variant="variant"
        :submittable-cart-item="submittableCartItem"
        :show-price="showPrice"
        :product="product"
        :category-product-store-pair="categoryProductStorePair"
        @quantity-set="quantitySet"
        @unselected="unselected"
      />
    </div>
    <div v-else class="flex flex-wrap items-center">
      <ProductVariantOption
        v-for="variantOption in variantOptions"
        :key="variantOption.id"
        class="px-4 py-2 border text-sm hover:border-gray-brand-600 mr-2 mb-2 w-full"
        :class="{ 'rounded-2xl': chooseOne, 'rounded-md': chooseMany }"
        :variant-option="variantOption"
        :variant="variant"
        :submittable-cart-item="submittableCartItem"
        :show-price="showPrice"
        :product="product"
        :category-product-store-pair="categoryProductStorePair"
        @selected="selected"
        @unselected="unselected"
      />
    </div>
  </div>
</template>

<script>
import ProductVariantOption from '@/components/Product/ProductVariantOption'
import ProductVariantOptionCustomQuantity from '@/components/Product/ProductVariantOptionCustomQuantity'

export default {
  name: 'ProductVariantOptions',
  components: {
    ProductVariantOption,
    ProductVariantOptionCustomQuantity
  },
  props: {
    variant: {
      type: Object,
      required: true
    },
    submittableCartItem: {
      type: Object,
      required: false
    },
    showPrice: {
      type: Boolean,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    categoryProductStorePair: {
      type: Object,
      required: false
    }
  },
  computed: {
    variantOptions() {
      return this.$store.getters.variantOptions(this.variant.id)
    },
    quantityOption() {
      return this.variant.attributes.quantity_option
    },
    chooseOne() {
      return this.quantityOption == 'choose_one'
    },
    chooseMany() {
      return !this.chooseOne
    },
    customQuantities() {
      return this.quantityOption == 'custom_quantities'
    }
  },

  mounted() {},

  methods: {
    submittableCartItemWithNewVariantOption(selectedVariantOption, quantity = 1) {
      // get cart_item_variant_options_attributes array while removing any duplicates
      let cartItemVariantOptionsAttributes = this.cartItemVariantOptionsWithoutVariantOption(selectedVariantOption)
      // if chooseOne, remove any matching variant_id's
      if (this.chooseOne) {
        cartItemVariantOptionsAttributes = cartItemVariantOptionsAttributes.filter(
          (option) => !(option.variant_id == selectedVariantOption.relationships?.variant?.data?.id)
        )
      }
      // add the variant_id and variant_option_id
      cartItemVariantOptionsAttributes.push({
        variant_option_id: selectedVariantOption.id,
        variant_id: selectedVariantOption.relationships?.variant?.data.id,
        quantity: quantity
      })
      // set the submittableCartItem.cart_item_variant_options_attributes to new array
      this.submittableCartItem.cart_item_variant_options_attributes = cartItemVariantOptionsAttributes
      // set the submittableCartItem.quantity to at least 1
      this.submittableCartItem.quantity = Math.max(this.submittableCartItem.quantity, 1)
      // submit
      return this.submittableCartItem
    },
    submittableCartItemWithoutVariantOption(unselectedVariantOption) {
      // get cart_item_variant_options_attributes array while removing any duplicates
      let cartItemVariantOptionsAttributes = this.cartItemVariantOptionsWithoutVariantOption(unselectedVariantOption)
      // set the submittableCartItem.cart_item_variant_options_attributes to new array
      this.submittableCartItem.cart_item_variant_options_attributes = cartItemVariantOptionsAttributes
      // submit
      return this.submittableCartItem
    },
    cartItemVariantOptionsWithoutVariantOption(withoutVariantOption) {
      return this.submittableCartItem.cart_item_variant_options_attributes.filter(
        (option) => !(option.variant_option_id == withoutVariantOption.attributes?.id)
      )
    },
    selected(selectedVariantOption) {
      this.$store.dispatch('updateSubmittableCart', {
        submittableCartItem: this.submittableCartItemWithNewVariantOption(selectedVariantOption)
      })
    },
    unselected(unselectedVariantOption) {
      this.$store.dispatch('updateSubmittableCart', {
        submittableCartItem: this.submittableCartItemWithoutVariantOption(unselectedVariantOption)
      })
    },
    quantitySet(selectedVariantOption, quantity) {
      this.$store.dispatch('updateSubmittableCart', {
        submittableCartItem: this.submittableCartItemWithNewVariantOption(selectedVariantOption, quantity)
      })
    }
  }
}
</script>
