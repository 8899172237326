<template>
  <article class="max-w-4xl m-auto p-4">
    <h1 class="text-4xl mt-2 mb-6">Bottle Labs, Inc. Privacy Policy</h1>

    <p>
      This Privacy Policy (“Policy”) describes how your information is collected, used, and disclosed by Bottle Labs,
      Inc. and its related companies (“Bottle” or “We” or “Us”). This Policy applies to the Bottle websites available at
      bottle.com or any of our other websites or mobile applications that post this Policy (the “Site”) and our online
      services (the “Services”). This Policy does not apply to websites that post different statements.
    </p>

    <h1 class="text-2xl mt-4 mb-2">WHAT WE COLLECT</h1>

    <p>We collect information about you in a range of ways.</p>

    <h2 class="text-xl mt-4 mb-2">Information You Give Us.</h2>

    <p>
      We collect any personal information you provide to us. For example, you may provide us your name, email address,
      phone number, username, password, and other information you directly give us using the Service.
    </p>

    <p>
      If you tell us where you are (e.g., by allowing your device to send us your location), we may store and use that
      information to provide you with location-based information.
    </p>
    <p>
      If you place orders on our Site, we will collect all information necessary to complete the transaction, including
      your name, credit card information, billing information and shipping information. This information may be shared
      with third parties who help process and fulfill your purchases.
    </p>

    <h2 class="text-xl mt-4 mb-2">Information Automatically Collected.</h2>

    <p>
      We automatically collect information about you and your computer or mobile device when you visit our Site or use
      our Services. For example, we may log your computer or device operating system type, location data, IP address,
      browser type, browser language, the website you visited before browsing to our Site, pages you viewed, how long
      you spent on a page, access times, and information about your use of and actions on our Site or in our Services.
    </p>
    <p>
      Cookies. We may automatically collect information using "cookies." Cookies are small data files stored on your
      hard drive by a website. Among other things, cookies help us make our Site and your experience better. We use
      cookies to see which parts and features of our Site are popular and to count visits to our Site.
    </p>
    <p>
      Web Beacons. We may log information using digital images called “web beacons” (also called “pixel tags”) on our
      Site or in our emails. We use web beacons to manage cookies, count visits, and to learn what marketing works and
      what does not. We also use web beacons to tell if you open or act on our emails.
    </p>

    <h1 class="text-2xl mt-4 mb-2">INFORMATION COLLECTED BY PAYMENT PROCESSOR</h1>

    <p>
      When you make payments on our Site, our third party payment processor will collect all information necessary to
      complete the transaction, including your name, credit card number and billing address. When you transmit your
      payment information to our third party payment processor, the personal information you upload, including your
      credit card number, name, and address will be transmitted to our payment processor in an encrypted format via
      Secure Sockets Layer (SSL) technology.
    </p>

    <h1 class="text-2xl mt-4 mb-2">USE OF PERSONAL INFORMATION</h1>

    <p>
      We use personal information collected through our Services, or disclosed by you on our Site or in connection with
      our Services, for the purposes described in this Policy. For example, we may use your information to: operate,
      maintain, and improve our Site and Services; fill orders you place on the Site and Services; understand you and
      your preferences to enhance your experience and enjoyment using our Sites and Services; respond to comments and
      questions and provide customer service; send you related information including confirmations, updates, security
      alerts, and support and administrative messages (whether to your email address or via SMS text).
    </p>

    <h1 class="text-2xl mt-4 mb-2">SHARING OF PERSONAL INFORMATION</h1>

    <p>
      We do not share your personal information other than as follows: to comply with laws or to respond to lawful
      requests and legal process; to protect the rights and property of Bottle, our agents, users, and others including
      to enforce our agreements, policies, and terms of use; in an emergency to protect the personal safety of its users
      or any person; in connection with or during negotiation of any merger, financing, acquisition, bankruptcy,
      dissolution, transaction or proceeding involving sale, transfer, divestiture or disclosure of all or a portion of
      our business or assets to another company. We may create, use, and disclose de-identified and aggregated data for
      any purpose.
    </p>

    <h1 class="text-2xl mt-4 mb-2">INFORMATION CHOICES AND CHANGES</h1>

    <p>
      You may “opt-out” of receiving our promotional emails by following the instructions in those emails. If you opt
      out, we may still send you non-marketing emails. Non-marketing emails include emails about your accounts and our
      business dealings with you.
    </p>

    <p>
      You may send requests about your personal information to our contact information below. You may request to change
      your contact preferences and update your personal information.
    </p>

    <p>
      When you visit our Site, we and others give you the following choices about use of mechanisms for tracking,
      including tracking of your online activities over time and across different websites and online services by third
      parties. Most web browsers are set to accept cookies by default. If you prefer, you can typically remove and
      reject cookies from our Site with your browser settings. If you remove or reject our cookies, it could affect how
      our Site and Services work for you. You can choose to opt-out of use of cookies by certain third-party analytics
      providers and advertising networks to deliver ads tailored to your profile and preferences. You can learn more
      about the Google Analytics opt out here:
      <a href="https://tools.google.com/dlpage/gaoptout" class="underline">https://tools.google.com/dlpage/gaoptout</a>
      . If you delete your cookies, use a different browser, or buy a new computer, you will need to renew your opt-out
      choice. While we and others give you choices described in this Policy, there are many ways web browser signals and
      other similar mechanisms can indicate your choice to disable tracking, and we may not be aware of or honor every
      mechanism.
    </p>

    <h1 class="text-2xl mt-4 mb-2">CUSTOMER CONTENT</h1>

    <p>
      We also may collect Customer Content in connection with your use of certain products or services. For example, if
      you use our messaging services, we collect the messages being sent and received so that we can convey those
      messages to and from the carrier networks. Similarly, to transmit voice calls to and from the telecommunications
      carrier networks, we have to collect the voice communications being sent and received to route them appropriately.
      You can also use our products and services to record voice communications or have them transcribed, in which case,
      we will also collect those voice recordings or transcriptions.
    </p>

    <p>
      You should not use Bottle’s products or services to receive, send or otherwise process Personal Health Information
      (PHI) as defined by the Health Insurance Portability and Accountability Act of 1996 as amended (HIPAA) unless you
      have either negotiated a Business Associate Agreement (BAA) with Bottle or your use case for Bottle’s products and
      services does not require a BAA. Bottle disclaims all liability for PHI sent, received or processed through
      Bottle’s products or services without a Business Associate Agreement. Just to be clear, Bottle may not necessarily
      sign a BAA in connection with your use of any Bottle product or service. Please contact our sales team to speak
      further regarding HIPAA-compliant uses of Bottle’s products and service.
    </p>

    <h1 class="text-2xl mt-4 mb-2">CONTACT INFORMATION</h1>

    <p>
      We welcome your comments or questions about this Policy. Feel free to email us at hello@bottle.com You may also
      contact us at our address:
      <br />
      1447 Peachtree Street, Suite 700
      <br />
      Atlanta, GA 30306
      <br />
      404-948-6228
    </p>

    <h1 class="text-2xl mt-4 mb-2">CHANGES TO THIS PRIVACY POLICY</h1>

    <p>Last updated on September 19 2019.</p>

    <p>
      We may change this Policy from time to time. If we make any changes to this Policy, we will change the Last
      Revised date above.
    </p>
  </article>
</template>

<script>
export default {
  // Other component properties like data, computed, methods, etc.
  mounted() {
    // Scroll to the top of the page when this component is mounted
    window.scrollTo(0, 0)
  }
}
</script>
