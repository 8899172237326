<template>
  <div v-if="hasCategoryProductStorePairsWithNoCategory">
    <div :id="`no-category`" class="mt-8 mx-4 md:mx-2">
      <h2 class="text-2xl font-bold text-gray-brand-900 w-full">No category</h2>
      <h3 class="text-md text-gray-brand-700 w-full">These items don't belong to any category.</h3>
    </div>

    <div class="md:mx-0 flex flex-wrap justify-between" :class="{ 'mt-8 mb-8': true }">
      <template v-for="categoryProductStorePair in categoryProductStorePairs" :key="categoryProductStorePair.id">
        <CategoryProductStorePairContainer :category-product-store-pair="categoryProductStorePair" :in-cart="inCart" />
      </template>
    </div>
  </div>
</template>

<script>
import CategoryProductStorePairContainer from '@/components/Merchant/CategoryProductStorePairContainer.vue'

export default {
  name: 'NoCategoryCategory',

  components: {
    CategoryProductStorePairContainer
  },

  props: ['inCart', 'categoryProductStorePairs'],

  computed: {
    hasCategoryProductStorePairsWithNoCategory() {
      return this.categoryProductStorePairs?.length > 0
    }
  },

  mounted() {}
}
</script>
