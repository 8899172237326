<template>
  <div v-if="hasNoLongerAvailableItems" class="w-full">
    <div :id="`category-no-longer-available`" class="mt-8 mx-4 md:mx-2 w-full">
      <h2 class="text-2xl font-bold text-gray-brand-900 tracking-tighter w-full">Unavailable items</h2>
      <h3 class="text-md text-gray-brand-700 w-full">
        These items are no longer available for the fulfillment option you've selected.
      </h3>
    </div>

    <div class="md:mx-0 flex flex-wrap justify-between" :class="{ 'mt-8': true }">
      <template v-for="categoryProductStorePair in categoryProductStorePairs" :key="categoryProductStorePair.id">
        <CategoryProductStorePairContainer :category-product-store-pair="categoryProductStorePair" :in-cart="inCart" />
      </template>
    </div>
    <hr class="mb-8" />
  </div>
</template>

<script>
import CategoryProductStorePairContainer from '@/components/Merchant/CategoryProductStorePairContainer.vue'

export default {
  name: 'Category',

  components: {
    CategoryProductStorePairContainer
  },

  props: ['inCart'],

  computed: {
    categoryProductStorePairs() {
      return this.$store.getters.categoryProductStorePairs(this.categoryProductStorePairPointers)
    },
    categoryProductStorePairPointers() {
      return this.$store.getters.currentBottle?.relationships?.removed_category_product_store_pairs_still_in_cart?.data
    },
    hasNoLongerAvailableItems() {
      return this.categoryProductStorePairPointers?.length > 0
    }
  },

  mounted() {}
}
</script>
