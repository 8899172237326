<template>
  <div>
    <div
      class="w-full mb-3 flex items-center"
      :class="{
        'cursor-pointer opacity-100': eligible,
        'cursor-not-allowed opacity-50': !eligible
      }"
    >
      <input
        :id="'deliveryAddress' + deliveryAddress.id"
        :value="deliveryAddress.id"
        :name="`delivery-address${bottle.id}`"
        type="radio"
        class="flex-shrink-0 form-radio h-4 w-4 text-brand-600 transition duration-150 ease-in-out"
        :class="{
          'cursor-pointer': eligible,
          'cursor-not-allowed': !eligible
        }"
        :checked="selected"
        @click="selectDeliveryAddress"
      />
      <label
        :for="'deliveryAddress' + deliveryAddress.id"
        class="ml-3 w-full"
        :class="{
          'cursor-pointer': eligible,
          'cursor-not-allowed': !eligible
        }"
        @click="selectDeliveryAddress"
      >
        <span class="text-md font-semibold text-brand-gray-800 tracking-tight mr-2">{{ topAddressLine }}</span>
        <span
          v-if="!eligible"
          class="text-xxs text-gray-brand-900 font-medium font-manrope uppercase p-1 px-1 bg-gray-brand-300 rounded-full tracking-widest no-wrap truncate"
        >
          Not available
        </span>
        <br />
        <span class="text-sm text-brand-gray-600 tracking-wide">{{ bottomAddressLine }}</span>
        <br v-if="notes" />
        <span class="text-sm text-brand-gray-600 tracking-wide">{{ notes }}</span>
      </label>

      <button
        class="ml-2 inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 group-hover:opacity-100 text-gray-500 hover:text-blue-600"
        @click="editDeliveryAddress()"
      >
        <PencilIcon class="h-4 w-4" aria-hidden="true" />
      </button>
      <button
        class="ml-2 inline-flex items-center shadow-sm p-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full bg-white hover:border-blue-600 transition-all duration-150 group-hover:opacity-100 text-gray-500 hover:text-blue-600"
        @click="removeDeliveryAddress()"
      >
        <XIcon class="h-4 w-4" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>

<script>
import { PencilIcon, XIcon } from '@heroicons/vue/solid'
export default {
  name: 'DeliveryAddressChoice',
  components: {
    PencilIcon,
    XIcon
  },
  props: ['deliveryAddress', 'bottle'],
  data: () => ({}),
  computed: {
    topAddressLine() {
      const a = this.deliveryAddress.attributes
      return [a.address1, a.address2].filter(Boolean).join(', ')
    },
    bottomAddressLine() {
      const a = this.deliveryAddress.attributes
      return [a.city, a.state, a.zip].filter(Boolean).join(', ')
    },
    id() {
      this.deliveryAddress.id
    },
    notes() {
      return this.deliveryAddress.attributes.notes
    },
    eligible() {
      return this.deliveryAddress.attributes.eligible
    },
    selected() {
      return this.bottle?.relationships?.delivery_address?.data?.id == this.deliveryAddress?.id
    }
  },
  methods: {
    removeDeliveryAddress() {
      this.$store
        .dispatch('removeDeliveryAddress', {
          deliveryAddressID: this.deliveryAddress.id,
          bottleId: this.bottle.id
        })
        .then(() => {
          this.$emit('deliveryAddressActionExecuted')
        })
    },
    editDeliveryAddress() {
      this.$emit('deliveryAddressChangeClicked', this.deliveryAddress)
    },
    selectDeliveryAddress() {
      if (this.eligible) {
        this.$emit('showLoading', true)
        this.$store
          .dispatch('setDeliveryAddressID', { deliveryAddressID: this.deliveryAddress.id, bottle: this.bottle })
          .then(
            () => this.$emit('showLoading', false),
            () => this.$emit('showLoading', false)
          )
        this.$emit('clickedNewMethod')
      }
    }
  }
}
</script>
