<template>
  <div class="mx-3 my-2 flex">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ProductBody'
}
</script>

<style scoped lang="scss"></style>
