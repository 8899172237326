<template>
  <label
    class="relative flex items-center justify-center w-full flex-none h-8 cursor-pointer text-gray-brand-900 hover:bg-gray-brand-100 focus:outline-none transition-all"
    :class="{ 'text-brand-800 bg-gray-brand-200': checked }"
  >
    <input :id="id" :value="id" type="radio" class="sr-only" :checked="checked" @click="clickedOption(id)" />
    <div class="flex flex-col items-center p-3">
      <span id="date-0-label" class="block text-xs font-medium text-gray-900 text-center">
        {{ fulfillmentSlotTimeRange }} {{ merchantTimeZoneAbbreviation }}
      </span>
    </div>
  </label>
</template>

<script>
let strftime = require('strftime')

export default {
  name: 'InfoRowSlotTime',
  components: {},
  props: ['option', 'selectedOption', 'merchantTimeZone'],
  data: () => ({}),
  computed: {
    merchantTZ() {
      return `(${this.merchantTimeZone})`
    },
    merchantAndBrowserTZMatches() {
      return this.merchantTimeZone === Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    merchantTimeZoneAbbreviation() {
      if (!this.merchantAndBrowserTZMatches) {
        return `(${
          new Date()
            .toLocaleTimeString('en-US', { timeZone: this.merchantTimeZone, timeZoneName: 'short' })
            .split(' ')[2]
        })`
      }
    },
    browserTZ() {
      return new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4)
    },
    id() {
      return this.option.attributes?.id
    },
    checked() {
      return this.id == this.selectedOption
    },
    fulfillmentSlotTimeRange() {
      const startTime = new Date(
        new Date(this.option?.attributes?.arrives_beginning_estimate).toLocaleString('en-US', {
          timeZone: this.merchantTimeZone
        })
      )
      const endTime = new Date(
        new Date(this.option?.attributes?.arrives_ending_estimate).toLocaleString('en-US', {
          timeZone: this.merchantTimeZone
        })
      )
      if (startTime.toString() == endTime.toString()) {
        return strftime('%-I:%M', startTime) + strftime('%p', startTime).toLowerCase()
      } else if (startTime.getDate() === endTime.getDate()) {
        return (
          'Between ' +
          strftime('%-I:%M', startTime) +
          strftime('%p and ', startTime).toLowerCase() +
          strftime('%-I:%M', endTime) +
          strftime('%p', endTime).toLowerCase()
        )
      } else {
        return (
          strftime('Between %A at ', startTime) +
          strftime('%-I:%M and ', startTime) +
          strftime('%A, %B %-d at ', endTime) +
          strftime('%-I:%M', endTime)
        )
      }
    }
  },
  methods: {
    clickedOption(value) {
      this.$emit('clickedOption', value)
    }
  }
}
</script>
