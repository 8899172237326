<template>
  <Box class="mt-4 md:mt-6" :is-loading="isLoading">
    <MembershipOptionsBlock
      :membership-tier="membershipTier"
      :membership-tiers="membershipTiers"
      :distribution-list="distributionList"
      :distribution-lists="distributionLists"
      :show-tier="showTier"
      :show-list="showList"
      :show-store="showStore"
      :show-autocharge="showAutocharge"
      :interval-cart-setting="intervalCartSetting"
      :autocharge-cart-setting="autochargeCartSetting"
      class=""
      @set-membership-tier="setMembershipTier"
      @set-distribution-list="setDistributionList"
      @set-cart-interval="setCartInterval"
      @set-cart-autocharge="setCartAutocharge"
    />
  </Box>
</template>

<script>
import Box from '@/components/Molecules/Box'
import MembershipOptionsBlock from '@/components/Checkout/MembershipOptionsBlock'
import mapRelationship from '../../utils/mapRelationship'

export default {
  components: {
    Box,
    MembershipOptionsBlock
  },
  props: ['showTier', 'showList', 'showStore', 'showAutocharge'],
  data: () => ({
    changeMembership: false,
    changeList: false,
    isLoading: false
  }),
  computed: {
    membershipTiers() {
      return this.$store.getters.membershipTiers
    },
    membershipTier() {
      return this.$store.getters.membershipTier
    },
    distributionLists() {
      return this.$store.getters.distributionLists(this.membershipTier?.relationships.distribution_lists?.data)
    },
    distributionList() {
      return this.$store.getters.distributionList
    },
    intervalCartSetting() {
      return mapRelationship(
        this.$store.state,
        this.$store.getters.currentBottle?.relationships?.interval_cart_subscription_setting?.data
      )
    },
    autochargeCartSetting() {
      return mapRelationship(
        this.$store.state,
        this.$store.getters.currentBottle?.relationships?.autocharge_cart_subscription_setting?.data
      )
    }
  },
  async mounted() {
    this.$store.dispatch('getMembershipTiers')
  },
  methods: {
    setMembershipTier(tierID) {
      this.showLoading(true)
      this.$store.dispatch('setMembershipTier', tierID).then(
        (response) => {
          // properly updated
          this.showLoading(false)
        },
        (error) => {
          // failed to update
          this.showLoading(false)
        }
      )
    },
    setDistributionList(listID) {
      this.showLoading(true)
      this.$store.dispatch('setDistributionList', listID).then(
        (response) => {
          // properly updated
          this.showLoading(false)
        },
        (error) => {
          // failed to update
          this.showLoading(false)
        }
      )
    },
    setCartInterval(intervalID) {
      this.showLoading(true)
      this.$store.dispatch('setIntervalCartSubscriptionSetting', intervalID).then(
        (response) => {
          // properly updated
          this.showLoading(false)
        },
        (error) => {
          // failed to update
          this.showLoading(false)
        }
      )
    },
    setCartAutocharge(autochargeID) {
      this.showLoading(true)
      this.$store.dispatch('setAutochargeCartSubscriptionSetting', autochargeID).then(
        (response) => {
          // properly updated
          this.showLoading(false)
        },
        (error) => {
          // failed to update
          this.showLoading(false)
        }
      )
    },
    showLoading(value) {
      this.isLoading = value
    }
  }
}
</script>
