<template>
  <div>
    <div :id="`category-${category.attributes.id}`" class="mt-8 mx-4 md:mx-2">
      <div class="flex items-center align-middle w-full flex-nowrap justify-between md:justify-start">
        <h2
          @click="toggleCategory"
          class="text-2xl font-bold text-gray-brand-900 tracking-tighter cursor-pointer align-middle"
        >
          {{ category.attributes.category_name }}
        </h2>
        <button
          v-if="offerCollapseFunctionality"
          @click="toggleCategory"
          class="ml-3 px-2 py-1 border border-gray-brand-900 text-xs text-gray-brand-900 rounded-full flex items-center transition-all flex-shrink-0"
        >
          <MinusIcon
            v-show="isCategoryVisible"
            class="w-3 h-3 mr-1"
            :class="{
              'opacity-0': !isCategoryVisible,
              'opacity-100': isCategoryVisible
            }"
          ></MinusIcon>
          <PlusIcon
            v-show="!isCategoryVisible"
            class="w-3 h-3 mr-1"
            :class="{
              'opacity-0': isCategoryVisible,
              'opacity-100': !isCategoryVisible
            }"
          ></PlusIcon>
          {{ isCategoryVisible ? 'Hide' : 'Show' }}
          <span v-if="!isCategoryVisible && itemsInCartCount > 0" class="ml-1 bg-gray-200 rounded-md px-1.5 py-0.5">
            <b>{{ itemsInCartCount }} in cart</b>
          </span>
        </button>
      </div>
      <h3 class="text-md text-gray-brand-700 w-full">
        {{ category.attributes.category_description }}
      </h3>
    </div>

    <div
      class="md:mx-0 flex flex-wrap justify-between transition-all duration-300"
      :class="{
        'mt-8 mb-8': true,
        'opacity-0 h-0 hidden': !isCategoryVisible,
        'opacity-100': isCategoryVisible
      }"
    >
      <template v-for="categoryProductStorePair in categoryProductStorePairs" :key="categoryProductStorePair.id">
        <CategoryProductStorePairContainer :category-product-store-pair="categoryProductStorePair" :in-cart="inCart" />
      </template>
    </div>
  </div>
</template>

<script>
import CategoryProductStorePairContainer from '@/components/Merchant/CategoryProductStorePairContainer.vue'
import { MinusIcon, PlusIcon } from '@heroicons/vue/solid'

export default {
  name: 'Category',

  components: {
    CategoryProductStorePairContainer,
    MinusIcon,
    PlusIcon
  },

  props: ['category', 'inCart', 'offerCollapseFunctionality'],

  data() {
    return {
      isCategoryVisible: true
    }
  },

  computed: {
    categoryProductStorePairs() {
      return this.$store.getters.categoryProductStorePairs(this.categoryProductStorePairPointers)
    },
    categoryProductStorePairPointers() {
      if (this.isMultibottleFunctionality()) {
        return this.category?.relationships?.category_product_store_pairs?.data
      } else {
        return this.category?.relationships?.category_product_store_pairs?.data?.filter((x) => {
          return this.categoryProductStorePairsForBottle?.includes(x.id)
        })
      }
    },
    categoryProductStorePairsForBottle() {
      return this.$store.getters.currentBottle?.relationships?.category_product_store_pairs?.data?.map((x) => x.id)
    },
    itemsInCartCount() {
      // return cartItems from the submittableCart that contain a category_product_store_pair_id that is in the categoryProductStorePairPointers
      // and then sum the quantity of those cartItems
      return this.$store.getters.submittableCart
        ?.filter((x) => {
          return this.categoryProductStorePairPointers
            ?.map((x) => x.id)
            ?.includes(x.category_product_store_pair_id.toString())
        })
        .reduce((acc, curr) => {
          return acc + curr.quantity
        }, 0)
    }
  },

  mounted() {},
  methods: {
    isMultibottleFunctionality() {
      const currentBottle = this.$store.getters.currentBottle
      return currentBottle && currentBottle.attributes.allow_multiple_days
    },
    toggleCategory() {
      if (this.offerCollapseFunctionality) {
        this.isCategoryVisible = !this.isCategoryVisible
      }
    }
  }
}
</script>
