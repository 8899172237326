export function dateObjectToServerDay(d) {
  // returns YYYY-MM-DD
  // for API calls that need only the day part
  return [d.getFullYear(), ('0' + (d.getMonth() + 1)).slice(-2), ('0' + d.getDate()).slice(-2)].join('-')
}

export function serverDayToDateObject(d) {
  // reverse of the above
  // converts YYYY-MM-DD to the start of day in the current timezone
  if (isNaN(new Date(d + 'T00:00'))) return null
  return new Date(d + 'T00:00')
}
