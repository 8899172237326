<template>
  <nav v-show="shouldShowCategoriesBar" class="bg-transparent">
    <div class="max-w-10xl mx-auto bg-white/90 backdrop-blur border-b-2 border-b-brand-800">
      <div class="flex h-14">
        <div class="flex shrink-0 z-10 cursor-pointer" @click="goToRoot">
          <img v-if="$store.getters.merchant" class="w-14" :src="$store.getters.merchant?.attributes.avatar_url" />
          <div
            v-if="false && $store.getters.merchant?.attributes.user_name"
            class="hidden sm:flex items-center h-full bg-black/40 sm:bg-white justify-center"
          >
            <div class="text-white sm:text-black z-10 px-4 text-lg sm:text-xl">
              {{ $store.getters.merchant?.attributes.user_name }}
            </div>
          </div>
        </div>

        <!-- :style="`width: ${cat?.attributes.category_name.length * 15}px`" -->

        <div ref="categoryBar" class="w-full flex flex-nowrap overflow-x-auto overflow-y-hidden whitespace-pre pl-2">
          <button
            v-for="cat in categories"
            :id="`category-menu-item-${cat?.attributes.id}`"
            :key="cat?.attributes.id"
            class="text-lg sm:text-xl h-full inline-flex flex-col items-center justify-center text-gray-brand-800 px-2 cursor-pointer after:content-[attr(data-text)] after:font-bold after:h-0 after:invisible tracking-tighter"
            :data-text="cat?.attributes.category_name"
            :class="{
              'font-bold': activeCategory?.attributes.id === cat?.attributes.id
            }"
            role="listitem"
            @click="handleCategoryClick(cat)"
          >
            {{ cat?.attributes.category_name }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { UserIcon } from '@heroicons/vue/outline'

export default {
  components: { UserIcon },
  setup() {
    const store = useStore()
    const categories = computed(() => store.getters.categories)
    const shouldShowCategoriesBar = ref(false)
    const activeCategory = ref(null)
    const categoryBar = ref(null)
    let prevCategory = null

    const listener = (e) => {
      shouldShowCategoriesBar.value = window.scrollY > 30 && categories.value.length > 1

      // reset scroll when returning to top
      if (window.scrollY <= 30) {
        categoryBar.value.scrollTo({ left: 0 })
      }

      activeCategory.value = store?.getters.categories
        ?.map((x) => [
          x,
          document.getElementById('category-' + x?.attributes.id)?.getBoundingClientRect().bottom -
            window.screen.height +
            300
        ])
        .filter((x) => x[1] < 0)
        .pop()?.[0]

      const cat = document.getElementById('category-menu-item-' + activeCategory.value?.attributes?.id)
      if (activeCategory.value !== prevCategory && cat) {
        const catCenter = cat.offsetLeft + cat.offsetWidth / 2
        const barCenter = categoryBar.value.offsetLeft + categoryBar.value.offsetWidth / 2
        categoryBar.value.scrollTo({
          left: catCenter - barCenter,
          behavior: 'smooth'
        })
        prevCategory = activeCategory.value
      }
    }

    onMounted(() => {
      shouldShowCategoriesBar.value = window.scrollY > 30 && categories.value.length > 1

      document.addEventListener('scroll', listener)
    })

    onBeforeUnmount(() => {
      document.removeEventListener('scroll', listener)
    })

    function handleCategoryClick(category) {
      const offset = document.getElementById(`category-${category.attributes.id}`).getBoundingClientRect().top - 100
      window.scrollBy({ top: offset, behavior: 'smooth' })
    }

    return {
      categories,
      shouldShowCategoriesBar,
      handleCategoryClick,
      activeCategory,
      categoryBar
    }
  },
  methods: {
    goToRoot() {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
