<template>
  <div class="z-20 w-full fixed top-0">
    <MerchantNav :disable-hide-on-scroll="true" />
  </div>

  <div v-fade-in v-if="loading" class="w-full">
    <Loading class="mt-10" />
  </div>
  <div v-show="!loading">
    <div v-if="!hasAccess" class="w-full max-w-5xl mx-auto h-full py-20">
      <div class="px-2 md:px-0 md:w-1/2 mx-auto pt-4 sm:pt-10">
        <div class="flex justify-start items-center space-x-2">
          <LockClosedIcon class="h-5 w-5" />
          <div class="text-2xl font-bold text-gray-brand-900 tracking-tighter leading-6">
            The {{ store?.attributes.store_name }} store is locked.
          </div>
        </div>
        <div v-if="store?.attributes.allow_password_access" className="mt-6 max-w-md">
          <div className="flex gap-x-4">
            <label htmlFor="password" className="sr-only">Store password</label>
            <input
              id="password"
              name="password"
              type="password"
              v-model="password"
              required
              className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-brand-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
              placeholder="Store password"
              @keydown="
                (e) => {
                  if (e.key === 'Enter') enterPassword()
                }
              "
            />
            <PrimaryButton
              @click="enterPassword"
              :size="'lg'"
              :justify="'justify-center'"
              :active="password?.length > 0"
              :streaking="password?.length > 0"
              :is-loading="loading"
            >
              Enter store
              <template #right-icon><ArrowRightIcon class="ml-2 h-4" /></template>
            </PrimaryButton>
          </div>
        </div>
        <h3 class="mt-8 text-md text-gray-brand-700 w-full leading-5 space-y-2">
          <SecondaryButton v-if="!isLoggedIn" @click="goToLogin" class="w-full" :size="'lg'">Login</SecondaryButton>
          <SecondaryButton @click="canText ? goTo(phoneLink) : goTo(emailLink)" class="w-full" :size="'lg'">
            <span v-if="canText">Text us to get access.</span>
            <span v-else>Email us to get access.</span>
          </SecondaryButton>
        </h3>
      </div>
    </div>
    <div v-else-if="hasExistingBottles" class="w-full max-w-5xl mx-auto h-full py-20">
      <!-- Existing bottles card -->
      <div class="px-2 md:px-0 md:w-1/2 mx-auto pt-4 sm:pt-10">
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter">
            Edit an existing {{ store?.attributes.store_name }} order?
          </div>
        </div>

        <div v-for="bottle in computedBottles" :key="bottle.id">
          <BottleCycleStoreBottleCard :bottle="bottle" />
        </div>
      </div>

      <!-- Start a new order -->
      <div class="px-2 md:px-0 md:w-1/2 mx-auto my-10">
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter">Or start a new order?</div>
        </div>
        <div class="mt-4 md:mt-6">
          <div class="">
            <SecondaryButton @click="newBottle" class="w-full" :size="'xl'" :is-loading="newBottleIsLoading">
              <template #right-icon>
                <svg
                  class="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                </svg>
              </template>
              Start a new order
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MerchantNav, Loading } from '@/components'
import BottleCycleStoreBottleCard from '@/components/Checkout/BottleCycleStoreBottleCard'
import BasicButton from '@/components/Molecules/BasicButton'
import PrimaryButton from '@/components/Molecules/PrimaryButton'
import SecondaryButton from '@/components/Molecules/SecondaryButton'
import mapRelationships from '../utils/mapRelationships'
import { LockClosedIcon, ArrowRightIcon } from '@heroicons/vue/outline'

export default {
  components: {
    MerchantNav,
    BottleCycleStoreBottleCard,
    BasicButton,
    Loading,
    PrimaryButton,
    SecondaryButton,
    LockClosedIcon,
    ArrowRightIcon
  },
  data() {
    return {
      bottles: [],
      newBottleIsLoading: false,
      existingBottleIsLoading: false,
      loading: true,
      enteredPassword: false
    }
  },
  props: {
    password: {
      type: String,
      default: ''
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    storeID() {
      return this.$route.params.storeId
    },
    store() {
      return this.$store.state.objects.store?.[this.storeID]
    },
    locked() {
      return this.store?.attributes.locked
    },
    hasAccess() {
      return !this.locked || this.store?.attributes.customer_has_access || this.enteredPassword
    },
    hasExistingBottles() {
      return this.bottles.length > 0
    },
    computedBottles() {
      return mapRelationships(this.$store.state, this.bottles)
    },
    info() {
      return this.$store.getters.info
    },
    phone() {
      return this.info?.attributes?.phone
    },
    phoneLink() {
      return 'sms:' + (this.phone ? this.phone : '')
    },
    canText() {
      return this.phone
    },
    emailLink() {
      return 'mailto:' + (this.info?.attributes?.email ? this.info?.attributes?.email : '')
    }
  },
  async mounted() {
    window.scrollTo(0, 0)
    await this.$store.dispatch('showStore', this.storeID)
    if (this.hasAccess) {
      await this.loadBottles()
    }
    this.loading = false
  },
  methods: {
    async loadBottles() {
      const data = await this.$store.dispatch('getStoreBottles', this.storeID)
      // if it's a hash, push immediately to selection
      if (Array.isArray(data)) {
        this.bottles = data
      } else {
        this.$router.replace({ name: 'Selection', params: { bottleid: data?.id } })
      }
    },
    async newBottle() {
      this.newBottleIsLoading = true
      const data = await this.$store.dispatch('getNewBottleId', this.storeID)
      this.newBottleIsLoading = false
      this.$router.push({ name: 'Selection', params: { bottleid: data?.id } })
    },
    goToBottle(bottleID) {
      this.$router.push({ name: 'Selection', params: { bottleid: bottleID } })
    },
    cartFor(bottle) {
      return this.$store.getters.cart(bottle.relationships?.cart.data)
    },
    lastEditedTimestamp(bottle) {
      if (!bottle.attributes.updated_at) return ''
      let time = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hourCycle: 'h12'
      }).format(new Date(bottle.attributes.updated_at))
      let day = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).format(new Date(bottle.attributes.updated_at))
      return day + ' at ' + time
    },
    async enterPassword() {
      if (this.password === this.store?.attributes?.simple_password) {
        this.enteredPassword = true
        this.loading = true
        await this.loadBottles()
        this.loading = false
      } else {
        alert('Incorrect password.')
      }
    },
    goToLogin() {
      this.$router.push({ name: 'Login' })
    },
    goTo(link) {
      window.location.href = link
    }
  }
}
</script>
