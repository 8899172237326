<template>
  <main class="max-w-5xl mx-auto pt-16 flex flex-wrap justify-between">
    <LoadingProductsSkeleton v-for="v in 4" :key="v" />
  </main>
</template>

<script>
import { LoadingProductsSkeleton } from '@/components'

export default {
  name: 'Loading',
  components: { LoadingProductsSkeleton }
}
</script>
