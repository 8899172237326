<template>
  <div ref="el" class="h-full w-full"></div>
</template>

<script setup>
import _ from 'lodash'
import mapRelationship from '@/utils/mapRelationship'
import { ref, watch } from 'vue'
import { useStore } from 'vuex'

const mapsPromise = google.maps.importLibrary('maps')
const corePromise = google.maps.importLibrary('core')
const markerPromise = google.maps.importLibrary('marker')

const store = useStore()
const props = defineProps(['methods', 'bottle'])
const emit = defineEmits(['showLoading', 'clickedNewMethod'])
const el = ref(null)

const map = ref(null)

const methodPosition = (method) => {
  const address = mapRelationship(store.state, method.relationships.delivery_address.data)
  if (address) return { lat: address.attributes.latitude, lng: address.attributes.longitude }
  return undefined
}

watch(
  () => props.methods,
  async () => {
    if (props.methods?.length < 1) return

    const { Map } = await mapsPromise
    map.value = new Map(el.value, {
      center: methodPosition(props.methods[0]),
      zoom: 13,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false
    })

    const { LatLngBounds } = await corePromise
    const bounds = new LatLngBounds()
    for (const point of props.methods
      .filter((x) => x.attributes.eligible)
      .map(methodPosition)
      .filter(Boolean))
      bounds.extend(point)
    if (props.methods.length > 1) map.value.fitBounds(bounds)

    const { Marker } = await markerPromise
    for (const [i, method] of props.methods.entries()) {
      if (!methodPosition(method) || !method.attributes.eligible) continue
      new Marker({
        position: methodPosition(method),
        map: map.value,
        label: (i + 1).toString()
      }).addListener('click', (e) => {
        emit('showLoading', true)
        store
          .dispatch('selectFulfillmentMethod', { methodId: method.id, bottle: props.bottle })
          .then(() => emit('showLoading', false))
        emit('clickedNewMethod')
      })
    }
  },
  { immediate: true }
)
</script>
