<template>
  <div class="min-h-full antialiased font-inter text-gray-brand-900 bg-gray-brand-100">
    <div class="w-full">
      <main class="max-w-5xl mx-auto pt-24 flex flex-wrap justify-between">
        <h1>Something went wrong, try again later.</h1>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServerError'
}
</script>
