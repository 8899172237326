<template>
  <article class="max-w-4xl m-auto p-4">
    <h1 class="text-4xl mt-2 mb-6">Terms of Service</h1>
    <p>Last updated: April 20, 2023</p>
    <p>
      Thank you for using the services provided by Bottle Labs Inc. ("Bottle Labs", "we", "us", "our"). By accessing,
      using or signing up for any of our services, you agree to be bound by these Terms of Service ("Terms"). Please
      read these Terms carefully, and if you do not agree with any part of these Terms, you must not use our services.
    </p>
    <h2 class="text-2xl mt-4 mb-4">1. Eligibility and Account Registration</h2>
    <p>
      To access and use our services, you must be at least 18 years old, or the age of legal majority in your
      jurisdiction, and have the legal capacity to enter into a binding agreement with us. You may be required to create
      an account, and you agree to provide accurate, complete, and up-to-date information for your account. You are
      solely responsible for maintaining the security of your account, and you agree to notify us immediately if you
      suspect any unauthorized access or use of your account.
    </p>
    <h2 class="text-2xl mt-4 mb-4">2. Our Services</h2>
    <p>
      Bottle Labs offers various services, including, but not limited to, providing tools for users to communicate with
      customers and take payment online. We reserve the right to modify or discontinue any part of our services at any
      time, with or without notice to you. We will not be liable to you or any third party for any modification or
      discontinuation of our services.
    </p>
    <h2 class="text-2xl mt-4 mb-4">3. User Content</h2>
    <p>
      Some of our services may allow you to upload, store, or share content, such as text, images, or other materials
      ("User Content"). By submitting User Content, you grant us a non-exclusive, royalty-free, worldwide,
      sublicensable, and transferable license to use, reproduce, distribute, modify, adapt, and publicly display your
      User Content for the purpose of providing our services. You represent and warrant that you have the necessary
      rights and permissions to submit your User Content and grant us the license described above.
    </p>
    <h2 class="text-2xl mt-4 mb-4">4. Prohibited Conduct</h2>
    <p>
      You agree not to use our services to engage in any illegal, harmful, or offensive activities, or to violate the
      rights of others. You also agree not to interfere with the operation of our services or attempt to gain
      unauthorized access to our systems, networks, or user accounts.
    </p>
    <h2 class="text-2xl mt-4 mb-4">5. Privacy and Data Collection</h2>
    <p>
      Your privacy is important to us. Please review our Privacy Policy, which explains how we collect, use, and protect
      your personal information when you use our services. By using our services, you agree to the collection and use of
      your personal information in accordance with our Privacy Policy.
    </p>
    <h2 class="text-2xl mt-4 mb-4">6. Third-Party Services and Integrations</h2>
    <p>
      Our services may integrate with, or be provided through, third-party services, such as Stripe, Twilio, Google,
      Amazon, Pusher, and Cloudinary. You agree that your use of these third-party services is subject to their
      respective terms of service and privacy policies. Bottle Labs is not responsible for the content, functionality,
      or practices of these third-party services. You agree to release Bottle Labs from any liability arising from your
      use of any third-party services or your interaction with their providers.
    </p>

    <h2 class="text-2xl mt-4 mb-4">7. Intellectual Property Rights</h2>
    <p>
      All intellectual property rights in our services, including but not limited to, any software, content, and designs
      provided by Bottle Labs, are owned by or licensed to us, and are protected by copyright, trademark, and other
      intellectual property laws. You agree not to reproduce, distribute, modify, or create derivative works from our
      services, without our prior written consent.
    </p>
    <h2 class="text-2xl mt-4 mb-4">8. Disclaimer of Warranties</h2>
    <p>
      Our services are provided "as is" and "as available" without any warranties of any kind, either express or
      implied, including but not limited to, warranties of merchantability, fitness for a particular purpose, and
      non-infringement. We do not warrant that our services will be error-free, secure, or uninterrupted, or that they
      will meet your requirements or expectations.
    </p>
    <h2 class="text-2xl mt-4 mb-4">9. Limitation of Liability</h2>
    <p>
      To the fullest extent permitted by applicable law, Bottle Labs and its affiliates, directors, officers, employees,
      and agents will not be liable for any indirect, incidental, special, consequential, or punitive damages, including
      but not limited to, loss of profits, data, use, or goodwill, arising out of or in connection with these Terms or
      your use of our services, whether in contract, tort, or otherwise, even if we have been advised of the possibility
      of such damages. Our total liability for any claims arising out of or in connection with these Terms or your use
      of our services will not exceed the greater of one hundred dollars ($100) or the amount you have paid us for our
      services in the twelve (12) months preceding the date of the claim.
    </p>
    <h2 class="text-2xl mt-4 mb-4">10. Indemnification</h2>
    <p>
      You agree to indemnify, defend, and hold harmless Bottle Labs and its affiliates, directors, officers, employees,
      and agents from and against any and all claims, liabilities, damages, losses, costs, and expenses, including
      reasonable attorneys' fees, arising out of or in connection with your use of our services, your breach of these
      Terms, or your violation of any applicable laws or the rights of any third party.
    </p>
    <h2 class="text-2xl mt-4 mb-4">11. Governing Law and Dispute Resolution</h2>
    <p>
      These Terms and any disputes arising out of or in connection with them will be governed by the laws of the state
      in which Bottle Labs is incorporated, without regard to its conflict of laws principles. Any disputes arising out
      of or in connection with these Terms will be resolved by binding arbitration, conducted in the state in which
      Bottle Labs is incorporated, in accordance with the rules of the American Arbitration Association.
    </p>
    <h2 class="text-2xl mt-4 mb-4">12. Changes to These Terms</h2>
    <p>
      We may update these Terms from time to time, at our sole discretion. We will notify you of any changes by posting
      the updated Terms on our website or through our services. By continuing to use our services after the changes
      become effective, you agree to be bound by the updated Terms.
    </p>
    <h2 class="text-2xl mt-4 mb-4">13. Termination</h2>
    <p>
      We may terminate or suspend your access to our services at any time, for any reason, without prior notice or
      liability to you, including, but not limited to, if you breach these Terms. Upon termination, all provisions of
      these Terms, which by their nature should survive termination, shall survive, including but not limited to,
      intellectual property rights, disclaimer of warranties, limitation of liability, and indemnification provisions.
    </p>
    <h2 class="text-2xl mt-4 mb-4">14. General Provisions</h2>
    <p>
      If any provision of these Terms is held to be invalid or unenforceable by a court of competent jurisdiction, the
      remaining provisions will remain in full force and effect. Our failure to enforce any right or provision of these
      Terms will not be considered a waiver of those rights. You may not assign or transfer your rights or obligations
      under these Terms without our prior written consent. We may assign or transfer our rights or obligations under
      these Terms without restriction. These Terms, together with our Privacy Policy, constitute the entire agreement
      between you and Bottle Labs with respect to our services and supersede any prior agreements, communications, or
      understandings, whether oral or written.
    </p>
    <h2 class="text-2xl mt-4 mb-4">15. Contact Information</h2>
    <p>If you have any questions or concerns about these Terms or our services, please contact us at:</p>
    <p>Bottle Labs Inc.</p>
    <p>1447 Peachtree Street, Suite 700, Atlanta, GA 30306</p>
    <p>Email: team at bottle.com</p>
  </article>
</template>

<script>
export default {
  // Other component properties like data, computed, methods, etc.
  mounted() {
    // Scroll to the top of the page when this component is mounted
    window.scrollTo(0, 0)
  }
}
</script>
