import { ref } from 'vue'

export function useExpandProductHero() {
  const isHeroExpanded = ref(false)
  const handleExpandHero = () => (isHeroExpanded.value = !isHeroExpanded.value)

  return {
    isHeroExpanded,
    handleExpandHero
  }
}
