import { createApp } from 'vue'
import App from '@/App.vue'
import store from '@/store'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import linkify from 'vue-linkify'
import fadeIn from './directives/fadeIn'

import './assets/styles/index.css'

import router from '@/router'

const app = createApp(App)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://23863da3f5044d08978257611634f477@o1224056.ingest.sentry.io/6418531',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'api.bottle.com', /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true
  })
}

app.use(router)
app.use(store)
app.directive('linkified', linkify)
app.directive('fade-in', fadeIn)
app.mount('#app')
