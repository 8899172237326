<template>
  <router-link v-if="type === 'router-link'" :to="to" v-bind="$attrs">
    <slot></slot>
  </router-link>
  <a v-if="type === 'a'" :href="to" v-bind="$attrs" target="_blank">
    <slot></slot>
  </a>
  <div v-if="type === 'button'" v-bind="$attrs">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      required: false,
      default: undefined
    },
    type: {
      type: String,
      required: true,
      default: 'router-link'
    }
  }
}
</script>
