<template>
  <article class="max-w-4xl m-auto p-4">
    <h1 class="text-4xl mt-2 mb-6">Bottle Labs Inc. SMS Terms</h1>
    <p>
      By providing your phone number to one of our clients, you consent to receive text messages from that business via
      the Bottle Labs Inc. platform, which uses the Twilio Inc. API to facilitate message delivery. These messages may
      include promotions, offers, alerts, or other notifications sent by the business on whose behalf we are collecting
      your phone number. Standard message and data rates may apply.
    </p>
    <p>
      It is the responsibility of the business collecting your phone number to obtain your consent to receive these text
      messages. You may opt out of receiving text messages at any time by replying with the word "STOP" to any message
      received. Following your opt-out request, you will no longer receive text messages from the business associated
      with that phone number, unless you provide your consent again.
    </p>
    <p>
      Please note that we do not control the content or frequency of the text messages sent by the businesses using our
      platform. If you have questions or concerns about the messages you receive or the information being collected,
      please contact the business directly.
    </p>
    <p>
      Bottle Labs Inc. respects your privacy and is committed to protecting the information we collect. For more
      information about our privacy practices, please review our
      <a href="/privacy">Privacy Policy</a>
      .
    </p>
    <p>
      By submitting your phone number to a business using our platform, you agree to these SMS Terms and our Privacy
      Policy. If you have any questions regarding these SMS Terms or our services, please contact us at team at
      bottle.com.
    </p>
  </article>
</template>

<script>
export default {
  // Other component properties like data, computed, methods, etc.
  mounted() {
    // Scroll to the top of the page when this component is mounted
    window.scrollTo(0, 0)
  }
}
</script>
