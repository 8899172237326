<template>
  <div class="border-t border-gray-200 py-6 px-4">
    <form class="sm:flex sm:items-center" @submit.prevent="addDiscount()">
      <div class="w-full">
        <label for="discount" class="sr-only">Discount and referral codes</label>
        <input
          id="discount"
          v-model="form.discountValue"
          type="text"
          name="discount"
          class="block w-full pl-3 pr-3 py-2.5 border border-b-0 sm:border-b sm:border-r-0 border-gray-300 shadow-sm rounded-t-md rounded-b-none sm:rounded-b-md sm:rounded-r-none text-gray-brand-900 md:hover:bg-brand-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-600"
          placeholder="Giftcard or discount code"
        />
      </div>
      <button
        type="submit"
        class="mt-0 sm:mt-0 sm:w-auto rounded-b-md rounded-t-none sm:rounded-t-md sm:rounded-l-none w-full inline-flex flex-none items-center justify-center px-4 py-2 sm:py-3 border border-gray-300 shadow-sm text-gray-brand-900 md:hover:bg-brand-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-600 font-semibold text-sm"
      >
        Apply
      </button>
    </form>

    <div v-if="appliedDiscountCodes?.length" class="mt-2 flex-wrap items-center justify-start">
      <span
        v-for="(item, i) in appliedDiscountCodes"
        :key="i"
        class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-brand-100 text-brand-700"
      >
        {{ item.attributes.code }}
        <button
          type="button"
          class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-brand-400 hover:bg-brand-200 hover:text-brand-500 focus:outline-none focus:bg-brand-500 focus:text-white"
          @click.prevent="removeDiscount(item.id)"
        >
          <span class="sr-only">Remove "{{ item.attributes.name }}"</span>
          <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
          </svg>
        </button>
      </span>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'

export default {
  emits: ['discount-updated'],
  setup(props, context) {
    const form = ref({
      discountValue: ''
    })
    const discounts = ref([
      {
        name: 'PowerUp',
        id: 209
      }
    ])
    const addDiscount = () => {
      context.emit('discount-updated', form.value.discountValue)
      form.value.discountValue = ''
    }
    const removeDiscount = (appliedDiscountID) => {
      context.emit('discount-removed', appliedDiscountID)
    }
    return {
      discounts,
      form,
      addDiscount,
      removeDiscount
    }
  },
  computed: {
    appliedDiscountCodes() {
      return this.$store.getters.appliedDiscountCodes(this.cart?.relationships.applied_discount_codes?.data)
    },
    cart() {
      return this.$store.getters.currentCart
    }
  }
}
</script>
