<template>
  <aside class="flex-none mt-1">
    <div class="relative w-20 h-20 pb-full border shadow-sm rounded-sm overflow-hidden cursor-pointer">
      <img
        loading="lazy"
        class="absolute h-full w-full object-cover"
        :src="sources[0].attributes.secure_url"
        :alt="sources[0].attributes.title"
      />
    </div>
  </aside>
</template>

<script>
export default {
  name: 'ProductImage',

  props: {
    sources: {
      type: Object,
      required: true
    }
  }
}
</script>
