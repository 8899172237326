<template>
  <div>
    <div class="flex w-full border-b cursor-pointer" @click="view">
      <div class="w-1/5 flex items-center">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5 mx-auto mb-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
              />
            </svg>
            #{{ bottle.attributes?.display_id }}
          </div>
        </div>
      </div>
      <div class="w-4/5 flex justify-between pt-4 pb-4 pl-6 pr-2">
        <div class="flex items-center">
          <fieldset class="">
            <div class="leading-5">
              <div class="flex items-center">
                <label for="1" class="cursor-pointer">
                  <span class="text-md font-semibold text-brand-gray-800">{{ displayPaymentTimestamp }}</span>
                  <div class="text-sm text-gray-brand-600" v-if="cancelled">Cancelled</div>
                  <div class="text-sm text-gray-brand-600" v-else>Paid {{ amount }}</div>
                </label>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="flex items-center">
          <button
            class="px-4 py-2 text-gray-brand-600 border border-transparent font-semibold text-sm rounded-full hover:border-gray-brand-600"
          >
            View
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottleRow',
  components: {},
  props: ['bottle'],
  data: () => ({}),
  computed: {
    cancelled() {
      return this.bottle?.attributes?.cancellation_status === 'cancelled'
    },
    checkout() {
      return this.$store.getters.checkoutFromRelationship(this.bottle?.relationships?.checkout?.data)
    },
    bottlePayment() {
      return this.$store.getters.bottlePaymentFromRelationship(this.checkout?.relationships?.bottle_payment?.data)
    },
    stripePaymentIntent() {
      return this.$store.getters.stripePaymentIntentFromRelationship(
        this.checkout?.relationships?.stripe_payment_intent?.data
      )
    },
    displayPaymentTimestamp() {
      if (!this.bottlePayment?.attributes.created_at) return ''
      let time = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hourCycle: 'h12'
      }).format(new Date(this.bottlePayment?.attributes.created_at))
      let day = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).format(new Date(this.bottlePayment?.attributes.created_at))
      return day + ' at ' + time
    },
    amount() {
      return this.stripePaymentIntent?.attributes.amount_received?.format
    }
  },
  methods: {
    view() {
      this.$router.push({ name: 'Receipt', params: { bottleid: this.bottle.id } })
    }
  }
}
</script>
