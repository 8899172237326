<template>
  <transition class="fixed" name="">
    <nav class="flex items-center h-14 absolute top-0 left-14 right-14 z-250">
      <ul class="flex w-full items-center mx-auto px-5 max-w-lg md:max-w-xl">
        <li
          v-for="(pill, index) in pills"
          :key="index"
          class="flex flex-1 grow mt-1"
          :class="{ 'pr-2 sm:pr-5': index + 1 < pills.length }"
        >
          <div class="w-full">
            <!-- Completed Step -->
            <router-link
              :to="pill.to"
              class="w-full h-1.5 flex items-center justify-center rounded-full focus:outline-none"
              :class="{
                'bg-brand-600  hover:bg-brand-700 focus:bg-brand-700': pill.status == 'completed',
                'bg-brand-100 border border-brand-600': pill.status == 'current',
                'bg-gray-brand-200 border border-gray-brand-300': pill.status == 'upcoming'
              }"
            ></router-link>
            <router-link
              :to="pill.to"
              class="w-full text-xs sm:text-xs tracking-tighter font-semibold -mt-1"
              :class="{
                'text-brand-600 ': pill.status == 'completed',
                'text-brand-500': pill.status == 'current',
                'text-gray-brand-500': pill.status == 'upcoming'
              }"
            >
              {{ pill.label }}
            </router-link>
          </div>
        </li>

        <li class="hidden grow">
          <!-- Current Step -->
          <router-link
            to=""
            class="w-full h-1.5 flex items-center justify-center bg-brand-100 border border-brand-600 rounded-full"
          ></router-link>
        </li>

        <li class="hidden relative">
          <!-- Upcoming Step -->
          <span
            class="group relative w-10 sm:w-24 h-1.5 flex items-center justify-center bg-gray-brand-200 border border-gray-brand-300 rounded-full hover:border-gray-brand-400 focus:border-gray-brand-400 focus:outline-none transition ease-in-out duration-150"
          ></span>
        </li>
      </ul>
    </nav>
  </transition>
</template>

<script>
export default {
  name: 'ProgressPills',
  props: ['pills']
}
</script>
