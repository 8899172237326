<template>
  <button
    class="relative rounded-lg flex items-center justify-center border border-brand-800 bg-white hover:bg-brand-100 outline-none focus:outline-none focus:ring-1 focus:ring-brand-800 overflow-hidden text-gray-brand-900"
    :class="{
      'opacity-70 pointer-events-none': isLoading,
      'px-2.5 py-1.5': sm,
      'px-3 py-2': md,
      'px-5 py-2.5': lg,
      'px-6 py-3.5': xl
    }"
  >
    <div v-show="!isLoading" class="flex items-center" :class="{ 'space-x-1': sm || md, 'space-x-2': lg || xl }">
      <slot name="left-icon"></slot>
      <div
        class="inline-block font-semibold items-center tracking-tight whitespace-nowrap"
        :class="{ 'text-xs': sm || md, 'text-sm': lg || xl }"
      >
        <slot>Secondary Button</slot>
      </div>
      <slot name="right-icon"></slot>
    </div>
    <BasicSpinner v-show="isLoading" />
  </button>
</template>

<script>
import BasicSpinner from '@/components/Molecules/BasicSpinner'

export default {
  name: 'SecondaryButton',
  components: { BasicSpinner },
  props: {
    size: {
      type: String,
      required: false,
      default: 'md'
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {},
  computed: {
    sm() {
      return this.size === 'sm'
    },
    md() {
      return this.size === 'md'
    },
    lg() {
      return this.size === 'lg'
    },
    xl() {
      return this.size === 'xl'
    }
  }
}
</script>

<style scoped></style>
