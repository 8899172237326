<template>
  <div class="w-full block">
    <div v-if="showButton && possiblePackages?.length > 0" class="mt-4">
      <!-- relative rounded-lg flex items-center justify-center border border-brand-800 bg-white hover:bg-brand-100 outline-none focus:outline-none focus:ring-1 focus:ring-brand-800 overflow-hidden text-gray-brand-900 -->
      <span class="group inline-flex rounded-md shadow-sm text-gray-brand-900">
        <button
          @click="showOptions = !showOptions"
          class="inline-flex items-center rounded-l-md border border-brand-800 bg-white group-hover:bg-brand-100 px-2 py-2"
        >
          <label for="select-all" class="sr-only">Select all</label>
          <svg
            v-if="showOptions"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
          </svg>
          <svg
            v-if="!showOptions"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            data-slot="icon"
            class="w-4 h-4 group-hover:hidden"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
            />
          </svg>
          <svg
            v-if="!showOptions"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            data-slot="icon"
            class="w-4 h-4 hidden group-hover:block"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
          </svg>
        </button>
        <button
          @click="showOptions = !showOptions"
          class="-ml-px block w-full rounded-l-none rounded-r-md border-0 bg-white group-hover:bg-brand-100 py-1.5 pl-3 pr-3 ring-1 ring-inset ring-brand-800 focus:ring-2 focus:ring-inset focus:ring-brand-800 text-sm font-semibold items-center tracking-tight whitespace-nowrap"
        >
          {{ loading ? 'Updating package...' : selectedPackage?.attributes?.package_name || 'Select a package' }}
        </button>
      </span>
    </div>

    <div v-show="showOptions || alwaysShow" class="mt-4 grid grid-cols-1 gap-y-2" :class="optionsCss">
      <!-- Active: "border-brand-600 ring-2 ring-brand-600", Not Active: "border-gray-300" -->
      <label
        v-for="pack in possiblePackages"
        :key="pack?.id"
        class="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
        :class="{
          'border-brand-600 ring-2 ring-brand-600': selected_package === pack.id,
          'border-gray-300': selected_package !== pack.id
        }"
        @click="setPackageID(pack.id)"
      >
        <input
          type="radio"
          v-model="selected_package"
          :value="pack.id"
          class="sr-only"
          aria-labelledby="project-type-0-label"
          aria-describedby="project-type-0-description-0 project-type-0-description-1"
        />
        <span class="flex flex-1">
          <span class="flex flex-col">
            <span id="project-type-0-label" class="block text-sm font-medium text-gray-900">
              {{ pack.attributes.package_name }}
            </span>
            <span id="project-type-0-description-0" class="mt-1 flex items-center text-sm text-gray-500">
              {{ pack.attributes.package_description }}
            </span>
            <span
              id="project-type-0-description-1"
              class="hidden mt-6 text-sm font-medium text-gray-900 future-price"
            ></span>
          </span>
        </span>
        <!-- Not Checked: "invisible" -->
        <svg
          class="h-5 w-5 text-brand-600"
          :class="{ invisible: selected_package !== pack.id }"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
            clip-rule="evenodd"
          />
        </svg>
        <span
          class="pointer-events-none absolute -inset-px rounded-lg border-2"
          :class="{
            'border border-brand-600': selected_package === pack.id,
            'border-2 border-transparent': selected_package !== pack.id
          }"
          aria-hidden="true"
        ></span>
      </label>
    </div>
  </div>
  <!-- /Drop info card -->
</template>

<script>
import mapRelationship from '../../utils/mapRelationship'
import mapRelationships from '../../utils/mapRelationships'

export default {
  components: {},
  props: {
    bottle: {
      type: Object
    },
    store: {
      type: Object
    },
    alwaysShow: {
      type: Boolean,
      default: false
    },
    showButton: {
      type: Boolean,
      default: true
    },
    optionsCss: {
      type: String,
      default: ''
    }
  },
  computed: {
    bottle() {
      return this.$store.getters.currentBottle
    },
    status() {
      return this.bottle?.attributes?.status
    },
    selectedPackage() {
      return mapRelationship(this.$store.state, this.bottle?.relationships?.package?.data)
    },
    possiblePackages() {
      return mapRelationships(this.$store.state, this.store?.relationships?.possible_packages?.data)
    }
  },

  mounted() {},

  methods: {
    setPackageID(packageID) {
      // this.showLoading(true)
      this.showOptions = false
      this.loading = true
      // emit this.loading to parent setIsLoadingButtons
      this.$emit('pendingSelection', this.loading)
      this.$store.dispatch('setPackageID', packageID).then(
        (response) => {
          // properly updated
          this.loading = false
          this.$emit('pendingSelection', this.loading)
        },
        (error) => {
          // failed to update
          this.loading = false
          this.$emit('pendingSelection', this.loading)
        }
      )
    }
  },

  data() {
    return {
      selected_package: this.bottle?.relationships?.package?.data?.id,
      showOptions: false,
      loading: false
    }
  }
}
</script>
