<template>
  <!-- Drop info card -->
  <div
    class="mt-4 md:mt-6 bg-white rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600 hover:bg-gray-brand-100 justify-center overflow-hidden"
  >
    <div class="mx-auto">
      <div class="mx-auto space-y-8">
        <div class="bg-white">
          <div class="flex items-center border-b border-gray-200 p-4">
            <dl class="grid flex-1 gap-x-6 text-sm grid-cols-3 col-span-2">
              <div>
                <dt class="font-semibold text-gray-brand-900 tracking-tight">Order number</dt>
                <dd class="mt-1 text-gray-500">{{ bottle?.attributes?.display_id }}</dd>
              </div>
              <div v-if="orderForDateDisplay" class="">
                <dt class="font-semibold text-gray-brand-900 tracking-tight">Fulfillment</dt>
                <dd class="mt-1 text-gray-500">
                  <time>{{ orderForDateDisplay }}</time>
                </dd>
              </div>
              <div v-if="skipped" class="">
                <dt class="font-semibold text-gray-brand-900 tracking-tight">Status</dt>
                <dd class="mt-1 text-gray-500">
                  <time>Skipped</time>
                </dd>
              </div>
              <div v-else>
                <dt class="font-semibold text-gray-brand-900 tracking-tight">Cart subtotal</dt>
                <dd class="mt-1 font-medium text-gray-900">{{ cart.attributes?.total_price?.format }}</dd>
              </div>
            </dl>
          </div>

          <!-- Products -->
          <h4 class="sr-only">Items</h4>
          <ul role="list" class="divide-y divide-gray-200">
            <CartItems v-if="!skipped" :cart-items="cartItems" :bottle="bottle" />
            <li class="p-4">
              <div v-if="isPaid" class="text-sm font-medium sm:mt-0 sm:border-none sm:pt-0 space-y-3">
                <SecondaryButton @click="goToBottle()" class="w-full" :size="'xl'">
                  Paid {{ bottlePaymentTimestamp }}
                  <template #left-icon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                      />
                    </svg>
                  </template>
                </SecondaryButton>
              </div>
              <div v-else class="text-sm font-medium sm:mt-0 sm:border-none sm:pt-0 space-y-3">
                <PrimaryButton
                  v-if="offerEditOrderButton"
                  @click="goToBottle()"
                  class="w-full"
                  :size="'xl'"
                  :streaking="true"
                  :justify="'justify-center'"
                >
                  Edit cart
                </PrimaryButton>
                <SecondaryButton
                  v-if="offerSkipButton"
                  @click="handleSkip"
                  class="w-full"
                  :size="'xl'"
                  :isLoading="submittingSkip"
                  :active="!submittingSkip"
                >
                  Skip order
                  <template #right-icon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3"
                      />
                    </svg>
                  </template>
                </SecondaryButton>
                <SecondaryButton
                  v-else-if="offerUnskipButton"
                  @click="handleUnskip"
                  class="w-full"
                  :size="'xl'"
                  :isLoading="submittingSkip"
                  :active="!submittingSkip"
                >
                  Unskip order
                  <template #left-icon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3"
                      />
                    </svg>
                  </template>
                </SecondaryButton>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- /Drop info card -->
</template>

<script>
let strftime = require('strftime')
import TheBanner from '@/components/Molecules/TheBanner'
import mapRelationships from '../../utils/mapRelationships'
import mapRelationship from '../../utils/mapRelationship'
import PrimaryButton from '@/components/Molecules/PrimaryButton'
import SecondaryButton from '@/components/Molecules/SecondaryButton'
import CartItems from '@/components/Checkout/CartItems'

export default {
  components: {
    TheBanner,
    PrimaryButton,
    SecondaryButton,
    CartItems
  },

  props: {
    bottle: {
      type: Object
    },
    alwaysShow: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    submittingSkip: false
  }),

  computed: {
    cart() {
      return this.$store.getters.cart(this.bottle.relationships?.cart.data)
    },
    cartItems() {
      return mapRelationships(this.$store.state, this.cart?.relationships?.cart_items?.data)
    },
    isPaid() {
      return this.bottle?.attributes?.payment_status == 'paid'
    },
    bottlePayment() {
      return mapRelationship(this.$store.state, this.bottle?.relationships?.bottle_payment?.data)
    },
    bottlePaymentDatetime() {
      return this.bottlePayment?.attributes?.created_at
    },
    bottlePaymentTimestamp() {
      if (!this.bottlePaymentDatetime) return ''
      let time = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hourCycle: 'h12'
      }).format(new Date(this.bottlePaymentDatetime))
      let day = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).format(new Date(this.bottlePaymentDatetime))
      return day + ' at ' + time
    },
    lastEditedTimestamp() {
      if (!this.bottle.attributes.updated_at) return ''
      let time = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hourCycle: 'h12'
      }).format(new Date(this.bottle.attributes.updated_at))
      let day = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).format(new Date(this.bottle.attributes.updated_at))
      return day + ' at ' + time
    },
    bottleCycleDetails() {
      return this.bottle?.attributes?.bottle_cycle_details
    },
    earliestOrderByDate() {
      let dates = []
      if (this.bottleCycleDetails?.autocharge_datetime) {
        dates.push(new Date(this.bottleCycleDetails?.autocharge_datetime))
      }
      if (this.bottleCycleDetails?.fulfillment_slot_cutoff) {
        dates.push(new Date(this.bottleCycleDetails?.fulfillment_slot_cutoff))
      }
      dates.sort() // NOTE: I don't think this is actually sorting right now
      if (dates.length > 0) return dates[0]
      return null
    },
    earliestOrderByDateDisplay() {
      if (!this.earliestOrderByDate) return ''
      let time = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hourCycle: 'h12'
      }).format(this.earliestOrderByDate)
      let day = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).format(this.earliestOrderByDate)
      return day + ' at ' + time
    },
    actionText() {
      if (this.skipped) return 'Skipped'
      else if (this.willAutocharge) return 'Skip or Edit'
      return 'Edit'
    },
    offerEditOrderButton() {
      return !this.bottleCycleDetails?.autocharge || !this.skipped
    },
    offerSkipButton() {
      return this.bottleCycleDetails?.autocharge && !this.skipped && !this.bottleCycleDetails?.block_skip
    },
    offerUnskipButton() {
      return this.skipped
    },
    skipped() {
      return this.bottleCycleDetails?.skipped
    },
    bottleCycleActions() {
      if (this.bottleCycleDetails?.autocharge) return [{ type: 'button', emitValue: 'skipButtonClicked', text: 'Skip' }]
      else return []
    },
    bottleCycleMessage() {
      if (this.bottleCycleDetails?.autocharge && this.bottleCycleDetails.autocharge_datetime)
        return 'Edit or skip your order by ' + this.autochargeDateDisplay
      else if (this.fulfillmentSlotCutoffDisplay) return 'Order by ' + this.fulfillmentSlotCutoffDisplay
    },
    bottleCycleMessageConfirmed() {
      if (this.hasCheckoutErrors && this.autochargeDateDisplay)
        return 'You will be charged ' + this.autochargeDateDisplay + '.'
      else if (this.autochargeDateDisplay) return 'You will be charged ' + this.autochargeDateDisplay + '.'
      else return ''
    },
    bottleCycleTitle() {
      const day = this.orderForDate
      if (day) return strftime('Cart for %A, %B %-d', day)
      return null
    },
    orderForDateDisplay() {
      const day = this.orderForDate
      if (day) return strftime('%B %-d', day)
      return null
    },
    bottleCycleTitleConfirmed() {
      if (this.possibleCutoffTimeToDisplay && !this.fulfillmentSlotCutoffDisplay) {
        return this.bottleCycleTitle ? this.bottleCycleTitle : null
      } else {
        return this.bottleCycleTitle
          ? this.bottleCycleTitle + ' is confirmed. Pay for your order to lock in your cart.'
          : null
      }
    },
    autochargeDateDisplay() {
      const dateTime = this.bottleCycleDetails?.autocharge_datetime
        ? new Date(this.bottleCycleDetails?.autocharge_datetime)
        : null
      if (dateTime)
        return (
          strftime('%A, %B %-d at ', dateTime) + strftime('%-I:%M', dateTime) + strftime('%p', dateTime).toLowerCase()
        )
      return null
    },
    fulfillmentSlotCutoffDisplay() {
      const dateTime = this.bottleCycleDetails?.fulfillment_slot_cutoff
        ? new Date(this.bottleCycleDetails?.fulfillment_slot_cutoff)
        : null
      if (dateTime)
        return (
          strftime('%A, %B %-d at ', dateTime) + strftime('%-I:%M', dateTime) + strftime('%p', dateTime).toLowerCase()
        )
      return null
    },
    possibleCutoffTimeToDisplay() {
      const dateTime =
        this.bottleCycleDetails?.possible_cutoff_times?.length > 0
          ? new Date(this.bottleCycleDetails?.possible_cutoff_times?.[0])
          : null
      if (dateTime) {
        return (
          strftime('%A, %B %-d at ', dateTime) + strftime('%-I:%M', dateTime) + strftime('%p', dateTime).toLowerCase()
        )
      }
      return null
    },
    orderForDate() {
      if (this.fulfillmentSlot) {
        return new Date(this.fulfillmentSlot?.attributes?.arrives_beginning_estimate)
      } else if (this.bottleCycleDetails?.fulfillment_slot_day) {
        return new Date(this.bottleCycleDetails?.fulfillment_slot_day + 'T12:00:00.000Z')
      } else if (this.bottleCycleDetails?.possible_fulfillment_days.length > 0) {
        return new Date(this.bottleCycleDetails?.possible_fulfillment_days[0] + 'T12:00:00.000Z')
      }
      return null
    },
    fulfillmentSlot() {
      return this.$store.getters.fulfillmentSlotForBottle(this.bottle)
    },
    fulfillmentMethod() {
      return this.$store.getters.fulfillmentMethodForBottle(this.bottle)
    },
    isConfirmed() {
      return this.bottleCycleDetails?.confirmed
    },
    willAutocharge() {
      return this.bottleCycleDetails?.autocharge
    },
    hasCheckoutErrors() {
      return this.bottleCycleDetails?.pending_errors?.length > 0
    },
    useEmoji() {
      if (this.isConfirmed && this.willAutocharge && this.hasCheckoutErrors) {
        return '⚠️'
      } else if (this.isConfirmed && this.willAutocharge) {
        return '✅'
      } else {
        return '🗓'
      }
    }
  },

  mounted() {},

  methods: {
    async bannerActionClicked(emitValue) {
      if (emitValue == 'skipButtonClicked') {
        await this.$store.dispatch('skipBottle')
        this.handleStatus()
      }
    },
    handleStatus() {
      const status = this.$store.getters.currentBottle?.attributes?.status
      if (status == 'skipped') {
        this.$router.replace({ name: 'Notice' })
      }
    },
    goToBottle() {
      this.$router.push({ name: 'Selection', params: { bottleid: this.bottle?.id } })
    },
    async handleSkip() {
      this.submittingSkip = true
      await this.$store.dispatch('skipBottle', this.bottle?.id)
      this.submittingSkip = false
    },
    async handleUnskip() {
      this.submittingSkip = true
      await this.$store.dispatch('unskipBottle', this.bottle?.id)
      this.submittingSkip = false
    }
  }
}
</script>
