<template>
  <!-- Drop info card -->
  <div class="w-full block">
    <div class="max-w-md mx-auto">
      <TheBanner
        v-if="showExpiredBanner"
        position="inline"
        :has-dismiss-btn="false"
        class="border shadow-lg md:shadow-none"
        :use-emoji="useEmoji"
        title="You are past the cutoff to place an order."
        :actions="bottleCycleActions"
        @on-action="bannerActionClicked"
      >
        <template #message></template>
      </TheBanner>
      <TheBanner
        v-else-if="showConfirmedBanner"
        position="inline"
        :has-dismiss-btn="false"
        class="border shadow-lg md:shadow-none"
        :use-emoji="useEmoji"
        :title="bottleCycleTitleConfirmed(bottle)"
        :actions="bottleCycleActions"
        @on-action="bannerActionClicked"
      >
        <template #message>{{ bottleCycleMessageConfirmed }}</template>
      </TheBanner>
      <TheBanner
        v-else-if="hasMultiBottleMessages(bottles) || isBottleCycleStoreBottle"
        position="inline"
        :has-dismiss-btn="false"
        class="border shadow-lg md:shadow-none"
        :use-emoji="useEmoji"
        :title="bottleCycleTitle(bottle)"
        :actions="bottleCycleActions"
        @on-action="bannerActionClicked"
      >
        <template v-if="isMultibottleFunctionality()" #message>
          <ul class="list-disc">
            <li v-for="(message, index) in multiBottleMessages(bottles)" :key="index">
              {{ message }}
            </li>
          </ul>
        </template>
        <template v-else #message>{{ bottleCycleMessageForBottle(bottle) }}</template>
      </TheBanner>
    </div>
  </div>
  <!-- /Drop info card -->
</template>

<script>
let strftime = require('strftime')
import TheBanner from '@/components/Molecules/TheBanner'

export default {
  components: {
    TheBanner
  },

  components: {
    TheBanner
  },

  props: {
    bottle: {
      type: Object
    },
    alwaysShow: {
      type: Boolean,
      default: false
    }
  },

  props: {
    alwaysShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bottles() {
      if (!this.$store.getters.currentBottles) return undefined
      return this.$store.getters.currentBottles.sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1))
    },
    onlyOneMultibottle() {
      return this.bottles.length < 2
    },
    showConfirmedBanner() {
      return this.willAutocharge && this.isConfirmed
    },
    showExpiredBanner() {
      return this.isExpired
    },
    bottle() {
      return this.$store.getters.currentBottle
    },
    storeID() {
      return this.bottle?.attributes?.store_id
    },
    isBottleCycleStoreBottle() {
      if (this.$store.getters.currentBottle) {
        return this.$store.getters.currentBottle.attributes.type === 'BottleCycleStoreBottle'
      }
    },
    // bottle cycle stuff
    bottleCycleActions() {
      if (this.bottleCycleDetailsForBottle(this.bottle)?.expired)
        return [{ type: 'button', emitValue: 'newOrderButtonClicked', text: 'Order for another day' }]
      else if (this.bottleCycleDetailsForBottle(this.bottle)?.autocharge)
        return [{ type: 'button', emitValue: 'skipButtonClicked', text: 'Skip' }]
      else return []
    },
    bottleCycleMessageConfirmed() {
      if (this.autochargeDateDisplayForBottle(this.bottle))
        return 'You will be charged ' + this.autochargeDateDisplayForBottle(this.bottle) + '.'
      else return ''
    },
    isConfirmed() {
      return this.bottleCycleDetailsForBottle(this.bottle)?.confirmed
    },
    willAutocharge() {
      return this.bottleCycleDetailsForBottle(this.bottle)?.autocharge
    },
    isExpired() {
      return this.bottleCycleDetailsForBottle(this.bottle)?.expired
    },
    hasCheckoutErrors() {
      return this.bottleCycleDetailsForBottle(this.bottle)?.pending_errors?.length > 0
    },
    useEmoji() {
      if (this.isConfirmed && this.willAutocharge && this.hasCheckoutErrors) {
        return '⚠️'
      } else if (this.isConfirmed && this.willAutocharge) {
        return '✅'
      } else {
        return '🗓'
      }
    }
  },

  mounted() {},

  mounted() {},

  methods: {
    async bannerActionClicked(emitValue) {
      if (emitValue == 'skipButtonClicked') {
        await this.$store.dispatch('skipBottle')
        this.handleStatus()
      } else if (emitValue == 'newOrderButtonClicked') {
        // start a new order and submit the cart in the process
        this.handleNewBottleCreation()
      }
    },
    async handleNewBottleCreation() {
      const data = await this.$store.dispatch('getNewBottleId', this.storeID)
      await this.$store.dispatch('submitOldCartToNewBottle', data?.id)
      this.$router.replace({ name: 'Selection', params: { bottleid: data?.id } })
    },
    autochargeDateDisplayForBottle(forBottle) {
      const dateTime = this.bottleCycleDetailsForBottle(forBottle)?.autocharge_datetime
        ? new Date(this.bottleCycleDetailsForBottle(forBottle)?.autocharge_datetime)
        : null
      if (dateTime)
        return (
          strftime('%A, %B %-d at ', dateTime) + strftime('%-I:%M', dateTime) + strftime('%p', dateTime).toLowerCase()
        )
      return null
    },
    fulfillmentSlotForBottle(bottle) {
      return this.$store.getters.fulfillmentSlotForBottle(bottle)
    },
    handleStatus() {
      const status = this.$store.getters.currentBottle?.attributes?.status
      if (status == 'skipped') {
        this.$router.replace({ name: 'Notice' })
      }
    },
    isMultibottleFunctionality() {
      const currentBottle = this.$store.getters.currentBottle
      return currentBottle && currentBottle.attributes.allow_multiple_days && !this.onlyOneMultibottle
    },
    bottleCycleTitle(forBottle) {
      if (this.isMultibottleFunctionality()) {
        return 'Your order(s) for'
      } else {
        const day = this.orderForDate(forBottle)

        // if (day) return strftime('%A, %B %-d at ', day) + strftime('%-I:%M', day) + strftime('%p', day).toLowerCase()
        if (day && this.possibleCutoffTimeToDisplay(forBottle))
          return (
            'Order by ' +
            this.possibleCutoffTimeToDisplay(forBottle) +
            ' to receive your order ' +
            strftime('on %A, %B %-d', day)
          )
        else if (day) return strftime('Your order for %A, %B %-d ', day)
        return null
      }
    },
    multibottleDescription(forBottle) {
      const day = this.orderForDate(forBottle)
      if (day && this.possibleCutoffTimeToDisplay(forBottle)) return strftime('%A, %B %-d', day)
      else if (day) return strftime('Your order for %A, %B %-d ', day)
    },
    bottleCycleTitleConfirmed(forBottle) {
      if (this.possibleCutoffTimeToDisplay(forBottle)) {
        return this.bottleCycleTitle(forBottle) ? this.bottleCycleTitle(forBottle) : null
      } else {
        return this.bottleCycleTitle(forBottle)
          ? this.bottleCycleTitle(forBottle) + ' is confirmed. Pay for your order to lock in your cart.'
          : null
      }
    },
    possibleCutoffTimeToDisplay(forBottle) {
      const dateTime =
        this.bottleCycleDetailsForBottle(forBottle)?.possible_cutoff_times?.length > 0
          ? new Date(this.bottleCycleDetailsForBottle(forBottle)?.possible_cutoff_times?.[0])
          : null
      if (dateTime) {
        return (
          strftime('%A, %B %-d at ', dateTime) + strftime('%-I:%M', dateTime) + strftime('%p', dateTime).toLowerCase()
        )
      }
      return null
    },
    orderForDate(forBottle) {
      if (!forBottle) return null
      if (forBottle.attributes.is_bottle_cycle) {
        if (this.bottleCycleDetailsForBottle(forBottle)?.fulfillment_slot_day) {
          return new Date(this.bottleCycleDetailsForBottle(forBottle)?.fulfillment_slot_day + 'T12:00:00.000Z')
        } else if (this.bottleCycleDetailsForBottle(forBottle)?.possible_fulfillment_days.length > 0) {
          return new Date(this.bottleCycleDetailsForBottle(forBottle)?.possible_fulfillment_days[0] + 'T12:00:00.000Z')
        }
      } else {
        if (this.fulfillmentSlotForBottle(forBottle)) {
          return new Date(this.fulfillmentSlotForBottle(forBottle).attributes.fulfillment_date + 'T12:00:00.000Z')
        }
      }

      return null
    },
    bottleCycleDetailsForBottle(forBottle) {
      return forBottle?.attributes?.bottle_cycle_details
    },
    multiBottleMessages(bottles) {
      if (!bottles) return undefined
      return [
        ...new Set(
          bottles.map((bottle) => (this.multibottleDescription(bottle) ? this.multibottleDescription(bottle) : null))
        )
      ].filter((i) => i)
    },
    hasMultiBottleMessages(bottles) {
      return this.multiBottleMessages(bottles) != undefined && this.multiBottleMessages(bottles).length
    },
    bottleCycleMessageForBottle(forBottle) {
      if (
        this.bottleCycleDetailsForBottle(forBottle)?.autocharge &&
        this.bottleCycleDetailsForBottle(forBottle).autocharge_datetime
      ) {
        return 'Edit or skip your order by ' + this.autochargeDateDisplayForBottle(forBottle)
      }
    },
    showBottleCycleBanner() {
      return this.bottleCycleDetailsForBottle(bottle) && (!this.isConfirmed || this.willAutocharge || this.alwaysShow)
    }
  }
}
</script>
