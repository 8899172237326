<template>
  <div>
    <div class="w-full border-b">
      <div class="leading-5 pt-4 pb-4 pr-6 -ml-1">
        <fieldset>
          <div v-for="option in options" :key="option.id">
            <InfoRowSlotTime
              v-if="showTimes"
              :option="option"
              :selected-option="selectedOption"
              :merchant-time-zone="merchantTimeZone"
              class="mb-3 flex items-center"
              @clicked-option="clickedOption"
            />
            <InfoRowSlotDay
              v-if="showDays"
              :option="option"
              :selected-option="selectedOption"
              class="mb-3 flex items-center"
              @clicked-option="clickedOption"
            />
            <InfoRowMethod
              v-if="showMethods"
              :option="option"
              :selected-option="selectedOption"
              class="mb-3 flex items-center"
              @clicked-option="clickedOption"
            />
            <InfoRowPaymentMethod
              v-if="showPaymentMethods"
              :option="option"
              :selected-option="selectedOption"
              class="mb-3 flex items-center"
              @clicked-option="clickedOption"
            />
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</template>

<script>
import InfoRowSlotTime from '@/components/Checkout/InfoRowSlotTime'
import InfoRowSlotDay from '@/components/Checkout/InfoRowSlotDay'
import InfoRowMethod from '@/components/Checkout/InfoRowMethod'
import InfoRowPaymentMethod from '@/components/Checkout/InfoRowPaymentMethod'

export default {
  name: 'InfoRowOptions',
  components: {
    InfoRowSlotTime,
    InfoRowSlotDay,
    InfoRowMethod,
    InfoRowPaymentMethod
  },
  props: ['options', 'selectedOption', 'optionsType', 'merchantTimeZone'],
  data: () => ({}),
  computed: {
    isFulfillmentMethod() {
      return this.options[0]?.type == 'fulfillment_method'
    },
    isFulfillmentSlot() {
      return this.options[0]?.type == 'fulfillment_slot'
    },
    showPaymentMethods() {
      return this.optionsType == 'stripe_payment_methods'
    },
    showTimes() {
      return this.optionsType == 'fulfillment_slot_times'
    },
    showDays() {
      return this.optionsType == 'fulfillment_slot_days'
    },
    showMethods() {
      return this.optionsType == 'fulfillment_methods'
    }
  },
  mounted() {},
  methods: {
    clickedOption(value) {
      this.$emit('clickedOption', value)
    }
  }
}
</script>
