<template>
  <Box>
    <div
      v-if="hasMembershipSavings || hasPricingTierSavings"
      class="p-4 border-b border-b-gray-100 bg-gray-50 space-y-3"
    >
      <div v-if="hasMembershipSavings" class="flex space-x-2">
        <div class="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5 text-green-600"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
        </div>
        <div class="text-sm">
          Saving
          <strong>{{ cart.attributes.membership_savings.format }}</strong>
          with
          <strong>{{ membershipTier?.attributes?.tier_name }}</strong>
          discounts
        </div>
      </div>
      <div v-if="hasPricingTierSavings" class="flex space-x-2">
        <div class="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5 text-green-600"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
        </div>
        <div class="text-sm">
          Saving
          <strong>{{ cart.attributes.pricing_tier_savings.format }}</strong>
          with
          <i>{{ pricingTier?.attributes?.title }}</i>
        </div>
      </div>
    </div>
    <div v-if="supportsSelectionScreen && !hasBeenPaid">
      <SecondaryButton @click="goToCart()" class="ml-3 mt-3 rounded-full" :size="'md'">
        <template #left-icon>
          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
          </svg>
        </template>
        Edit Cart
      </SecondaryButton>
    </div>
    <CartItems :cart-items="list" :bottle="bottle" />
    <DiscountCodeModule
      v-if="hasDiscountCodeBox"
      @discount-updated="updateDiscount"
      @discount-removed="removeDiscount"
    />
    <div v-if="checkoutAttributes" class="border-t border-gray-200">
      <div class="py-4 px-4">
        <div class="flex justify-between items-center pb-2">
          <span class="text-xs font-bold">Cart Total</span>
          <span class="text-xs font-medium">
            {{ checkoutAttributes.cart_total_amount.format }}
          </span>
        </div>
        <div v-if="hasFulfillmentAmount" class="flex justify-between items-center pb-2">
          <span class="text-xs font-bold">Fulfillment</span>
          <span v-if="hasFufillmentAmountOff" class="text-xs font-medium">
            <s>{{ checkoutAttributes.fulfillment_amount.format }}</s>
            {{ checkoutAttributes.net_fulfillment_amount.format }}
          </span>
          <span v-else class="text-xs font-medium">
            {{ checkoutAttributes.fulfillment_amount.format }}
          </span>
        </div>
        <div v-if="hasDiscountCodeAmount" class="flex justify-between items-center pb-2">
          <span class="text-xs font-bold">Discount Codes</span>
          <span class="text-xs font-medium">({{ checkoutAttributes.discount_code_amount.format }})</span>
        </div>
        <div v-if="hasDiscountCodeAmount" class="flex justify-between items-center pb-2">
          <span class="text-xs font-medium">{{ checkoutAttributes.discount_codes }}</span>
        </div>
        <div class="flex justify-between items-center pb-2">
          <span class="text-xs font-bold">Subtotal</span>
          <span class="text-xs font-medium">
            {{ checkoutAttributes.bottle_subtotal.format }}
          </span>
        </div>
        <div v-if="hasFeeToChargeCustomer" class="pb-2">
          <div class="flex justify-between items-center">
            <span class="text-xs font-bold">Taxes &amp; Fees</span>
            <span class="text-xs font-medium">
              {{ checkoutAttributes.taxes_and_fees?.format }}
            </span>
          </div>
          <div v-if="showTaxesAndFeesBreakdown">
            <div
              v-for="fee in checkoutAttributes.taxes_and_fees_breakdown"
              :key="fee.description"
              class="flex justify-between items-center"
            >
              <span class="text-xs text-gray-500">{{ fee.description }}</span>
              <span class="text-xs text-gray-500">
                {{ fee.amount?.format }}
              </span>
            </div>
          </div>
          <div v-else class="-mt-1">
            <button class="text-xs text-gray-500" @click="showTaxesAndFeesBreakdown = true">View breakdown</button>
          </div>
        </div>
        <div v-else-if="hasTaxAmount" class="pb-2">
          <div class="flex justify-between items-center">
            <span class="text-xs font-bold">Taxes</span>
            <span class="text-xs font-medium">
              {{ checkoutAttributes.tax_amount?.format }}
            </span>
          </div>
          <div v-if="showTaxesAndFeesBreakdown">
            <div
              v-for="fee in checkoutAttributes.taxes_and_fees_breakdown"
              :key="fee.description"
              class="flex justify-between items-center"
            >
              <span class="text-xs text-gray-500">{{ fee.description }}</span>
              <span class="text-xs text-gray-500">
                {{ fee.amount?.format }}
              </span>
            </div>
          </div>
          <div v-else class="-mt-1">
            <button class="text-xs text-gray-500" @click="showTaxesAndFeesBreakdown = true">View breakdown</button>
          </div>
        </div>
        <div v-if="hasTipAmount" class="flex justify-between items-center pb-2">
          <span class="text-xs font-bold">Tip</span>
          <span class="text-xs font-medium">
            {{ checkoutAttributes.tip_amount.format }}
          </span>
        </div>
      </div>
      <div class="border-t border-gray-200 py-4 px-4">
        <div class="flex justify-between items-center">
          <span class="text-md font-bold">Total</span>

          <span class="text-xs font-medium">
            {{ checkoutAttributes.bottle_total.format }}
          </span>
        </div>
        <div v-if="hasAccountCreditAmount && !hasBeenPaid" class="flex justify-between items-center pb-2 pt-2">
          <span class="text-xs font-bold">Amount covered by account credit</span>
          <span class="text-xs font-medium">({{ checkoutAttributes.account_credit_amount.format }})</span>
        </div>
        <div v-if="hasAccountCreditAmount && !hasBeenPaid" class="flex justify-between items-center pb-2">
          <span class="text-xs font-bold">Amount remaining to charge</span>
          <span class="text-xs font-medium">{{ checkoutAttributes.chargeable_amount.format }}</span>
        </div>
      </div>
    </div>
  </Box>
</template>
<script>
import DiscountCodeModule from '@/components/Checkout/DiscountCodeModule'
import Box from '@/components/Molecules/Box'
import SecondaryButton from '../Molecules/SecondaryButton.vue'
import CartItems from '@/components/Checkout/CartItems'

export default {
  components: {
    Box,
    DiscountCodeModule,
    SecondaryButton,
    CartItems
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    checkoutAttributes: {
      type: Object,
      required: false,
      default: null
    },
    useNeutralTheme: {
      type: Boolean,
      required: false,
      default: false
    },
    hasDiscountCodeBox: {
      type: Boolean,
      required: false,
      default: false
    },
    hasDiscountCodeList: {
      type: Boolean,
      required: false,
      default: false
    },
    passedBottle: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({ showTaxesAndFeesBreakdown: false }),
  computed: {
    checkout() {
      return this.$store.getters.checkout
    },
    bottle() {
      return this.passedBottle || this.$store.getters.currentBottle
    },
    cart() {
      return this.$store.getters.currentCart
    },
    hasMembershipSavings() {
      return this.cart?.attributes?.membership_savings?.cents > 0
    },
    hasPricingTierSavings() {
      return this.cart?.attributes?.pricing_tier_savings?.cents > 0
    },
    membershipTier() {
      return this.$store.getters.membershipTier
    },
    pricingTier() {
      return this.$store.getters.pricingTierWithID(this.cart?.relationships?.pricing_tier?.data?.id)
    },
    unavailableItems() {
      return this.bottle?.relationships?.removed_category_product_store_pairs_still_in_cart?.data
    },
    hasFulfillmentAmount() {
      return this.checkout?.attributes.fulfillment_amount && this.checkout?.attributes.fulfillment_amount.cents > 0
    },
    hasFufillmentAmountOff() {
      return (
        this.checkout?.attributes.fulfillment_amount_off && this.checkout?.attributes.fulfillment_amount_off.cents > 0
      )
    },
    hasDiscountCodeAmount() {
      return this.checkout?.attributes.discount_code_amount && this.checkout?.attributes.discount_code_amount.cents > 0
    },
    hasAccountCreditAmount() {
      return (
        this.checkout?.attributes.account_credit_amount && this.checkout?.attributes.account_credit_amount.cents > 0
      )
    },
    hasTaxAmount() {
      return this.checkout?.attributes.tax_amount && this.checkout?.attributes.tax_amount.cents > 0
    },
    hasTipAmount() {
      return this.checkout?.attributes.tip_amount && this.checkout?.attributes.tip_amount.cents > 0
    },
    hasFeeToChargeCustomer() {
      return (
        this.checkout?.attributes.fee_to_charge_customer && this.checkout?.attributes.fee_to_charge_customer.cents > 0
      )
    },
    hasBeenPaid() {
      return this.checkout?.attributes.payment_status == 'paid'
    },
    supportsSelectionScreen() {
      return this.bottle?.attributes?.supports_selection_screen
    }
  },
  methods: {
    updateDiscount(data) {
      this.$store.dispatch('addDiscountCode', data)
    },
    removeDiscount(data) {
      this.$store.dispatch('removeAppliedDiscountCode', data)
    },
    goToCart() {
      this.$router.push({ name: 'Selection', params: { bottleid: this.bottle?.id }, query: { showCart: 'y' } })
    }
  }
}
</script>
