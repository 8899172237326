<template>
  <button
    class="transition-all px-4 py-2 border font-semibold text-sm hover:border-gray-brand-600 mr-2 mb-2 text-left"
    :class="{ 'text-gray-brand-100 bg-gray-brand-600': selected, 'text-gray-brand-600': !selected }"
    @click="clicked"
  >
    {{ variantOption.attributes.name }}
    <span v-if="hasPrice && showPrice && showPlusSign">(+{{ variantOption.attributes.price?.format }})</span>
    <span v-else-if="hasPrice && showPrice">({{ variantOption.attributes.price?.format }})</span>
    <div class="font-normal">{{ variantOption.attributes.description }}</div>
  </button>
</template>

<script>
import { ref, toRefs } from 'vue'

export default {
  name: 'ProductVariantOption',

  props: {
    key: {
      type: [String, Number]
    },
    submittableCartItem: {
      type: Object,
      required: false
    },
    variantOption: {
      type: Object,
      required: true
    },
    variant: {
      type: Object,
      required: true
    },
    showPrice: {
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    categoryProductStorePair: {
      type: Object,
      required: false
    }
  },

  computed: {
    selected() {
      if (!this.submittableCartItem || !this.submittableCartItem.cart_item_variant_options_attributes) return false
      return this.submittableCartItem.cart_item_variant_options_attributes
        .map((x) => x.variant_option_id)
        .includes(this.variantOption.id)
    },
    hasPrice() {
      return this.variantOption.attributes.price?.cents > 0
    },
    showPlusSign() {
      return this.priceToEvaluate?.cents > 0
    },
    priceToEvaluate() {
      if (this.submittableCartItem?.membership_price?.cents && this.submittableCartItem?.membership_price?.cents > 0)
        return this.submittableCartItem?.membership_price
      return this.categoryProductStorePair?.attributes?.product_price_tiers?.find(
        (item) => item.membership_tier_id == this.membershipTierID && item.pricing_tier_id == this.pricingTierID
      )
    },
    membershipTierID() {
      return this.$store.getters.currentCart?.relationships.membership_tier?.data?.id
    },
    pricingTierID() {
      return this.$store.getters.currentCart?.relationships.pricing_tier?.data?.id
    }
  },

  methods: {
    clicked() {
      if (this.selected) {
        this.$emit('unselected', this.variantOption)
      } else {
        this.$emit('selected', this.variantOption)
      }
    }
  }
}
</script>
