<template>
  <div class="bg-gray-brand-100 h-screen pb-20" :style="computedColors">
    <!-- navbar -->
    <div class="fixed z-40 w-full top-0">
      <MerchantNav :disable-hide-on-scroll="true" />
      <div class="w-full">
        <div v-if="false" class="mx-auto flex justify-center items-center px-4 py-4 sm:px-6 sm:py-2 lg:px-8">
          <div>
            <a href="#" class="flex">
              <img class="h-6 w-auto" src="/images/bottle.svg" alt="Bottle" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- main -->
    <div class="w-full max-w-5xl mx-auto pt-12 h-full">
      <!-- Gift card -->
      <div class="px-2 md:px-0 md:w-1/2 mx-auto mt-14 mb-8">
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter ml-3">Gift Card</div>
        </div>
        <!-- Read-only receipt layout for payment method: -->
        <!-- TODO: replace placeholder content -->
        <div
          class="mt-4 md:mt-6 bg-white rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600"
        >
          <div v-if="issuedGiftCard" class="leading-5 pt-4 pb-5 px-6">
            <span class="text-md font-semibold text-brand-gray-800 mb-1 block">{{ code }}</span>
            <!-- <div class="text-sm text-gray-brand-600"></div> -->
            <div class="flex justify-between mt-3 items-center">
              <div class="flex items-center flex-grow">
                <span class="w-8 mr-3 text-gray-500 block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6 mx-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
                    />
                  </svg>
                </span>
                <div class="text-sm font-mono text-gray-brand-900">{{ amount }}</div>
              </div>

              <div class="ml-3 text-sm font-mono text-gray-brand-900">{{ displayPaymentTimestamp }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Gift Card -->
    </div>
  </div>
</template>
<script>
let strftime = require('strftime')
import { ref } from 'vue'

import { TheBanner, MerchantNav } from '@/components'

export default {
  components: { TheBanner, MerchantNav },
  setup() {},
  data: () => ({
    issuedGiftCard: null
  }),
  computed: {
    computedColors() {
      const hsl = this.$store.getters.info?.attributes.color.hsl
      if (hsl)
        return {
          '--main-brand-hue': hsl[0],
          '--main-brand-saturation': hsl[1],
          '--main-brand-lightness': hsl[2]
        }
      else {
        return undefined
      }
    },
    code() {
      return this.$route.params.code
    },
    createdAt() {
      return this.issuedGiftCard?.attributes?.created_at
    },
    amount() {
      return this.issuedGiftCard?.attributes?.gift_card_value?.format
    },
    displayPaymentTimestamp() {
      if (!this.createdAt) return ''
      let day = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).format(new Date(this.createdAt))
      return day
    }
  },
  async mounted() {
    window.scrollTo(0, 0)
    this.issuedGiftCard = await this.$store.dispatch('getIssuedGiftCard', this.code)
    this.issuedGiftCard = this.issuedGiftCard?.data
  },
  methods: {}
}
</script>
