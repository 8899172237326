<template>
  <transition
    enter-active-class="transition-opacity ease-in duration-200"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition-opacity ease-out duration-200"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    appear
  >
    <div
      v-show="isVisible"
      :class="[
        position == 'sticky'
          ? 'sticky'
          : position == 'fixed'
            ? `fixed top-0 z-100 w-full border-b-2 border-b-gray-brand-700`
            : `relative border-b-2 md:border-t-2 md:border-r-2 md:border-l-2 md:rounded-md md:border-b-${statusColor}-400`
      ]"
    >
      <div
        :class="[
          statusColor != 'gray' ? `bg-${statusColor}-100` : 'bg-white',
          position == 'sticky'
            ? 'rounded-b-md shadow-md'
            : position == 'fixed'
              ? 'md:shadow-md'
              : position == 'absolute'
                ? 'md:shadow-md'
                : 'md:rounded-md',
          'p-4'
        ]"
      >
        <div class="flex md:px-4">
          <div v-if="!iconIsHidden && !useEmoji" class="flex-shrink-0">
            <component :is="statusIcon" :class="['h-5 w-5', `text-${statusColor}-400`]" />
          </div>
          <div v-if="!iconIsHidden && useEmoji" class="flex-shrink-0 leading-5">
            {{ useEmoji }}
          </div>
          <div class="ml-3">
            <h3 v-show="title" :class="['text-sm font-semibold tracking-tight', `text-${statusColor}-800`]">
              {{ title }}
            </h3>
            <div v-if="hasMessage" :class="['text-sm', `text-${statusColor}-700`, { 'mt-2': title }, 'rte-body']">
              <slot name="message"><!-- Recommended Max Characters: 64-178 --></slot>
            </div>
            <div v-if="actions" class="mt-4">
              <div class="-mx-2 -mt-1.5 flex">
                <SmartLink
                  v-for="(action, i) in actions"
                  :key="`action-${i}`"
                  :to="action.to"
                  :type="action.type ?? 'router-link'"
                  :class="[
                    'px-2 py-1.5 mr-2 rounded-md text-xs font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 border cursor-pointer',
                    `border-${statusColor}-400 bg-${statusColor}-100  text-${statusColor}-800 hover:border-${statusColor}-800 focus:ring-offset-${statusColor}-100 focus:ring-${statusColor}-600`
                  ]"
                  v-on="action.emitValue ? { click: () => handleActionClick(action.emitValue) } : {}"
                >
                  {{ action.text }}
                </SmartLink>
              </div>
            </div>
          </div>
          <div v-if="hasDismissBtn" class="ml-auto pl-3" @click="dismissBanner">
            <div class="-mx-1.5 -my-1.5">
              <button
                type="button"
                :class="[
                  'inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2',
                  `bg-${statusColor}-100 text-gray-brand-700 hover:bg-${statusColor}-100 focus:ring-offset-${statusColor}-100 focus:ring-${statusColor}-600`
                ]"
              >
                <span class="sr-only">Dismiss</span>
                <XIcon class="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue'

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XIcon
} from '@heroicons/vue/outline'

import SmartLink from '@/components/Molecules/SmartLink'

export default {
  components: {
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    SmartLink,
    XCircleIcon,
    XIcon
  },
  props: {
    hasDismissBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    dismissTimeout: {
      type: Number,
      required: false,
      default: null
    },
    iconIsHidden: {
      type: Boolean,
      required: false,
      default: false
    },
    useEmoji: {
      type: String,
      required: false,
      default: null
    },
    position: {
      type: String,
      required: false,
      default: 'inline'
    },
    status: {
      type: String,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    actions: {
      type: Object,
      required: false,
      default: null
    },
    stackIndex: {
      type: Number,
      required: false,
      default: 0
    },
    uniqueId: {
      type: Number,
      required: false,
      default: null
    }
  },
  emits: ['on-dismiss', 'on-action'],
  setup(props, { slots, emit }) {
    const hasMessage = ref(!!slots.message)

    const statusIcon = ref('InformationCircleIcon')
    const statusColor = ref('gray')

    switch (props.status) {
      case 'success':
        statusIcon.value = 'CheckCircleIcon'
        statusColor.value = 'green'
        break
      case 'info':
        statusIcon.value = 'InformationCircleIcon'
        statusColor.value = 'blue'
        break
      case 'warning':
        statusIcon.value = 'ExclamationCircleIcon'
        statusColor.value = 'yellow'
        break
      case 'critical':
        statusIcon.value = 'XCircleIcon'
        statusColor.value = 'red'
        break
      case 'brand':
        statusIcon.value = 'InformationCircleIcon'
        statusColor.value = 'brand'
        break
      default:
        statusIcon.value = 'InformationCircleIcon'
        statusColor.value = 'gray'
        break
    }

    // Manage visibility (single vs multiple)
    let isVisible = ref(false)
    const updateState = () => {
      if (props.stackIndex) {
        isVisible.value = props.stackIndex == 0 ? true : false
      } else {
        isVisible.value = true
      }
    }

    // Handle action
    const handleActionClick = (val) => {
      emit('on-action', val)
      // isVisible.value = false // NOTE: (Will) I commented this out because the action button should be handled by the parent, maybe we don't want to dismiss the notice
    }

    // Dismiss Event (single vs multiple)
    const dismissBanner = () => {
      emit('on-dismiss')
      if (!props.stackIndex) {
        isVisible.value = !isVisible.value
      }
    }

    // Timed Dismissal
    const handleTimedDismissal = () => {
      if (props.stackIndex == 0 && props.dismissTimeout) {
        setTimeout(() => {
          dismissBanner()
        }, props.dismissTimeout)
      }
    }

    // Multipe Banners
    updateState()
    handleTimedDismissal()

    return {
      dismissBanner,
      handleActionClick,
      hasMessage,
      isVisible,
      statusColor,
      statusIcon
    }
  }
}
</script>
<style>
.rte-body ul {
  @apply pl-4 mt-3 mb-6 text-xs;
}
.rte-body li {
  @apply mt-1;
}
</style>
