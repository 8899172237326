<template>
  <section>
    <div class="absolute z-10 mt-1 mx-1">
      <h2 class="py-2 px-3 bg-white/50 backdrop-blur font-bold text-lg leading-5 rounded-md tracking-tighter">
        {{ title }}
      </h2>
      <slot></slot>
    </div>
    <swiper :modules="modules" pagination navigation @click="clicked">
      <swiper-slide v-for="source in sources" :key="source.id">
        <img
          loading="lazy"
          :src="source.attributes.secure_url"
          :alt="source.attributes.title"
          class="h-full w-full object-contain aspect-square"
        />
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default {
  name: 'ProductAttachmentHero',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    title: {
      type: String,
      required: true
    },
    sources: {
      type: Object,
      required: true
    }
  },
  setup() {
    return { modules: [Pagination, Navigation] }
  },
  methods: {
    clicked() {
      this.$emit('clicked')
    }
  }
}
</script>
