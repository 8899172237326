<template>
  <div class="bg-gray-brand-100 pt-4 pb-10">
    <!-- Navbar -->
    <div class="fixed z-40 w-full top-0">
      <div class="w-full">
        <MerchantNav :disable-hide-on-scroll="true" />
      </div>
    </div>

    <div class="w-full max-w-5xl mx-auto h-full pt-10 pb-10">
      <!-- Upcoming drops -->
      <div
        v-if="upcomingBottleCycleBottles && upcomingBottleCycleBottles.length > 0"
        class="px-2 md:px-0 md:w-1/2 mx-auto mt-10 mb-8"
      >
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter">Upcoming drops</div>
        </div>
        <div v-for="b in upcomingBottleCycleBottles" :key="b.id">
          <BottleCycleStoreBottleCard v-if="b.attributes?.type == 'BottleCycleStoreBottle'" :bottle="b" />
        </div>
      </div>

      <!-- Referral discount program -->
      <div
        v-for="discountCode in referralDiscountCodes"
        :key="discountCode?.id"
        class="px-2 md:px-0 md:w-1/2 mx-auto mt-10 sm:mt-20"
      >
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter">Refer friends. Get rewarded.</div>
        </div>
        <div class="bg-white rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600 mt-4">
          <div class="flex w-full border-b py-2">
            <div class="w-1/5 flex items-center">
              <div
                class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
              >
                <div class="cursor-pointer" @click="copy(discountCode?.attributes?.code)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5 mx-auto mb-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 3.75v16.5M2.25 12h19.5M6.375 17.25a4.875 4.875 0 004.875-4.875V12m6.375 5.25a4.875 4.875 0 01-4.875-4.875V12m-9 8.25h16.5a1.5 1.5 0 001.5-1.5V5.25a1.5 1.5 0 00-1.5-1.5H3.75a1.5 1.5 0 00-1.5 1.5v13.5a1.5 1.5 0 001.5 1.5zm12.621-9.44c-1.409 1.41-4.242 1.061-4.242 1.061s-.349-2.833 1.06-4.242a2.25 2.25 0 013.182 3.182zM10.773 7.63c1.409 1.409 1.06 4.242 1.06 4.242S9 12.22 7.592 10.811a2.25 2.25 0 113.182-3.182z"
                    />
                  </svg>
                  {{ discountCode?.attributes?.code }}
                </div>
              </div>
            </div>
            <div class="flex justify-between w-4/5 pt-4 pb-4 pl-6 pr-2 items-center">
              <div>
                <fieldset class="">
                  <div class="leading-5">
                    <div class="flex items-center">
                      <label for="1" class="">
                        <span class="text-md text-brand-gray-800">
                          {{ referralExplanationFor(discountCode) }}
                        </span>
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- YOUR INFO card -->
      <div class="px-2 md:px-0 md:w-1/2 mx-auto sm:py-20 py-10">
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter">Your info</div>
        </div>
        <div class="mt-4 md:mt-6 bg-white">
          <div class="rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600">
            <InfoRow
              icon-slug="name"
              :main-text="userNameDisplay"
              layout="checkout"
              :text-field-value="customer?.attributes?.user_name"
              options-type="textfield"
              placeholder="Full name"
              @saved-option="updatedUserName"
            />
            <InfoRow icon-slug="phone" :main-text="customer?.attributes?.phone_number?.formatted" layout="receipt" />
            <InfoRow
              icon-slug="email"
              :main-text="emailDisplay"
              layout="checkout"
              :text-field-value="customer?.attributes?.email"
              options-type="textfield"
              placeholder="Email"
              @saved-option="updatedEmail"
            />
            <InfoRow
              icon-slug="preferences"
              :main-text="labelsDisplay"
              layout="checkout"
              options-type="multiselect"
              :options="allPossibleLabels"
              :selected-options="customerLabels"
              @saved-options="updatedLabels"
            />
            <InfoRow
              v-if="customer?.attributes?.account_credit.cents"
              icon-slug="credit"
              :main-text="customer?.attributes?.account_credit.format"
              layout="receipt"
            />

            <!-- <div class="border p-4">
            <fieldset>
              <label for="phone" class="mb-2 block">
                <div class="leading-5 form-inputblock mt-1 text-md text-gray-brand-800 font-semibold">
                  Your mobile number
                </div>
              </label>
              <div class="block">
                <input
                  model="tel"
                  @keyup="checkTelValidity"
                  @keyup.enter="signIn"
                  type="tel"
                  id="phone"
                  data-int-tel
                  class="form-input shadow-sm w-full p-2 rounded-md"
                />
              </div>
            </fieldset>
          </div> -->
            <!-- <div class="p-4 py-4 border-l border-r border-b">
            <button
              class="
                h-12
                px-4
                py-2
                mt-2
                w-full
                text-white
                border border-brand-100
                bg-brand-500
                font-semibold
                text-sm
                rounded-md
                hover:border-brand-800
                flex
                justify-center
                items-center
              "
              @click="signIn"
              :disabled="!enableSignInButton"
              :class="[enableSignInButton ? 'opacity-100 cursor-pointer' : 'opacity-50 cursor-not-allowed']"
            >
              <div class="inline-block h-5 font-semibold text-sm">Next</div>
              <div class="inline-block h-5 w-5 ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="fill-current"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 13l-5 5m0 0l-5-5m5 5V6"
                  />
                </svg>
              </div>
            </button>
            <span v-if="false" class="block text-sm text-brand-gray-600 mt-4 text-center">Brief helper text here.</span>
          </div>
          <div class="flex">
            <div class="w-full border-b border-l border-r bg-brand-100 text-brand-800">
              <div class="leading-5 pt-4 pb-4 px-6 scrolling-touch text-sm text-center">
                By creating an account, you give @{{ merchantHandle }} permission to text and email you. You agree to
                receive recurring automated marketing text messages at the phone number provided. Consent is not a
                condition to purchase. Msg &amp; data rates may apply. Msg frequency varies. Reply HELP for help and
                STOP to cancel. View our
                <a href="#" class="font-semibold">SMS Policy,</a>
                &nbsp;
                <a href="#" class="font-semibold">Terms of Service,</a>
                and
                <a href="#" class="font-semibold">Privacy Policy.</a>
              </div>
            </div>
          </div> -->
          </div>
        </div>
      </div>

      <div class="px-2 md:px-0 md:w-1/2 mx-auto mb-8">
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter">Membership</div>
        </div>
        <div class="mt-4 md:mt-6 bg-white">
          <div class="rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600">
            <MembershipOptionsBlock
              :membership-tier="membershipTier"
              :membership-tiers="membershipTiers"
              :distribution-list="distributionList"
              :distribution-lists="distributionLists"
              :interval-cart-setting="intervalCartSubscriptionSetting"
              :show-tier="true"
              :show-list="true"
              :show-store="true"
              :show-autocharge="true"
              :show-package="true"
              :cart-subscription="currentCartSubscription"
              @set-membership-tier="setMembershipTier"
              @set-distribution-list="setDistributionList"
              @set-cart-interval="setCartInterval"
              @set-cart-autocharge="setCartAutocharge"
              @set-cart-store="setCartStore"
              @set-cart-package="setCartPackage"
            />
          </div>
        </div>
      </div>

      <div v-if="hasOrders" class="px-2 md:px-0 md:w-1/2 mx-auto mt-20 mb-8">
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter">Orders</div>
        </div>
        <div class="mt-4 md:mt-6 bg-white">
          <div class="rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600">
            <BottleRow v-for="bottle in paidBottles" :key="bottle.id" :bottle="bottle" />
          </div>
        </div>
      </div>

      <div v-if="hasIssuedGiftCards" class="px-2 md:px-0 md:w-1/2 mx-auto mt-20 mb-8">
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter">Issued Gift Cards</div>
        </div>
        <div class="mt-4 md:mt-6 bg-white">
          <div class="rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600">
            <IssuedGiftCardRow
              v-for="issuedGiftCard in issuedGiftCards"
              :key="issuedGiftCard.id"
              :issued-gift-card="issuedGiftCard"
            />
          </div>
        </div>
      </div>

      <!-- SETTINGS card -->
      <div class="px-2 md:px-0 md:w-1/2 mx-auto mt-20 mb-8">
        <div class="flex justify-start items-center">
          <div class="text-xl font-bold text-gray-brand-900 tracking-tighter">Settings</div>
        </div>
        <div class="mt-4 md:mt-6 bg-white">
          <SecondaryButton class="w-full" :size="'lg'" @click="logOut">
            <template #left-icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                />
              </svg>
            </template>
            Log out
          </SecondaryButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MerchantNav } from '@/components'
import InfoRow from '@/components/Checkout/InfoRow'
import MembershipOptionsBlock from '@/components/Checkout/MembershipOptionsBlock'
import BottleRow from '@/components/Profile/BottleRow'
import IssuedGiftCardRow from '@/components/Profile/IssuedGiftCardRow'
import BottleCycleStoreBottleCard from '@/components/Checkout/BottleCycleStoreBottleCard'

import SecondaryButton from '../components/Molecules/SecondaryButton.vue'

import mapRelationships from '@/utils/mapRelationships'
import mapRelationship from '@/utils/mapRelationship'

export default {
  components: {
    MerchantNav,
    InfoRow,
    MembershipOptionsBlock,
    BottleRow,
    IssuedGiftCardRow,
    BottleCycleStoreBottleCard,
    SecondaryButton
  },
  computed: {
    customer() {
      return this.$store.getters.customer
    },
    referralDiscountCodes() {
      return mapRelationships(this.$store.state, this.customer?.relationships?.referral_discount_codes?.data)
    },
    emailDisplay() {
      return this.customer?.attributes?.email ? this.customer?.attributes?.email : 'None'
    },
    userNameDisplay() {
      return this.customer?.attributes?.user_name ? this.customer?.attributes?.user_name : 'Your name'
    },
    labelsDisplay() {
      if (this.customerLabels.length > 0)
        return this.customerLabels.map((x) => x.attributes?.label_filter_text).join(', ')
      return 'No preferences selected.'
    },
    customerLabels() {
      return this.$store.getters.labelsFromRelationships(this.customer?.relationships?.labels?.data)
    },
    allPossibleLabels() {
      if (this.$store.state.objects.label) {
        return Object.values(this.$store.state.objects.label)
      }
      return []
    },
    upcomingBottleCycleBottles() {
      return this.$store.getters.bottles(this.customer?.relationships?.upcoming_bottle_cycle_bottles?.data)
    },
    membershipTiers() {
      return this.$store.getters.membershipTiers
    },
    membershipTier() {
      return this.$store.getters.membershipTierFromRelationship(this.customer?.relationships?.membership_tier?.data)
    },
    currentMembershipTierSelection() {
      return mapRelationship(this.$store.state, this.customer?.relationships?.current_membership_tier_selection?.data)
    },
    currentCartSubscription() {
      return mapRelationships(
        this.$store.state,
        this.currentMembershipTierSelection?.relationships?.cart_subscriptions?.data
      )?.[0]
    },
    distributionLists() {
      return this.$store.getters.distributionLists(this.membershipTier?.relationships.distribution_lists?.data)
    },
    distributionList() {
      return this.$store.getters.distributionListFromRelationship(
        this.customer?.relationships?.distribution_lists?.data?.[0]
      )
    },
    intervalCartSubscriptionSetting() {
      return mapRelationship(
        this.$store.state,
        this.currentCartSubscription?.relationships?.interval_cart_subscription_setting?.data
      )
    },
    paidBottles() {
      return this.$store.getters.bottles(this.customer?.relationships?.paid_bottles?.data)?.slice(0, 8)
    },
    hasOrders() {
      return this.paidBottles && this.paidBottles.length > 0
    },
    issuedGiftCards() {
      return this.$store.getters.issuedGiftCards(this.customer?.relationships?.issued_gift_cards?.data)?.slice(0, 8)
    },
    hasIssuedGiftCards() {
      return this.issuedGiftCards && this.issuedGiftCards.length > 0
    }
  },
  async mounted() {
    window.scrollTo(0, 0)
    if (!this.$store.getters.isLoggedIn) {
      this.$router.replace({ name: 'NakedLogin' })
    } else {
      await this.$store.dispatch('getCustomerInfo', [
        'paid_bottles',
        'paid_bottles.checkout',
        'paid_bottles.checkout.bottle_payment',
        'paid_bottles.checkout.stripe_payment_intent',
        'paid_bottles.cart',
        'paid_bottles.cart.cart_items',
        'possible_labels',
        'possible_membership_tiers',
        'possible_membership_tiers.distribution_lists',
        'possible_membership_tiers.cart_subscription_settings',
        'possible_membership_tiers.cart_subscription_store_options',
        'distribution_lists',
        'issued_gift_cards',
        'upcoming_bottle_cycle_bottles',
        'upcoming_bottle_cycle_bottles.cart',
        'referral_discount_codes',
        'referral_discount_codes.referral_discount_program',
        'membership_tier',
        'current_membership_tier_selection',
        'current_membership_tier_selection.cart_subscriptions',
        'upcoming_bottle_cycle_bottles.cart.cart_items',
        'upcoming_bottle_cycle_bottles.cart.cart_items.product',
        'upcoming_bottle_cycle_bottles.bottle_payment',
        'current_membership_tier_selection.cart_subscriptions.possible_packages',
        'current_cart_subscription',
        'current_cart_subscription.possible_packages',
        'current_cart_subscription.package'
      ])
    }
  },
  methods: {
    logOut() {
      localStorage.removeItem('token')
      this.$store.commit('CLEAR_PROMISES')
      this.$router.push('/')
    },
    updatedEmail(newEmail) {
      this.$store.dispatch('updateCustomerInfo', {
        customer: { email: newEmail }
      })
    },
    updatedUserName(newName) {
      this.$store.dispatch('updateCustomerInfo', {
        customer: { user_name: newName }
      })
    },
    updatedLabels(newLabels) {
      const newLabelIDs = newLabels.map((x) => x.id)
      this.$store.dispatch('updateCustomerInfo', {
        customer: { label_ids: newLabelIDs }
      })
    },
    setMembershipTier(tierID) {
      this.$store.dispatch('updateCustomerInfo', {
        customer: { membership_tier_id: tierID }
      })
    },
    setDistributionList(listID) {
      this.$store.dispatch('updateCustomerInfo', {
        customer: { distribution_list_id: listID }
      })
    },
    setCartInterval(intervalID) {
      this.$store.dispatch('updateCustomerInfo', {
        customer: { interval_cart_subscription_setting_id: intervalID }
      })
    },
    setCartStore(storeID) {
      this.$store.dispatch('updateCustomerInfo', {
        customer: { store_id: storeID }
      })
    },
    setCartPackage(packageID) {
      this.$store.dispatch('updateCustomerInfo', {
        customer: { package_id: packageID }
      })
    },
    copy(text) {
      const input = document.createElement('input')
      input.setAttribute('value', text)
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      window.alert(`Copied your referral code: ${text}`)
    },
    referralExplanationFor(discountCode) {
      let referralDiscountProgram = mapRelationship(
        this.$store.state,
        discountCode.relationships?.referral_discount_program?.data
      )
      return (
        referralDiscountProgram?.attributes?.explanation ||
        `Share your code to earn ${referralDiscountProgram?.attributes?.referrer_reward?.format} for everybody new who places an order.`
      )
    }
  }
}
</script>
