<template>
  <article class="w-full px-2 sm:w-1/2 sm:px-2 mb-4 md:mb-8">
    <section class="relative pt-1 rounded-sm bg-white w-full shadow-sm">
      <div class="mx-3 my-2 flex">
        <div class="w-9/12 flex-grow cursor-pointer">
          <div
            v-for="v in 4"
            :key="v"
            class="skeleton skeleton-tag inline-block text-xxs px-1 py-1 mr-1 rounded-md shadow-sm"
          ></div>
          <article>
            <h2 class="pr-3 pt-2 sm:pt-2 md:pt-3 lg:pt-4 pb-2 md:pb-3 font-bold text-lg leading-5 tracking-tighter">
              <div class="skeleton skeleton-title"></div>
            </h2>
            <div class="pb-3 pr-3 skeleton skeleton-description"></div>
          </article>
        </div>
        <aside class="w-3/12 flex-none hidden md:block mt-1">
          <div
            class="skeleton relative w-full pb-full border shadow-sm rounded-sm overflow-hidden cursor-pointer"
          ></div>
        </aside>
      </div>
      <div class="relative flex justify-between items-center px-3 py-3 border-t">
        <div class="my-auto text-sm text-gray-brand-600 rounded-full">
          <div class="skeleton skeleton-price"></div>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: 'LoadingProductsSkeleton'
}
</script>

<style scoped lang="scss">
.skeleton {
  color: #eeeeee;
  background-color: #eeeeee;
  border: none;
  margin-right: 10px;
  animation: pulse-bg 0.5s infinite;

  &.skeleton-tag {
    width: 50px;
    height: 20px;
  }

  &.skeleton-title {
    width: 330px;
    height: 20px;
  }

  &.skeleton-description {
    width: 340px;
    height: 47px;
  }

  &.skeleton-price {
    width: 80px;
    height: 20px;
  }
}

@keyframes pulse-bg {
  0% {
    background-color: #ddd;
    color: #ddd;
  }
  50% {
    background-color: #d0d0d0;
    color: #d0d0d0;
  }
  100% {
    background-color: #ddd;
    color: #ddd;
  }
}
</style>
