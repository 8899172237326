import store from '@/store'

import _ from 'lodash'

const makeTree = (includes) => {
  const root = { name: '', children: [] }
  for (const [layer, line] of includes.entries()) {
    let node = root
    for (const part of line.split('.')) {
      let child = node.children.find((c) => c.name === part)
      if (!child) {
        child = { name: part, children: [], layer }
        node.children.push(child)
      }
      node = child
    }
  }
  return root.children
}

const flattenChildren = (level, top = true) => {
  if (top) return level.children.map((x) => flattenChildren(x, false)).flat()
  if (!level.children.length) return level.name
  return level.children.map((x) => level.name + '.' + flattenChildren(x, false)).flat()
}

const getRelationships = async (response, includes, context = {}) => {
  const tree = makeTree(includes)

  const deferredResponses = []
  const getLevel = async (response, level) => {
    let data
    if (Array.isArray(response.data)) {
      data = response.data.reduce((acc, x) => acc.concat(x.relationships[level.name]?.data), [])
    } else {
      data = [response.data.relationships[level.name]?.data]
    }
    data = data.flat().filter(Boolean)
    if (!data.length) return

    const actualType = data[0].type
    const getChunk = async (chunk) => {
      const params = new URLSearchParams()
      params.append('type', actualType)
      for (const [name, id] of Object.entries(context)) params.append(`context[${name}_id]`, id)
      for (const obj of chunk) params.append('ids[]', obj.id)
      params.append('included', level.layer > 0 ? flattenChildren(level).join(',') || 'none' : 'none')

      const r = await merchantFetch('relationships', { params })
      deferredResponses.push(r)
      if (level.layer === 0) return Promise.all(level.children.map((sublevel) => getLevel(r, sublevel)))
    }

    const chunks = _.chunk(data, 15)
    return Promise.all(chunks.map((x) => getChunk(x)))
  }

  await Promise.all(tree.map((level) => getLevel(response, level)))

  for (const response of deferredResponses) store.commit('RECEIVE_OBJECT', response)
}

export default async function merchantFetch(url, options = {}, json = true) {
  const token = await store.dispatch('getToken', {
    subdomain: window.location.host
  })

  options.params = options.params || {}
  if (!options.temporaryActualUltramarineEnable && options.includes) {
    options.params.included = options.includes.join(',')
    delete options.includes
  }
  if (options.includes) options.params.included = 'none'
  let paramsString = new URLSearchParams(options.params).toString()
  if (paramsString) paramsString = '?' + paramsString

  const res = await fetch(process.env.VUE_APP_API_BASE_URL + '/merchant/' + url + paramsString, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    },
    ...options
  })

  const resJson = await res.json()

  if (options.includes) await getRelationships(resJson, options.includes, options.context)

  if (!res.ok) throw new Error(res.status)

  if (json) {
    return resJson
  } else {
    return res
  }
}
