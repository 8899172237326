<template>
  <section v-if="splitEnabled" class="px-2 mt-4">
    <div class="mb-1 font-medium">Items for</div>
    <div class="flex flex-wrap gap-2">
      <div
        @click="selectDay(null)"
        class="relative bg-white rounded-md shadow-sm flex items-center justify-center w-24 flex-none h-20 cursor-pointer text-gray-brand-900 hover:bg-gray-brand-100 focus:outline-none border border-gray-400 transition-all hover:transform hover:scale-105"
        :class="{ 'ring-2 ring-gray-brand-700 bg-gray-brand-200 text-brand-800': selectedDay === null }"
      >
        <div class="flex flex-col items-center p-3 text-gray-900">
          <span class="text-lg font-semibold">All days</span>
        </div>
      </div>
      <div
        v-for="date in possibleDays"
        @click="selectDay(date)"
        class="relative bg-white rounded-md shadow-sm flex items-center justify-center w-24 flex-none h-20 cursor-pointer text-gray-brand-900 hover:bg-gray-brand-100 focus:outline-none border border-gray-400 transition-all hover:transform hover:scale-105"
        :class="{ 'ring-2 ring-gray-brand-700 bg-gray-brand-200 text-brand-800': selectedDay === date }"
      >
        <div class="flex flex-col items-center p-3 text-gray-900">
          <span class="block text-xs font-medium text-center">{{ weekday(date) }}</span>
          <span class="text-xl font-bold">{{ day(date) }}</span>
          <span class="block text-xs font-medium text-center">{{ month(date) }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { serverDayToDateObject } from '@/helpers/dates'
import { useStore } from 'vuex'
import _ from 'lodash'

const store = useStore()

const splitEnabled = computed(() => store.getters.fulfillmentSlot?.attributes.allow_day_splits)
const selectedDay = computed(() => store.state.splitDay)
const possibleDays = computed(() =>
  store.getters.fulfillmentSlot?.attributes.possible_cart_item_fulfillment_dates.sort()
)

const selectDay = (day) => {
  store.commit('SET_SPLIT_DAY', day)
}

const weekday = (day) =>
  new Intl.DateTimeFormat('en-US', {
    weekday: 'long'
  }).format(serverDayToDateObject(day))

const day = (day) =>
  new Intl.DateTimeFormat('en-US', {
    day: 'numeric'
  }).format(serverDayToDateObject(day))

const month = (day) =>
  new Intl.DateTimeFormat('en-US', {
    month: 'long'
  }).format(serverDayToDateObject(day))
</script>
