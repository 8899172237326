<template>
  <div>
    <div class="flex w-full border-b cursor-pointer" @click="view">
      <div class="w-1/5 flex items-center">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5 mx-auto mb-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
              />
            </svg>
            {{ issuedGiftCard.attributes?.gift_card_value.format }}
          </div>
        </div>
      </div>
      <div class="w-4/5 flex justify-between pt-4 pb-4 pl-6 pr-2">
        <div class="flex items-center">
          <fieldset class="">
            <div class="leading-5">
              <div class="flex items-center">
                <label for="1" class="cursor-pointer">
                  <span class="text-md font-semibold text-brand-gray-800">
                    {{ issuedGiftCard.attributes?.code }}
                  </span>
                  <div class="text-sm text-gray-brand-600">{{ displayPaymentTimestamp }}</div>
                </label>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="flex items-center">
          <button
            class="px-4 py-2 text-gray-brand-600 border border-transparent font-semibold text-sm rounded-full hover:border-gray-brand-600"
          >
            View
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IssuedGiftCardRow',
  components: {},
  props: ['issuedGiftCard'],
  data: () => ({}),
  computed: {
    createdAt() {
      return this.issuedGiftCard?.attributes?.created_at
    },
    code() {
      return this.issuedGiftCard?.attributes?.code
    },
    displayPaymentTimestamp() {
      if (!this.createdAt) return ''
      let time = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hourCycle: 'h12'
      }).format(new Date(this.createdAt))
      let day = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).format(new Date(this.createdAt))
      return day + ' at ' + time
    }
  },
  mounted() {},
  methods: {
    view() {
      this.$router.push({ name: 'IssuedGiftCard', params: { code: this.code } })
    }
  }
}
</script>
