<template>
  <div
    class="bg-white rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600 overflow-hidden w-full"
  >
    <slot v-if="!isLoading" />
    <BasicSpinner v-else color="gray-400" class="my-8 flex justify-center" />
  </div>
</template>
<script>
import BasicSpinner from '@/components/Molecules/BasicSpinner'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    BasicSpinner
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
</script>
