<template>
  <Box>
    <QuestionRow
      v-for="answer in answers"
      :key="answer.id"
      :question="questionForAnswer(answer)"
      :answer="answer"
      :editable="editable"
    />
  </Box>
</template>
<script>
import QuestionRow from '@/components/Checkout/QuestionRow'
import AnswerRow from '@/components/Checkout/AnswerRow'
import Box from '@/components/Molecules/Box'
export default {
  components: {
    Box,
    AnswerRow,
    QuestionRow
  },
  props: {
    answers: {
      type: Array,
      required: true
    },
    bottle: {
      type: Object,
      required: false,
      default: null
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({}),
  computed: {},
  methods: {
    questionForAnswer(answer) {
      return this.$store.getters.question(answer?.relationships?.question?.data)
    }
  }
}
</script>
