<template>
  <div>
    <input
      :id="id"
      :value="id"
      type="radio"
      class="flex-shrink-0 form-radio h-4 w-4 text-brand-600 transition duration-150 ease-in-out cursor-pointer"
      :checked="checked"
      @click="clickedOption(id)"
    />
    <label :for="id" class="ml-3 cursor-pointer text-brand-gray-800" @click="clickedOption(id)">
      <span class="text-md font-semibold">{{ option.attributes?.name }}</span>
      <span class="text-sm ml-1 text-brand-gray-600"></span>
      <span class="block text-sm text-brand-gray-600">
        {{ option.attributes?.description }}
      </span>
    </label>
  </div>
</template>

<script>
let strftime = require('strftime')

export default {
  name: 'InfoRowMethod',
  components: {},
  props: ['option', 'selectedOption'],
  data: () => ({}),
  computed: {
    id() {
      return this.option.attributes?.id
    },
    checked() {
      return this.id == this.selectedOption
    }
  },
  methods: {
    clickedOption(value) {
      this.$emit('clickedOption', value)
    }
  }
}
</script>
