<template>
  <div class="flex mt-4 mb-3 items-start">
    <div class="w-10 top-0 mr-1">
      <span
        v-if="method.attributes.type === 'PickupFulfillmentMethod'"
        class="bg-brand-600 text-white w-6 h-6 rounded-full inline-flex items-center justify-center mx-3 flex-shrink-0"
      >
        {{ index }}
      </span>
    </div>
    <div class="min-w-0 w-full">
      <div class="w-full flex flex-col">
        <div
          class="flex justify-between items-center w-full"
          :class="{
            'cursor-pointer opacity-100': eligible,
            'cursor-not-allowed opacity-50': !eligible
          }"
          @click="eligible && selectFulfillmentMethod()"
        >
          <span class="text-md font-semibold text-gray-brand-800 tracking-tight">
            {{ method.attributes.name }}
          </span>
          <div class="flex items-center gap-2 mr-2">
            <span v-if="hasPrice" class="text-xs text-brand-gray-600 font-medium font-manrope uppercase tracking-wider">
              {{ method.attributes.price.format }}
            </span>
            <CheckCircleIcon v-if="selected" class="h-6 w-6 text-brand-600" />
          </div>
        </div>
        <div class="text-xs tracking-wide leading-4 text-brand-900 font-light mt-0.5">
          <AddressLine
            :delivery-address="$store.getters.deliveryAddress(method.relationships.delivery_address?.data?.id)"
          />
        </div>
      </div>
      <div class="overflow-x-auto flex justify-start space-x-2 py-4 pl-2 -ml-2 pr-4">
        <!-- Add more label elements for other dates -->
        <InfoRowSlotDay
          v-for="option in filteredFulfillmentSlots"
          :key="option.date"
          :option="option"
          :class="{
            'cursor-not-allowed': !option.can_select
          }"
          @clicked-option="selectedNewFulfillmentSlot"
          :selected="selected"
        />
        <div
          class="relative bg-white rounded-md shadow-sm flex items-center justify-center w-24 flex-none h-20 cursor-pointer text-gray-brand-900 hover:bg-gray-brand-100 focus:outline-none border transition-all hover:transform hover:scale-105"
          v-if="!filteredFulfillmentSlots?.length"
        >
          <div class="flex flex-col items-center p-3 text-gray-400">
            <span class="text-sm font-medium">Unavailable</span>
          </div>
        </div>
      </div>
      <div v-if="method.attributes.description" class="font-inter text-sm leading-4 text-brand-900">
        {{ method.attributes.description }}
      </div>
    </div>
  </div>
</template>

<script>
import AddressLine from '@/components/Partials/AddressLine'
import mapRelationship from '@/utils/mapRelationship'
import InfoRowSlotDay from '@/components/Checkout/InfoRowSlotDay'
import { CheckCircleIcon } from '@heroicons/vue/solid'

export default {
  name: 'FulfillmentMethodChoice',
  components: {
    AddressLine,
    CheckCircleIcon,
    InfoRowSlotDay
  },
  props: {
    method: {
      type: Object,
      required: true
    },
    bottle: {
      type: Object,
      required: true
    },
    index: { type: Number }
  },
  data: () => ({}),
  computed: {
    store() {
      return mapRelationship(this.$store.state, this.bottle.relationships.store.data)
    },
    merchantTZ() {
      return mapRelationship(this.$store.state, this.bottle.relationships.merchant.data)?.attributes.time_zone
    },
    merchantAndBrowserTZMatches() {
      return this.merchantTZ === Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    merchantTimeZone() {
      if (!this.merchantAndBrowserTZMatches) {
        return `(${
          new Date().toLocaleTimeString('en-US', { timeZone: this.merchantTZ, timeZoneName: 'short' }).split(' ')[2]
        })`
      }
    },
    selected() {
      if (this.isMultibottleFunctionality()) {
        return (
          this.$store.getters.fulfillmentMethodForBottle(this.bottle)?.id &&
          this.$store.getters.fulfillmentMethodForBottle(this.bottle)?.id == this.method?.id
        )
      } else {
        return this.$store.getters.fulfillmentMethod?.id && this.$store.getters.fulfillmentMethod?.id == this.method?.id
      }
    },
    hasPrice() {
      return this.method.attributes.price.cents > 0
    },
    isFree() {
      return !this.hasPrice
    },
    eligible() {
      if (this.isMultibottleFunctionality()) {
        const methodId = this.method.attributes.id
        const eligible_fulfillment_methods_for_bottle = this.bottle.attributes.eligible_fulfillment_methods_for_bottle
        const fulfillment_method_for_bottle = eligible_fulfillment_methods_for_bottle.find(
          (obj) => obj.fulfillment_method_id === methodId
        )
        return fulfillment_method_for_bottle.eligible
      } else {
        return this.method.attributes.eligible
      }
    },
    hasDescriptionText() {
      return this.method.attributes.description?.length > 0
    },
    hasUpcomingWindows() {
      return this.method.attributes.upcoming_windows?.length > 0
    },
    calculatedFulfillmentSlots() {
      return mapRelationship(this.$store.state, this.method.relationships.fulfillment_slots_detail.data)?.attributes
        ?.calculated_fulfillment_slots
    },
    filteredFulfillmentSlots() {
      if (this.store?.attributes?.pickup_fulfillment_method_visibility == 'available-only') {
        return this.calculatedFulfillmentSlots.filter((option) => option.can_select)
      } else {
        return this.calculatedFulfillmentSlots
      }
    },
    usableWindows() {
      let temp = []
      let contains = []
      this.method.attributes.upcoming_windows.forEach(function (element) {
        if (!contains.includes(element.day_of_week) && temp.length < 3) {
          temp.push(element)
          contains.push(element.day_of_week)
        }
      })
      return temp
    },
    hasMoreWindows() {
      return this.usableWindows.length < this.method.attributes.upcoming_windows.length
    }
  },
  mounted() {},
  methods: {
    isMultibottleFunctionality() {
      const currentBottle = this.$store.getters.currentBottle
      if (currentBottle) {
        return currentBottle.attributes.allow_multiple_days
      } else {
        return false
      }
    },
    selectFulfillmentMethod() {
      this.$emit('showLoading', true)
      this.$store.dispatch('selectFulfillmentMethod', { methodId: this.method.id, bottle: this.bottle }).then(
        (response) => {
          // properly updated
          this.$emit('showLoading', false)
        },
        (error) => {
          // failed to update
          this.$emit('showLoading', false)
        }
      )
      this.$emit('clickedNewMethod')
    },
    selectedNewFulfillmentSlot(newSlotID) {
      this.$emit('showLoading', true)
      this.$store.dispatch('selectFulfillmentSlot', { newSlotId: newSlotID, bottleId: this.bottle.id }).then(
        (response) => {
          // properly updated
          this.$emit('showLoading', false)
        },
        (error) => {
          // failed to update
          this.$emit('showLoading', false)
        }
      )
      this.$emit('clickedNewMethod')
    }
  }
}
</script>
