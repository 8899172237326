<template>
  <div class="w-full">
    <MerchantNav />
    <main class="max-w-5xl mx-auto flex flex-wrap flex-col justify-between">
      <div class="md:mx-0 mt-8 mb-8 flex flex-wrap justify-between">
        <!-- <Product
          v-for="p in $store.state.draftCart"
          :key="p.attributes.id"
          :product="p"
          :category="$store.state.objects.category[p.cartAttributes.categoryId]"
        /> -->
      </div>
    </main>
    <MerchantBasket :in-cart="true" />
  </div>
</template>
<script>
import { MerchantMain, MerchantNav, MerchantBasket, Product } from '@/components'

export default {
  components: {
    MerchantMain,
    MerchantNav,
    MerchantBasket,
    Product
  },

  mounted() {
    // this.$store.dispatch('getStore')
    // this.$store.dispatch('getInfo')
  }
}
</script>
