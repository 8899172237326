<template>
  <article>
    <h2
      v-if="title"
      class="pr-3 pt-2 sm:pt-2 md:pt-3 lg:pt-4 pb-2 md:pb-3 font-bold text-lg leading-5 tracking-tighter"
    >
      {{ title }}
    </h2>
    <p
      v-if="description"
      v-linkified:options="{ defaultProtocol: 'https' }"
      :class="['pb-3 pr-3 text-gray-brand-700 text-sm whitespace-pre-line', collapsed && 'collapsed']"
    >
      {{ description }}
    </p>
  </article>
</template>

<script>
export default {
  name: 'ProductDescription',

  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    description: {
      required: true
    },
    collapsed: {
      type: Boolean,
      required: true,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
.collapsed {
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0.5rem;
}
.linkified {
  @apply text-blue-500;
}
</style>
