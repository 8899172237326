<template>
  <div class="bg-gray-brand-100 h-screen pb-20" :style="computedColors">
    <!-- Navbar -->
    <div class="fixed w-full top-0 z-10">
      <div class="w-full">
        <MerchantNav :disable-hide-on-scroll="true" :empty-middle="true">
          <ProgressPills :pills="progressPills" />
        </MerchantNav>
      </div>
    </div>

    <!-- main -->
    <div class="w-full max-w-5xl mx-auto h-full">
      <div class="md:px-2 md:w-1/2 md:mx-auto pt-24">
        <InfoBanner :bottle="bottle" />
      </div>
    </div>
  </div>
</template>
<script>
let strftime = require('strftime')
import { ref } from 'vue'

import { TheBanner, MerchantNav } from '@/components'
import InfoBanner from '@/components/Checkout/InfoBanner.vue'

import ProgressPills from '@/components/Merchant/ProgressPills'

export default {
  components: { TheBanner, MerchantNav, InfoBanner, ProgressPills },
  setup() {},
  computed: {
    computedColors() {
      const hsl = this.$store.getters.info?.attributes.color.hsl
      if (hsl)
        return {
          '--main-brand-hue': hsl[0],
          '--main-brand-saturation': hsl[1],
          '--main-brand-lightness': hsl[2]
        }
      else {
        return undefined
      }
    },
    bottle() {
      return this.$store.getters.currentBottle
    },
    progressPills() {
      return [
        { status: 'completed', to: '/' },
        { status: 'completed', to: '' },
        { status: 'completed', to: '' }
      ]
    }
  },
  async mounted() {
    window.scrollTo(0, 0)
    await this.$store.dispatch('getBottle')
    this.handleStatus()
  },
  methods: {
    handleStatus() {
      const status = this.bottle?.attributes?.status
      if (status !== 'skipped') {
        this.$router.push({ name: 'Selection' })
      }
    }
  }
}
</script>
