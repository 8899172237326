<template>
  <div>
    <div class="flex w-full border-b" :class="{ 'py-2': addedPaddingNeeded }">
      <div v-if="deliveryIcon" class="w-1/5 flex flex-none items-center">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <div>
            <svg
              class="w-5 h-5 mx-auto mb-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"></path>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
              ></path>
            </svg>
            Delivery
          </div>
        </div>
      </div>
      <div v-if="pickupIcon" class="w-1/5 flex flex-none items-center">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <div>
            <svg
              class="w-5 h-5 mx-auto mb-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
              ></path>
            </svg>
            Pickup
          </div>
        </div>
      </div>
      <div v-if="methodIcon" class="w-1/5 flex flex-none items-center">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <div>
            <svg
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5 mx-auto mb-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              ></path>
            </svg>
            Method
          </div>
        </div>
      </div>
      <div v-if="dateIcon" class="w-1/5 flex flex-none items-center">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5 mx-auto mb-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            ></path>
          </svg>
          Date
        </div>
      </div>
      <div v-if="timeIcon" class="w-1/5 flex flex-none items-center">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5 mx-auto mb-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          Time
        </div>
      </div>
      <div v-if="phoneIcon" class="w-1/5 flex flex-none items-center">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-5 h-5 mx-auto mb-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
            />
          </svg>
          Phone
        </div>
      </div>
      <div v-if="emailIcon" class="w-1/5 flex flex-none items-center">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5 mx-auto mb-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          Email
        </div>
      </div>
      <div v-if="nameIcon" class="w-1/5 flex flex-none items-center">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5 mx-auto mb-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
            />
          </svg>
          Name
        </div>
      </div>
      <div v-if="preferencesIcon" class="w-1/5 flex flex-none items-center">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5 mx-auto mb-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
            />
          </svg>
          Options
        </div>
      </div>
      <div v-if="creditIcon" class="w-1/5 flex flex-none items-center">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5 mx-auto mb-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          Credit
        </div>
      </div>
      <div
        v-if="!choose"
        class="flex justify-between pt-4 pb-4 w-full"
        :class="{ 'pl-6': !hasIcon, 'w-4/5 pl-2': hasIcon }"
      >
        <div class="flex items-center w-full">
          <fieldset class="w-full">
            <div class="leading-5 w-full">
              <div class="flex items-center w-full">
                <label for="1" class="w-full">
                  <div class="w-full">
                    <button
                      class="text-md font-semibold text-gray-brand-800 pr-3 inline-flex justify-between items-center align-middle text-left tracking-tight w-full"
                      :class="{ 'rounded-t-md': hasDescriptionText, 'rounded-md': !hasDescriptionText }"
                      @click="changeClicked"
                    >
                      {{ mainText }}
                      <span
                        v-if="canChange"
                        class="items-center px-2.5 py-2 text-xs font-medium italic rounded-md bg-gray-brand-100 text-gray-brand-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 ml-2"
                      >
                        Change
                      </span>
                    </button>
                    <div v-if="hasDescriptionText" class="rounded-b-md">
                      <div class="pr-3">
                        <div class="max-w-3xl mx-auto">
                          <dl class="divide-y divide-white">
                            <div class="py-2">
                              <dt class="text-lg">
                                <!-- Expand/collapse question button -->
                                <div
                                  class="text-left w-full flex justify-between items-start text-gray-500 min-w-0 overflow-hidden cursor-default pr-4"
                                >
                                  <span class="font-inter text-sm leading-5 tracking-wide text-brand-900">
                                    {{ descriptionText }}
                                  </span>
                                </div>
                              </dt>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    v-if="hasSecondaryTextLink"
                    class="block text-sm leading-5 text-left tracking-wide text-brand-900 underline pr-8"
                    :href="secondaryTextLink"
                    target="_blank"
                  >
                    {{ secondaryText }}
                  </a>
                  <div
                    v-else-if="hasSecondaryText"
                    class="text-sm text-left leading-5 tracking-wide text-brand-900 pr-8"
                  >
                    {{ secondaryText }}
                  </div>
                </label>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <InfoRowOptions
        v-if="showChooseDropdown"
        :options="options"
        :selected-option="selectedOption"
        :options-type="optionsType"
        :class="{ 'pl-6': !hasIcon }"
        :merchant-time-zone="merchantTimeZone"
        @clicked-option="clickedOption"
      />
      <InfoRowEditField
        v-if="showEditField"
        :class="{ 'w-full': !hasIcon, 'w-4/5': hasIcon }"
        :text-field-value="textFieldValue"
        :placeholder="placeholder"
        action-text="Save"
        @saved-option="savedOption"
      />
      <InfoRowMultiselect
        v-if="showMultiselect"
        :class="{ 'w-full': !hasIcon, 'w-4/5': hasIcon }"
        :options-type="optionsType"
        :options="options"
        :selected-options="selectedOptions"
        @saved-options="savedOptions"
      />
    </div>
  </div>
</template>

<script>
import InfoRowOptions from '@/components/Checkout/InfoRowOptions'
import InfoRowEditField from '@/components/Checkout/InfoRowEditField'
import InfoRowMultiselect from '@/components/Checkout/InfoRowMultiselect'

export default {
  name: 'InfoRow',
  components: {
    InfoRowOptions,
    InfoRowEditField,
    InfoRowMultiselect
  },
  props: [
    'mainText',
    'secondaryText',
    'secondaryTextLink',
    'descriptionText',
    'iconSlug',
    'options',
    'selectedOption',
    'selectedOptions',
    'optionsType',
    'layout',
    'placeholder',
    'textFieldValue',
    'merchantTimeZone'
  ],
  data: () => ({
    choose: false,
    open: false
  }),
  computed: {
    hasIcon() {
      return this.iconSlug
    },
    addedPaddingNeeded() {
      return !this.canChange && (!this.mainText || !this.secondaryText)
    },
    deliveryIcon() {
      return this.iconSlug == 'delivery'
    },
    pickupIcon() {
      return this.iconSlug == 'pickup'
    },
    methodIcon() {
      return this.iconSlug == 'method'
    },
    dateIcon() {
      return this.iconSlug == 'date'
    },
    timeIcon() {
      return this.iconSlug == 'time'
    },
    phoneIcon() {
      return this.iconSlug == 'phone'
    },
    emailIcon() {
      return this.iconSlug == 'email'
    },
    nameIcon() {
      return this.iconSlug == 'name'
    },
    preferencesIcon() {
      return this.iconSlug == 'preferences'
    },
    creditIcon() {
      return this.iconSlug == 'credit'
    },
    hasSecondaryText() {
      return this.secondaryText && this.secondaryText.length > 0
    },
    hasSecondaryTextLink() {
      return this.hasSecondaryText && this.secondaryTextLink && this.secondaryTextLink.length > 0
    },
    hasDescriptionText() {
      return this.descriptionText && this.descriptionText.length > 0
    },
    hasOptions() {
      return this.options && this.options.length > 0
    },
    isEditField() {
      return this.optionsType == 'textfield'
    },
    isMultiselect() {
      return this.optionsType == 'multiselect'
    },
    canChange() {
      return this.layoutCheckout && (!this.optionsType || this.hasOptions || this.isEditField)
    },
    layoutReceipt() {
      return this.layout == 'receipt'
    },
    layoutCheckout() {
      return this.layout == 'checkout'
    },
    showChooseDropdown() {
      return this.choose && this.hasOptions && !this.isEditField && !this.isMultiselect
    },
    showEditField() {
      return this.choose && this.isEditField
    },
    showMultiselect() {
      return this.choose && this.hasOptions && this.isMultiselect
    }
  },
  mounted() {},
  methods: {
    changeClicked() {
      this.$emit('change')
      if (this.hasOptions || this.isEditField) {
        this.choose = true
      }
    },
    clickedOption(value) {
      this.$emit('clickedOption', value)
      this.choose = false
    },
    savedOption(value) {
      this.$emit('savedOption', value)
      this.choose = false
    },
    savedOptions(value) {
      this.$emit('savedOptions', value)
      this.choose = false
    }
  }
}
</script>
