<template>
  <div
    class="min-h-screen antialiased font-inter text-gray-brand-900 bg-gray-brand-100 flex flex-col justify-between"
    :style="
      hsl && {
        '--main-brand-hue': hsl[0],
        '--main-brand-saturation': hsl[1],
        '--main-brand-lightness': hsl[2]
      }
    "
  >
    <div>
      <router-view />
    </div>
    <MerchantFooter />
    <LoggedInAs />
  </div>
</template>

<script>
import { ref } from 'vue'
import LoggedInAs from '@/components/Profile/LoggedInAs'
import { mapGetters } from 'vuex'
import MerchantFooter from '@/components/Merchant/MerchantFooter'
import mitt from 'mitt'

export default {
  components: { LoggedInAs, MerchantFooter },
  computed: {
    ...mapGetters(['info']),
    hsl() {
      return this.info?.attributes?.color.hsl
    },
    title() {
      return [this.capitalizedHandle, ' ', 'Checkout'].join('')
    },
    facebookPixelContainerID() {
      return this.info?.attributes?.facebook_pixel_container_id
    },
    capitalizedHandle() {
      if (this.info?.attributes?.handle) {
        return this.info?.attributes?.handle?.charAt(0)?.toUpperCase() + this.info?.attributes?.handle?.slice(1)
      } else {
        return ''
      }
    }
  },
  watch: {
    info: function (newInfo, oldInfo) {
      if (newInfo) {
        this.setTitle()
        if (this.facebookPixelContainerID) {
          this.configureFacebookPixel()
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('initializeCurrentTokens')
    this.$store.dispatch('getInfo').then(() => {
      this.initHooks()
      this.setCustomHeadTags()
    })
    this.setTitle()
  },
  methods: {
    setTitle() {
      document.title = this.title
    },
    initHooks() {
      window.bottle = { hooks: { v1: mitt() } }
    },
    setCustomHeadTags() {
      let wrapper = document.createElement('div')
      wrapper.innerHTML = this.info?.attributes?.html_head_content
      const wrapperChildrenAsArray = [].slice.call(wrapper.children)
      wrapperChildrenAsArray.forEach((item) => {
        let newScriptElement = document.createElement(item.nodeName.toLowerCase())
        let existingAttributes = item.getAttributeNames()
        newScriptElement.innerHTML = item.innerHTML
        existingAttributes.forEach((attr) => {
          newScriptElement.setAttribute(attr, item.getAttribute(attr))
        })
        document.head.appendChild(newScriptElement)
      })
    },
    configureFacebookPixel() {
      window.fbq('init', this.facebookPixelContainerID)
    }
  }
}
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
}
// input:focus,
// button:focus,
// textarea:focus {
//   outline: none !important;
//   box-shadow: 0 0 0 0.15rem #025bc4;
//   transition-duration: 0.15s;
// }
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbars-hidden::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE and Edge */
.scrollbars-hidden {
  -ms-overflow-style: none;
}
</style>
