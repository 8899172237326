<template>
  <nav
    v-if="hasBothFulfillmentOptions"
    class="flex bg-gray-brand-200 border border-gray-brand-500 p-1 rounded-lg mb-4"
    :class="showDeliveryFulfillmentOptionsFirst ? 'flex-row-reverse' : 'flex-row'"
  >
    <button
      v-if="hasPickupOptions"
      class="w-full rounded-lg py-2.5 text-sm font-medium leading-5 ring-white/60 ring-offset-2 ring-offset-brand-800 focus:outline-none focus:ring-2"
      :class="
        showPickupFulfillmentMethodsSection
          ? 'bg-white text-brand-800 shadow'
          : 'text-brand-900 hover:bg-black/[0.12] hover:text-black'
      "
      @click="fulfillmentOptionsSection = 'pickup'"
    >
      {{ pickupTabTitle }}
    </button>
    <div class="w-2"></div>
    <button
      v-if="hasDeliveryOptions"
      class="w-full rounded-lg py-2.5 text-sm font-medium leading-5 ring-white/60 ring-offset-2 ring-offset-brand-800 focus:outline-none focus:ring-2"
      :class="
        !showPickupFulfillmentMethodsSection
          ? 'bg-white text-brand-800 shadow'
          : 'text-brand-900 hover:bg-black/[0.12] hover:text-black'
      "
      @click="fulfillmentOptionsSection = 'delivery'"
    >
      {{ deliveryTabTitle }}
    </button>
  </nav>

  <Box :is-loading="isLoading" class="relative mb-4">
    <div v-if="showPickupFulfillmentMethodsSection" class="h-64 w-full rounded-b-md overflow-hidden">
      <PickupMap
        :methods="possiblePickupFulfillmentMethods"
        @clicked-new-method="clickedNewMethod"
        :bottle="bottle"
        @show-loading="showLoading"
      />
    </div>

    <div
      v-fade-in
      v-if="showPickupFulfillmentMethodsSection"
      class="leading-5"
      :class="!showAllPickupOptions && 'max-h-[27rem] overflow-y-auto'"
    >
      <div v-for="(method, i) in possiblePickupFulfillmentMethods" class="border-gray-300 border-b">
        <FulfillmentMethodChoice
          :index="i + 1"
          :key="method.id"
          :method="method"
          :bottle="bottle"
          @clicked-new-method="clickedNewMethod"
          @show-loading="showLoading"
        />
      </div>
    </div>
    <div
      v-if="showPickupFulfillmentMethodsSection && possiblePickupFulfillmentMethods.length > 2 && !showAllPickupOptions"
      class="absolute bottom-0 w-full flex justify-center pb-2"
    >
      <SecondaryButton @click="showAllPickupOptions = true" class="ml-3 mt-3 rounded-full shadow-md" :size="'md'">
        Show all {{ possiblePickupFulfillmentMethods.length }} locations
        <template #right-icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
          </svg>
        </template>
      </SecondaryButton>
    </div>
    <div v-fade-in v-if="!showPickupFulfillmentMethodsSection">
      <div v-show="hasDeliveryAddresses" class="pt-4 pb-2 px-6 max-h-72 overflow-y-auto border-b">
        <fieldset>
          <div class="leading-5">
            <DeliveryAddressChoice
              v-for="deliveryAddress in possibleDeliveryAddresses"
              :key="deliveryAddress.id"
              :delivery-address="deliveryAddress"
              :bottle="bottle"
              @clicked-new-method="clickedNewMethod"
              @show-loading="showLoading"
              @delivery-address-change-clicked="deliveryAddressChangeClicked"
              @delivery-address-action-executed="deliveryAddressActionExecuted"
            />
          </div>
        </fieldset>
      </div>
      <AddDeliveryAddress
        :passed-delivery-address="selectedDeliveryAddressForEdit"
        :force-open="fulfillmentOptionsSection === 'delivery' && !hasDeliveryAddresses"
        :passed-bottle="bottle"
        @delivery-address-edited="deliveryAddressEdited"
        @delivery-address-edit-canceled="deliveryAddressEditCanceled"
      />
    </div>
  </Box>
</template>

<script>
import FulfillmentMethodChoice from '@/components/Checkout/FulfillmentMethodChoice'
import DeliveryAddressChoice from '@/components/Checkout/DeliveryAddressChoice'
import AddDeliveryAddress from '@/components/Checkout/AddDeliveryAddress'
import PickupMap from '@/components/Checkout/PickupMap'
import SecondaryButton from '@/components/Molecules/SecondaryButton.vue'

import Box from '@/components/Molecules/Box'
import mapRelationship from '../../utils/mapRelationship'

export default {
  name: 'FulfillmentSelectionModule',
  components: {
    Box,
    FulfillmentMethodChoice,
    DeliveryAddressChoice,
    AddDeliveryAddress,
    SecondaryButton,
    PickupMap
  },
  props: {
    fulfillmentOptionsSection: {
      type: String,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    passedBottle: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    selectedDeliveryAddressForEdit: undefined,
    showAllPickupOptions: false
  }),
  computed: {
    bottle() {
      if (this.passedBottle && this.isMultibottleFunctionality()) {
        return this.passedBottle
      } else {
        return this.$store.getters.currentBottle
      }
    },
    store() {
      return mapRelationship(this.$store.state, this.bottle?.relationships?.store?.data)
    },
    possibleFulfillmentMethods() {
      // if this.store.attributes.fulfillment_method_order == 'alphabetical', then sort the methods by their .name attribute
      if (this.store?.attributes?.fulfillment_method_order == 'alphabetical') {
        return this.$store.getters
          .fulfillmentMethods(this.bottle?.relationships?.possible_fulfillment_methods?.data)
          .sort((x, y) => x.attributes.name.localeCompare(y.attributes.name))
          .sort((x, y) => y.attributes.eligible - x.attributes.eligible)
        // if this.store.attributes.fulfillment_method_order == 'upcoming-windows', then sort the methods by their first upcoming window
      } else if (this.store?.attributes?.fulfillment_method_order == 'upcoming-windows') {
        return (
          this.$store.getters
            .fulfillmentMethods(this.bottle?.relationships?.possible_fulfillment_methods?.data)
            // open up the fulfillment method and get the first upcoming window to compare
            .sort((x, y) => {
              const xFirstUpcomingWindow = x.attributes.upcoming_windows?.[0]
              const yFirstUpcomingWindow = y.attributes.upcoming_windows?.[0]
              if (xFirstUpcomingWindow && yFirstUpcomingWindow) {
                return xFirstUpcomingWindow.arrives_beginning_estimate.localeCompare(
                  yFirstUpcomingWindow.arrives_beginning_estimate
                )
              } else if (xFirstUpcomingWindow) {
                return -1
              } else if (yFirstUpcomingWindow) {
                return 1
              } else {
                return 0
              }
            })
        )
      } else {
        // if this.store.attributes.fulfillment_method_order == 'order', then sort the methods by their .order attribute
        return this.$store.getters
          .fulfillmentMethods(this.bottle?.relationships?.possible_fulfillment_methods?.data)
          .sort((x, y) => y.attributes.eligible - x.attributes.eligible)
      }
    },
    possiblePickupFulfillmentMethods() {
      return this.possibleFulfillmentMethods?.filter((x) => x?.attributes?.type === 'PickupFulfillmentMethod')
    },
    hasPickupOptions() {
      // return this.possiblePickupFulfillmentMethods?.length > 0
      return this.bottle?.attributes?.possible_fulfillment_method_types?.pickup?.type == 'pickup'
    },
    hasDeliveryOptions() {
      return this.bottle?.attributes?.possible_fulfillment_method_types?.delivery?.type == 'delivery'
    },
    hasBothFulfillmentOptions() {
      return this.hasPickupOptions && this.hasDeliveryOptions
    },
    showDeliveryFulfillmentOptionsFirst() {
      return this.store?.attributes?.show_delivery_fulfillment_options_first
    },
    pickupTabTitle() {
      return this.bottle?.attributes?.possible_fulfillment_method_types?.pickup?.title || 'Pickup'
    },
    deliveryTabTitle() {
      return this.bottle?.attributes?.possible_fulfillment_method_types?.delivery?.title || 'Delivery'
    },
    possibleDeliveryAddresses() {
      return (
        this.$store.getters
          .deliveryAddresses(this.bottle?.relationships?.possible_delivery_addresses?.data)
          // sort eligible first
          .sort((x, y) => y.attributes.eligible - x.attributes.eligible)
      )
    },
    hasDeliveryAddresses() {
      return this.possibleDeliveryAddresses && this.possibleDeliveryAddresses.length > 0
    },
    showPickupFulfillmentMethodsSection() {
      return this.hasPickupOptions && this.fulfillmentOptionsSection == 'pickup'
    }
  },
  methods: {
    deliveryAddressChangeClicked(address) {
      this.selectedDeliveryAddressForEdit = address
      this.$emit('deliveryAddressActionExecuted')
    },
    deliveryAddressActionExecuted() {
      this.$emit('deliveryAddressActionExecuted')
    },
    clickedNewMethod() {
      this.$emit('clickedNewMethod')
    },
    showLoading(value) {
      this.$emit('showLoading', value)
    },
    isMultibottleFunctionality() {
      const currentBottle = this.$store.getters.currentBottle
      return currentBottle && currentBottle.attributes.allow_multiple_days
    },
    deliveryAddressEdited() {
      this.selectedDeliveryAddressForEdit = undefined
      this.$emit('deliveryAddressEditedAndSelected')
    },
    deliveryAddressEditCanceled() {
      this.selectedDeliveryAddressForEdit = false
    }
  }
}
</script>
