<template>
  <Box class="flex justify-between pt-4 pb-4 pl-6 pr-2 w-full">
    <div v-linkified:options="{ defaultProtocol: 'https' }">
      {{ note }}
    </div>
  </Box>
</template>
<script>
import Box from '@/components/Molecules/Box'
export default {
  components: {
    Box
  },
  props: {
    note: {
      type: String,
      required: true,
      default: ''
    }
  }
}
</script>
