import { createRouter, createWebHistory, useLink } from 'vue-router'
import store from '../store'
import Selection from '@/views/Selection.vue'
import Cart from '@/views/Cart.vue'
import Checkout from '@/views/Checkout.vue'
import Login from '@/views/Login.vue'
import NotFound from '@/views/Errors/NotFound.vue'
import ServerError from '@/views/Errors/ServerError.vue'
import Home from '@/views/Home.vue'
import Notice from '@/views/Notice.vue'
import IssuedGiftCard from '@/views/IssuedGiftCard.vue'
import Receipt from '@/views/Receipt.vue'
import Profile from '@/views/Profile.vue'
import Link from '@/views/Link.vue'
import BottleFactory from '@/views/BottleFactory.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import TermsOfService from '@/views/TermsOfService.vue'
import SMSTermsOfService from '@/views/SMSTermsOfService.vue'
import FullPage from '@/views/FullPage.vue'
import config from '@/config'

const routes = [
  {
    path: '/:pathMatch(.*)',
    component: NotFound
  },
  {
    path: '/500',
    component: ServerError
  },
  {
    path: '/',
    component: Home,
    name: 'Home'
  },
  {
    path: '/s/:storeId',
    component: BottleFactory,
    name: 'BottleFactory'
  },
  {
    path: '/login',
    component: Login,
    name: 'NakedLogin'
  },
  {
    path: '/profile',
    component: Profile,
    name: 'Profile'
  },
  {
    path: '/b/:bottleid',
    component: Selection,
    name: 'Selection'
  },
  {
    path: '/b/:bottleid/cart',
    component: Cart,
    name: 'Cart'
  },
  {
    path: '/b/:bottleid/checkout',
    component: Checkout,
    name: 'Checkout'
  },
  {
    path: '/b/:bottleid/login',
    component: Login,
    name: 'Login'
  },
  {
    path: '/b/:bottleid/notice',
    component: Notice,
    name: 'Notice'
  },
  {
    path: '/b/:bottleid/receipt',
    component: Receipt,
    name: 'Receipt'
  },
  {
    path: '/l/:id',
    component: Link,
    name: 'Link'
  },
  {
    path: '/p/:route',
    component: FullPage
  },
  {
    path: '/gc/:code',
    component: IssuedGiftCard,
    name: 'IssuedGiftCard'
  },
  {
    path: '/privacy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-of-service',
    component: TermsOfService
  },
  {
    path: '/sms-terms-of-service',
    component: SMSTermsOfService
  }
]

const router = createRouter({
  history: createWebHistory(config.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  if (store.getters.info && store.getters.info.attributes.facebook_pixel_container_id) {
    window.fbq('track', 'PageView', {
      URL: to.fullPath
    })
  }
  return true
})

export default router
