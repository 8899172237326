<template>
  <div>
    <input
      :id="id"
      :value="id"
      type="radio"
      class="flex-shrink-0 form-radio h-4 w-4 text-brand-600 transition duration-150 ease-in-out cursor-pointer"
      :checked="checked"
      @click="clickedOption(id)"
    />
    <label :for="id" class="ml-3 cursor-pointer text-brand-gray-800" @click="clickedOption(id)">
      <span class="text-md font-semibold">{{ title }}</span>
      <span class="text-sm ml-1 text-brand-gray-600"></span>
      <span class="block text-sm text-brand-gray-600">
        {{ subtitle }}
      </span>
    </label>
  </div>
</template>

<script>
let strftime = require('strftime')

export default {
  name: 'InfoRowPaymentMethod',
  components: {},
  props: ['option', 'selectedOption'],
  data: () => ({}),
  computed: {
    id() {
      return this.option.id
    },
    checked() {
      return this.id == this.selectedOption
    },
    title() {
      const brand = this.option?.attributes?.brand?.toString()
      return '*' + this.option?.attributes.last4?.toString() + ' ' + brand?.charAt(0).toUpperCase() + brand?.slice(1)
    },
    subtitle() {
      return (
        (this.option?.attributes.name ? this.option?.attributes.name + ' ' : '') +
        this.option?.attributes.exp_month +
        '/' +
        this.option?.attributes.exp_year
      )
    }
  },
  methods: {
    clickedOption(value) {
      this.$emit('clickedOption', value)
    }
  }
}
</script>
