<template>
  <label
    class="relative bg-white rounded-md shadow-sm flex items-center justify-center w-24 flex-none h-20 cursor-pointer text-gray-brand-900 hover:bg-gray-brand-100 focus:outline-none border transition-all hover:transform hover:scale-105"
    :class="{ 'ring-2 ring-gray-brand-700 bg-gray-brand-200 text-brand-800': checked }"
  >
    <input
      :value="id"
      type="radio"
      class="sr-only"
      :checked="checked"
      @click="option.can_select && clickedOption(id)"
    />
    <div
      v-if="!option.can_select"
      class="absolute rounded-full flex items-center justify-center text-xs font-semibold uppercase tracking-tighter text-gray-700 leading-3 px-1.5 py-1 text-center"
      :class="{
        'bg-red-500 text-red-800 -mt-2.5 mx-auto top-0 -rotate-1': option.closed,
        'bg-white border border-gray-brand-600 top-0 -mt-2 mx-auto rotate-12 text-center py-0.5 w-[6.5rem]':
          option.hasnt_started_yet,
        'bg-gray-brand-600 text-gray-100 -mt-2.5 mx-auto top-0 -rotate-1': option.has_ended,
        'bg-yellow-400 text-yellow-800 -mt-2.5 mx-auto top-0 -rotate-1': option.sold_out
      }"
    >
      <span v-if="option.closed">Closed</span>
      <span v-else-if="option.hasnt_started_yet">Opens {{ willOpenMonthDayAndTime }}</span>
      <span v-else-if="option.has_ended">Ordering closed</span>
      <span v-else-if="option.sold_out">Sold Out</span>
    </div>
    <div
      class="flex flex-col items-center p-3"
      :class="{
        'text-gray-400': !option.can_select,
        'text-gray-900': option.can_select
      }"
    >
      <span class="block text-xs font-medium text-center">
        {{ fulfillmentSlotDayOfWeek }}
      </span>
      <span class="text-xl font-bold">{{ fulfillmentSlotDayOfMonth }}</span>
      <span class="block text-xs font-medium text-center">{{ fulfillmentSlotMonth }}</span>
    </div>
  </label>
</template>

<script>
import mapRelationship from '../../utils/mapRelationship'
import { serverDayToDateObject } from '@/helpers/dates'

export default {
  name: 'InfoRowSlotDay',
  components: {},
  props: ['option', 'selected'],
  data: () => ({}),
  computed: {
    id() {
      return this.option.fulfillment_slot_id
    },
    fulfillmentDate() {
      return this.option.date
    },
    bottle() {
      return this.$store.getters.currentBottle
    },
    currentFulfillmentSlot() {
      return mapRelationship(this.$store.state, this.bottle.relationships?.fulfillment_slot.data)
    },
    checked() {
      return (
        // strict check with false so that it only applies in the method selection section
        // tbh all of this should be done differently, the separation of responsiblities between all the layers of components is absolutely all over the place
        this.selected !== false &&
        this.option?.date == this.currentFulfillmentSlot?.attributes?.arrives_beginning_estimate_date
      )
    },
    fulfillmentSlotDate() {
      return this.option.date
    },
    fulfillmentSlotDayOfWeek() {
      return new Intl.DateTimeFormat('en-US', {
        weekday: 'long'
      }).format(serverDayToDateObject(this.fulfillmentSlotDate))
    },
    fulfillmentSlotDayOfMonth() {
      return new Intl.DateTimeFormat('en-US', {
        day: 'numeric'
      }).format(serverDayToDateObject(this.fulfillmentSlotDate))
    },
    fulfillmentSlotMonth() {
      return new Intl.DateTimeFormat('en-US', {
        month: 'long'
      }).format(serverDayToDateObject(this.fulfillmentSlotDate))
    },
    willOpenMonthDayAndTime() {
      return new Intl.DateTimeFormat('en-US', {
        timeZone: this.merchantTZ,
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(new Date(this.option.will_open_datetime))
    },
    merchantTZ() {
      return mapRelationship(this.$store.state, this.bottle.relationships.merchant.data)?.attributes.time_zone
    }
  },
  methods: {
    clickedOption(value) {
      this.$emit('clickedOption', value)
    }
  }
}
</script>
