<template>
  <button
    :disabled="!active"
    class="relative rounded-lg flex items-center justify-center border border-brand-600 bg-brand-500 hover:bg-brand-600 outline-none focus:outline-none focus:ring-2 focus:ring-brand-900 font-semibold text-white overflow-hidden"
    :class="{
      'opacity-70 pointer-events-none': isLoading,
      'cursor-pointer opacity-100': active,
      'cursor-not-allowed opacity-50': !active,
      'px-2.5 py-1.5': sm,
      'px-3 py-2': md,
      'px-5 py-2.5': lg,
      'px-6 py-3.5': xl
    }"
  >
    <div v-show="!isLoading" class="flex items-center w-full" :class="justify">
      <slot name="left-icon"></slot>
      <div
        class="inline-block font-semibold items-center tracking-tight whitespace-nowrap"
        :class="{ 'text-xs': sm || md, 'text-sm': lg || xl }"
      >
        <slot>Primary Button</slot>
      </div>
      <slot name="right-icon"></slot>
    </div>
    <BasicSpinner v-show="isLoading" />
    <div v-if="streaking == true" class="streak absolute h-full w-full opacity-0 animate-streak"></div>
  </button>
</template>

<script>
import BasicSpinner from '@/components/Molecules/BasicSpinner'

export default {
  name: 'SecondaryButton',
  components: { BasicSpinner },
  props: {
    size: {
      type: String,
      required: false,
      default: 'md'
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    streaking: {
      type: Boolean,
      required: false,
      default: false
    },
    justify: {
      type: String,
      required: false,
      default: 'justify-between'
    },
    active: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {},
  computed: {
    sm() {
      return this.size === 'sm'
    },
    md() {
      return this.size === 'md'
    },
    lg() {
      return this.size === 'lg'
    },
    xl() {
      return this.size === 'xl'
    }
  }
}
</script>

<style scoped>
@keyframes streak {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  25% {
    transform: translateX(0%);
    opacity: 1;
  }
  75% {
    transform: translateX(100%);
    opacity: 1;
  }
  100% {
    transform: translateX(200%);
    opacity: 0;
  }
}
.animate-streak {
  animation: streak 1.5s infinite linear;
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.3) 25%,
    rgba(255, 255, 255, 0.3) 75%,
    transparent
  );
}
</style>
