<template>
  <div>
    <div v-if="showSelection" class="flex w-full">
      <div class="w-1/5 flex items-center flex-none">
        <div
          class="w-auto mx-auto inline-block text-xxs sm:text-xs uppercase font-manrope font-semibold tracking-widest px-2 py-1 text-brand-800"
        >
          <svg
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5 mx-auto mb-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            ></path>
          </svg>
          Date
        </div>
      </div>
      <div class="w-4/5">
        <div class="overflow-x-auto flex justify-start space-x-2 py-4 pl-2 pr-4">
          <!-- Add more label elements for other dates -->
          <InfoRowSlotDay
            v-for="option in options"
            :key="option.date"
            :option="option"
            :selected-option="selectedOption"
            class="flex items-center"
            :class="{
              'cursor-not-allowed': !option.can_select
            }"
            @clicked-option="clickedOption"
          />
          <label
            v-if="hasOtherOrderingOptionsOutsideBottleCycle"
            class="relative bg-white rounded-md shadow-sm flex items-center justify-center w-24 flex-none h-20 cursor-pointer text-gray-brand-900 hover:bg-gray-brand-100 focus:outline-none border transition-all hover:transform hover:scale-105"
          >
            <input id="new-order" value="new-order" type="radio" class="sr-only" @click="handleNewBottleCreation()" />
            <div class="flex flex-col items-center p-3">
              <span class="block text-xs font-medium text-gray-900 text-center">Change order day</span>
            </div>
          </label>
        </div>
      </div>
    </div>
    <div v-if="showSelection && showTimeRow" class="rounded-md mb-4 mx-4 border divide-y shadow-sm">
      <!-- Add more label elements for other dates -->
      <InfoRowSlotTime
        v-for="option in timeOptions"
        :key="option.id"
        :option="option"
        :selected-option="selectedTimeOption"
        class="flex items-center"
        @clicked-option="clickedOption"
      />
    </div>
    <InfoRow
      v-if="!showSelection"
      icon-slug="date"
      :main-text="fulfillmentSlotDayOfWeek"
      :secondary-text="fulfillmentSlotTimeString"
      layout="receipt"
      :merchant-time-zone="merchantTimeZone"
    />
  </div>
</template>

<script>
let strftime = require('strftime')
import mapRelationship from '../../utils/mapRelationship'
import InfoRowSlotDay from './InfoRowSlotDay.vue'
import InfoRowSlotTime from './InfoRowSlotTime.vue'
import InfoRow from './InfoRow.vue'

export default {
  name: 'DateRow',
  components: {
    InfoRowSlotDay,
    InfoRowSlotTime,
    InfoRow
  },
  props: [
    'mainText',
    'secondaryText',
    'secondaryTextLink',
    'descriptionText',
    'iconSlug',
    'options',
    'timeOptions',
    'selectedOption',
    'selectedTimeOption',
    'selectedOptions',
    'optionsType',
    'layout',
    'placeholder',
    'textFieldValue',
    'merchantTimeZone'
  ],
  data: () => ({
    choose: false,
    open: false
  }),
  computed: {
    bottle() {
      return this.$store.getters.currentBottle
    },
    bottleCycleDetails() {
      return this.bottle?.attributes?.bottle_cycle_details
    },
    hasOtherOrderingOptionsOutsideBottleCycle() {
      return this.bottleCycleDetails?.other_fulfillment_days_available_for_store
    },
    fulfillmentSlot() {
      return this.$store.getters.fulfillmentSlot
    },
    layoutReceipt() {
      return this.layout == 'receipt'
    },
    layoutCheckout() {
      return this.layout == 'checkout'
    },
    showSelection() {
      return this.layoutCheckout && (this.options?.length > 1 || this.timeOptions?.length > 1)
    },
    showTimeRow() {
      return this.methodHasSlotsOverMultipleTimes
    },
    methodHasSlotsOverMultipleTimes() {
      return this.possibleFulfillmentSlotTimes.length > 1
    },
    possibleFulfillmentSlotTimes() {
      return this.$store.getters
        .fulfillmentSlots(this.bottle?.relationships?.possible_fulfillment_slot_times?.data)
        .sort((a, b) => {
          const aDate = new Date(a.attributes.arrives_beginning_estimate)
          const bDate = new Date(b.attributes.arrives_beginning_estimate)
          return aDate - bDate
        })
    },
    fulfillmentSlotDayOfWeek() {
      if (
        !this.fulfillmentSlot?.attributes?.fulfillment_date ||
        !this.fulfillmentSlot?.attributes?.arrives_beginning_estimate
      )
        return null

      return new Intl.DateTimeFormat('en-US', {
        weekday: 'long'
      }).format(new Date(this.fulfillmentSlot?.attributes?.arrives_beginning_estimate))
    },
    fulfillmentSlotDate() {
      if (
        !this.fulfillmentSlot?.attributes?.fulfillment_date ||
        !this.fulfillmentSlot?.attributes?.arrives_beginning_estimate
      )
        return null

      return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).format(new Date(this.fulfillmentSlot?.attributes?.arrives_beginning_estimate))
    },
    fulfillmentSlotTimeString() {
      const startTime = new Date(
        new Date(this.fulfillmentSlot?.attributes?.arrives_beginning_estimate).toLocaleString('en-US', {
          timeZone: this.merchantTZ
        })
      )
      const endTime = new Date(
        new Date(this.fulfillmentSlot?.attributes?.arrives_ending_estimate).toLocaleString('en-US', {
          timeZone: this.merchantTZ
        })
      )
      if (startTime.toString() == endTime.toString()) {
        return (
          strftime('%B %-d at ', startTime) +
          strftime('%-I:%M', startTime) +
          strftime('%p', startTime).toLowerCase() +
          ' ' +
          this.merchantTZAbbreviation
        )
      } else if (startTime.getDate() === endTime.getDate()) {
        return (
          strftime('%B %-d from ', startTime) +
          strftime('%-I:%M', startTime) +
          strftime('%p to ', startTime).toLowerCase() +
          strftime('%-I:%M', endTime) +
          strftime('%p', endTime).toLowerCase() +
          ' ' +
          this.merchantTZAbbreviation
        )
      } else {
        return (
          strftime('%A, %B %-d at ', startTime) +
          strftime('%-I:%M and ', startTime) +
          strftime('%A, %B %-d at ', endTime) +
          strftime('%-I:%M', endTime) +
          ' ' +
          this.merchantTZAbbreviation
        )
      }
    },
    merchantTZAbbreviation() {
      if (!this.merchantAndBrowserTZMatches) {
        return `(${
          new Date().toLocaleTimeString('en-US', { timeZone: this.merchantTZ, timeZoneName: 'short' }).split(' ')[2]
        })`
      } else {
        return ''
      }
    },
    merchantTZ() {
      return mapRelationship(this.$store.state, this.bottle.relationships.merchant.data)?.attributes.time_zone
    },
    merchantAndBrowserTZMatches() {
      return this.merchantTZ === Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    timeVerb() {
      if (
        !this.fulfillmentSlot?.attributes?.fulfillment_date ||
        !this.fulfillmentSlot?.attributes?.arrives_beginning_estimate
      )
        return null
      const beginningDate = new Date(this.fulfillmentSlot?.attributes?.arrives_beginning_estimate)
      const endDate = new Date(this.fulfillmentSlot?.attributes?.arrives_ending_estimate)
      if (beginningDate.toDateString() === endDate.toDateString()) {
        return 'on'
      } else {
        return 'between'
      }
    }
  },
  mounted() {},
  methods: {
    changeClicked() {
      this.$emit('change')
      if (this.hasOptions || this.isEditField) {
        this.choose = true
      }
    },
    clickedOption(value) {
      this.$emit('clickedOption', value)
      this.choose = false
    },
    savedOption(value) {
      this.$emit('savedOption', value)
      this.choose = false
    },
    savedOptions(value) {
      this.$emit('savedOptions', value)
      this.choose = false
    },
    async handleNewBottleCreation() {
      const data = await this.$store.dispatch('getNewBottleId', this.bottle?.relationships?.store?.data?.id)
      await this.$store.dispatch('submitOldCartToNewBottle', data?.id)
      this.$router.push({ name: 'Selection', params: { bottleid: data?.id } })
    }
  }
}
</script>
