<template>
  <Box :is-loading="isLoading">
    <InfoRow
      v-if="showPickupRow"
      icon-slug="pickup"
      :main-text="fulfillmentMethodName"
      :secondary-text="fulfillmentAddressOneLine"
      :secondary-text-link="fulfillmentAddressLink"
      :description-text="fulfillmentMethodDescription"
      :layout="layout"
      :merchant-time-zone="merchantTimeZone"
      @change="changePickupFulfillmentMethod"
    />
    <InfoRow
      v-if="showDeliveryRow"
      icon-slug="delivery"
      :main-text="fulfillmentAddressTopLine"
      :secondary-text="fulfillmentAddressBottomLine"
      :secondary-text-link="fulfillmentAddressLink"
      :description-text="fulfillmentMethodDescription"
      :merchant-time-zone="merchantTimeZone"
      :layout="layout"
      @change="changeAddress"
    />
    <InfoRow
      v-if="showMethodRow"
      icon-slug="method"
      :main-text="fulfillmentMethodName"
      :secondary-text="fulfillmentMethodPrice"
      :options="possibleFulfillmentMethodsForAddress"
      :selected-option="fulfillmentMethod?.id"
      options-type="fulfillment_methods"
      :layout="layout"
      :merchant-time-zone="merchantTimeZone"
      @change="changeDeliveryFulfillmentMethod"
      @clicked-option="selectedNewFulfillmentMethod"
    />
    <DateRow
      v-if="showDateRow"
      :layout="layout"
      :options="filteredFulfillmentSlots"
      :selected-option="fulfillmentSlot?.attributes?.arrives_beginning_estimate"
      :time-options="possibleFulfillmentSlotTimes"
      :selected-time-option="fulfillmentSlot?.id"
      :merchant-time-zone="merchantTimeZone"
      @change="changeFulfillmentSlotDate"
      @clicked-option="selectedNewFulfillmentSlot"
      @time-change="changeFulfillmentSlotTime"
    />
    <div v-if="showBottomNoticeCheckout" class="flex">
      <div class="w-full border-b bg-brand-100 text-brand-800">
        <div
          v-if="fulfillmentSlot?.attributes?.custom_fulfillment_description_unpaid"
          class="leading-5 pt-4 pb-4 px-6 scrolling-touch text-sm text-center"
        >
          {{ fulfillmentSlot?.attributes?.custom_fulfillment_description_unpaid }}
        </div>
        <div v-else class="leading-5 pt-4 pb-4 px-6 scrolling-touch text-sm text-center">
          Order by
          <span class="font-semibold">{{ orderByTime }}&nbsp;</span>
          <span v-if="isPickup">to pick up</span>
          <span v-if="isDelivery">for delivery</span>
          {{ timeVerb }}
          <span class="font-semibold">{{ fulfillmentTime }} {{ merchantTZAbbreviation }}.</span>
        </div>
      </div>
    </div>
    <div v-if="showBottomNoticeReceipt" class="flex">
      <div class="w-full border-b bg-brand-100 text-brand-800">
        <div
          v-if="fulfillmentSlot?.attributes?.custom_fulfillment_description_receipt"
          class="leading-5 pt-4 pb-4 px-6 scrolling-touch text-sm text-center"
        >
          {{ fulfillmentSlot?.attributes?.custom_fulfillment_description_receipt }}
        </div>
        <div v-else class="leading-5 pt-4 pb-4 px-6 scrolling-touch text-sm text-center">
          <span v-if="isPickup">Pick up</span>
          <span v-if="isDelivery">{{ deliveryTitle }}</span>
          on
          <span class="font-semibold">{{ fulfillmentTime }} {{ merchantTZAbbreviation }}.</span>
        </div>
      </div>
    </div>
  </Box>
</template>

<script>
let strftime = require('strftime')
import AddressLine from '@/components/Partials/AddressLine'
import InfoRow from '@/components/Checkout/InfoRow'
import DateRow from '@/components/Checkout/DateRow'
import Box from '@/components/Molecules/Box'
import isInFuture from '@/utils/dateHelper'
import OrderSummarySection from '@/components/Checkout/OrderSummarySection'
import mapRelationship from '@/utils/mapRelationship'

export default {
  name: 'FulfillmentSelectedModule',
  components: {
    AddressLine,
    Box,
    InfoRow,
    DateRow,
    OrderSummarySection
  },
  props: {
    layout: {
      type: String,
      required: true
    },
    passedBottle: {
      type: Object,
      required: false
    }
  },
  data: () => ({}),
  computed: {
    merchantTZAbbreviation() {
      if (!this.merchantAndBrowserTZMatches) {
        return `(${
          new Date().toLocaleTimeString('en-US', { timeZone: this.merchantTZ, timeZoneName: 'short' }).split(' ')[2]
        })`
      } else {
        return ''
      }
    },
    merchantTZ() {
      return mapRelationship(this.$store.state, this.bottle.relationships.merchant.data)?.attributes.time_zone
    },
    merchantAndBrowserTZMatches() {
      return this.merchantTZ === Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    bottle() {
      return this.$store.getters.currentBottle
    },
    store() {
      return mapRelationship(this.$store.state, this.bottle.relationships.store.data)
    },
    merchantTimeZone() {
      return mapRelationship(this.$store.state, this.bottle.relationships.merchant.data)?.attributes.time_zone
    },
    fulfillmentSlot() {
      return this.$store.getters.fulfillmentSlot
    },
    selectNewFulfillmentMethod() {
      return false
    },
    fulfillmentMethod() {
      return this.$store.getters.fulfillmentMethod
    },
    calculatedFulfillmentSlots() {
      return mapRelationship(this.$store.state, this.fulfillmentMethod.relationships.fulfillment_slots_detail.data)
        ?.attributes?.calculated_fulfillment_slots
    },
    filteredFulfillmentSlots() {
      if (this.store?.attributes?.pickup_fulfillment_method_visibility == 'available-only') {
        return this.calculatedFulfillmentSlots?.filter((option) => option.can_select)
      } else {
        return this.calculatedFulfillmentSlots
      }
    },
    possibleFulfillmentMethodsForAddress() {
      return this.$store.getters.fulfillmentMethods(
        this.fulfillmentAddress?.relationships?.eligible_fulfillment_methods?.data
      )
    },
    possibleFulfillmentSlotTimes() {
      return this.$store.getters
        .fulfillmentSlots(this.bottle?.relationships?.possible_fulfillment_slot_times?.data)
        .sort((a, b) => {
          const aDate = new Date(a.attributes.arrives_beginning_estimate)
          const bDate = new Date(b.attributes.arrives_beginning_estimate)
          return aDate - bDate
        })
    },
    possibleFulfillmentSlotDays() {
      return this.$store.getters
        .fulfillmentSlots(this.bottle?.relationships?.possible_fulfillment_slot_days?.data)
        .sort((a, b) => {
          return new Date(a.attributes.arrives_beginning_estimate) - new Date(b.attributes.arrives_beginning_estimate)
        })
    },
    isPickup() {
      return this.fulfillmentMethod?.attributes?.type === 'PickupFulfillmentMethod'
    },
    isDelivery() {
      return (
        this.fulfillmentMethod?.attributes?.type === 'DeliveryFulfillmentMethod' ||
        this.fulfillmentMethod?.attributes?.type === 'ShippingFulfillmentMethod'
      )
    },
    deliveryTitle() {
      const deliveryDate = new Date(this.fulfillmentSlot?.attributes?.arrives_beginning_estimate)
      return isInFuture(deliveryDate) ? 'Delivery' : 'Delivered'
    },
    fulfillmentAddress() {
      return this.$store.getters.deliveryAddress(this.bottle.relationships?.delivery_address?.data?.id)
    },
    fulfillmentAddressTopLine() {
      let temp = []
      if (this.fulfillmentAddress?.attributes?.address1) temp.push(this.fulfillmentAddress?.attributes?.address1)
      if (this.fulfillmentAddress?.attributes?.address2) temp.push(this.fulfillmentAddress?.attributes?.address2)
      return temp.join(', ')
    },
    fulfillmentAddressBottomLine() {
      let temp = []
      if (this.fulfillmentAddress?.attributes?.city) temp.push(this.fulfillmentAddress?.attributes?.city)
      if (this.fulfillmentAddress?.attributes?.state) temp.push(this.fulfillmentAddress?.attributes?.state)
      if (this.fulfillmentAddress?.attributes?.zip) temp.push(this.fulfillmentAddress?.attributes?.zip)
      if (this.fulfillmentAddress?.attributes?.notes)
        '(' + temp.push(this.fulfillmentAddress?.attributes?.notes).toString() + ')'
      return temp.join(', ')
    },
    fulfillmentAddressOneLine() {
      let temp = []
      if (this.fulfillmentAddress?.attributes?.address1) temp.push(this.fulfillmentAddress?.attributes?.address1)
      if (this.fulfillmentAddress?.attributes?.address2) temp.push(this.fulfillmentAddress?.attributes?.address2)
      if (this.fulfillmentAddress?.attributes?.city) temp.push(this.fulfillmentAddress?.attributes?.city)
      if (this.fulfillmentAddress?.attributes?.state) temp.push(this.fulfillmentAddress?.attributes?.state)
      if (this.fulfillmentAddress?.attributes?.zip) temp.push(this.fulfillmentAddress?.attributes?.zip)
      return temp.join(', ')
    },
    fulfillmentAddressLink() {
      return ['http://maps.google.com/?q=', JSON.stringify(this.fulfillmentAddressOneLine)].join('')
    },
    fulfillmentMethodName() {
      return this.fulfillmentMethod?.attributes?.name
    },
    fulfillmentMethodDescription() {
      return this.fulfillmentMethod?.attributes?.description
    },
    fulfillmentMethodPrice() {
      if (this.fulfillmentMethod?.attributes?.price?.cents > 0) {
        return this.fulfillmentMethod?.attributes?.price.format
      } else {
        return 'Free'
      }
    },
    fulfillmentSlotDayOfWeek() {
      if (
        !this.fulfillmentSlot?.attributes?.fulfillment_date ||
        !this.fulfillmentSlot?.attributes?.arrives_beginning_estimate
      )
        return null

      return new Intl.DateTimeFormat('en-US', {
        weekday: 'long'
      }).format(new Date(this.fulfillmentSlot?.attributes?.arrives_beginning_estimate))
    },
    fulfillmentSlotDate() {
      if (
        !this.fulfillmentSlot?.attributes?.fulfillment_date ||
        !this.fulfillmentSlot?.attributes?.arrives_beginning_estimate
      )
        return null

      return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }).format(new Date(this.fulfillmentSlot?.attributes?.arrives_beginning_estimate))
    },
    fulfillmentSlotTimeRange() {
      if (
        !this.fulfillmentSlot?.attributes?.fulfillment_date ||
        !this.fulfillmentSlot?.attributes?.arrives_beginning_estimate
      )
        return null
      const beginningDate = new Date(
        new Date(this.fulfillmentSlot?.attributes?.arrives_beginning_estimate).toLocaleString('en-US', {
          timeZone: this.merchantTZ
        })
      )
      let beginningOfRange = strftime('%-I:%M', beginningDate) + strftime('%p', beginningDate).toLowerCase()
      const endDate = new Date(
        new Date(this.fulfillmentSlot?.attributes?.arrives_ending_estimate).toLocaleString('en-US', {
          timeZone: this.merchantTZ
        })
      )
      let endOfRange = strftime('%-I:%M', endDate) + strftime('%p', endDate).toLowerCase()
      if (beginningOfRange === endOfRange) {
        return beginningOfRange + ` ${this.merchantTZAbbreviation}`
      } else {
        return beginningOfRange + ' to ' + endOfRange + ` ${this.merchantTZAbbreviation}`
      }
    },
    timeVerb() {
      if (
        !this.fulfillmentSlot?.attributes?.fulfillment_date ||
        !this.fulfillmentSlot?.attributes?.arrives_beginning_estimate
      )
        return null
      const beginningDate = new Date(this.fulfillmentSlot?.attributes?.arrives_beginning_estimate)
      const endDate = new Date(this.fulfillmentSlot?.attributes?.arrives_ending_estimate)
      if (beginningDate.toDateString() === endDate.toDateString()) {
        return 'on'
      } else {
        return 'between'
      }
    },
    orderByTime() {
      const date = new Date(
        new Date(this.fulfillmentSlot?.attributes?.display_cutoff).toLocaleString('en-US', {
          timeZone: this.merchantTZ
        })
      )
      return strftime('%A, %B %-d at ', date) + strftime('%-I:%M', date) + strftime('%p', date).toLowerCase()
    },
    fulfillmentTime() {
      const startTime = new Date(
        new Date(this.fulfillmentSlot?.attributes?.arrives_beginning_estimate).toLocaleString('en-US', {
          timeZone: this.merchantTZ
        })
      )
      const endTime = new Date(
        new Date(this.fulfillmentSlot?.attributes?.arrives_ending_estimate).toLocaleString('en-US', {
          timeZone: this.merchantTZ
        })
      )
      if (startTime.toString() == endTime.toString()) {
        return (
          strftime('%A, %B %-d at ', startTime) +
          strftime('%-I:%M', startTime) +
          strftime('%p', startTime).toLowerCase()
        )
      } else if (startTime.getDate() === endTime.getDate()) {
        return (
          strftime('%A, %B %-d from ', startTime) +
          strftime('%-I:%M', startTime) +
          strftime('%p to ', startTime).toLowerCase() +
          strftime('%-I:%M', endTime) +
          strftime('%p', endTime).toLowerCase()
        )
      } else {
        return (
          strftime('%A, %B %-d at ', startTime) +
          strftime('%-I:%M and ', startTime) +
          strftime('%A, %B %-d at ', endTime) +
          strftime('%-I:%M', endTime)
        )
      }
    },
    showPickupRow() {
      return this.isPickup
    },
    showDeliveryRow() {
      return this.isDelivery
    },
    showMethodRow() {
      return this.isDelivery
    },
    showDateRow() {
      return true
    },
    showTimeRow() {
      return this.methodHasSlotsOverMultipleTimes
    },
    methodHasSlotsOverMultipleDays() {
      return true
    },
    methodHasSlotsOverMultipleTimes() {
      return this.possibleFulfillmentSlotTimes.length > 1
    },
    layoutReceipt() {
      return this.layout == 'receipt'
    },
    layoutCheckout() {
      return this.layout == 'checkout'
    },
    showBottomNoticeCheckout() {
      return this.layoutCheckout
    },
    showBottomNoticeReceipt() {
      return this.layoutReceipt
    }
  },
  mounted() {},
  methods: {
    changePickupFulfillmentMethod() {
      this.$emit('selectNewFulfillmentOption', { method: 'selected', bottle: this.passedBottle })
    },
    changeAddress() {
      this.$emit('selectNewFulfillmentOption', { method: 'address', bottle: this.passedBottle })
    },
    changeDeliveryFulfillmentMethod() {
      // this.$emit('selectNewFulfillmentOption', 'pickup')
    },
    changeFulfillmentSlotDate() {},
    changeFulfillmentSlotTime() {},
    selectedNewFulfillmentSlot(newSlotID) {
      this.$emit('showLoading', true)
      this.$store.dispatch('selectFulfillmentSlot', { newSlotId: newSlotID, bottleId: this.bottle.id }).then(
        (response) => {
          // properly updated
          this.$emit('showLoading', false)
        },
        (error) => {
          // failed to update
          this.$emit('showLoading', false)
        }
      )
    },
    selectedNewFulfillmentMethod(newMethodID) {
      this.$emit('showLoading', true)
      this.$store.dispatch('selectFulfillmentMethod', { methodId: newMethodID, bottle: this.bottle }).then(
        (response) => {
          // properly updated
          this.$emit('showLoading', false)
        },
        (error) => {
          // failed to update
          this.$emit('showLoading', false)
        }
      )
    },
    showLoading(value) {
      this.$emit('showLoading', value)
    }
  }
}
</script>
