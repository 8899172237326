<template>
  <div v-if="module.attributes.visible" class="max-w-4xl m-auto p-4">
    <div
      v-if="module.attributes.type === 'HeroPageModule'"
      class="min-h-400 sm:min-h-800 relative w-full flex items-center justify-center z-0 sm:mb-10 mb-6"
    >
      <div class="z-10 w-full mx-auto lg:px-0 sm:px-5 px-10 mt-20 sm:mt-0 mb-20 max-w-5xl">
        <header
          v-if="getText(module)"
          class="text-shadow-solid mx-auto sm:mx-0 text-white leading-10 font-extrabold tracking-tight text-5xl sm:text-8xl sm:mt-56 mt-10"
        >
          <!-- &#8203; is a zero width space, it makes sure this element doesn't change size even without text -->
          &#8203;{{ getText(module).attributes.primary_text }}
        </header>

        <button
          v-if="getLink(module).attributes.url"
          class="relative rounded-lg bg-white p-4 font-semibold sm:font-medium transition-colors duration-150 border border-black shadow-black shadow-solid-xs flex-grow sm:flex-none transition-all duration-150 before:rounded-l-md before:absolute before:left-0 before:top-0 before:h-full before:bg-brand-500 before:w-4 before:z-0 before:duration-300 before:transition-all before:z-0 before:hover:w-full before:hover:rounded-r-md hover:text-white w-full sm:w-fit mt-16"
        >
          <a :href="getLink(module).attributes.url">
            <div class="ml-2 sm:ml-4 h-5 sm:h-8 text-lg sm:text-xl flex items-center relative justify-center">
              {{ getLink(module).attributes.primary_text }}
              <ArrowRightIcon class="ml-2 sm:ml-4 h-4 sm:h-6" />
            </div>
          </a>
        </button>
      </div>
      <div v-if="getAttachment(module)" class="absolute top-0 left-0 w-full h-full">
        <img
          :src="
            getAttachment(module).attributes.high_res_url
              ? getAttachment(module).attributes.high_res_url
              : getAttachment(module).attributes.secure_url
          "
          class="w-full h-full object-center object-cover bg-white"
        />
      </div>
    </div>

    <div v-if="module.attributes.type === 'FormPageModule'" class="">
      <div class="max-w-lg lg:max-w-xl mx-auto">
        <FormSignup v-fade-in :form="getForm(module)" />
      </div>
    </div>

    <div v-if="module.attributes.type === 'StoreLinksPageModule'">
      <StoreList />
    </div>

    <div v-if="module.attributes.type === 'LinkSectionPageModule'" class="">
      <ul class="flex flex-col items-center space-y-3">
        <li v-fade-in v-for="link in getLinks(module)" class="block max-w-lg lg:max-w-xl w-full">
          <a :href="link.attributes.url">
            <button
              class="relative bg-white py-4 px-6 font-bold transition-colors duration-150 rounded-full border-black border shadow-black shadow-solid-xs hover:bg-brand-500 hover:text-white w-full"
            >
              {{ link.attributes.primary_text }}
            </button>
          </a>
        </li>
      </ul>
    </div>

    <div
      v-fade-in
      v-if="module.attributes.type === 'TextContentPageModule'"
      class="mx-auto max-w-lg lg:max-w-xl space-y-3"
    >
      <div
        v-if="getText(module)?.attributes.primary_text"
        class="prose prose-headings:text-gray-brand-900 prose-headings:tracking-tight"
      >
        <h1>{{ getText(module)?.attributes.primary_text }}</h1>
      </div>
      <article
        v-if="getText(module)?.attributes.secondary_text_display"
        class="prose"
        v-html="getText(module)?.attributes.secondary_text_display"
      />
    </div>

    <div v-fade-in v-if="module.attributes.type === 'MediaPageModule' && getAttachment(module)">
      <div class="relative rounded-md overflow-hidden mx-auto">
        <img
          loading="lazy"
          class="object-cover mx-auto"
          :src="getAttachment(module)?.attributes.secure_url"
          :alt="getAttachment(module)?.attributes.title"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { ArrowRightIcon } from '@heroicons/vue/solid'
import mapRelationship from '@/utils/mapRelationship'
import mapRelationships from '@/utils/mapRelationships'
import { useStore } from 'vuex'
import FormSignup from '@/components/Merchant/FormSignup'
import StoreList from '@/components/Merchant/StoreList'

export default {
  name: 'PageModulePartial',
  components: { ArrowRightIcon, FormSignup, StoreList },
  props: {
    module: {
      type: Object,
      required: true
    }
  },
  setup() {
    const store = useStore()

    const getElement = (module, elementType) => {
      const elements = mapRelationships(store.state, module.relationships.content_elements.data)
      return elements.find((x) => x.attributes.type === elementType)
    }

    const getElements = (module, elementType) => {
      const elements = mapRelationships(store.state, module.relationships.content_elements.data)
      return elements.filter((x) => x.attributes.type === elementType)
    }

    const getText = (module) => getElement(module, 'TextContentElement')

    const getLink = (module) => getElement(module, 'LinkContentElement')

    const getPageLink = (module) => getElement(module, 'PageLinkContentElement')

    const getLinks = (module) => getElements(module, 'LinkContentElement')

    const getAttachment = (module) => {
      const element = getElement(module, 'AttachmentContentElement')
      const attachment = mapRelationship(store.state, element?.relationships.attachments?.data?.at(-1))
      return attachment
    }

    const getForm = (module) => mapRelationship(store.state, module.relationships.form?.data)

    return {
      getElement,
      getElements,
      getText,
      getLink,
      getPageLink,
      getLinks,
      getAttachment,
      getForm
    }
  }
}
</script>
