<template>
  <div class="pb-16">
    <div class="z-20 w-full fixed top-0">
      <MerchantNav :disable-hide-on-scroll="true" />
    </div>

    <div v-if="!rootModules.some((x) => x.attributes.type === 'HeroPageModule' && x.attributes.visible)" class="h-24">
      <!-- spacer -->
    </div>
    <template v-if="rootModules.length" class="gap-y-4">
      <div v-for="(mod, i) in rootModules">
        <PageModulePartial :module="mod" />
      </div>
    </template>
    <StoreList
      v-if="!rootModules.some((x) => x.attributes.type === 'StoreLinksPageModule' && x.attributes.visible)"
      class="px-4"
    />
  </div>
</template>
<script>
import { MerchantNav } from '@/components'
import mapRelationship from '../utils/mapRelationship'
import mapRelationships from '../utils/mapRelationships'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import StoreList from '@/components/Merchant/StoreList.vue'
import PageModulePartial from '@/components/Merchant/PageModulePartial.vue'

export default {
  components: { MerchantNav, StoreList, PageModulePartial },
  setup() {
    const store = useStore()
    const router = useRouter()

    const rootPage = computed(() => store.getters.rootPage)

    const rootModules = computed(() =>
      mapRelationships(store.state, rootPage.value?.relationships.page_modules.data).sort(
        (a, b) => a.attributes.order - b.attributes.order
      )
    )

    onMounted(() => {
      window.scrollTo(0, 0)
      store.dispatch('getPages')
    })

    return {
      rootPage,
      rootModules
    }
  }
}
</script>
<style>
.linkified {
  @apply text-blue-500;
}
</style>
