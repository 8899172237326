<template>
  <div class="z-20 w-full sticky top-0">
    <MerchantNav />
  </div>
  <div v-for="mod in modules">
    <PageModulePartial :module="mod" />
  </div>
</template>
<script>
import { MerchantNav } from '@/components'
import mapRelationship from '../utils/mapRelationship'
import mapRelationships from '../utils/mapRelationships'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import PageModulePartial from '../components/Merchant/PageModulePartial.vue'

export default {
  components: { MerchantNav, PageModulePartial },
  setup() {
    const store = useStore()
    const route = useRoute()

    const currentPage = computed(() => store.getters.page('/' + route.params.route))

    const modules = computed(() =>
      mapRelationships(store.state, currentPage.value?.relationships.page_modules.data).sort((a, b) => {
        a.attributes.order - b.attributes.order
      })
    )

    onMounted(() => {
      window.scrollTo(0, 0)
      store.dispatch('getPages')
    })

    return {
      modules
    }
  }
}
</script>
