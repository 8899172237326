export default {
  beforeMount(el) {
    el.style.opacity = '0'
    el.style.transition = 'opacity 700ms'
  },
  mounted(el) {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          el.style.opacity = '1'
          observer.disconnect()
        }
      },
      { threshold: 0.1 }
    )

    observer.observe(el)
  }
}
