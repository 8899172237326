<template>
  <div class="flex flex-col gap-4" :class="hasGroups && 'my-4'">
    <div v-for="(items, group) in groupedCartItems" :class="hasGroups && 'border rounded-lg mx-2'">
      <div class="text-sm px-2 py-1 rounded-t-lg border-b bg-gray-50 mb-1" v-if="hasGroups">
        Items for
        <span class="font-bold">
          {{
            new Intl.DateTimeFormat('en-US', {
              month: 'long',
              day: 'numeric',
              weekday: 'long'
            }).format(serverDayToDateObject(group))
          }}
        </span>
      </div>
      <ul class="divide-y divide-gray-200">
        <li v-for="item in items" :key="`cart-item-${item.id}`">
          <div class="p-4">
            <div
              class="min-w-0 flex-1 flex"
              :class="item.attributes.variant_options_selection?.length > 0 ? 'items-start' : 'items-center'"
            >
              <div class="min-w-0 flex-1">
                <div>
                  <div
                    :class="
                      item.attributes.variant_options_selection?.length > 0 ? 'flex items-start' : 'flex items-center'
                    "
                  >
                    <span
                      :class="[
                        'inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium  mr-2',
                        useNeutralTheme ? 'bg-gray-brand-300 text-brand-900' : 'bg-brand-500 text-brand-100'
                      ]"
                    >
                      {{ quantity(item) }}
                    </span>
                    <p
                      class="text-sm font-medium text-brand-gray-800 max-w-sm text-left cursor-pointer"
                      @click="goToCart()"
                    >
                      <span
                        v-if="!isItemAvailable(item) && !bottleIsPaid"
                        class="bg-yellow-100 border border-yellow-700 text-yellow-700 text-xxs font-manrope uppercase px-1.5 py-0.5 rounded-lg tracking-widest"
                      >
                        Unavailable
                      </span>
                      <br v-if="!isItemAvailable(item) && !bottleIsPaid" />
                      <span class="font-medium">
                        {{ productAttributes(item)?.product_name }}
                      </span>
                      <br v-if="item.attributes.variant_options_selection?.length > 0" />
                      <template
                        v-for="(variantOptionSelection, variantIndex) in item.attributes.variant_options_selection"
                        :key="variantIndex"
                      >
                        <span class="font-light text-xs">
                          {{
                            variantDescription(
                              variantOptionSelection,
                              variantIndex,
                              item.attributes.variant_options_selection.length
                            )
                          }}
                        </span>
                        <span v-if="(variantOptionSelection.quantity || 0) > 1" class="font-light text-xs">
                          x {{ variantOptionSelection.quantity }}
                        </span>
                        <br />
                      </template>
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="showPrice(item)" class="ml-4">
                <div class="text-xs text-right items-start">
                  <span class="font-medium">
                    {{ item.attributes.total_price.format }}
                  </span>
                  <br />
                  <span class="font-light">
                    <span
                      v-if="
                        item.attributes.default_price_plus_variants_price.cents >
                        item.attributes.net_price_plus_net_variants_price.cents
                      "
                    >
                      <s>{{ item.attributes.default_price_plus_variants_price.format }}</s>
                    </span>
                    {{ item.attributes.net_price_plus_net_variants_price.format }}
                    ({{ item.attributes.quantity }})
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- <li>for an extra divider</li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { serverDayToDateObject } from '@/helpers/dates'

export default {
  props: {
    bottle: {
      type: Object
    },
    cartItems: {
      type: Array
    }
  },
  computed: {
    groupedCartItems() {
      const slotDay = this.$store.getters.fulfillmentSlot?.attributes.fulfillment_date
      let items = this.cartItems.map(
        (x) => (x.attributes.fulfillment_group_date = x.attributes.fulfillment_group_date ?? slotDay)
      )
      items = _.sortBy(this.cartItems, 'attributes.fulfillment_group_date')
      return _.groupBy(items, 'attributes.fulfillment_group_date')
    },
    unavailableItems() {
      return this.bottle?.relationships?.removed_category_product_store_pairs_still_in_cart?.data
    },
    bottleIsPaid() {
      return this.bottle?.attributes?.payment_status === 'paid'
    },
    hasGroups() {
      return Object.keys(this.groupedCartItems).length > 1
    }
  },
  methods: {
    quantity(item) {
      return item.attributes.quantity
    },
    isItemAvailable(item) {
      // Check if the unavailableItems array contains the given item
      const unavailableItem = this.unavailableItems?.find(
        (unavailable) => unavailable.id.toString() === item.attributes.category_product_store_pair_id.toString()
      )
      // If the item is not found in the unavailableItems array, it is available
      return !unavailableItem
    },
    productAttributes(item) {
      return this.$store.state.objects.product?.[item?.relationships?.product?.data?.id]?.attributes
    },
    variantDescription(variantOptionSelection, i, count) {
      return variantOptionSelection?.variant_option_name + this.variantSecondLevelDescription(variantOptionSelection)
    },
    variantSecondLevelDescription(variantOptionSelection) {
      return variantOptionSelection?.variant_option_description
        ? ' (' + variantOptionSelection?.variant_option_description + ')'
        : ''
    },
    showPrice(item) {
      return (
        this.$store.state.objects.category_product_store_pair?.[item?.attributes?.category_product_store_pair_id]
          ?.attributes?.show_prices || item.attributes.type === 'CustomCartItem'
      )
    },
    goToCart() {
      this.$router.push({ name: 'Selection', params: { bottleid: this.bottle?.id }, query: { showCart: 'y' } })
    },
    serverDayToDateObject
  }
}
</script>
