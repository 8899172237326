<template>
  <div v-if="data.tipModalText" class="flex">
    <div class="w-full border-t bg-brand-100 text-brand-900">
      <div
        ref="notice"
        v-linkified:options="{ defaultProtocol: 'https' }"
        class="leading-5 pt-4 pb-4 px-6 scrolling-touch text-sm text-center whitespace-pre-line"
      >
        {{ data.tipModalText }}
      </div>
    </div>
  </div>
  <div v-if="data.possibleTipAmounts.length > 0" class="flex p-3">
    <span class="isolate flex justify-between rounded-md shadow-sm w-full">
      <template v-for="tip in data.possibleTipAmounts" :key="tip.id">
        <button
          type="button"
          class="relative inline-flex flex-col justify-center items-center text-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 sm:hover:bg-gray-50 focus:z-10 flex-grow flex-shrink flex-1"
          :class="{
            'rounded-l-md': isFirstTip(tip),
            '-ml-px': !isFirstTip(tip),
            'bg-white': !tipPercentageIdSelected(tip),
            'bg-gray-brand-200': tipPercentageIdSelected(tip)
          }"
          @click="onSelectTipPercentage(tip)"
        >
          <BasicSpinner v-if="data.spinning == tip.id" color="gray-400" class="flex justify-center" />
          <span v-else>
            <span v-if="tip.attributes?.type == 'PercentageTipAmount'">
              {{ formatPercentage(tip.attributes?.tip_percentage) }}%
            </span>
            <span v-else-if="tip.attributes?.type == 'CustomTipAmount'">
              {{ tip.attributes?.tip_amount?.format }}
            </span>
            <span v-if="tip.attributes?.type == 'PercentageTipAmount'" class="helper-text text-xs block font-normal">
              {{ tip.attributes?.tip_amount?.format }} Tip
            </span>
            <span v-else-if="tip.attributes?.type == 'CustomTipAmount'" class="helper-text text-xs block font-normal">
              Tip
            </span>
          </span>
        </button>
      </template>
      <button
        type="button"
        class="relative -ml-px inline-flex flex-col justify-center items-center text-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 sm:hover:bg-gray-50 focus:z-10 flex-grow flex-shrink flex-1"
        :class="{ 'bg-gray-brand-200': data.customTipSelected }"
        @click="onAddCustomTipAmount()"
      >
        <BasicSpinner v-if="data.spinning == 'custom'" color="gray-400" class="flex justify-center" />
        <span v-else>
          <span class="font-semibold text-md">
            {{ customTipButtonContent.name }}
          </span>
          <span v-show="customTipButtonContent.info" class="text-xs block">
            {{ customTipButtonContent.info }}
          </span>
        </span>
      </button>
    </span>
  </div>
  <div
    v-if="local.visibility.showCustomTipInput || data.possibleTipAmounts.length == 0"
    class="px-3"
    :class="{ 'pb-6': data.possibleTipAmounts.length > 0, 'py-3': data.possibleTipAmounts.length == 0 }"
  >
    <form class="sm:flex sm:items-center" @submit.prevent="onSubmitCustomTipAmount()">
      <div class="w-full">
        <label for="custom-tip-amount" class="sr-only">{{ tipAdded ? 'Update Tip' : 'Custom Tip' }}</label>
        <div class="relative rounded-md">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span class="text-gray-500">$</span>
          </div>
          <input
            id="custom-tip-amount"
            ref="tip"
            v-model="local.content.selectedTip"
            type="number"
            inputmode="decimal"
            pattern="\d*"
            step="0.01"
            name="tip-amount-dollars"
            placeholder="Tip amount"
            class="block w-full pl-7 pr-3 py-2.5 border border-b-0 sm:border-b sm:border-r-0 border-gray-300 shadow-sm rounded-t-md rounded-b-none sm:rounded-b-md sm:rounded-r-none text-gray-brand-900 md:hover:bg-gray-brand-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-600"
          />
        </div>
      </div>
      <BasicButton
        type="submit"
        class="mt-0 sm:mt-0 sm:w-auto rounded-b-md rounded-t-none sm:rounded-t-md sm:rounded-l-none w-full inline-flex flex-none items-center justify-center px-4 py-2 sm:py-3 border border-gray-300 shadow-sm text-gray-brand-900 md:hover:bg-gray-brand-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-600 font-semibold text-sm tracking-tight"
        @click="onSubmitCustomTipAmount()"
      >
        <span v-if="data.possibleTipAmounts.length == 0">Apply Tip</span>
        <span v-else>Apply</span>
      </BasicButton>
    </form>
  </div>
</template>
<script>
import BasicButton from '@/components/Molecules/BasicButton'
import BasicSpinner from '@/components/Molecules/BasicSpinner'
import mapRelationships from '@/utils/mapRelationships'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import mapRelationship from '@/utils/mapRelationship'

export default defineComponent({
  components: {
    BasicButton,
    BasicSpinner
  },
  props: {
    checkout: {
      type: Object,
      required: true
    },
    bottle: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore()

    const data = ref({
      possibleTipAmounts: computed(() => {
        return mapRelationships(store.state, props.bottle?.relationships?.possible_tip_amounts?.data)
      }),
      customTipSelected: computed(
        () =>
          data.value.possibleTipAmounts.length == 0 ||
          (data.value.possibleTipAmounts.filter((tip) => tip.id == props.bottle?.relationships?.tip_amount?.data?.id)
            .length == 0 &&
            props.bottle?.relationships?.tip_amount?.data?.id)
      ),
      tipModalText: computed(() => {
        return mapRelationship(store.state, props.bottle?.relationships?.store?.data)?.attributes?.tip_modal_text
      }),
      spinning: 'none'
    })

    const local = ref({
      status: {
        tip: {
          loading: false,
          error: false
        }
      },
      content: {},
      visibility: {
        showCustomTipInput: false
      }
    })

    onMounted(() => {
      local.value.content.selectedTip = props.checkout?.attributes?.tip_amount?.cents
        ? props.checkout?.attributes?.tip_amount?.cents / 100.0
        : null
    })

    const formatPercentage = (value) => Math.round(value)

    const tipPercentageIdSelected = (tip) => props.bottle?.relationships?.tip_amount?.data?.id == tip?.id

    const onSelectTipPercentage = (tip) => {
      if (tipPercentageIdSelected(tip)) {
        local.value.content.selectedType = 'percent'
        local.value.content.selectedTip = null
        local.value.status.tip.loading = true
        local.value.visibility.showCustomTipInput = false
        data.value.spinning = tip.id
        store
          .dispatch('setTipAmount', null)
          .catch(() => {
            local.value.status.tip.error = false
            data.value.spinning == 'none'
          })
          .then(() => {
            local.value.status.tip.loading = false
            data.value.spinning = 'none'
          })
      } else {
        local.value.content.selectedType = 'percent'
        local.value.content.selectedTip = tip
        local.value.status.tip.loading = true
        local.value.visibility.showCustomTipInput = false
        data.value.spinning = tip.id
        store
          .dispatch('setTipAmount', tip?.id)
          .catch(() => {
            local.value.status.tip.error = false
            data.value.spinning == 'none'
          })
          .then(() => {
            local.value.status.tip.loading = false
            data.value.spinning = 'none'
          })
      }
    }

    const onAddCustomTipAmount = () => {
      local.value.content.selectedType = 'custom'
      local.value.visibility.showCustomTipInput = true
      local.value.status.tip.loading = true
      local.value.content.selectedTip = (data.value.possibleTipAmounts?.[0]?.attributes?.tip_amount?.cents || 0) / 100.0
      data.value.spinning = 'custom'
      store
        .dispatch('setCustomTipAmount', data.value.possibleTipAmounts?.[0]?.attributes?.tip_amount?.cents)
        .catch(() => {
          local.value.status.tip.error = false
          data.value.spinning == 'none'
        })
        .then(() => {
          local.value.status.tip.loading = false
          data.value.spinning = 'none'
        })
    }

    const onSubmitCustomTipAmount = () => {
      local.value.content.selectedType = 'custom'
      local.value.status.tip.loading = true
      data.value.spinning = 'custom'
      const customAmountCents = Math.max(Math.round(local.value.content.selectedTip * 100.0), 0)
      store
        .dispatch('setCustomTipAmount', customAmountCents)
        .then(() => {
          local.value.visibility.showCustomTipInput = false
          local.value.status.tip.loading = false
          local.value.content = {}
          data.value.spinning = 'none'
        })
        .catch(() => {
          local.value.status.tip.error = false
          data.value.spinning = 'none'
        })
    }
    const customTipButtonContent = computed(() => {
      if (data.value.customTipSelected && local.value.content?.selectedTip?.attributes?.type !== 'PercentageTipAmount')
        if (props.checkout?.attributes?.tip_amount?.cents) {
          return {
            name: `${props.checkout?.attributes?.tip_amount?.format} Tip`,
            info: 'Edit'
          }
        } else {
          return {
            name: 'Custom Tip',
            info: ''
          }
        }
      else {
        return {
          name: 'Custom Tip',
          info: ''
        }
      }
    })

    const tipAdded = () => {
      if (props.checkout.attributes.tip_amount.cents != 0) return true
      else return false
    }

    const isFirstTip = (tip) => {
      return data.value.possibleTipAmounts.indexOf(tip) === 0
    }
    const isLastTip = (tip) => {
      return data.value.possibleTipAmounts.indexOf(tip) === data.value.possibleTipAmounts.length - 1
    }

    return {
      data,
      local,
      formatPercentage,
      tipPercentageIdSelected,
      onSelectTipPercentage,
      onAddCustomTipAmount,
      onSubmitCustomTipAmount,
      customTipButtonContent,
      tipAdded,
      isFirstTip,
      isLastTip
    }
  }
})
</script>
