<template>
  <div>
    <div v-if="brandedActionType" class="p-4 pt-0 mt-1">
      <PrimaryButton
        @click="savedOptions"
        class="w-full"
        :class="{
          'cursor-pointer opacity-100': canProceed,
          'cursor-not-allowed opacity-50': !canProceed
        }"
        :size="'xl'"
        :streaking="canProceed"
        :justify="'justify-center'"
      >
        {{ actionText }}
      </PrimaryButton>
    </div>
    <div v-if="showOptionalButton" class="p-4 pt-0 -mt-1">
      <SecondaryButton v-if="brandedActionType" @click="skipQuestion" class="w-full" :size="'xl'">
        Skip Question
      </SecondaryButton>
    </div>
    <div v-if="showCancelButton" class="p-4 pt-0 -mt-1">
      <SecondaryButton v-if="brandedActionType" @click="cancelAnswer" class="w-full" :size="'xl'">
        Cancel
      </SecondaryButton>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '@/components/Molecules/PrimaryButton'
import SecondaryButton from '@/components/Molecules/SecondaryButton'
export default {
  name: 'InfoRowActionButtons',
  components: { PrimaryButton, SecondaryButton },
  props: ['options', 'selectedOptions', 'actionText', 'actionType', 'optional', 'mode', 'canProceed'],
  data: () => ({
    optionsArray: []
  }),
  computed: {
    showOptionalButton() {
      return this.optional && !this.inlineMode
    },
    showCancelButton() {
      return this.inlineMode
    },
    inlineMode() {
      return this.mode == 'inline'
    },
    brandedActionType() {
      return this.actionType == 'branded'
    } // ,
    // canProceed() {
    //   return true
    // }
  },
  mounted() {
    this.optionsArray = this.selectedOptions
  },
  methods: {
    savedOptions() {
      if (this.canProceed) {
        this.$emit('savedOptions', this.optionsArray)
      }
    },
    displayFor(option) {
      if (option?.type == 'label') return option.attributes?.label_filter_text
      if (option?.type == 'question') return option.title
      return ''
    },
    descriptionFor(option) {
      if (option?.type == 'question') return option.description
      return null
    },
    selected(option) {
      return this.optionsArray?.filter((x) => x?.id == option.id).length > 0
    },
    flipSelection(option) {
      if (this.selected(option)) {
        this.optionsArray = this.optionsArray?.filter((x) => x?.id != option.id)
      } else {
        this.optionsArray.push({ type: option.type, id: option.id, title: option.title })
      }
    },
    skipQuestion() {
      this.$emit('skipQuestion')
    },
    cancelAnswer() {
      this.$emit('cancelAnswer')
    }
  }
}
</script>
