<template>
  <div class="sm:col-span-9 sm:col-start-2 lg:col-span-7 lg:col-start-3 xl:col-span-5 xl:col-start-4">
    <div class="px-2 mb-8">
      <div class="mb-4 sm:mb-6">
        <div class="flex justify-start items-center">
          <div
            class="text-xl bg-gray-brand-300 font-bold text-gray-brand-900 w-10 h-10 p-1 flex justify-center items-center rounded-lg"
          >
            <CheckIcon v-if="confirmed" class="w-5 h-5" />
            <BellIcon v-else class="w-5 h-5" />
          </div>
          <div v-if="confirmed" class="text-2xl font-bold text-gray-brand-900 tracking-tighter ml-3">
            {{ form?.attributes?.success_title || 'Signed up!' }}
          </div>
          <div v-else class="text-2xl font-bold text-gray-brand-900 tracking-tighter ml-3">
            {{ form?.attributes?.title || 'Sign up for news and alerts' }}
          </div>
        </div>
        <div v-if="confirmed && form?.attributes?.success_description" class="flex justify-start items-center mt-4">
          <div class="text-gray-brand-900 tracking-tighter">
            {{ form?.attributes?.success_description }}
          </div>
        </div>
        <div v-else-if="!confirmed && form?.attributes?.description" class="flex justify-start items-center mt-4">
          <div class="text-gray-brand-900 tracking-tighter">
            {{ form?.attributes?.description }}
          </div>
        </div>
      </div>
      <div
        v-if="!requestedToken && !confirmed"
        class="rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600 overflow-hidden"
      >
        <div class="bg-white rounded-md overflow-hidden">
          <div class="p-4 pb-0">
            <fieldset>
              <label for="phone" class="pb-0.5 block">
                <div class="leading-5 form-inputblock mt-1 text-sm text-gray-brand-700 tracking-tight font-semibold">
                  Mobile number
                </div>
              </label>
              <div class="block">
                <input
                  id="phone"
                  model="tel"
                  type="tel"
                  data-int-tel
                  class="form-input shadow-sm w-full p-2 rounded-md"
                  @keyup="checkTelValidity"
                  @keyup.enter="signIn"
                />
              </div>
            </fieldset>
          </div>
          <div class="p-4 py-4 mt-1">
            <PrimaryButton
              @click="signIn"
              class="w-full"
              :size="'xl'"
              :justify="'justify-center'"
              :active="canSubmitFirstStep"
              :streaking="enableSignInButton"
              :is-loading="numberPending"
            >
              Join our list
              <template #right-icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="ml-1 w-4 h-4 fill-current"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                </svg>
              </template>
            </PrimaryButton>
          </div>
        </div>
        <div v-if="form?.attributes?.modal_text" class="flex">
          <div class="w-full border-t bg-brand-100 text-brand-900">
            <div
              ref="notice"
              v-linkified:options="{ defaultProtocol: 'https' }"
              class="leading-5 pt-4 pb-4 px-6 scrolling-touch text-sm text-center whitespace-pre-line"
            >
              {{ form?.attributes?.modal_text }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="requestedToken && !confirmed"
        class="rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600"
      >
        <div class="mt-2 bg-white rounded-md overflow-hidden">
          <div class="p-4 pb-1">
            <fieldset>
              <label for="token" class="mb-2 block">
                <div class="leading-5 form-inputblock mt-1 text-sm text-gray-brand-700 tracking-tight font-semibold">
                  To confirm you want to be on the list, enter the token we just texted you.
                </div>
              </label>
              <div class="block">
                <input
                  id="token"
                  ref="tokenInputField"
                  v-model="tokenCode"
                  type="numeric"
                  pattern="[0-9]*"
                  autocomplete="one-time-code"
                  class="form-input shadow-sm w-full p-2 rounded-md"
                  placeholder="00000"
                  @keyup.enter="proceed"
                />
              </div>
            </fieldset>
          </div>
          <div v-if="showTokenVerificationError" class="px-4 py-1 w-full">
            <div class="text-brand-900 font-medium text-sm text-center w-full">
              The token you submitted isn't valid.
            </div>
          </div>
          <div class="p-4 pt-0">
            <PrimaryButton
              @click="proceed"
              class="w-full mt-2"
              :size="'xl'"
              :justify="'justify-center'"
              :active="enableCheckoutButton && tokenCodeValid"
              :streaking="enableCheckoutButton && tokenCodeValid"
              :is-loading="tokenPending"
            >
              Confirm number
              <template #right-icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-1 w-5 h-5 fill-current"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                </svg>
              </template>
            </PrimaryButton>

            <SecondaryButton
              @click="signIn"
              class="w-full mt-3"
              :size="'xl'"
              :isLoading="numberPending"
              :active="canSubmitFirstStep"
            >
              Didn't receive the token?
              <span class="font-bold">Resend code</span>
            </SecondaryButton>
          </div>
        </div>
      </div>
      <div v-if="!confirmed" class="flex">
        <div class="w-full text-brand-800">
          <div class="leading-4 sm:leading-5 pt-4 px-5 scrolling-touch text-xs text-left">
            By signing up, you give @{{ merchantHandle }} permission to text and email you. You agree to receive
            recurring automated marketing text messages at the phone number provided. Consent is not a condition to
            purchase. Msg &amp; data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel. View
            our
            <router-link to="/sms-terms-of-service" class="font-semibold">SMS Policy,&nbsp;</router-link>
            <router-link to="/terms-of-service" class="font-semibold">Terms</router-link>
            &amp;
            <router-link to="/privacy" class="font-semibold">Privacy.</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import utilsJs from 'intl-tel-input/build/js/utils.js'
import merchantFetch from '@/utils/merchantFetch'
import BasicButton from '@/components/Molecules/BasicButton'
import PrimaryButton from '@/components/Molecules/PrimaryButton'
import SecondaryButton from '@/components/Molecules/SecondaryButton'
import { BellIcon, CheckIcon } from '@heroicons/vue/outline'

export default {
  name: 'FormSignup',
  components: {
    BasicButton,
    PrimaryButton,
    SecondaryButton,
    BellIcon,
    CheckIcon
  },
  data: () => ({
    tel: undefined,
    firstName: null,
    lastName: null,
    email: null,
    intlTelInstance: undefined,
    enableSignInButton: false,
    enableCheckoutButton: false,
    requestedToken: false,
    tokenId: null,
    tokenCode: null,
    showTokenVerificationError: false,
    numberPending: false,
    tokenPending: false,
    confirmed: false
  }),
  props: {
    form: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    computedColors() {
      const hsl = this.$store.getters.info?.attributes.color.hsl
      if (hsl)
        return {
          '--main-brand-hue': hsl['0'],
          '--main-brand-saturation-500': `${hsl['1']}%`,
          '--main-brand-lightness-500': `${hsl['2']}%`
        }
    },
    merchant() {
      return this.$store.getters.merchant
    },
    merchantHandle() {
      return this.merchant?.attributes.handle
    },
    formId() {
      return this.form?.id
    },
    checkout() {
      return this.$store.getters.checkout
    },
    progressPills() {
      return [
        { status: 'completed', to: { name: 'Selection' }, label: 'Selection' },
        { status: 'current', to: '', label: 'Information' },
        { status: 'upcoming', to: '', label: 'Checkout' }
      ].filter(Boolean)
    },
    tokenCodeValid() {
      return this.tokenCode?.toString().length >= 5
    },
    bottle() {
      return this.$store.getters.currentBottle
    },
    store() {
      return this.$store.getters.store(this.bottle?.relationships?.store?.data)
    },
    questions() {
      return this.$store.getters.questions(this.store?.relationships?.questions?.data) || []
    },
    canSubmitFirstStep() {
      return this.enableSignInButton
    }
  },
  watch: {
    tokenCode(newToken) {
      if (newToken.toString().length == 5) {
        this.proceed()
      }
    }
  },
  mounted() {
    const intTelInput = document.querySelector('[data-int-tel]')
    this.intlTelInstance = intlTelInput(intTelInput, {
      utilsScript: utilsJs
    })
  },
  methods: {
    checkTelValidity() {
      if (this.intlTelInstance.isValidNumber()) this.enableSignInButton = true
      else this.enableSignInButton = this.intlTelInstance.isValidNumber()
    },
    focusTokenField() {
      setTimeout(() => document.getElementById('token').focus(), 500)
      // document.getElementById('token').focus()
      this.$refs.tokenInputField.click()
      this.$refs.tokenInputField.$el.focus()
    },
    signInViaPhoneCall() {
      this.signIn('phone')
    },
    async signIn(sendMethod = 'sms') {
      const countryCode = this.intlTelInstance.getSelectedCountryData().dialCode
      this.numberPending = true
      const tokensRes = await merchantFetch('customers/session_tokens', {
        method: 'POST',
        body: JSON.stringify({
          token: {
            phone_number: this.intlTelInstance.getNumber().substring(countryCode.length + 1),
            country_code: countryCode,
            next: 'submission',
            situation: 'form',
            form_id: this.formId
          },
          send_method: sendMethod
        })
      })
      this.requestedToken = true
      this.numberPending = false
      this.tokenId = tokensRes.data.id
      this.enableCheckoutButton = true
      this.focusTokenField
    },
    async proceed() {
      if (this.tokenPending) return
      try {
        this.tokenPending = true
        const verifyPromise = await merchantFetch('customers/session_tokens/verifications', {
          method: 'POST',
          body: JSON.stringify({
            session_token: {
              id: this.tokenId,
              token: this.tokenCode
            },
            form_submission: {
              form_id: this.formId
            }
          })
        }).then((x) => {
          localStorage.setItem('token', x.token)
          this.$store.commit('SET_CURRENT_TOKENS', x.token)
          return x.token
        })
        this.tokenPending = false
        this.$store.commit('SET_PROMISE', {
          name: 'token',
          promise: verifyPromise
        })
        this.confirmed = true
      } catch (e) {
        this.tokenPending = false
        this.showTokenVerificationError = true
      }
    }
  }
}
</script>
<style>
.iti {
  @apply w-full;
}
</style>
