<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex px-4 py-6 items-end sm:items-start sm:p-6 z-1000"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="show"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-gray-brand-800 shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-center">
              <div class="flex w-0 flex-1 justify-between">
                <router-link
                  to="/profile"
                  class="w-0 flex-1 text-sm font-medium text-gray-brand-100 tracking-tighter text-left"
                >
                  Logged in as
                  <i>{{ customerDisplayUserName }}</i>
                </router-link>
                <button
                  type="button"
                  class="ml-3 flex-shrink-0 rounded-md text-sm font-bold text-gray-brand-100 hover:text-gray-brand-200 focus:outline-none focus:ring-1 focus:ring-gray-brand-100 focus:ring-offset-2 tracking-tight"
                  @click="logOut()"
                >
                  Log out
                </button>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  class="inline-flex rounded-md text-gray-brand-200 hover:text-gray-brand-300 focus:outline-none focus:ring-1 focus:ring-gray-brand-200 focus:ring-offset-2"
                  @click="dismissBanner()"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { XIcon } from '@heroicons/vue/solid'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'LoggedInAs',
  components: { XIcon },
  computed: {
    ...mapState(['currentTokenCustomer', 'anonymousTokenSecret', 'decodedJWT']),
    ...mapGetters(['customer']),
    show() {
      // NOTE: The whole purpose of this banner is to tell the customer who they're logged in as, in the case that they got logged in by clicking a hot/unique link
      // this.decodedJWT.awcc stands for "Authenticated With Challegne Code"
      //  this.decodedJWT.awcc == true WHEN the end customer logged in by getting a code texted to their phone, and inputting the token
      //  this.decodedJWT.awcc == false WHEN the end customer logged in by clicking a unique link which included a web token
      // This logic is checking that:
      //  1. We have a decodedJWT,
      //  2. the decodedJWT has a key called "awcc",
      //  3. the decodedJWT.awcc is either null or false - meaning the customer got logged in by clicking a link
      //  4. the customer hasn't already dismissed a banner telling them they're logged in as this particular person
      //  5. and lastly, we have a name to display as the "Logged in as __"
      return (
        this.decodedJWT !== null &&
        typeof this.decodedJWT == 'object' &&
        'awcc' in this.decodedJWT &&
        this.decodedJWT?.awcc !== true &&
        !this.hasDismissedLoggedInAsForTokenCustomer &&
        typeof this.customerDisplayUserName == 'string'
      )
    },
    hasDismissedLoggedInAsForTokenCustomer() {
      return this.currentTokenCustomer?.id == this.$store.state.dismissedLoggedInAsFor
    },
    customerID() {
      return this.currentTokenCustomer?.id
    },
    customerDisplayUserName() {
      return this.customer?.attributes?.display_user_name
    }
  },
  watch: {
    customerID(newID, oldID) {
      if (this.isNumber(newID)) {
        this.$store.dispatch('getCustomerInfo')
      }
    },
    show(newShow, oldShow) {
      if (newShow && !oldShow) {
        setTimeout(() => {
          this.dismissBanner()
        }, 15000)
      }
    }
  },
  async mounted() {
    if (this.isNumber(this.customerID)) {
      this.$store.dispatch('getCustomerInfo')
    }
  },
  methods: {
    dismissBanner() {
      this.$store.commit('SET_DISMISSED_LOGGED_IN_FOR', this.currentTokenCustomer?.id)
    },
    goToProfile() {
      this.$router.replace({ name: 'Profile' })
    },
    logOut() {
      localStorage.removeItem('token')
      this.$store.commit('CLEAR_PROMISES')
      window.location.reload()
    },
    isNumber(n) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(n)
    }
  }
}
</script>
