<template>
  <Product
    v-fade-in
    v-for="submittableCartItem in submittableCartItems"
    :submittable-cart-item="submittableCartItem"
    :product="product"
    :category-product-store-pair="categoryProductStorePair"
    :in-cart="inCart"
    :in-upsell="inUpsell"
  />
</template>

<script>
import { ulid } from 'ulid'
import { Product } from '@/components'

export default {
  name: 'CategoryProductStorePairContainer',

  components: {
    Product
  },

  props: ['categoryProductStorePair', 'inCart', 'inUpsell'],

  computed: {
    product() {
      return this.$store.getters.product(this.productRelationshipHash) //this.$store.getters.categories
    },
    productRelationshipHash() {
      return this.categoryProductStorePair?.relationships?.product?.data
    },
    submittableCartItemsForProduct() {
      return this.$store.getters.submittableCartItems(this.categoryProductStorePair.attributes.id).filter((x) => {
        if (this.$store.state.splitDay) return this.$store.state.splitDay === x.fulfillment_group_date
        else return true
      })
    },
    showEmptyProductCard() {
      return this.product && (!this.submittableCartItemsForProduct || this.submittableCartItemsForProduct.length == 0)
    },
    blankSubmittableCartItem() {
      return {
        frontend_key: ulid() + (this.inUpsell ? '-upsell' : ''),
        quantity: 0,
        fulfillment_group_date: this.$store.state.splitDay,
        category_product_store_pair_id: this.categoryProductStorePair?.id,
        cart_item_variant_options_attributes: []
      }
    },
    submittableCartItems() {
      if (!this.inCart && this.showEmptyProductCard) {
        return [this.blankSubmittableCartItem]
      } else if (this.inCart && this.showEmptyProductCard) {
        return []
      } else {
        return this.submittableCartItemsForProduct
      }
    }
  },

  mounted() {},

  methods: {}
}
</script>
