<template>
  <div class="max-w-lg lg:max-w-xl mx-auto">
    <div class="md:mx-0 flex gap-y-3" :class="stores?.length > 1 ? 'flex-wrap justify-between' : 'justify-center'">
      <div v-fade-in v-for="store in stores" :key="store.id" class="w-full">
        <div
          class="relative bg-white w-full rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600 cursor-pointer overflow-hidden"
          @click="(e) => go(e, store.id)"
        >
          <div v-if="storeImage(store)" class="flex-shrink-0 border-b border-gray-brand-300">
            <img class="h-48 w-full object-cover" :src="storeImage(store)" alt="" />
          </div>
          <button class="blockflex-1 bg-white px-6 py-4 flex flex-col justify-between w-full">
            <div class="flex justify-between items-center w-full">
              <div class="flex justify-start space-x-2 items-center">
                <div v-if="store.attributes.locked">
                  <LockOpenIcon v-if="store.attributes.customer_has_access" class="h-5" />
                  <LockClosedIcon v-else class="h-5" />
                </div>
                <p class="font-bold text-xl leading-5 tracking-tighter">
                  {{ store.attributes.store_name }}
                </p>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </div>
            </div>
          </button>
          <div
            v-if="store.attributes.store_description && store.attributes.store_description.length > 0"
            class="flex-1 bg-white px-6 py-4 flex flex-col justify-between border-t border-gray-brand-300"
          >
            <div class="flex-1">
              <p v-linkified:options="{ defaultProtocol: 'https' }" class="text-base text-gray-500 whitespace-pre-line">
                {{ store.attributes.store_description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MerchantNav } from '@/components'
import mapRelationship from '../../utils/mapRelationship'
import mapRelationships from '../../utils/mapRelationships'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { LockClosedIcon, LockOpenIcon } from '@heroicons/vue/outline'

export default {
  name: 'StoreList',
  components: { MerchantNav, LockClosedIcon, LockOpenIcon },
  setup() {
    const store = useStore()
    const router = useRouter()

    const stores = computed(() =>
      store.getters.stores
        .filter((store) => store.attributes.is_active && store.attributes.is_public)
        .sort((a, b) => a.attributes.order - b.attributes.order)
    )

    onMounted(() => {
      store.dispatch('getStores')
    })

    const go = async (e, storeId) => {
      if (e.target.tagName === 'A') return
      window.bottle.hooks.v1.emit('view_item_list', {
        item_list_id: storeId,
        item_list_name: stores.value.find((s) => s.id === storeId).attributes.store_name
      })
      router.push({ name: 'BottleFactory', params: { storeId: storeId } })
    }

    const storeImage = (s) => {
      const attachment = mapRelationship(store.state, s.relationships.attachments.data?.[0])
      return attachment?.attributes.secure_url
    }

    return {
      stores,
      go,
      storeImage
    }
  }
}
</script>
<style>
.linkified {
  @apply text-blue-500;
}
</style>
