<template>
  <div class="flex-grow cursor-pointer" :class="textGrow()">
    <slot></slot>
  </div>
</template>

<script>
import { useExpandProductHero } from '@/composables'

export default {
  name: 'ProductText',

  setup() {
    const { isHeroExpanded } = useExpandProductHero()

    const textGrow = () => (isHeroExpanded.value ? 'w-9/12' : 'w-full')

    return {
      isHeroExpanded,
      textGrow
    }
  }
}
</script>
