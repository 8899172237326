<template>
  <div class="w-full block">
    <div v-if="showSkippedBanner" class="flex md:justify-center mt-6 mx-4 md:mx-2 md:text-center">
      <div class="flex flex-col md:items-center">
        <div class="flex items-center align-middle w-full flex-nowrap justify-start md:justify-center">
          <h2 class="text-2xl font-bold text-gray-brand-900 tracking-tighter align-middle flex items-center leading-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mr-1 hidden md:block"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
            </svg>
            <div class="flex flex-wrap items-center">Skipped</div>
          </h2>
        </div>
        <h3 class="mt-2 text-md text-gray-brand-700 w-full leading-5">
          <span v-if="orderForDate">You're successfully skipped your order for {{ orderForDateFormatted }}.</span>
          <span v-else>This order has been skipped.</span>
        </h3>
        <div class="flex items-center">
          <button
            class="mt-2 relative px-3 py-2 rounded-lg flex items-center border border-brand-800 hover:bg-brand-100 bg-white"
            @click="handleUnskip()"
          >
            <div class="inline-block font-semibold text-xs">Unskip</div>
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="showExpiredBanner" class="flex md:justify-center mt-6 mx-4 md:mx-2 md:text-center">
      <div class="flex flex-col md:items-center">
        <div class="flex items-center align-middle w-full flex-nowrap justify-start md:justify-center">
          <h2 class="text-2xl font-bold text-gray-brand-900 tracking-tighter align-middle flex items-center leading-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mr-1 hidden md:block"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
              />
            </svg>
            <div class="flex flex-wrap items-center">You are past the cutoff</div>
          </h2>
        </div>
        <div class="flex items-center">
          <button
            v-if="openForOrders"
            class="mt-2 relative px-3 py-2 rounded-lg flex items-center border border-brand-800 hover:bg-brand-100 bg-white"
            @click="handleNewBottleCreation()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="mr-1 h-4 w-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
              />
            </svg>
            <div class="inline-block font-semibold text-xs">Order for a different day</div>
          </button>
          <button
            v-else
            class="mt-2 relative px-3 py-2 rounded-lg flex items-center border border-brand-800 hover:bg-brand-100 bg-white"
            @click="goHome()"
          >
            <svg
              class="mr-1 h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
            </svg>
            <div class="inline-block font-semibold text-xs">Go back</div>
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="showConfirmedBanner" class="flex md:justify-center mt-6 mx-4 md:mx-2 md:text-center">
      <div class="flex flex-col md:items-center">
        <div class="flex items-center align-middle w-full flex-nowrap justify-start md:justify-center">
          <h2 class="text-2xl font-bold text-gray-brand-900 tracking-tighter align-middle flex items-center leading-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mr-1 hidden md:block"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div class="flex flex-wrap items-center">
              <span v-if="orderForDate">Your order for {{ orderForDateFormatted }} is confirmed</span>
              <span v-else>Your order is confirmed</span>
            </div>
          </h2>
        </div>
        <h3 class="mt-2 text-md text-gray-brand-700 w-full leading-5">
          <span v-if="autochargeTimeFormatted">
            Edit
            <span v-if="offerSkipButton">or skip</span>
            your order until {{ autochargeTimeFormatted }}. Pay to lock in your cart.
          </span>
        </h3>
        <div class="flex items-center space-x-2">
          <button
            v-if="offerSkipButton"
            class="mt-2 relative px-3 py-2 rounded-lg flex items-center border border-brand-800 hover:bg-brand-100 bg-white"
            @click="handleSkip()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 mr-1"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
            </svg>
            <div class="inline-block font-semibold text-xs">Skip</div>
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="isBottleCycleStoreBottle" class="flex md:justify-center mt-6 mx-4 md:mx-2 md:text-center">
      <div class="flex flex-col md:items-center">
        <div class="flex items-center align-middle w-full flex-nowrap justify-start md:justify-center">
          <h2 class="text-2xl font-bold text-gray-brand-900 tracking-tighter align-middle flex items-center leading-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mr-1 hidden md:block"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
              />
            </svg>
            <div class="flex flex-wrap items-center">
              Your upcoming
              <span v-if="orderForDate">&nbsp;{{ orderForDateFormatted }}&nbsp;</span>
              order
            </div>
          </h2>
        </div>
        <h3 class="mt-2 text-md text-gray-brand-700 w-full leading-5">
          <span v-if="autochargeTimeFormatted">
            Edit
            <span v-if="offerSkipButton">or skip</span>
            your order until {{ autochargeTimeFormatted }}. Pay to lock in your cart.
          </span>
          <span v-else-if="possibleCutoffTimeFormatted">Place your order by {{ possibleCutoffTimeFormatted }}.</span>
        </h3>
        <div class="flex items-center space-x-2">
          <button
            v-if="offerSkipButton"
            class="mt-2 relative px-3 py-2 rounded-lg flex items-center border border-brand-800 hover:bg-brand-100 bg-white"
            @click="handleSkip()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 mr-1"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
            </svg>
            <div class="inline-block font-semibold text-xs">Skip</div>
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="!openForOrders" class="flex md:justify-center mt-6 mx-4 md:mx-2 md:text-center">
      <div class="flex flex-col md:items-center">
        <div class="flex items-center align-middle w-full flex-nowrap justify-start md:justify-center">
          <h2 class="text-2xl font-bold text-gray-brand-900 tracking-tighter align-middle flex items-center leading-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mr-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
              />
            </svg>
            <div class="flex flex-wrap items-center">Ordering is closed</div>
          </h2>
        </div>
        <h3 class="mt-2 text-md text-gray-brand-700 w-full leading-5">
          <span v-if="timeOrderingOpens">Ordering opens {{ formattedTimeOrderingOpens }}.</span>
          <span v-else>These items are not currently available for purchase.</span>
        </h3>
      </div>
    </div>
  </div>
  <!-- /Drop info card -->
</template>

<script>
import mapRelationship from '../../utils/mapRelationship'
let strftime = require('strftime')

export default {
  components: {},
  props: {
    bottle: {
      type: Object
    },
    alwaysShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bottle() {
      return this.$store.getters.currentBottle
    },
    status() {
      return this.bottle?.attributes?.status
    },
    storeID() {
      return this.bottle?.attributes?.store_id
    },
    fulfillmentSlot() {
      return mapRelationship(this.$store.state, this.bottle?.relationships.fulfillment_slot?.data)
    },
    bottleCycleDetails() {
      return this.bottle?.attributes?.bottle_cycle_details
    },
    statusForPlacingOrders() {
      return this.bottle?.attributes?.status_for_placing_orders?.status
    },
    timeOrderingOpens() {
      return this.bottle?.attributes?.status_for_placing_orders?.next_slot_opens
    },
    formattedTimeOrderingOpens() {
      return this.formatTimeOrderingOpens(this.timeOrderingOpens)
    },
    openForOrders() {
      return this.statusForPlacingOrders === 'open'
    },
    showConfirmedBanner() {
      return this.willAutocharge && this.isConfirmed
    },
    showSkippedBanner() {
      return this.status == 'skipped'
    },
    showExpiredBanner() {
      return this.isExpired
    },
    isBottleCycleStoreBottle() {
      return this.bottle?.attributes?.type === 'BottleCycleStoreBottle'
    },
    isConfirmed() {
      return this.bottleCycleDetails?.confirmed
    },
    willAutocharge() {
      return this.bottleCycleDetails?.autocharge
    },
    isExpired() {
      return this.bottleCycleDetails?.expired
    },
    hasCheckoutErrors() {
      return this.bottleCycleDetails?.pending_errors?.length > 0
    },
    offerSkipButton() {
      return this.bottleCycleDetails?.autocharge && !this.bottleCycleDetails?.block_skip
    },

    orderForDate() {
      if (!this.bottle) return null
      if (this.bottle.attributes.is_bottle_cycle) {
        if (this.bottleCycleDetails?.fulfillment_slot_arrives_beginning_estimate) {
          return new Date(this.bottleCycleDetails?.fulfillment_slot_arrives_beginning_estimate)
        } else if (this.bottleCycleDetails?.possible_fulfillment_days.length > 0) {
          return new Date(this.bottleCycleDetails?.possible_fulfillment_days[0] + 'T12:00:00.000Z')
        }
      } else {
        if (this.fulfillmentSlot) {
          return new Date(this.fulfillmentSlot.attributes.arrives_beginning_estimate)
        }
      }
      return null
    },
    orderForDateFormatted() {
      if (this.orderForDate) {
        return strftime('%A, %B %-d', this.orderForDate)
      }
      return null
    },
    possibleCutoffTimeFormatted() {
      const dateTime =
        this.bottleCycleDetails?.possible_cutoff_times?.length > 0
          ? new Date(this.bottleCycleDetails?.possible_cutoff_times?.[0])
          : null
      if (dateTime) {
        return (
          strftime('%A, %B %-d at ', dateTime) + strftime('%-I:%M', dateTime) + strftime('%p', dateTime).toLowerCase()
        )
      }
      return null
    },
    autochargeTimeFormatted() {
      const dateTime = new Date(this.bottleCycleDetails?.autocharge_datetime)
      if (dateTime && this.willAutocharge) {
        return (
          strftime('%A, %B %-d at ', dateTime) + strftime('%-I:%M', dateTime) + strftime('%p', dateTime).toLowerCase()
        )
      }
      return null
    }
  },

  mounted() {},

  methods: {
    async handleSkip() {
      await this.$store.dispatch('skipBottle')
      this.handleStatus()
    },
    async handleUnskip() {
      await this.$store.dispatch('unskipBottle')
      this.handleStatus()
    },
    async handleNewBottleCreation() {
      const data = await this.$store.dispatch('getNewBottleId', this.storeID)
      await this.$store.dispatch('submitOldCartToNewBottle', data?.id)
      this.$router.push({ name: 'Selection', params: { bottleid: data?.id } })
    },
    goHome() {
      this.$router.replace({ name: 'Home' })
    },
    formatTimeOrderingOpens(timeString) {
      const date = new Date(timeString)

      // Convert the UTC time to EDT (New York)
      const dateString = date.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      })

      // Get the timezone abbreviation
      const timeZoneAbbr = this.getTimeZoneAbbr(date)

      return `${dateString}`
    },
    getTimeZoneAbbr(date) {
      const dateString = date.toString()
      const timeZoneAbbr = dateString.match(/\(([A-Za-z\s]+)\)/)
      return timeZoneAbbr ? timeZoneAbbr[1] : ''
    },
    handleStatus() {
      if (this.status == 'skipped') {
        this.$router.replace({ name: 'Notice' })
      } else if (this.status != 'skipped') {
        this.$router.replace({ name: 'Selection' })
      }
    }
  }
}
</script>
