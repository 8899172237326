<template>
  <div>
    <transition
      v-if="showPricingTierProgress"
      enter-active-class="transition-all ease-in duration-200"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-all ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      appear
    >
      <div class="px-5 h-full w-full">
        <div class="py-2 max-w-xl mx-auto w-full">
          <div class="" aria-hidden="true">
            <div
              class="grid text-sm font-semibold tracking-tight text-gray-brand-900 mb-1 w-full"
              :class="{ 'grid-cols-1 sm:grid-cols-2': showLeftPricingTier, 'grid-cols-1': !showLeftPricingTier }"
            >
              <div v-if="showLeftPricingTier" class="hidden sm:block text-brand-500 font-semibold">
                {{ pricingTierTitle }}
              </div>
              <div v-if="nextPricingTier" class="text-center w-full">
                {{ cart?.attributes?.progress_remaining_language_next_pricing_tier }} for
                {{ nextPricingTier?.attributes?.title }}
              </div>
              <div v-else class="text-center">
                {{ pricingTierTitle }}
              </div>
            </div>
            <div class="mb-1 bg-gray-brand-200 overflow-hidden rounded-full border border-brand-800 shadow-solid-xs">
              <div
                class="transition-all ease-out duration-1000 h-4 bg-brand-800"
                :style="'width: ' + cart?.attributes?.progress_toward_next_pricing_tier?.toString() + '%'"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    overrideShow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {},
  setup() {},

  data() {
    return {
      timeout: null,
      showBar: false
    }
  },

  computed: {
    cart() {
      return this.$store.getters.currentCart
    },
    showPricingTierProgress() {
      return (
        (this.overrideShow || this.showBar) &&
        (this.pricingTier?.attributes?.title != 'Default' || this.nextPricingTier)
      )
    },
    pricingTierID() {
      return this.cart?.relationships?.pricing_tier?.data?.id
    },
    pricingTier() {
      return this.pricingTierWithID(this.pricingTierID)
    },
    pricingTierTitle() {
      if (this.pricingTier?.attributes?.is_default && this.pricingTier?.attributes?.title == 'Default') {
        return 'Standard pricing'
      } else {
        return this.pricingTier?.attributes?.title
      }
    },
    nextPricingTierID() {
      return this.cart?.relationships?.next_pricing_tier?.data?.id
    },
    nextPricingTier() {
      return this.pricingTierWithID(this.nextPricingTierID)
    },
    showLeftPricingTier() {
      return false // this.pricingTier?.attributes?.title != 'Default' && this.nextPricingTier
    },
    progressPercentage() {
      return this.cart?.attributes?.progress_toward_next_pricing_tier
    }
  },

  watch: {
    pricingTierID: function (newPricingTierID, oldPricingTierID) {
      let newPricingTier = this.pricingTierWithID(newPricingTierID)
      let oldPricingTier = this.pricingTierWithID(oldPricingTierID)
      if (
        oldPricingTier &&
        newPricingTier &&
        (newPricingTier.attributes?.rate_off > oldPricingTier.attributes?.rate_off ||
          (newPricingTier.attributes?.rate_off == oldPricingTier.attributes?.rate_off &&
            newPricingTier.attributes?.free_shipping))
      ) {
        this.flashPricingTier()
      }
    },
    progressPercentage: function (oldProgressPercentage, newProgressPercentage) {
      this.flashPricingTier()
    }
  },
  async mounted() {
    if (this.nextPricingTier) {
      // this.flashPricingTier()
    }
  },

  methods: {
    pricingTierWithID(pricingTierID) {
      return this.$store.getters.pricingTierWithID(pricingTierID)
    },
    flashPricingTier() {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
      this.showBar = true
      this.timeout = setTimeout(() => {
        this.showBar = false
      }, 6000)
    }
  }
}
</script>
