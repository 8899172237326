<template>
  <div></div>
</template>

<script>
import merchantFetch from '@/utils/merchantFetch'
import VueJwtDecode from 'vue-jwt-decode'

export default {
  components: {},
  data: () => ({
    response: null
  }),
  computed: {
    uniqueIdentifier() {
      return this.$route.params.id
    },
    shouldSetToken() {
      return this.token
    },
    token() {
      return this.response?.token
    },
    objectID() {
      return this.response?.object_id
    },
    isBottleLink() {
      return this.response?.link_type == 'BottleLink'
    },
    isProfileLink() {
      return this.response?.link_type == 'ProfileLink'
    },
    isIssuedGiftCardLink() {
      return this.response?.link_type == 'IssuedGiftCardLink'
    }
  },
  mounted() {
    this.handleLink()
  },
  methods: {
    async handleLink() {
      const tokensRes = await merchantFetch('links/' + this.uniqueIdentifier, {
        method: 'GET'
      })
      this.response = tokensRes
      if (this.shouldSetToken) {
        this.setToken()
      }
      this.redirect()
    },
    setToken() {
      // The JWT either has its awcc (Authenticated With Challenge Code) == true || false;
      // If the link's JWT.awcc == false, but the customer is already logged in either JWT.awcc == true, we want to preserve the JWT.awcc == true
      // So if we're not swapping out the customer, we always just want to leave the "logged in" JWT that the customer has in their browser
      if (VueJwtDecode.decode(this.token)?.customer?.id != this.$store.state.currentTokenCustomer?.id) {
        localStorage.setItem('token', this.token)
        this.$store.commit('SET_CURRENT_TOKENS', this.token)
      }
    },
    redirect() {
      if (this.isBottleLink) {
        this.$router.replace({ name: 'Selection', params: { bottleid: this.objectID } })
      } else if (this.isProfileLink) {
        this.$router.replace({ name: 'Profile' })
      } else if (this.isIssuedGiftCardLink) {
        this.$router.replace({ name: 'IssuedGiftCard', params: { code: this.response?.account_credit_code } })
      } else {
        this.$router.replace({ name: 'Home' })
      }
    }
  }
}
</script>
