<template>
  <div class="bg-gray-brand-100 md:pt-4 pb-10" :style="computedColors">
    <!-- navbar -->
    <div class="fixed w-full top-0 z-10">
      <div class="w-full">
        <MerchantNav :disable-hide-on-scroll="true" :empty-middle="isBottleLogin">
          <div v-if="isBottleLogin">
            <ProgressPills :pills="progressPills" />
          </div>
        </MerchantNav>
      </div>
    </div>

    <!-- main body -->
    <div
      class="sm:grid sm:grid-cols-12 py-16 sm:py-10 sm:py-18 sm:px-8 lg:px-0"
      :class="{ 'sm:grid-cols-12': isBottleLogin, 'sm:grid-cols-11': !isBottleLogin }"
    >
      <!-- Cards -->
      <!-- <div class="w-full md:col-span-5 md:col-start-2 mx-auto h-full"> -->
      <div
        :class="{
          'sm:col-span-7 lg:col-span-6 lg:col-start-2 xl:col-span-5 xl:col-start-3 sm:mr-4': isBottleLogin,
          'sm:col-span-9 sm:col-start-2 lg:col-span-7 lg:col-start-3 xl:col-span-5 xl:col-start-4': !isBottleLogin
        }"
      >
        <div class="px-2 mb-8" :class="{ 'mt-12 sm:mt-14': isBottleLogin, 'mt-12': !isBottleLogin }">
          <div class="flex justify-between items-center mb-4 sm:mb-6">
            <div class="flex justify-start items-center">
              <div
                class="text-xl bg-gray-brand-300 font-bold text-gray-brand-700 w-10 h-10 p-1 flex justify-center items-center rounded-lg"
              >
                1
              </div>
              <div class="text-2xl font-bold text-gray-brand-900 tracking-tighter ml-3">
                <span v-if="signupMode">Information</span>
                <span v-else>Log in</span>
              </div>
            </div>
            <div>
              <SecondaryButton v-if="signupMode" :size="'md'" @click="signupMode = false">
                Have an account?
                <b>Log in</b>
              </SecondaryButton>
              <SecondaryButton v-else :size="'md'" @click="signupMode = true">
                Need an account?
                <b>Sign up</b>
              </SecondaryButton>
            </div>
          </div>
          <div class="rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600 overflow-hidden">
            <div class="bg-white rounded-md overflow-hidden">
              <div class="p-4 pb-0">
                <div v-if="signupMode && shouldAskForName" class="mb-4">
                  <label for="firstName" class="mb-0.5 block">
                    <div
                      class="leading-5 form-inputblock mt-1 text-sm text-gray-brand-700 tracking-tight font-semibold"
                    >
                      Your name
                    </div>
                  </label>
                  <div class="flex justify-right w-full">
                    <input
                      id="firstName"
                      v-model="firstName"
                      type="text"
                      autocomplete="given-name"
                      name="firstName"
                      aria-label="First name"
                      placeholder="First name"
                      class="form-input shadow-sm p-2 rounded-md flex-grow mr-2 w-1/2"
                    />
                    <input
                      id="lastName"
                      v-model="lastName"
                      type="text"
                      autocomplete="family-name"
                      name="lastName"
                      aria-label="Last name"
                      placeholder="Last name"
                      class="form-input shadow-sm p-2 rounded-md flex-grow w-1/2"
                    />
                  </div>
                </div>
                <div v-if="signupMode && shouldAskForEmail" class="mb-4">
                  <label for="email" class="mb-0.5 block">
                    <div
                      class="leading-5 form-inputblock mt-1 text-sm text-gray-brand-700 tracking-tight font-semibold"
                    >
                      Email
                    </div>
                  </label>
                  <div class="flex justify-right w-full">
                    <input
                      id="email"
                      v-model="email"
                      type="email"
                      autocomplete="email"
                      name="email"
                      aria-label="Your email"
                      placeholder="Your email"
                      class="form-input shadow-sm p-2 rounded-md flex-grow w-full"
                    />
                  </div>
                </div>
                <fieldset>
                  <label for="phone" class="pb-0.5 block">
                    <div
                      class="leading-5 form-inputblock mt-1 text-sm text-gray-brand-700 tracking-tight font-semibold"
                    >
                      Mobile number
                    </div>
                  </label>
                  <div class="block">
                    <input
                      id="phone"
                      model="tel"
                      type="tel"
                      data-int-tel
                      class="form-input shadow-sm w-full p-2 rounded-md"
                      @keyup="checkTelValidity"
                      @keyup.enter="signIn"
                    />
                  </div>
                </fieldset>
              </div>
              <div class="p-4 py-4 mt-1">
                <PrimaryButton
                  class="w-full"
                  :size="'xl'"
                  :justify="'justify-center'"
                  :active="canSubmitFirstStep"
                  :streaking="enableSignInButton"
                  :is-loading="numberPending"
                  @click="signIn"
                >
                  Next
                  <template #right-icon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-1 w-5 h-5 fill-current"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 13l-5 5m0 0l-5-5m5 5V6"
                      />
                    </svg>
                  </template>
                </PrimaryButton>
              </div>
            </div>
            <div v-if="signupModalText && signupMode" class="flex">
              <div class="w-full border-t bg-brand-100 text-brand-900">
                <div
                  ref="notice"
                  v-linkified:options="{ defaultProtocol: 'https' }"
                  class="leading-5 pt-4 pb-4 px-6 scrolling-touch text-sm text-center whitespace-pre-line"
                >
                  {{ signupModalText }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="w-full text-brand-800">
              <div class="leading-4 sm:leading-5 pt-4 px-5 scrolling-touch text-xs text-left">
                By creating an account, you give @{{ merchantHandle }} permission to text and email you. You agree to
                receive recurring automated marketing text messages at the phone number provided. Consent is not a
                condition to purchase. Msg &amp; data rates may apply. Msg frequency varies. Reply HELP for help and
                STOP to cancel. View our
                <router-link to="/sms-terms-of-service" class="font-semibold">SMS Policy,&nbsp;</router-link>
                <router-link to="/terms-of-service" class="font-semibold">Terms</router-link>
                &amp;
                <router-link to="/privacy" class="font-semibold">Privacy.</router-link>
              </div>
            </div>
          </div>
        </div>
        <div
          class="px-2 mt-8 sm:mt-10 mb-8"
          :class="{
            'opacity-100 cursor-pointer': enableSignInButton,
            'opacity-50 cursor-not-allowed': !enableSignInButton,
            'hidden opacity-0': !requestedToken
          }"
        >
          <div class="flex justify-start items-center mb-4 sm:mb-6">
            <div
              class="text-xl bg-gray-brand-300 font-bold text-gray-brand-700 w-10 h-10 p-1 flex justify-center items-center rounded-lg"
            >
              2
            </div>
            <div class="text-2xl font-bold text-gray-brand-900 tracking-tighter ml-3">Authenticate</div>
          </div>
          <div class="rounded-lg shadow-solid-xs border shadow-gray-brand-600 border-gray-brand-600 overflow-hidden">
            <div class="mt-2 bg-white rounded-md overflow-hidden">
              <div class="p-4 pb-1">
                <fieldset>
                  <label for="token" class="mb-2 block">
                    <div
                      class="leading-5 form-inputblock mt-1 text-sm text-gray-brand-700 tracking-tight font-semibold"
                    >
                      The token we just texted you
                    </div>
                  </label>
                  <div class="block">
                    <input
                      id="token"
                      ref="tokenInputField"
                      v-model="tokenCode"
                      type="numeric"
                      pattern="[0-9]*"
                      autocomplete="one-time-code"
                      class="form-input shadow-sm w-full p-2 rounded-md"
                      placeholder="00000"
                      @keyup.enter="proceed"
                    />
                  </div>
                </fieldset>
              </div>
              <div v-if="showTokenVerificationError" class="px-4 py-1 w-full">
                <div class="text-brand-900 font-medium text-sm text-center w-full">
                  The token you submitted isn't valid.
                </div>
              </div>
              <div class="p-4 pt-0">
                <PrimaryButton
                  class="w-full mt-2"
                  :size="'xl'"
                  :justify="'justify-center'"
                  :active="enableCheckoutButton && tokenCodeValid"
                  :streaking="enableCheckoutButton && tokenCodeValid"
                  :is-loading="tokenPending"
                  @click="proceed"
                >
                  {{ proceedLanguage }}
                  <template #right-icon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-1 w-5 h-5 fill-current"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </template>
                </PrimaryButton>

                <SecondaryButton
                  class="w-full mt-3"
                  :size="'xl'"
                  :is-loading="numberPending"
                  :active="canSubmitFirstStep"
                  @click="signIn"
                >
                  Didn't receive the token?
                  <span class="font-bold">Resend code</span>
                </SecondaryButton>
                <SecondaryButton
                  class="w-full mt-3"
                  :size="'xl'"
                  :is-loading="numberPending"
                  :active="canSubmitFirstStep"
                  @click="signInViaPhoneCall"
                >
                  Not getting the text?
                  <span class="font-bold">Get a call</span>
                  <template #right-icon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                      />
                    </svg>
                  </template>
                </SecondaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->

      <div v-if="isBottleLogin" class="w-full sm:col-span-5 sm:col-start-8 lg:col-span-4 lg:col-start-8 mx-auto h-full">
        <div class="px-2 max-w-5xl sm:mt-10 mb-8 lg:max-w-md">
          <div class="relative py-5 mb-2">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center">
              <span class="bg-gray-brand-100 px-3 text-2xl font-bold text-gray-brand-900 tracking-tighter">
                Order Summary
              </span>
            </div>
          </div>
          <OrderSummarySection
            :list="$store.getters.cartItems"
            :checkout-attributes="checkout?.attributes"
            has-discount-code-box
          />
        </div>
      </div>
    </div>
    <!-- /Cards -->
  </div>
</template>

<script>
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import utilsJs from 'intl-tel-input/build/js/utils.js'
import merchantFetch from '@/utils/merchantFetch'
import { MerchantNav } from '@/components'
import BasicButton from '@/components/Molecules/BasicButton'
import PrimaryButton from '@/components/Molecules/PrimaryButton'
import SecondaryButton from '@/components/Molecules/SecondaryButton'
import ProgressPills from '@/components/Merchant/ProgressPills'
import OrderSummarySection from '@/components/Checkout/OrderSummarySection'

export default {
  components: {
    MerchantNav,
    BasicButton,
    ProgressPills,
    PrimaryButton,
    SecondaryButton,
    OrderSummarySection
  },
  data: () => ({
    tel: undefined,
    firstName: null,
    lastName: null,
    email: null,
    intlTelInstance: undefined,
    enableSignInButton: false,
    enableCheckoutButton: false,
    requestedToken: false,
    tokenId: null,
    tokenCode: null,
    showTokenVerificationError: false,
    numberPending: false,
    tokenPending: false,
    signupMode: false
  }),
  computed: {
    computedColors() {
      const hsl = this.$store.getters.info?.attributes.color.hsl
      if (hsl)
        return {
          '--main-brand-hue': hsl['0'],
          '--main-brand-saturation-500': `${hsl['1']}%`,
          '--main-brand-lightness-500': `${hsl['2']}%`
        }
    },
    merchant() {
      return this.$store.getters.merchant
    },
    merchantHandle() {
      return this.merchant?.attributes.handle
    },
    bottleId() {
      return this.$store.getters.bottleId
    },
    checkout() {
      return this.$store.getters.checkout
    },
    isBottleLogin() {
      return this.bottleId
    },
    signinMode() {
      return !this.signupMode
    },
    showTokenBox() {
      return this.signinMode || this.requestedToken
    },
    paramNext() {
      return this.isBottleLogin ? 'pay' : 'account'
    },
    paramSituation() {
      return this.isBottleLogin ? 'checkout' : 'login'
    },
    proceedLanguage() {
      return this.isBottleLogin ? 'Proceed to Checkout' : 'Login'
    },
    progressPills() {
      return [
        { status: 'completed', to: { name: 'Selection' }, label: 'Selection' },
        { status: 'current', to: '', label: 'Information' },
        { status: 'upcoming', to: '', label: 'Checkout' }
      ].filter(Boolean)
    },
    tokenCodeValid() {
      return this.tokenCode?.toString().length >= 5
    },
    bottle() {
      return this.$store.getters.currentBottle
    },
    store() {
      return this.$store.getters.store(this.bottle?.relationships?.store?.data)
    },
    signupModalText() {
      return this.store?.attributes?.signup_modal_text
    },
    questions() {
      return this.$store.getters.questions(this.store?.relationships?.questions?.data) || []
    },
    shouldAskForName() {
      // if checkout_questions contains a question with question.attributes.type === 'name' || 'first_name'
      return (
        !this.isBottleLogin ||
        this.questions.some((question) => {
          return question.attributes.type === 'NameQuestion' || question.attributes.type === 'FirstAndLastNameQuestion'
        })
      )
    },
    shouldAskForEmail() {
      // if checkout_questions contains a question with question.attributes.type === 'name' || 'first_name'
      return (
        !this.isBottleLogin ||
        this.questions.some((question) => {
          return question.attributes.type === 'EmailQuestion'
        })
      )
    },
    isFirstNameValid() {
      return typeof this.firstName === 'string' && this.firstName.trim().length > 0
    },
    isLastNameValid() {
      return typeof this.lastName === 'string' && this.lastName.trim().length > 0
    },
    isEmailValid() {
      const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      return emailRegEx.test(this.email)
    },
    canSubmitFirstStep() {
      if (this.signupMode) {
        return (
          this.enableSignInButton &&
          (!this.shouldAskForName || (this.isFirstNameValid && this.isLastNameValid)) &&
          (!this.shouldAskForEmail || this.isEmailValid)
        )
      } else {
        return this.enableSignInButton
      }
    }
  },
  watch: {
    tokenCode(newToken) {
      if (newToken.toString().length == 5) {
        this.proceed()
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('getInfo')
    if (this.isBottleLogin) {
      this.mountedWithBottle()
      this.signupMode = true
    } else {
      this.mountedWithoutBottle()
    }
    const intTelInput = document.querySelector('[data-int-tel]')
    this.intlTelInstance = intlTelInput(intTelInput, {
      utilsScript: utilsJs
    })
  },
  methods: {
    checkTelValidity() {
      if (this.intlTelInstance.isValidNumber()) this.enableSignInButton = true
      else this.enableSignInButton = this.intlTelInstance.isValidNumber()
    },
    focusTokenField() {
      setTimeout(() => document.getElementById('token').focus(), 500)
      // document.getElementById('token').focus()
      this.$refs.tokenInputField.click()
      this.$refs.tokenInputField.$el.focus()
    },
    signInViaPhoneCall() {
      this.signIn('phone')
    },
    async signIn(sendMethod = 'sms') {
      const countryCode = this.intlTelInstance.getSelectedCountryData().dialCode
      this.numberPending = true
      const tokensRes = await merchantFetch('customers/session_tokens', {
        method: 'POST',
        body: JSON.stringify({
          token: {
            phone_number: this.intlTelInstance.getNumber().substring(countryCode.length + 1),
            country_code: countryCode,
            next: this.paramNext,
            situation: this.paramSituation,
            bottle_id: this.bottleId
          },
          send_method: sendMethod
        })
      })
      this.requestedToken = true
      this.numberPending = false
      this.tokenId = tokensRes.data.id
      this.enableCheckoutButton = true
      setTimeout(() => {
        this.scrollToTokenArea()
      }, 10)
      this.focusTokenField
    },
    scrollToTokenArea() {
      const id = 'token'
      const yOffset = -200
      const element = document.getElementById(id)
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
    },
    async proceed() {
      if (this.tokenPending) return
      try {
        this.tokenPending = true
        const verifyPromise = await merchantFetch('customers/session_tokens/verifications', {
          method: 'POST',
          body: JSON.stringify({
            session_token: {
              id: this.tokenId,
              token: this.tokenCode
            },
            ...(this.signupMode
              ? {
                  additional_info: {
                    name: this.firstName + ' ' + this.lastName,
                    email: this.email
                  }
                }
              : {})
          })
        }).then((x) => {
          localStorage.setItem('token', x.token)
          this.$store.commit('SET_CURRENT_TOKENS', x.token)
          return x.token
        })
        this.tokenPending = false
        this.$store.commit('SET_PROMISE', {
          name: 'token',
          promise: verifyPromise
        })
        window.bottle.hooks.v1.emit('login')
        if (this.isBottleLogin) {
          this.$router.replace({ name: 'Checkout' })
        } else {
          this.$router.replace({ name: 'Profile' })
        }
      } catch (e) {
        this.tokenPending = false
        this.showTokenVerificationError = true
      }
    },
    async mountedWithBottle() {
      if (!this.$store.getters.bottleId || this.$store.getters.bottleId == 'undefined') {
        this.$router.push({ name: 'NakedLogin' })
        return
      }
      await this.$store.dispatch('getBottle', {
        includes: [
          'cart',
          'cart.cart_items',
          'cart.cart_items.cart_item_variant_options',
          'cart.cart_items.product',
          'cart.membership_tier',
          'cart.pricing_tier',
          'cart.applied_discount_codes',
          'fulfillment_slot',
          'fulfillment_slot.fulfillment_method',
          'merchant',
          'customer',
          'membership_tier',
          'distribution_list',
          'checkout',
          'store.questions'
        ]
      })
      if (this.$store.getters.ownsBottle(this.$store.getters.currentBottle)) {
        this.$router.replace({ name: 'Checkout' })
      } else if (!this.$store.getters.authorizedForBottle(this.$store.getters.currentBottle)) {
        this.$router.replace({ name: 'Home' })
      }
    },
    mountedWithoutBottle() {
      if (this.$store.getters.isLoggedIn) {
        this.$router.replace({ name: 'Profile' })
      }
    }
  }
}
</script>
<style>
.iti {
  @apply w-full;
}
</style>
