<template>
  <section v-if="splitEnabled">
    <div class="transition-all text-sm border-t px-3 py-3 justify-between cursor-pointer" @click="openTray = !openTray">
      <button class="transition-all uppercase tracking-wider text-left inline-flex items-center flex-shrink">
        Fulfillment date
        <div class="inline-block ml-1 w-4 h-4 flex-shrink-0">
          <svg class="transition-all" v-if="!openTray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
          <svg class="transition-all" v-if="openTray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M10.707 7.05L10 6.343 4.343 12l1.414 1.414L10 9.172l4.243 4.242L15.657 12z" />
          </svg>
        </div>
      </button>
      <div
        v-if="!openTray && submittableCartItem.quantity"
        class="flex flex-shrink-0 min-w-[5em] items-center justify-beginning gap-2 mt-2"
      >
        <div
          class="bg-gray-brand-200 text-gray-brand-700 rounded-lg text-xs font-manrope font-semibold tracking-tight px-2 py-1"
        >
          {{ weekdayMonthDay(selectedDay) }}
        </div>
      </div>

      <div v-if="openTray" class="pt-3 pb-1 flex flex-wrap gap-2">
        <div
          v-for="date in possibleDays"
          @click="selectDay(date)"
          class="relative bg-white rounded-md shadow-sm flex items-center justify-center w-24 flex-none h-20 cursor-pointer text-gray-brand-900 hover:bg-gray-brand-100 focus:outline-none border transition-all hover:transform hover:scale-105"
          :class="{ 'ring-2 ring-gray-brand-700 bg-gray-brand-200 text-brand-800': selectedDay === date }"
        >
          <div class="flex flex-col items-center p-3 text-gray-900">
            <span class="block text-xs font-medium text-center">{{ weekday(date) }}</span>
            <span class="text-xl font-bold">{{ day(date) }}</span>
            <span class="block text-xs font-medium text-center">{{ month(date) }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue'
import { serverDayToDateObject } from '@/helpers/dates'
import { useStore } from 'vuex'
import _ from 'lodash'

const props = defineProps(['product', 'submittableCartItem'])

const store = useStore()

const splitEnabled = computed(() => store.getters.fulfillmentSlot?.attributes.allow_day_splits)
const slotDay = computed(() => store.getters.fulfillmentSlot?.attributes.arrives_beginning_estimate_date)
const selectedDay = computed(() => props.submittableCartItem.fulfillment_group_date || slotDay.value)
const possibleDays = computed(() =>
  store.getters.fulfillmentSlot?.attributes.possible_cart_item_fulfillment_dates.sort()
)

const openTray = ref(false)

const selectDay = (day) => {
  const item = _.cloneDeep(props.submittableCartItem)
  if (item.quantity === 0) item.quantity = 1
  item.fulfillment_group_date = day
  store.dispatch('updateSubmittableCart', {
    submittableCartItem: item
  })
}

const weekday = (day) =>
  new Intl.DateTimeFormat('en-US', {
    weekday: 'long'
  }).format(serverDayToDateObject(day))

const day = (day) =>
  new Intl.DateTimeFormat('en-US', {
    day: 'numeric'
  }).format(serverDayToDateObject(day))

const month = (day) =>
  new Intl.DateTimeFormat('en-US', {
    month: 'long'
  }).format(serverDayToDateObject(day))

const weekdayMonthDay = (day) =>
  new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  }).format(serverDayToDateObject(day))
</script>
